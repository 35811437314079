import styled from 'styled-components';
import { Col, Row } from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import { typography, links } from '../../styles/constants';
import PropTypes from 'prop-types';

export const HeaderCol = styled(Col)`
  padding: 0px 8px 0px 16px;
  text-align: left;
  font-size: ${typography.fontSize.smtext};
  font-weight: 400;
`;

let ParHeaderCol = styled(HeaderCol)`
  padding: 0;
  ${(props) => props.clickable && 'cursor: pointer;'}
  &:nth-child(2) {
    text-align: left;
    padding-left: 48px;
  }
`;

ParHeaderCol.propTypes = {
  clickable: PropTypes.bool,
};
export default { ParHeaderCol };
export const HeaderRow = styled(Row)`
  max-height: 60px;
  text-align: left;
  padding: 5px;
  font-size: ${typography.fontSize.smtext};
`;

export const ListCol = styled(Col)`
  min-height: 50px;
  text-align: left;
  padding: 0px;
  border: 1px solid rgba(165, 201, 255, 1);
  border-radius: 6px;
  margin-top: 10px;
`;

export const ItemRow = styled(Row)``;
export const ItemCol = styled(Col)`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  font-size: ${typography.fontSize.smtext};
  .match-ready {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
`;

export const BoldH6 = styled.h6`
  font-weight: bold;
`;

export const ItemImageCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
`;
export const LocationDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
`;

export const LocationInfo = styled.div`
  display: inline;
  font-size: ${typography.fontSize.smtext};
  font-weight: regular;
`;

export const HousingImg = styled.img`
  width: 123px;
  height: 94px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
`;

export const NotificationFontAwesome = styled(FontAwesome)`
  color: ${(props) => (props.complete ? 'green' : 'orange')};
  margin-right: 4px;
`;
export const NotificationSpan = styled.span`
  font-size: ${typography.fontSize.smtext};
  font-weight: 300;
`;

export const ArrowAwesome = styled(FontAwesome)`
  font-size: 14px;
  color: #5bb8b4;
`;

export const GridContainer = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  width: 100%;
  ${(props) =>
    props.$gridTemplateColumns
      ? `grid-template-columns:${props.$gridTemplateColumns}`
      : 'grid-template-columns: 2.5fr 1fr .75fr 2.5fr'};
`;

export const GridItem = styled.div`
  & .secondary-cta {
    justify-self: center;
  }
  &.column-header-1 {
    grid-column: 2/3;
  }
  &.column-header-2 {
    grid-column: 3/4;
  }
  &.column-header-3 {
    grid-column: 4/5;
    ${(props) => props.$gridTemplateColumns && `display: grid;`}
    ${(props) => props.$gridTemplateColumns && `grid-template-columns: ${props.$gridTemplateColumns};`}
  }
  & .hold-deadline-add {
    text-decoration: none;
    font-weight: bold;
    color: ${links.listLink};
  }
`;
