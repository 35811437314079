import { gql } from '@apollo/client';
export const GetInitialEmailForUnit = gql`
  query GetInitialEmailForUnit($id: ID!) {
    GetInitialEmailForUnit(id: $id) {
      emailTemplate
      PAR_NAME
      HMISID
      PAR_AGE
      UNIT_ADDRESS
      UNIT_NUMBER
      PRICE_RANGE
      ADVOCATE_NAME
      UNIT_IMAGE_URL
      UNIT_MARKETING_URL
      MPO_NAME
      MPO_EMAIL
      LOCATOR_NAME
      INTERESTED_URL
      NOT_INTERESTED_URL
    }
  }
`;

export const GetMatchingPARsForUnit = gql`
  query GetMatchingPARsForUnit($id: ID!) {
    HousingUnit(id: $id) {
      id
      name
      location
      unitNumber
      allowsEvictions
      allowsConvictions
      allowsSexOffenders
      allowsIndoorSmoking
      numNotifiedParticipants
      accessibilitiesWithDegree {
        degree
        Accessibility {
          id
          ADA
          detail
        }
      }
      bedrooms {
        id
        rent
        squareFeet
        amenities {
          id
          type
        }
      }
      region {
        id
        name
      }
      accepts {
        id
        type
      }
      allows {
        id
        size
        species
      }
      SHOWS_rel(orderBy: order_asc) {
        Image {
          id
          url
          publicId
        }
        order
      }
      participantsWithMetNeeds {
        id
        name
        age
        status {
          id
          type
        }
        gender {
          id
          name
        }
        currentIncome
        income
        HMISID
        email
        contactEmail
        smokingIndoorsNeeded
        marijuanaSmokingIndoors
        tobaccoSmokingIndoors
        tobaccoPreference
        marijuanaPreference
        accessibilitiesWithDegree {
          degree
          Accessibility {
            id
            ADA
            detail
          }
        }
        assignedHousingUnits {
          id
          name
        }
        notifiedHousingUnits {
          id
        }
        pets {
          id
          size
          species
        }
        accessibility {
          id
          ADA
          detail
        }
        vouchers {
          id
          type
        }
        preferredRegions {
          id
          name
        }
        interestedIn {
          id
        }
        disinterestedIn {
          id
        }
        createdAt {
          day
          month
          year
          timezone
        }
        updatedAt {
          day
          month
          year
          timezone
        }
        ADVOCATES_FOR_rel(orderBy: createdAt_desc, first: 1) {
          Advocate {
            name
            id
            organization {
              name
            }
          }
        }
      }
      matchingProcesses {
        id
        NOTIFIED_rel {
          createdAt {
            formatted
            month
            day
          }
          Participant {
            id
            HMISID
            name
          }
        }
      }
      interestedParticipants {
        id
        name
      }
      disinterestedParticipants {
        id
        name
      }
    }
  }
`;
