import { physicalAccessibilityOptionsDict } from '../components/HousingUnitCreation/sections/AccessibilityByDegreesDict';

export const checkADADegree = (data) => {
  const type = data?.__typename;
  const hasAccessibilityInfo = !!data?.accessibilitiesWithDegree?.length;
  let degree;
  let degreeLabel = '';

  if (hasAccessibilityInfo) {
    if (degree === null && type === 'Participant') {
      degree = 100;
    } else if (degree === null && type === 'HousingUnit') {
      degree = 1;
    } else {
      degree = data.accessibilitiesWithDegree[0]?.degree;
    }
  } else if (!hasAccessibilityInfo) {
    degree = 0;
  }

  if (degree === 1) {
    degreeLabel = 'Unknown';
  } else {
    degreeLabel = physicalAccessibilityOptionsDict?.[degree]?.[type];
  }

  return {
    degree,
    degreeLabel,
  };
};
