import styled from 'styled-components';
import FontAwesome from 'react-fontawesome';
import { colors, typography } from './constants';

export const RedFontAwesome = styled(FontAwesome)`
  color: ${colors.red};
`;

export const GreenFontAwesome = styled(FontAwesome)`
  color: ${colors.green};
`;
export const GrayFontAwesome = styled(FontAwesome)`
  color: ${colors.grayText};
`;

export const Text = styled.div`
  color: ${(props) => (props.color ? props.color : typography.fontColor.text)};
  display: ${(props) => (props.inline ? 'inline' : 'block')};
  font-weight: ${(props) => (props.bold ? '700' : props.thin ? '300' : '400')};
  margin: ${(props) => {
    return `${props.marginTop || 0}px ${props.marginRight || 0}px ${props.marginBottom || 0}px ${
      props.marginLeft || 0
    }px`;
  }};
`;

export const XSmallText = styled(Text)`
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : typography.fontSize.xsmtext)};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : typography.fontSize.xsmtext)};
`;

export const SmallText = styled(Text)`
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : typography.fontSize.smtext)};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : typography.fontSize.smtext)};
`;

export const MediumText = styled(Text)`
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : typography.fontSize.mdtext)};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : typography.fontSize.mdtext)};
`;

export const XMediumText = styled(Text)`
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : typography.fontSize.lgtext)};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : typography.fontSize.xsmtitle)};
`;
export const MediumLargeText = styled(Text)`
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : typography.fontSize.xsmtitle)};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : typography.fontSize.xsmtitle)};
`;

export const LargeText = styled(Text)`
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : typography.fontSize.smtitle)};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : typography.fontSize.smtitle)};
`;

export const XLargeText = styled(Text)`
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : typography.fontSize.title)};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : typography.fontSize.title)};
`;
