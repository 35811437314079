import { gql } from '@apollo/client';

export const CreateBedroom = gql`
  mutation CreateBedroom($housingUnit: ID!, $rent: Int!, $squareFeet: Int) {
    CreateBedroom(housingUnit: $housingUnit, rent: $rent, squareFeet: $squareFeet) {
      id
    }
  }
`;

export const DeleteBedroom = gql`
  mutation DeleteBedroom($id: ID!) {
    DeleteBedroom(id: $id) {
      id
    }
  }
`;

export const AddUtilityToHousingUnit = gql`
  mutation AddUtilityToHousingUnit($from: _HousingUnitInput!, $to: _UtilityInput!) {
    AddUtilityHousingUnits(from: $from, to: $to) {
      from {
        id
      }
      to {
        id
      }
    }
  }
`;

export const RemoveUtilityFromHousingUnit = gql`
  mutation RemoveUtilityFromHousingUnit($from: _HousingUnitInput!, $to: _UtilityInput!) {
    RemoveUtilityHousingUnits(from: $from, to: $to) {
      from {
        id
      }
      to {
        id
      }
    }
  }
`;

export const AddAmenityToHousingUnit = gql`
  mutation AddAmenityToHousingUnit($from: _HousingUnitInput!, $to: _HousingUnitAmenityInput!) {
    AddHousingUnitAmenityHousingUnits(from: $from, to: $to) {
      from {
        id
      }
      to {
        id
      }
    }
  }
`;

export const RemoveAmenityFromHousingUnit = gql`
  mutation RemoveAmenityToHousingUnit($from: _HousingUnitInput!, $to: _HousingUnitAmenityInput!) {
    RemoveHousingUnitAmenityHousingUnits(from: $from, to: $to) {
      from {
        id
      }
      to {
        id
      }
    }
  }
`;

export const UpdateHousingUnit = gql`
  mutation UpdateHousingUnitBathApp(
    $id: ID!
    $totalBathrooms: Float
    $totalBedrooms: Int
    $applicationFee: Int
    $updatedAt: _Neo4jDateTimeInput
  ) {
    UpdateHousingUnit(
      id: $id
      totalBathrooms: $totalBathrooms
      totalBedrooms: $totalBedrooms
      applicationFee: $applicationFee
      updatedAt: $updatedAt
    ) {
      id
    }
  }
`;

export const GetOptionsHURent = gql`
  query GetOptionsHURent($id: ID!) {
    HousingUnit(id: $id) {
      bedrooms {
        id
        rent
        squareFeet
        amenities {
          id
        }
        createdAt {
          formatted
        }
        updatedAt {
          formatted
        }
      }
      totalBathrooms
      totalBedrooms
      housingUnitAmenities {
        id
        type
      }
      utilities {
        id
        type
      }
      applicationFee
      LISTS_rel {
        Listing {
          id
          listingUrl
        }
        updatedAt {
          formatted
        }
      }
      SHOWS_rel(orderBy: order_asc) {
        Image {
          id
          url
          publicId
        }
        order
      }
    }
    Utility {
      id
      type
    }
    HousingUnitAmenity {
      id
      type
    }
  }
`;

export const UpdateBedroom = gql`
  mutation UpdateBedroom($id: ID!, $squareFeet: Int, $rent: Int) {
    UpdateBedroom(id: $id, squareFeet: $squareFeet, rent: $rent) {
      id
    }
  }
`;

export const AddAmenityToBedroom = gql`
  mutation AddAmenityToBedroom($bedroomId: ID!, $amenityId: ID!) {
    AddAmenityBedrooms(from: { id: $bedroomId }, to: { id: $amenityId }) {
      from {
        id
      }
      to {
        id
      }
    }
  }
`;

export const RemoveAmenityFromBedroom = gql`
  mutation RemoveAmenityFromBedroom($bedroomId: ID!, $amenityId: ID!) {
    RemoveAmenityBedrooms(from: { id: $bedroomId }, to: { id: $amenityId }) {
      from {
        id
      }
      to {
        id
      }
    }
  }
`;
