import styled, { createGlobalStyle } from 'styled-components';
import { typography } from '../../styles/constants';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
export const GlobalStyle = createGlobalStyle`
    .modal-dialog {
      max-width: 75% !important;
    }

    .modal-backdrop {
      background-color: #373a3c;
    }

    .modal-backdrop.show {
      opacity: 0.7 !important;
    }
    
    .details {
      p {
        margin: 0;
        color: ${typography.fontColor.text};
        font-size: ${typography.fontSize.lgtext};
      }

      p.parName {
        font-size: 20px;
        color: ${typography.fontColor.title};
      }
    }

    .avatar {
      align-self: center;
      margin-right: 15px;
    }
`;

export const ParDetailsModalContainer = styled(Modal)`
  button {
    outline: none;
  }

  .modal-header,
  .modal-footer {
    border: none;
  }

  .modal-title {
    width: 100%;
    text-align: center;
  }
`;

export const StyledModalHeader = styled(ModalHeader)`
  h5 {
    font-size: ${typography.fontSize.title} !important;
  }
`;
