import React, { useState, useCallback, useEffect } from 'react';
import { Collapse, UncontrolledTooltip } from 'reactstrap';
import Avatar from 'react-avatar';
import FontAwesome from 'react-fontawesome';
import { MediumText, MediumLargeText, SmallText, RedFontAwesome } from '../../styles/text';
import {
  DataColsDiv,
  LabelsColDiv,
  ParsDataFrameDiv,
  ParsContainerYScrollableDiv,
  ParCardsRowDiv,
  ParCardDiv,
  LabelDiv,
  CategoryHeaderLabelDiv,
  CatHeaderRowExtensionDiv,
  ResponseDataDiv,
  ViewMoreLabel,
} from './ParToParComparisonTableStyles';
import { colorFromName } from '../../utilities/hash';
import { AllParsData, ParToParIds } from '../../apollo/cache.js';

export const ParToParComparisonTable = (props) => {
  // JS Object logic
  const categories = props.categoriesObject;
  let pars = props.parsObject.map((par) => ({
    ...par,
    row: JSON.parse(par.row),
  }));

  const numPars = pars.length;
  const colWidth = 280;

  // styles to control layout width w.r.t. num of bedrooms/columns
  const dataColsDivStyles = {
    width: colWidth * numPars,
  };
  const cardsRowDivStyles = {
    width: colWidth * numPars,
  };
  const catHeaderRowDivStyles = {
    width: '100%',
  };

  // accordion responses within categories
  let initialCollapsed = Array(categories.length).fill(true);
  initialCollapsed[0] = false;

  const [isCollapsed, setIsCollapsed] = useState(initialCollapsed);

  const [genderConflict, setGenderConflict] = useState(false);
  const [politicalConflict, setPoliticalConflict] = useState(false);
  const [petsConflict, setPetsConflict] = useState(false); // even though it is a hard filter it is not in
  const [smokingConflict, setSmokingConflict] = useState(false);
  const [dryHomeConflict, setDryHomeConflict] = useState(false);
  const toggleCollapsed = useCallback(
    (index) => {
      const collapsed = [...isCollapsed];
      collapsed[index] = !collapsed[index];
      setIsCollapsed(collapsed);
    },
    [isCollapsed, setIsCollapsed],
  );

  useEffect(() => {
    let genderConflictTemp = false;
    let politicsConflictTemp = false;
    let petsConflictTemp = false;
    let smokingTobaccoConflictTemp = false;
    let smokingMarijuanaConflictTemp = false;
    let dryHomeConflictTemp = false;
    const toCompare = AllParsData().filter((par) => ParToParIds().has(par.id));
    const rejectsTobaccoAny = toCompare.some((par) => par?.tobaccoPreference === 'Rejects');
    const rejectsMarijuanaAny = toCompare.some((par) => par?.marijuanaPreference === 'Rejects');
    if (toCompare.length) {
      // not very javascripty, but just as fast as the concat
      for (let par of toCompare) {
        const otherPARs = toCompare.filter((v) => v.id !== par.id); // all the other pars
        if (!genderConflictTemp) {
          for (let otherPAR of otherPARs) {
            const gA =
              otherPAR?.genderAversions?.map((ga) => ga?.name).filter((v) => v && v === par?.gender?.name) || [];
            if (gA.length) {
              genderConflictTemp = true;
            }
          }
        }
        if (!politicsConflictTemp) {
          if (par.politicalInterestsWithScore?.length) {
            let politicsAversionsTemp = new Set(
              otherPARs.reduce((acc, par) => [...acc, ...(par?.politicsAversion?.map((pa) => pa?.type) || [])], []),
            ); // all the political aversions
            const prefers = par.politicalInterestsWithScore
              .map((piws) => {
                return piws.interest && piws.interest > 50 ? piws.type : null;
              })
              .filter((v) => v)[0];
            if (prefers && politicsAversionsTemp.has(prefers)) {
              politicsConflictTemp = true;
            }
          }
        }
        if (!petsConflictTemp) {
          if (par.pets.length) {
            let petsIds = par.pets.map((pet) => pet?.id).filter((v) => v);
            for (let otherPAR of otherPARs) {
              for (let otherParPetsAversion of otherPAR.petsAversions) {
                if (otherParPetsAversion?.id && petsIds.includes(otherParPetsAversion.id)) {
                  petsConflictTemp = true;
                  break;
                }
                if (petsConflictTemp) {
                  break;
                }
              }
            }
          }
        }
        // shouldn't this be Prefers if it is Rejects? I thought it was changed in the cypher
        // future proofing this if we change it to Prefers rather than Preference
        const prefersT = par?.tobaccoPreference?.startsWith('Prefer');
        const prefersM = par?.marijuanaPreference?.startsWith('Prefer');
        if (!smokingTobaccoConflictTemp) {
          smokingTobaccoConflictTemp = rejectsTobaccoAny && prefersT;
        }
        // I'll check if we can make this a non-repetitive function with ?.["tobacco/marijuanaPreference"]
        if (!smokingMarijuanaConflictTemp) {
          smokingMarijuanaConflictTemp = rejectsMarijuanaAny && prefersM;
        }
        // this is a real hard filter, but we don't really need it because the other ones already check this
        if (!smokingMarijuanaConflictTemp && !smokingTobaccoConflictTemp) {
          const needs = par?.smokingIndoorsNeeded === 'Need';
          // we don't specify the type of need for indoor smoking unless
          smokingMarijuanaConflictTemp = needs && prefersM && rejectsMarijuanaAny;
          smokingTobaccoConflictTemp = needs && prefersT && rejectsTobaccoAny;
        }
        if (!dryHomeConflictTemp) {
          const prefsOtherPars = otherPARs
            .map((oPAR) => oPAR.dryHomePref === 'Rejects')
            .filter((v) => v && (par.dryHomePref === 'Requires' || par.dryHomePref === 'Prefers'));
          if (prefsOtherPars.length) {
            dryHomeConflictTemp = true;
          }
        }
      }
    }
    setGenderConflict(genderConflictTemp);
    setPoliticalConflict(politicsConflictTemp);
    setPetsConflict(petsConflictTemp);
    setSmokingConflict(smokingTobaccoConflictTemp || smokingMarijuanaConflictTemp);
    setDryHomeConflict(dryHomeConflictTemp);
  }, [ParToParIds, AllParsData]);

  const responseFullText = (responseLabel, par) => {
    const text = responseLabel.responseValue?.(par) || par?.row?.[responseLabel.responseKey] || 'N/A';
    return text.toString();
  };
  const redCircleWithSpace = (
    <>
      <RedFontAwesome name="exclamation-circle" />
      &nbsp;
    </>
  );

  return (
    <div>
      <ParsContainerYScrollableDiv>
        <LabelsColDiv>
          {categories.map((category, outerIndex) => {
            return (
              <div className="labels-container" key={`label-cat-${category.name}-${outerIndex}`}>
                <CategoryHeaderLabelDiv
                  className="cat-header-row set-height-cell"
                  onClick={() => toggleCollapsed(outerIndex)}
                >
                  {category.name === 'Gender' && genderConflict && redCircleWithSpace}
                  {category.name === 'Beliefs' && politicalConflict && redCircleWithSpace}
                  {category.name === 'Pets' && petsConflict && redCircleWithSpace}
                  {category.name === 'Smoking' && smokingConflict && redCircleWithSpace}
                  {category.name === 'Substances' && dryHomeConflict && redCircleWithSpace}
                  {category.name} &nbsp;
                  <FontAwesome name={isCollapsed[outerIndex] ? 'angle-right' : 'angle-down'} />
                </CategoryHeaderLabelDiv>
                <Collapse isOpen={!isCollapsed[outerIndex]} key={`collapsible-label-${outerIndex}`}>
                  {category.responseLabels.map((responseLabel, innerIndex) => {
                    if (responseLabel?.show) {
                      return (
                        <LabelDiv className="set-height-cell" key={`labeldiv-${innerIndex}`}>
                          <SmallText bold>
                            <MediumText>{responseLabel.name}</MediumText>
                          </SmallText>
                        </LabelDiv>
                      );
                    }
                  })}
                </Collapse>
              </div>
            );
          })}
        </LabelsColDiv>
        <ParsDataFrameDiv>
          <DataColsDiv className="x-scrolling-container" style={dataColsDivStyles}>
            <ParCardsRowDiv style={cardsRowDivStyles}>
              {pars.map((par, index) => {
                return (
                  <ParCardDiv key={`par-card-name-${index}`}>
                    <Avatar color={colorFromName(par?.name)} name={par?.name} round size="40" />
                    <MediumLargeText className="par-name">{par?.name}</MediumLargeText>
                  </ParCardDiv>
                );
              })}
            </ParCardsRowDiv>
            {categories.map((category, outerIndex) => {
              return (
                <div className="category-data-container" key={`div-category-${category.name}-${outerIndex}`}>
                  <CatHeaderRowExtensionDiv
                    className="cat-header-row"
                    style={catHeaderRowDivStyles}
                  ></CatHeaderRowExtensionDiv>
                  <Collapse isOpen={!isCollapsed[outerIndex]} key={`collapsible-par-${category.name}-${outerIndex}`}>
                    {category.responseLabels.map((responseLabel, middleIndex) => {
                      if (responseLabel?.show) {
                        return (
                          <div key={`cat-${category.name}-${outerIndex}-${responseLabel.name}`}>
                            {pars.map((par, innerIndex) => {
                              return (
                                <ResponseDataDiv
                                  className="response-data-div set-height-cell"
                                  key={`cat-${category.name}-par-${par?.id}-${innerIndex}`}
                                >
                                  {responseFullText(responseLabel, par).length < 70 ? (
                                    <span
                                      id={`cat-${category.name
                                        .split(' ')[0]
                                        .toLowerCase()}-${middleIndex}-${innerIndex}`}
                                    >
                                      {responseFullText(responseLabel, par)}{' '}
                                    </span>
                                  ) : (
                                    <>
                                      <div
                                        id={`cat-${category.name
                                          .split(' ')[0]
                                          .toLowerCase()}-${middleIndex}-${innerIndex}`}
                                      >
                                        {responseFullText(responseLabel, par).slice(0, 60)}{' '}
                                        <ViewMoreLabel> ...More</ViewMoreLabel>
                                      </div>
                                      <UncontrolledTooltip
                                        placement="bottom"
                                        target={`cat-${category.name
                                          .split(' ')[0]
                                          .toLowerCase()}-${middleIndex}-${innerIndex}`}
                                      >
                                        {responseFullText(responseLabel, par)}
                                      </UncontrolledTooltip>
                                    </>
                                  )}
                                </ResponseDataDiv>
                              ); // end inner map
                            })}
                          </div>
                        );
                      } // end middle map
                    })}
                  </Collapse>
                </div>
              ); // end outer map
            })}
          </DataColsDiv>
        </ParsDataFrameDiv>
      </ParsContainerYScrollableDiv>
    </div>
  );
};
