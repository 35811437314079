import React, { useState, useEffect } from 'react';
import { useToggle } from '../../../utilities/hooks/index';
import { useMutation } from '@apollo/react-hooks';
import { Col, Row } from 'reactstrap';
import ViewMatchHistoryModal from '../../modals/ViewMatchHistoryModal';
import { ListGroupRow } from '../ParticipantStyles';
import { ParticipantHUItem } from './ParticipantHUItem';
import { PanelContainer, PanelCol } from '../HousingStyles';
import {
  ViewMatchingHistoryGroup,
  ViewMatchingHistoryLabelButton,
  ViewMatchingHistoryIcon,
} from './ParticipantHUMatchPanelStyles';
import {
  RemoveInterestInHousingUnit,
  RemoveDisinterestInHousingUnit,
} from '../../HousingUnitCreation/ParticipantStatusUpdatesGQL';
import historyIcon from '../../../assets/icons/Icon-material-history.svg';
import { SelectedCard } from '../../../apollo/cache';
import PropTypes from 'prop-types';

const ParticipantHUMatchPanel = ({ role, participant, refetch }) => {
  const [openVMHModal, setopenVMHModal] = useToggle(false);
  const [removeInterestInUnit] = useMutation(RemoveInterestInHousingUnit, {
    onCompleted() {
      refetch?.();
    },
  });
  const [removeDisinterestInUnit] = useMutation(RemoveDisinterestInHousingUnit, {
    onCompleted() {
      refetch?.();
    },
  });
  const handleChangeClick = async (e, participant, housingUnit, isInterestedInHu, isDisinterestedInHu) => {
    e.preventDefault();
    isInterestedInHu
      ? await removeInterestInUnit({
          variables: {
            participant: { id: participant.id },
            housingUnit: { id: housingUnit.id },
          },
        })
      : isDisinterestedInHu
      ? await removeDisinterestInUnit({
          variables: {
            participant: { id: participant.id },
            housingUnit: { id: housingUnit.id },
          },
        })
      : null;
  };
  const handleVMHClick = () => {
    setopenVMHModal();
  };

  useEffect(() => {
    const selectedCard = SelectedCard();
    const selectedElement = document.querySelector(`div[class*="${selectedCard}"]`);
    selectedElement?.addEventListener('animationstart', (e) => {
      if (selectedCard) {
        document.querySelector(`div[class*='selected']`)?.scrollIntoView();
      } else {
        document.querySelector('body')?.scrollIntoView();
      }
      setTimeout(() => {
        if (selectedCard) {
          const removeSelected = document.querySelector(`div[class*="selected"]`);
          removeSelected?.classList?.remove('selected');
        }
      }, 3000);
    });
  }, [openVMHModal]);

  useEffect(() => {
    SelectedCard('');
  }, []);

  return (
    <ListGroupRow>
      <Col md="12">
        <PanelContainer>
          <ParticipantHUItem
            refetch={refetch}
            participant={participant}
            notifiedUnits={participant?.notifiedHousingUnits}
            handleChangeClick={handleChangeClick}
            role={role}
          />
        </PanelContainer>
      </Col>
      {participant?.notifiedHousingUnits?.length ? (
        <PanelCol md="12">
          <ViewMatchingHistoryGroup>
            <ViewMatchingHistoryLabelButton onClick={handleVMHClick}>
              <ViewMatchingHistoryIcon src={historyIcon} alt="Circle arrow around clock" />
              View Match History
            </ViewMatchingHistoryLabelButton>
          </ViewMatchingHistoryGroup>
        </PanelCol>
      ) : null}
      <ViewMatchHistoryModal
        role={role}
        participant={participant}
        open={openVMHModal}
        close={setopenVMHModal}
        handleChangeClick={handleChangeClick}
      />
    </ListGroupRow>
  );
};

ParticipantHUMatchPanel.propTypes = {
  role: PropTypes.shape({
    __typename: PropTypes.string.isRequired,
  }),
  participant: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default ParticipantHUMatchPanel;
