export default (housingUnit) => {
  const state = {
    vouchers: [],
    originalVouchers: [],
    noIndoorSmoking: null,
    noSexOffender: null,
    noPriorConvictions: null,
    noPriorEvictions: null,
    pets: [],
    originalPets: [],
  };
  for (const pet of housingUnit.allows || []) {
    if (pet?.id) {
      state.pets.push(pet.id);
    }
  }
  state.originalPets = [...state.pets];
  for (const voucher of housingUnit.accepts || []) {
    if (voucher?.id) {
      state.vouchers.push(voucher.id);
    }
  }
  state.originalVouchers = [...state.vouchers];
  if (housingUnit.allowsIndoorSmoking === false) {
    state.noIndoorSmoking = true;
  } else if (housingUnit.allowsIndoorSmoking === true) {
    state.noIndoorSmoking = false;
  }
  if (housingUnit.allowsSexOffenders === false) {
    state.noSexOffender = true;
  } else if (housingUnit.allowsSexOffenders === true) {
    state.noSexOffender = false;
  }
  if (housingUnit.allowsEvictions === false) {
    state.noPriorEvictions = true;
  } else if (housingUnit.allowsEvictions === true) {
    state.noPriorEvictions = false;
  }
  if (housingUnit.allowsConvictions === false) {
    state.noPriorConvictions = true;
  } else if (housingUnit.allowsConvictions === true) {
    state.noPriorConvictions = false;
  }
  return state;
};
