import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';

export const StatusPanelContainer = styled(Container)`
  height: 70px;
  margin: 0;
  max-width: 100vw;
  background-color: white;
  box-shadow: 0 3px 30px #0a4b6d3e;
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: -3px;

  button {
    margin: 0;
  }

  p {
    display: inline-block;
    margin: 0 20px 0 0;
    align-self: center;
  }

  .hide {
    display: none;
  }

  .show {
    display: flex;
  }

  .reverse {
    flex-direction: row-reverse;
  }

  .changeLink {
    padding-right: 15px;
  }
`;

export const StatusPanelCol = styled(Col)`
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  &:last-child {
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-left: 120px;
  }
`;

export const StatusPanelRow = styled(Row)`
  width: 100vw;
`;
