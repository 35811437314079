import styled from 'styled-components';
import { borders } from '../../styles/constants';

export const ComparisonDiv = styled.div`
  border: ${borders.border};
  border-radius: ${borders.borderRadius};
  padding: 24px;
  height: 560px;
  position: relative;
  width: calc(100% - 60px);
  margin-right: 40px;
  margin-left: 20px;

  .col-center {
    justify-content: center;
  }
`;

export const ViewButtonDiv = styled.div`
  position: absolute;
  bottom: 60px;
  width: calc(100% - 40px);
`;
