import React from 'react';

// This is just a dummy, but anyone can use it
export default function BaseParagraph(props) {
  return (
    <div style={{}} {...props}>
      {props.children}
    </div>
  );
}
