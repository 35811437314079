import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Box, Grid } from '@material-ui/core';
import BrochureCardWrapper from './BrochureCardWrapper';
import { cardStyles } from './BrochureStyles';
import KeyValPair from './BrochureKeyVal';
import PropTypes from 'prop-types';

const Bedroom = ({ bedroom, bedNum }) => {
  const listAmenities = () => {
    return bedroom.amenities?.map((amenity, ix) => {
      return (
        <Typography key={ix} color="primary">
          {amenity}
        </Typography>
      );
    });
  };

  return (
    <>
      <Box marginBottom={2}>
        <Typography variant="h6" color="primary">{`Bedroom: ${bedNum}`}</Typography>
      </Box>
      <KeyValPair category="Room Rent" value={`$${bedroom?.rent?.toLocaleString()}`} />
      <KeyValPair category="Sq Ft" value={bedroom?.squareFeet?.toLocaleString()} />
      <KeyValPair category="Amenities" value={listAmenities()} />
    </>
  );
};

const BedroomDetails = ({ classes, bedrooms }) => {
  return (
    <>
      <Typography variant="h3" color="primary">
        Available Bedrooms
      </Typography>
      <Grid container>
        {bedrooms.map((bed, ix) => {
          return (
            <Grid item key={ix} xs={12} sm={6}>
              <Box className={classes.box}>
                <Bedroom bedroom={bed} bedNum={ix + 1} />
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

const BrochureBedroomDetails = (props) => {
  return (
    <BrochureCardWrapper>
      <BedroomDetails {...props} />
    </BrochureCardWrapper>
  );
};

BrochureBedroomDetails.propTypes = {
  bedrooms: PropTypes.arrayOf(
    PropTypes.shape({
      amenities: PropTypes.arrayOf(PropTypes.string),
      rent: PropTypes.number,
      squareFeet: PropTypes.number,
    }),
  ).isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(cardStyles)(BrochureBedroomDetails);
