import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { ReactComponent as People } from '../../assets/icons/icon-material-people-outline.svg';

const drawerWidth = 225;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: -1,
  },
  drawerPaper: {
    paddingLeft: '15px',
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  list: { padding: '25px 0 15px' },
  iconWrapper: {
    minWidth: '35px',
  },
  listTitle: {
    color: theme.palette.disabled.main,
    opacity: '66%',
    paddingBottom: '3px',
  },
  activeTextPath: {
    fontWeight: 600,
  },
}));

export default function PermanentDrawerLeft({ currentPath }) {
  const classes = useStyles();

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <div className={classes.toolbar} />
      <List className={classes.list}>
        <ListItem className={classes.listTitle}>
          <ListItemText primary="ADMIN SETTINGS" />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.iconWrapper}>
            <People />
          </ListItemIcon>
          <ListItemText
            primary="Manage Staff"
            className={currentPath === 'manageStaff' ? classes.activeTextPath : {}}
            disableTypography
          />
        </ListItem>
      </List>
      <Divider />
    </Drawer>
  );
}
