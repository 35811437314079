import React from 'react';
import { PopoverHeader, PopoverBody } from 'reactstrap';
import { CustomPopover } from './ToolTipStyles';
import { useToggle } from '../../utilities/hooks/index';
import PropTypes from 'prop-types';

const Tooltip = (props) => {
  const [openToolTip, toggleToolTip] = useToggle(false);

  return (
    <CustomPopover placement={props.placement} isOpen={openToolTip} target={props.target} toggle={toggleToolTip}>
      {props.headerText && <PopoverHeader>{props?.headerText}</PopoverHeader>}
      <PopoverBody>{props.children}</PopoverBody>
    </CustomPopover>
  );
};

Tooltip.defaultProps = {
  placement: 'top',
};

Tooltip.propTypes = {
  placement: PropTypes.string,
  target: PropTypes.string.isRequired,
  headerText: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Tooltip;
