import colors from './colors';

const fontColor = {
  title: colors.darkBlue,
  link: colors.gray,
  text: colors.blue,
  white: colors.whiteShade,
  disabled: colors.selected,
  btnColor: colors.selected,
  alertLinkColor: colors.lightBlueGreen,
  grayText: colors.grayText,
};

const fontSize = {
  xsmtext: '11px',
  smtext: '12px',
  mdtext: '14px',
  lgtext: '15px',
  xsmtitle: '18px',
  smtitle: '24px',
  title: '32px',
};

const fontWeight = {
  light: 300,
  regular: 400,
  title: 700,
};

const fontFamily = {
  regular: "'Lato', sans-serif;",
  close: "'Kumbh Sans', sans-serif;",
};
export default { fontColor, fontSize, fontFamily, fontWeight };
