import styled from 'styled-components';
import { ReactComponent as EditIconSVG } from '../assets/icons/feather-edit-2.svg';

export const IconButton = styled.button`
  background-color: transparent;
  outline: none;
  border: none;
`;

export const EditIcon = styled(EditIconSVG)`
  width: 12px;
  height: 12px;
  vertical-align: top;
  path {
    stroke: #5bb8b4;
  }
  &:hover {
    path {
      stroke: lightgray;
    }
  }
`;
