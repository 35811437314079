import { gql } from '@apollo/client';

export const QueryImages = gql`
  query QueryImages($id: ID!) {
    HousingUnit(id: $id) {
      SHOWS_rel(orderBy: order_asc) {
        Image {
          id
          url
          publicId
        }
        order
        _id
      }
    }
  }
`;

export const UpdateImageSHOWS_relOrder = gql`
  mutation UpdateImageSHOWS_relOrder($from: _ImageInput!, $to: _HousingUnitInput!, $order: Int!) {
    UpdateImageSHOWS_rel(from: $from, to: $to, data: { order: $order }) {
      order
    }
  }
`;
