import { gql } from '@apollo/client';

export const AddParNoteGQL = gql`
  mutation AddParNote($content: String!, $noteFor: ID!) {
    CreateParNote(content: $content, noteFor: $noteFor) {
      id
    }
  }
`;

export const EditParNote = gql`
  mutation UpdateParNote($id: ID!, $content: String!) {
    UpdateParNote(id: $id, content: $content) {
      id
      content
    }
  }
`;

export const DeleteParNote = gql`
  mutation DeleteParNote($id: ID!) {
    DeleteParNote(id: $id)
  }
`;
