import React from 'react';

export default function Checkbox_({ checked, className }) {
  return (
    <span className={className}>
      <input type="checkbox" checked={checked} onChange={() => {}} />
      <label></label>
    </span>
  );
}
