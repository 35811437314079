import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Container } from 'reactstrap';
import { AssessmentTopicBar } from '../components/HousingUnitCreation/AssessmentTopicBar';
import LocationDetail from '../components/HousingUnitCreation/sections/LocationDetail';
import RentRoom from '../components/HousingUnitCreation/sections/RentRoom';
import UnitAcceptances from '../components/HousingUnitCreation/sections/UnitAcceptances';
import Accessibility from '../components/HousingUnitCreation/sections/Accessibility';
import UnitDescription from '../components/HousingUnitCreation/sections/UnitDescription';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { UnitImages } from '../components/HousingUnitCreation/sections/UnitImages';
import { isComplete } from '../utilities/isComplete';
import { QueryHousingUnitByIdAllData } from '../components/HousingUnitCreation/QueryHousingByIdGQL';
import { useLazyQuery } from '@apollo/react-hooks';
import { useToggle } from '../utilities/hooks';
import ViewingOptions from '../components/HousingUnitCreation/sections/ViewingOptions';
import UnitCompleteModal from '../components/modals/UnitCompleteModal';

export const AddHousingUnitView = () => {
  const [completedSections, setCompletedSections] = React.useState({
    location: false,
    rooms: false,
    acceptances: false,
    accessibility: false,
    viewing: false,
  });
  const [isCompleteModal, toggleIsCompleteModal] = useToggle(false);
  const match = useRouteMatch();
  const { houseUnitId } = useParams();
  const [isUnitActive, setIsUnitActive] = React.useState(false);

  const [queryHousingUnit, { data, error }] = useLazyQuery(QueryHousingUnitByIdAllData);

  useEffect(() => {
    if (houseUnitId) {
      queryHousingUnit({
        variables: {
          id: houseUnitId,
        },
      });
    }
  }, [houseUnitId, queryHousingUnit]);
  useEffect(() => {
    if (data?.HousingUnit) {
      const { HousingUnit } = data;
      const housingUnit = HousingUnit[0];
      setCompletedSections({
        location: isComplete('location', housingUnit),
        rooms: isComplete('rooms', housingUnit),
        acceptances: isComplete('acceptances', housingUnit),
        accessibility: isComplete('accessibility', housingUnit),
        viewing: isComplete('viewing-options', housingUnit),
      });
      setIsUnitActive(housingUnit?.status === 'Active');
    }
  }, [data, setCompletedSections, isComplete]);

  if (error) {
    console.error('Something went wrong:', error);
  }

  return (
    <div>
      <Row style={{ marginRight: '0px' }}>
        <Col md="2" className="form-topic-side">
          <AssessmentTopicBar />
        </Col>
        <Col className="assessment-side" md="10">
          <Container className="questions themed-container" fluid="md">
            <Switch>
              <Route path={`${match.path}/description`}>
                <UnitDescription
                  toggle={toggleIsCompleteModal}
                  modal={isCompleteModal}
                  isUnitActive={isUnitActive}
                  completedSections={completedSections}
                />
              </Route>
              <Route path={`${match.path}/room-rent`}>
                <RentRoom
                  toggle={toggleIsCompleteModal}
                  modal={isCompleteModal}
                  isUnitActive={isUnitActive}
                  completedSections={completedSections}
                />
              </Route>
              <Route path={`${match.path}/unit-acceptances`}>
                <UnitAcceptances
                  toggle={toggleIsCompleteModal}
                  modal={isCompleteModal}
                  isUnitActive={isUnitActive}
                  completedSections={completedSections}
                />
              </Route>
              <Route path={`${match.path}/accessibility`}>
                <Accessibility
                  toggle={toggleIsCompleteModal}
                  modal={isCompleteModal}
                  isUnitActive={isUnitActive}
                  completedSections={completedSections}
                />
              </Route>
              <Route path={`${match.path}/location`}>
                <LocationDetail
                  toggle={toggleIsCompleteModal}
                  modal={isCompleteModal}
                  isUnitActive={isUnitActive}
                  completedSections={completedSections}
                  add={false}
                />
              </Route>
              <Route path={`${match.path}/unit-images`}>
                <UnitImages
                  toggle={toggleIsCompleteModal}
                  modal={isCompleteModal}
                  isUnitActive={isUnitActive}
                  completedSections={completedSections}
                />
              </Route>
              <Route path={`${match.path}/viewing-options`}>
                <ViewingOptions
                  toggle={toggleIsCompleteModal}
                  modal={isCompleteModal}
                  isUnitActive={isUnitActive}
                  completedSections={completedSections}
                />
              </Route>
              <Route path={`/housing/add/location`}>
                <LocationDetail add={true} />
              </Route>
            </Switch>
            <UnitCompleteModal toggle={toggleIsCompleteModal} modal={isCompleteModal} />
          </Container>
        </Col>
      </Row>
    </div>
  );
};
