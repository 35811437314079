import { gql } from '@apollo/client';

export const CreateViewingNote = gql`
  mutation CreateViewingNote($content: String!, $noteFor: ID!) {
    CreateViewingNote(content: $content, noteFor: $noteFor) {
      id
      content
      createdAt {
        formatted
      }
    }
  }
`;

export const DeleteViewingNote = gql`
  mutation DeleteViewingNote($id: ID!) {
    DeleteViewingNote(id: $id)
  }
`;

export const UpdateViewingNote = gql`
  mutation UpdateViewingNote($id: ID!, $content: String!) {
    UpdateViewingNote(id: $id, content: $content) {
      id
      content
      createdAt {
        formatted
      }
      noteFor {
        id
        name
      }
    }
  }
`;

export const UpdateHousingUnitHoldDeadline = gql`
  mutation UpdateHousingUnitHoldDeadline($id: ID!, $deadline: _Neo4jDateTimeInput) {
    UpdateHousingUnit(id: $id, deadline: $deadline) {
      id
    }
  }
`;
