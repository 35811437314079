import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import Avatar from 'react-avatar';
import { DeactivateUser, DeleteUser } from '../../views/AdminManageStaffGQL';
import ConfirmModal from '../modals/ConfirmModal';
import PreventActionModal from '../modals/PreventActionModal';
import { MediumText } from '../../styles/text';
import { colorFromName } from '../../utilities/hash';
import { UserRow, UserCell } from './AdminDashStyles';
import DeleteEditMenu from '../menus/DeleteEditMenu';
import Fade from '@material-ui/core/Fade';
import { useToggle } from '../../utilities/hooks/';
import { capitalizeSentence } from '../../utilities/stringProcessing';

const roleDict = {
  Advocate: 'Advocate',
  HousingLocator: 'Locator',
  MPO: 'MPO',
  SurveyCreator: 'Survey Creator',
  UserAdmin: 'User Admin',
};

const deactivationText =
  'The staff member will no longer be able to access the dashboard. You can reactivate this staff member anytime.';
const deletionText =
  'The staff member will be permanently removed from the system and their profile cannot be recovered.';

export const UserTableRow = ({
  user,
  handleEditUser,
  handleCreateOrUpdateUserSuccess,
  handleReactivate,
  handleToastErrorMessage,
  isNew,
  refetch,
}) => {
  const [deactivateUser, { loading: deactivateLoading, error: deactivationErr }] = useMutation(DeactivateUser);
  const [deleteUser, { loading: deleteLoading, error: deleteErr }] = useMutation(DeleteUser);
  const [confirmDeactivateModalOpen, setConfirmDeactivateModalOpen] = useState(false);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [blockDeactivationModalOpen, setBlockDeactivationModalOpen] = useToggle(false);
  const [fadeIn, setFadeIn] = useState(true);
  const [error, setError] = useState('');
  const [isMenuOpen, toggleIsMenuOpen] = useToggle(false);

  useEffect(() => {
    if (deactivationErr || deleteErr) {
      setError(deactivationErr);
    }
  }, [deactivationErr, deleteErr]);

  const createRoles = (labels) => {
    const roles = labels?.reduce((acc, cur) => {
      if (roleDict[cur]) {
        acc.push(roleDict[cur]);
      }
      return acc;
    }, []);
    return roles.join(', ');
  };

  const handleEditUserClick = () => {
    handleEditUser(user);
  };

  const selectDeactivate = async () => {
    if (!user?.participants?.length >= 1) {
      setConfirmDeactivateModalOpen(true);
    } else {
      setBlockDeactivationModalOpen();
    }
  };

  const toggleStatus = () => {
    if (user?.status?.type === 'Active') {
      selectDeactivate();
    } else {
      handleReactivate(user);
    }
  };

  const handleConfirmDeactivate = () => {
    const result = sendDeactivation();
    result.then((data) => handleSuccess(data));
  };

  const handleSuccess = (data) => {
    if (data?.DeactivateUser) {
      handleCreateOrUpdateUserSuccess(user, 'deactivate');
      refetch();
    } else if (data?.DeleteUser) {
      handleCreateOrUpdateUserSuccess(user, 'delete');
      setConfirmDeleteModalOpen(false);
      handleDeleteRow();
    } else {
      setError(data);
      handleToastErrorMessage(data?.message);
    }
    setConfirmDeactivateModalOpen(false);
  };

  const sendDeactivation = async () => {
    if (user?.email) {
      try {
        const response = await deactivateUser({ variables: { email: user?.email } });
        return response?.data;
      } catch (err) {
        console.error(err);
        setError(err);
        return err;
      }
    }
  };

  const toggleDelete = async () => {
    setConfirmDeleteModalOpen(true);
  };

  const handleConfirmDelete = () => {
    const result = sendDelete();
    result.then((data) => handleSuccess(data));
  };

  const sendDelete = async () => {
    if (user?.id) {
      try {
        const response = await deleteUser({ variables: { id: user?.id } });
        return response?.data;
      } catch (err) {
        console.error(err);
        setError(err);
        return err;
      }
    }
  };

  const handleDeleteRow = () => {
    setFadeIn(false);
  };

  const writeBlockMsg = () => {
    let parSection;
    if (user?.participants?.length == 1) {
      parSection = 'one participant.';
    } else {
      parSection = `${user?.participants?.length} participants.`;
    }
    return `${user?.name} cannot be deactivated because they support ${parSection}`;
  };

  const timeout = isNew ? 6000 : 0;

  return (
    <>
      <Fade in={fadeIn} exit={true} timeout={{ enter: timeout, exit: 2000 }} unmountOnExit>
        <UserRow $opaque={user?.status?.type !== 'Active'}>
          <UserCell style={{ width: '5%' }}>
            <Avatar color={colorFromName(user?.name)} size="30" round name={user?.name} />
          </UserCell>
          <UserCell style={{ width: '20%' }}>
            <MediumText>{capitalizeSentence(user?.name)}</MediumText>
          </UserCell>
          <UserCell style={{ width: '31%' }}>
            <MediumText>{user?.email}</MediumText>
          </UserCell>
          <UserCell style={{ width: '25%' }}>
            <MediumText>{createRoles(user?.labels)}</MediumText>
          </UserCell>
          <UserCell style={{ width: '12%' }}>
            <MediumText>{user?.status?.type === 'Active' ? 'Active' : 'Inactive'}</MediumText>
          </UserCell>
          <UserCell style={{ width: '7%' }}>
            <div>
              <DeleteEditMenu
                id={user?.id}
                menuOpen={isMenuOpen}
                toggleMenuOpen={toggleIsMenuOpen}
                handleEditClick={handleEditUserClick}
                isDeletable={user?.isDeletable}
                status={user?.status?.type}
                toggleArchive={toggleStatus}
                toggleDelete={toggleDelete}
                type="user"
              />
            </div>
          </UserCell>
        </UserRow>
      </Fade>
      {confirmDeactivateModalOpen && (
        <ConfirmModal
          confirmationText={`Are you sure you want to deactivate ${user?.name}?`}
          submittedMsg="deactivating..."
          helperText={deactivationText}
          modalOpen={confirmDeactivateModalOpen}
          toggleModalOpen={setConfirmDeactivateModalOpen}
          handleConfirm={handleConfirmDeactivate}
          waitForLoad={true}
          isLoading={deactivateLoading}
          error={error}
        />
      )}
      {confirmDeactivateModalOpen && (
        <ConfirmModal
          confirmationText={`Are you sure you want to reactivate ${user?.name}?`}
          submittedMsg="reactivating..."
          helperText={deactivationText}
          modalOpen={confirmDeactivateModalOpen}
          toggleModalOpen={setConfirmDeactivateModalOpen}
          handleConfirm={handleConfirmDeactivate}
          isLoading={deactivateLoading}
          error={error}
        />
      )}
      {confirmDeleteModalOpen && (
        <ConfirmModal
          confirmationText={`Are you sure you want to delete ${user?.name}?`}
          submittedMsg="deleting..."
          helperText={deletionText}
          modalOpen={confirmDeleteModalOpen}
          toggleModalOpen={setConfirmDeleteModalOpen}
          handleConfirm={handleConfirmDelete}
          waitForLoad={true}
          isLoading={deleteLoading}
          error={error}
        />
      )}
      <PreventActionModal
        isOpen={blockDeactivationModalOpen}
        handleCancelClick={setBlockDeactivationModalOpen}
        message={writeBlockMsg()}
      />
    </>
  );
};
