import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import useSlate from '../../useSlate';
import { ClearButton, PrimaryButton } from '../../buttons/ButtonStyles';
import pencilIcon from '../../../assets/icons/feather-edit-2.svg';
import trashIcon from '../../../assets/icons/feather-trash-2.svg';
import {
  ViewingSectionLabel,
  ViewingPanel,
  PanelButtonGroup,
  PanelTextAreaGroup,
  LabelGroup,
  InputLabel,
  HelperText,
  ViewingNoteCard,
  HeaderLabelGroup,
  HeaderLabelIcon,
  ViewingNoteCardHeader,
  HeaderLabel,
  CardDetails,
  GridContainer,
  EditButton,
  DeleteButton,
  AddTourButton,
  FontAwesomePlus,
  GlobalStyle,
} from './ViewingOptionsStyles';
import clipboardpath from '../../../assets/icons/clipboard.svg';
import { ErrorToast } from '../../Alerts';
import PropTypes from 'prop-types';

const ViewingOptionsDisplay = ({ controls, state, HURequiredData, slate, submitting, setSubmitting, autoFocus }) => {
  const { value, Editor } = slate;
  const { QHURequiredData: data, error } = HURequiredData;
  const {
    viewingNotes,
    viewingNoteContent,
    showAddBtn,
    isEditing,
    showInputPanel,
    setViewingNotes,
    setViewingNoteContent,
    setShowInputPanel,
  } = state;
  const {
    handleSaveViewing,
    handleEditViewingNote,
    handleCancelViewingNote,
    handleDeleteViewing,
    handleAddTour,
  } = controls;

  useEffect(() => {
    if (data) {
      const { HousingUnit } = data;
      if (HousingUnit) {
        const { viewingNotes } = HousingUnit[0];
        if (viewingNotes) {
          setViewingNotes(viewingNotes);
        }
      }
    }
  }, [data, viewingNotes, setViewingNotes]);

  useEffect(() => {
    if (viewingNotes) {
      setShowInputPanel(!viewingNotes?.length && !showAddBtn ? true : false);
    }
  }, [viewingNotes, setViewingNotes, showAddBtn]);

  useEffect(() => {
    const valueToSubmit = value.reduce((text, paragraph) => {
      return (text + '\n' + paragraph.children.reduce((para, child) => para + child.text, '').trim()).trim();
    }, '');
    setSubmitting(false);
    setViewingNoteContent(valueToSubmit);
  }, [value, setViewingNoteContent]);

  const displayNotes = () => {
    return viewingNotes.map((note, i) => {
      return (
        <GridContainer colWidth="2.5fr .25fr .25fr" key={note.id + i}>
          <CardDetails>{note.content}</CardDetails>
          <EditButton onClick={() => handleEditViewingNote(note?.id)} className="withIcon" type="card">
            <HeaderLabelIcon className="color" src={pencilIcon} />
            Edit
          </EditButton>
          <DeleteButton onClick={() => handleDeleteViewing(note?.id)} className="withIcon" type="card">
            <HeaderLabelIcon src={trashIcon} />
            Delete
          </DeleteButton>
        </GridContainer>
      );
    });
  };

  if (error) {
    return <ErrorToast />;
  }

  return (
    <>
      <GlobalStyle />
      <ViewingSectionLabel>Visit in Person</ViewingSectionLabel>
      <AddTourButton className={showAddBtn && !showInputPanel && !isEditing ? 'show' : 'hide'} onClick={handleAddTour}>
        <FontAwesomePlus name="plus" />
        <div>Add Viewing Option*</div>
      </AddTourButton>
      <ViewingPanel className={(showInputPanel && !showAddBtn) || isEditing ? 'show' : 'hide'}>
        <PanelTextAreaGroup>
          <LabelGroup $flexColumn>
            <InputLabel>Provide details</InputLabel>
            <HelperText>Please enter details including date(s), time(s), and any necessary access details</HelperText>
          </LabelGroup>
          <div className="viewing-editor" onClick={autoFocus}>
            {Editor}
          </div>
        </PanelTextAreaGroup>
        <PanelButtonGroup>
          <ClearButton onClick={handleCancelViewingNote}>Cancel</ClearButton>
          <PrimaryButton disabled={!viewingNoteContent} className="saveBtn" onClick={handleSaveViewing}>
            Save
          </PrimaryButton>
        </PanelButtonGroup>
      </ViewingPanel>
      <ViewingNoteCard className={!showInputPanel && !showAddBtn && !isEditing ? 'show' : 'hide'}>
        <ViewingNoteCardHeader>
          <HeaderLabelGroup>
            <HeaderLabelIcon src={clipboardpath} alt="Clipboard" title="Clipboard: Details" />{' '}
            <HeaderLabel>Details</HeaderLabel>
          </HeaderLabelGroup>
        </ViewingNoteCardHeader>
        {displayNotes()}
      </ViewingNoteCard>
      {!viewingNotes?.length && submitting ? (
        <div className="invalid-feedback" style={{ display: 'block', textAlign: 'left', marginBottom: 10 }}>
          Required Field. Please add details.
        </div>
      ) : null}
    </>
  );
};

ViewingOptionsDisplay.propTypes = {
  submitting: PropTypes.bool.isRequired,
  setSubmitting: PropTypes.func.isRequired,
};

export default ViewingOptionsDisplay;
