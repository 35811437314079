import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Collapse, Row, Col } from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import { useQuery } from '@apollo/react-hooks';
import { ResponseDetailLayoutObj } from '../../ParToParComparisonTable/CategoryToResponseMapObj.js';
import { ErrorToast, LoadingSpinner } from '../../Alerts';
import { CategoryWrapper, Category, Question, Answer, EditBtn } from './ParDetailsDisplayStyles';
import { GetParById } from './ParDetailsDisplayGQL';
import PropTypes from 'prop-types';

const ParDetailsDisplay = (props) => {
  const [parResponse, setParResponse] = useState({});
  const [openCategory, setOpenCategory] = useState([]);
  const { participantId } = useParams();
  const parId = participantId || props.participantId;
  const { data, loading, error } = useQuery(GetParById, {
    variables: { id: parId },
  });
  const toggle = (index) => {
    const updateCollapsed = [...openCategory];
    updateCollapsed[index] = !updateCollapsed[index];
    setOpenCategory(updateCollapsed);
  };

  const handleEdit = (e) => {
    // console.error(e); // noop
  };

  useEffect(() => {
    if (data) {
      const parDetails = {};
      let { Participant } = data;
      if (Participant && Participant?.length) {
        const CurrentPar = { ...Participant[0] };
        if (CurrentPar?.row) {
          CurrentPar.row = JSON.parse(CurrentPar.row);
          if (CurrentPar) {
            for (const category of ResponseDetailLayoutObj) {
              if (category?.responseLabels?.length) {
                parDetails[category?.name] = {};
                const subCount = category?.responseLabels?.length || 0;
                const halfSubCount = subCount && subCount % 2 !== 0 ? Math.floor(subCount / 2 + 1) : subCount / 2;
                parDetails[category?.name].subCount = subCount;
                parDetails[category?.name].halfSubCount = halfSubCount;
                parDetails[category?.name].labels = [];
                for (const label of category?.responseLabels) {
                  let value;
                  if (label?.responseValue) {
                    value = label.responseValue(CurrentPar);
                  } else {
                    value = CurrentPar?.row[label?.responseKey];
                  }
                  if (value == undefined || value == '' || value == null) {
                    value = 'N/A';
                  }
                  parDetails[category?.name]?.labels?.push([label?.name, value]);
                }
              }
            }
          }
        }
      }
      setParResponse(parDetails);
    }
  }, [data, GetParById]);

  useEffect(() => {
    const catLength = Object.keys(parResponse)?.length;
    if (catLength) {
      setOpenCategory(Array(catLength)?.fill(false));
    }
  }, [parResponse, setParResponse, setOpenCategory]);

  const createDisplay = () => {
    const categories = Object.keys(parResponse);
    // maps through the different categories -> inner map handles the subCategories
    return categories.map((category, index) => {
      const halfSubCount = parResponse[category].halfSubCount;
      // maps through each categories subCategory(ies)
      const subCategories = parResponse[category]?.labels.map((subCategory, index) => {
        return (
          <Col
            key={`${category}-${subCategory}-${index}`}
            className={index + 1 > halfSubCount ? 'right-pane' : 'left-pane'}
          >
            <div className="subCategory">
              <Row>
                <Question>{`${subCategory[0]}`}</Question>
                <Answer>{`${subCategory[1]}`}</Answer>
              </Row>
            </div>
          </Col>
        );
      });
      return (
        <CategoryWrapper key={`${category}-${index}`}>
          <Category onClick={() => toggle(index)}>
            <span className="category">{category}</span>
            <span className={openCategory[index] ? 'chevron-down' : 'chevron-right'}>
              {<FontAwesome name="chevron-right" />}
            </span>
            <EditBtn className="edit-response" onClick={handleEdit}>
              {<FontAwesome name="pencil" />} Edit
            </EditBtn>
            <Collapse isOpen={openCategory[index]} className="subCollapse">
              <Row>
                <Col>
                  {subCategories.filter((response) => {
                    return response.props.className === 'left-pane';
                  })}
                </Col>
                <Col>
                  {subCategories.filter((response) => {
                    return response.props.className === 'right-pane';
                  })}
                </Col>
              </Row>
            </Collapse>
          </Category>
        </CategoryWrapper>
      );
    });
  };

  if (error) {
    return <ErrorToast />;
  }
  return <>{loading ? <LoadingSpinner isStatic={true} /> : createDisplay()}</>;
};

ParDetailsDisplay.propTypes = {
  participantId: PropTypes.string,
};

export default ParDetailsDisplay;
