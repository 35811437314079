import Fuse from 'fuse.js';
const digit = new RegExp('^[0-9]+$');
import escapeStringRegexp from 'escape-string-regexp';

/**
 *
 * @param {String} searchTerm
 * @param {[Object]} arrayToFilter // array of objects
 * @param {[String]} searchOnFields // array of keys
 */
export default function Search(searchTerm, arrayToFilter, searchOnFields, fuzzy = false, fuzziness = 0.7) {
  if (fuzzy) {
    const options = {
      keys: searchOnFields,
      threshold: fuzziness,
      ignoreLocation: false,
      findAllMatches: false,
      includeMatches: false,
    };
    const fuse = new Fuse(arrayToFilter, options);
    const result = fuse.search(`'${filter}`);
    return result.map((r) => r.item);
  } else {
    const regex = new RegExp(escapeStringRegexp(searchTerm).split(' ').join('|'), 'i');
    return arrayToFilter.filter((objectToSearch) =>
      searchOnFields.some((searchField) => {
        const value = searchField.split('.').reduce((acc, currSelector) => {
          if (acc == null) {
            return null;
          }
          const selector = digit.test(currSelector) ? parseInt(currSelector) : currSelector;
          if (Number.isInteger(selector)) {
            if (!Array.isArray(acc) || acc.length < selector) {
              return null;
            }
          }
          return acc[selector];
        }, objectToSearch);
        return regex.test(value);
      }),
    );
  }
}
