import React, { useEffect, useState } from 'react';
import { Row, Col, Modal } from 'reactstrap';
import {
  SideBySideMatchDiv,
  RedAwesome,
  GreenAwesome,
  BlackAwesome,
  ColRJ,
  ColLeft,
  ColRight,
  SideBySideRowA,
  SideBySideRowB,
  ScrollDiv,
  ViewMoreButton,
  QuestionDiv,
  ResponseDiv,
  ResponseHolder,
  ResponseModalHeader,
} from './MatchSideBySideStyles';
import { minArray } from '../../utilities/arrayOperations';
import { checkADADegree } from '../../utilities/ADA';
import { MediumText } from '../../styles/text';
import petsInPets from '../../utilities/petsInPets';

export default function MatchSideBySidePanel({ housingUnit, participant }) {
  const baseRent = housingUnit.bedrooms?.length ? minArray(housingUnit.bedrooms?.map((b) => b?.rent)) : 0;
  const [advocateNotes, setAdvocateNotes] = useState({});
  const [allowsPets, setAllowsPets] = useState(null);
  const [hasPets, setHasPets] = useState(null);
  const [conflictIndoorSmoking, setConflictIndoorSmoking] = useState(false);
  const [smokingPreference, setSmokingPreference] = useState(null);
  const [conflictConvictions, setConflictConvictions] = useState(null);
  const [convictions, setConvictions] = useState(null);
  const [needsParking, setNeedsParking] = useState(null);
  const [conflictParking, setConflictParking] = useState(null);

  useEffect(() => {
    if (participant?.row && housingUnit) {
      const row = JSON.parse(participant.row);
      const notes = {
        observedDisabilities: row[85].startsWith('A mobility') ? row[85] : null,
        recentHistory: row[83],
        timeSinceStable: row[82],
        knownMentalOrMedicalBarriers: row[86],
        traumas: row[87],
      };
      setAdvocateNotes(notes);
    }
    /**
     * Smoking
     */
    let smokingTerm = 'No';
    if (
      participant?.smokingIndoorsNeeded === 'Need' ||
      participant?.tobaccoSmokingIndoors === 'NEEDS' ||
      participant?.marijuanaSmokingIndoors === 'NEEDS'
    ) {
      smokingTerm = 'Yes';
    } else if (
      participant?.smokingIndoorsNeeded === 'Preference' ||
      participant?.tobaccoSmokingIndoors === 'PREFERS' ||
      participant?.marijuanaSmokingIndoors === 'PREFERS'
    ) {
      smokingTerm = 'Preferred but not mandatory';
    }
    setSmokingPreference(smokingTerm);
    if (smokingTerm === 'Yes' && !housingUnit.allowsIndoorSmoking) {
      setConflictIndoorSmoking(true);
    }
    /**
     * End Smoking
     */

    /**
     * Convictions
     */
    let hasConvictions = false;
    if (participant?.convictionType || participant?.convictions?.length) {
      hasConvictions = true;
      setConvictions(participant?.convictionType || participant?.convictions?.map((c) => c.type).join(','));
    }
    if ((housingUnit.allowsConvictions && hasConvictions) || !hasConvictions) {
      setConflictConvictions(false);
    }
    if (!housingUnit.allowsConvictions && hasConvictions) {
      setConflictConvictions(true);
    }
    /**
     * End Convictions
     */

    /**
     * Parking
     */
    let needsParking = participant.parkingNeeded || participant.parkingPreference === 'NEEDS';
    setNeedsParking(needsParking);
    setConflictParking(needsParking && !housingUnit.parkingSpots);
    /**
     * End Parking
     */

    if (participant?.pets && housingUnit?.allows) {
      const hPets = housingUnit?.allows?.map((a) => (a.size ? `${a.size} ${a.species}` : a.species));
      const houseAllowsPets = hPets?.length ? `Yes/${hPets.join(', ')}` : 'Not allowed';

      const pPets = new Set(
        participant?.pets?.map((pet) => (pet?.size ? `${pet?.size} ${pet?.species}` : pet?.species)),
      );
      const lenPPets = pPets?.size;
      const parHasPets =
        pPets && lenPPets ? `Yes/${[...pPets].join(', ')} (${lenPPets}${lenPPets > 1 ? ' total' : ''})` : 'No';

      setAllowsPets(houseAllowsPets);
      setHasPets(parHasPets);
    }
  }, [participant, housingUnit, setAllowsPets, setHasPets, setAdvocateNotes]);

  const pRequiredADA = participant && checkADADegree(participant);
  const hProvidesADA = housingUnit && checkADADegree(housingUnit);
  const [showNeedVisit, setShowNeedVisit] = useState(false);
  const [showPreferredHousemates, setShowPreferredHousemates] = useState(false);
  const [showAdvocateNoteObservedDisability, setShowAdvocateNoteObservedDisability] = useState(false);
  const Exclamation = <RedAwesome name="exclamation-circle" />;
  const Check = <GreenAwesome name="check-circle" />;
  const Question = <BlackAwesome name="question-circle" />;

  return (
    <Row>
      <Col md={{ size: 10, offset: 1 }}>
        <SideBySideMatchDiv>
          <ScrollDiv>
            <SideBySideRowA>
              <ColRJ md="1">{participant.HMnames ? Exclamation : Check}</ColRJ>
              <ColLeft md="2">Has preferred housemate</ColLeft>
              <ColRight md="3">
                {participant.HMnames ? 'Yes' : 'No'}
                {participant.HMnames && (
                  <>
                    <ViewMoreButton
                      color="clear"
                      onClick={() => {
                        setShowPreferredHousemates(true);
                      }}
                    >
                      View response
                    </ViewMoreButton>
                    <Modal
                      isOpen={showPreferredHousemates}
                      toggle={() => {
                        setShowPreferredHousemates(false);
                      }}
                      centered
                    >
                      <ResponseModalHeader
                        toggle={() => {
                          setShowPreferredHousemates(false);
                        }}
                      />
                      <ResponseHolder>
                        <QuestionDiv>Do you have any preferred housemates?</QuestionDiv>{' '}
                        <ResponseDiv>{participant.HMnames}</ResponseDiv>
                      </ResponseHolder>
                    </Modal>
                  </>
                )}
              </ColRight>
              <ColLeft md="2">Number of Rooms</ColLeft>
              <ColRight md="3">{housingUnit.bedrooms?.length}</ColRight>
            </SideBySideRowA>
            <SideBySideRowB>
              <ColRJ md="1">
                {(participant?.income || participant?.currentIncome || 0) > baseRent ? Check : Exclamation}
              </ColRJ>
              <ColLeft md="2">Monthly Income</ColLeft>
              <ColRight md="3">${participant?.income || participant?.currentIncome || 0}</ColRight>
              <ColLeft md="2">Min. Room Rent</ColLeft>
              <ColRight md="3">${baseRent}</ColRight>
            </SideBySideRowB>
            <SideBySideRowA>
              <ColRJ md="1">&nbsp;</ColRJ>
              <ColLeft md="2">Has Voucher</ColLeft>
              <ColRight md="3">
                {participant.vouchers?.length ? 'Yes/' : 'No'}
                {participant.vouchers
                  ?.map((v) => {
                    return v.type;
                  })
                  .join(',')}
              </ColRight>
              <ColLeft md="2">Accepts Voucher</ColLeft>
              <ColRight md="3">
                {housingUnit.accepts?.length ? 'Yes/' : 'No'}
                {housingUnit.accepts
                  ?.map((v) => {
                    return v.type;
                  })
                  .join(',')}
              </ColRight>
            </SideBySideRowA>
            <SideBySideRowB>
              <ColRJ md="1">
                {participant.preferredRegions?.map((pr) => pr.id).includes(housingUnit.region?.id)
                  ? Check
                  : Exclamation}
              </ColRJ>
              <ColLeft md="2">Preferred SPAs</ColLeft>
              <ColRight md="3">
                {participant.preferredRegions
                  ?.map((pr) => pr?.name.split(' ')[1])
                  .sort()
                  .join(',')}
                {(participant?.needToVisitWhoWhat || participant?.needsToVisit?.name) && (
                  <>
                    <ViewMoreButton
                      color="clear"
                      onClick={() => {
                        setShowNeedVisit(true);
                      }}
                    >
                      View response
                    </ViewMoreButton>
                    <Modal
                      isOpen={showNeedVisit}
                      toggle={() => {
                        setShowNeedVisit(false);
                      }}
                      centered
                    >
                      <ResponseModalHeader
                        toggle={() => {
                          setShowNeedVisit(false);
                        }}
                      />
                      <ResponseHolder>
                        <QuestionDiv>Do you have a special reason for selecting this region?</QuestionDiv>{' '}
                        <ResponseDiv>
                          <MediumText>
                            Need to visit {participant?.needToVisitWhoWhat || participant?.needsToVisit?.name}
                          </MediumText>
                          <MediumText>{participant?.needVisitLocation || participant?.needsToVisit?.detail}</MediumText>
                          <MediumText>
                            {participant?.needVisitFrequency?.toLowerCase() || participant?.needsToVisit?.frequency}
                          </MediumText>
                        </ResponseDiv>
                      </ResponseHolder>
                    </Modal>
                  </>
                )}
              </ColRight>
              <ColLeft md="2">SPA</ColLeft>
              <ColRight md="3">{housingUnit.region?.name.split(' ')[1]}</ColRight>
            </SideBySideRowB>
            <SideBySideRowA>
              <ColRJ md="1">{pRequiredADA?.degree <= hProvidesADA?.degree ? Check : Exclamation}</ColRJ>
              <ColLeft md="2">Required Physical Accessibility</ColLeft>
              <ColRight md="3">
                {pRequiredADA?.degreeLabel}
                {advocateNotes?.observedDisabilities && (
                  <>
                    <ViewMoreButton
                      color="clear"
                      onClick={() => {
                        setShowAdvocateNoteObservedDisability(true);
                      }}
                    >
                      View Advocate Note
                    </ViewMoreButton>
                    <Modal
                      isOpen={showAdvocateNoteObservedDisability}
                      toggle={() => {
                        setShowAdvocateNoteObservedDisability(false);
                      }}
                      centered
                    >
                      <ResponseModalHeader
                        toggle={() => {
                          setShowAdvocateNoteObservedDisability(false);
                        }}
                      />
                      <ResponseHolder>
                        <QuestionDiv>Advocate noted disability related information</QuestionDiv>{' '}
                        <ResponseDiv>{advocateNotes.observedDisabilities}</ResponseDiv>
                      </ResponseHolder>
                    </Modal>
                  </>
                )}
              </ColRight>
              <ColLeft md="2">Physical Accessibility</ColLeft>
              <ColRight md="3">{hProvidesADA?.degreeLabel}</ColRight>
            </SideBySideRowA>
            <SideBySideRowB>
              <ColRJ md="1">
                {participant.evictions == null && Check}
                {((participant.evictions > 0 && housingUnit.allowsEvictions) || participant.evictions === 0) && Check}
                {participant.evictions > 0 && !housingUnit.allowsEvictions && Exclamation}
              </ColRJ>
              <ColLeft md="2">Evictions</ColLeft>
              <ColRight md="3">{participant.evictions ? `Yes/${participant.evictions}` : 'No'}</ColRight>
              <ColLeft md="2">Allows Evictions</ColLeft>
              <ColRight md="3">{housingUnit.allowsEvictions ? 'Yes' : 'No'}</ColRight>
            </SideBySideRowB>
            <SideBySideRowA>
              <ColRJ md="1">{conflictConvictions ? Exclamation : Check}</ColRJ>
              <ColLeft md="2">Convictions</ColLeft>
              <ColRight md="3">{convictions ? `Yes/${convictions}` : 'No'}</ColRight>
              <ColLeft md="2">Allows Convictions</ColLeft>
              <ColRight md="3">{housingUnit.allowsConvictions ? 'Yes' : 'No'}</ColRight>
            </SideBySideRowA>
            <SideBySideRowB>
              <ColRJ md="1">{conflictParking ? Exclamation : Check}</ColRJ>
              <ColLeft md="2">Parking needed</ColLeft>
              <ColRight md="3">{needsParking ? 'Yes' : 'No'}</ColRight>
              <ColLeft md="2">Parking Spots</ColLeft>
              <ColRight md="3">{housingUnit.parkingSpots || 0}</ColRight>
            </SideBySideRowB>
            <SideBySideRowA>
              <ColRJ md="1">
                {participant.isSexOffender === false || participant.isSexOffender === housingUnit.allowsSexOffenders
                  ? Check
                  : participant.isSexOffender == null
                  ? Question
                  : Exclamation}
              </ColRJ>
              <ColLeft md="2">Registered Sex Offender</ColLeft>
              <ColRight md="3">
                {participant.isSexOffender ? 'Yes' : participant.isSexOffender === false ? 'No' : 'Unsure'}
              </ColRight>
              <ColLeft md="2">Allows Reg. Sex Offenders</ColLeft>
              <ColRight md="3">{housingUnit.allowsSexOffenders ? 'Yes' : 'No'}</ColRight>
            </SideBySideRowA>
            <SideBySideRowB>
              <ColRJ md="1">
                {petsInPets(
                  participant?.pets ? participant?.pets : [],
                  housingUnit?.allows ? housingUnit?.allows : [],
                ) ? (
                  <GreenAwesome name="check-circle" />
                ) : (
                  <RedAwesome name="exclamation-circle" />
                )}
              </ColRJ>
              <ColLeft md="2">Planned Pets</ColLeft>
              <ColRight md="3">{hasPets}</ColRight>
              <ColLeft md="2">Allows Pets</ColLeft>
              <ColRight md="3">{allowsPets}</ColRight>
            </SideBySideRowB>
            <SideBySideRowA>
              <ColRJ md="1">{conflictIndoorSmoking ? Exclamation : Check}</ColRJ>
              <ColLeft md="2">Requires Indoor Smoking</ColLeft>
              <ColRight md="3">{smokingPreference}</ColRight>
              <ColLeft md="2">Allows Indoor Smoking</ColLeft>
              <ColRight md="3">{housingUnit.allowsIndoorSmoking ? 'Yes' : 'No'}</ColRight>
            </SideBySideRowA>
          </ScrollDiv>
        </SideBySideMatchDiv>
      </Col>
    </Row>
  );
}
