import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import { colors, typography } from '../styles/constants';

export const StyledSearchTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    &:hover fieldset {
      border: 1px solid ${colors.selected};
    }
    &.focused fieldset {
      border: 1px solid ${colors.selected};
    }
  }
  .MuiOutlinedInput-root.Mui-focused fieldset {
    border: 1px solid ${colors.selected};
  }
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${colors.disabledButton};
  }
`;
