import styled from 'styled-components';
import { borders, links, colors } from '../../../styles/constants';
import { PrimaryButton } from '../../buttons/ButtonStyles';

export const NotesContainer = styled.div`
  margin: ${(props) => (props.align === 'center' ? 'auto' : '')};
`;

export const noteWrapperStyle = { display: 'flex', margin: '28px 0', alignItems: 'baseline' };

export const NotesHeaderDiv = styled.div`
  padding-bottom: 8px;
  border-bottom: ${borders.border};
  margin-bottom: 28px;
`;

export const InputDivHolder = styled.div`
  display: flex;
  background: white;
  flex-direction: row;
  min-height: ${(props) => (props.minHeight ? `${props.minHeight}px` : '32px')};
  width: 100%;
  border: ${(props) => (props.border ? props.border : borders.border)};
  border-radius: ${(props) => (props.squared ? '0px' : '7px')};
  padding: 0px;
  margin: ${(props) => (props.marginTop != null ? `${props.marginTop}px` : '15px')} 0 50px;
`;

export const LeftInputDiv = styled.div`
  width: 80%;
  padding: 8px;
`;

export const rightInputStyle = {
  width: '100%',
  background: 'white',
  minHeight: '130px',
  margin: '15px 0',
  borderRadius: '7px',
  border: '2px solid #5BB8B4',
  boxShadow: '0px 3px 16px #0A4B6D14',
};

export const ParagraphHolder = styled.div`
  &:first-child {
    margin-top: 10px;
  }
`;

export const RightSubmitButton = styled(PrimaryButton)`
  width: 20%;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  height: unset;
`;

export const LinkButton = styled.button`
  border: none;
  color: ${links.regularLink};
  margin-right: 10px;
  background-color: transparent;
  &:disabled {
    opacity: 0.4;
  }
  &:hover {
    background-color: ${colors.hover};
  }
`;

export const NoteIconButton = styled.button`
  border: none;
  color: ${links.regularLink};
  background-color: transparent;
  &:focus {
    outline: none;
  }
`;

export const iconsWrapperStyle = { display: 'flex', margin: '0 8px 20px 15px' };
