import React, { useState, useCallback } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import { cypressFriendlyId } from '../utilities/cypressFriendlyId';
import { makeStyles } from '@material-ui/core';
import { colors, typography } from '../styles/constants';
import { StyledSearchTextField } from './SearchFieldStyles';
import FontAwesome from 'react-fontawesome';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    color: colors.selected,
    borderRadius: '8px',
  },
  input: {
    color: typography.fontColor.text,
    width: '100%',
    borderRadius: '8px',
    backgroundColor: 'white',
  },
}));

export const SearchField = ({ content, inputReactiveVariable }) => {
  const [value, setValue] = useState(inputReactiveVariable());
  const handleChange = useCallback(
    (evt) => {
      const value = evt.target.value;
      inputReactiveVariable(value);
      setValue(value);
    },
    [setValue, inputReactiveVariable],
  );
  const classes = useStyles();

  return (
    <div>
      <StyledSearchTextField
        size="small"
        classes={classes}
        InputProps={{
          className: classes.input,
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <FontAwesome name="search" style={{ color: colors.selected }} />
            </InputAdornment>
          ),
        }}
        variant="outlined"
        id={`${cypressFriendlyId(content.split(' ').join(''))}`}
        placeholder={content}
        onChange={handleChange}
        defaultValue={value}
      />
    </div>
  );
};
