import React, { useEffect, useState } from 'react';
import { UnitDashBoardView } from './views/UnitDashBoardView';
import { ParticipantDashboardView } from './views/ParticipantDashboardView';
import { ParticipantDetailView } from './views/ParticipantDetailView';
import { AddHousingUnitView } from './views/AddHousing';
import { DetailHousing } from './views/DetailHousing';
import { AddParticipantView } from './views/AddParticipantView.jsx';
import { AdminManageStaff } from './views/AdminManageStaff';
import MatchPARtoUnit from './views/MatchPARtoUnit';
import DetailMatch from './views/DetailMatch';
import ParToParSelectionView from './views/ParToParSelection.jsx';
import ParToParDetailView from './views/ParToParDetail.jsx';
import { MainNavbar } from './components/navbars/MainNavBar.jsx';
import AuthDashboardView from './views/AuthDashboardView';
import Interest from './views/Interest';
import Brochure from './views/Brochure';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { MuiThemeProvider } from '@material-ui/core';
import theme from './styles/mui/MUITheme';

export default function Routes() {
  const { isAuthenticated, user, isLoading } = useAuth0();
  const [isAdvocate, setIsAdvocate] = useState(false);
  const [isMPO, setIsMPO] = useState(false);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [isHousingLocator, setIsHousingLocator] = useState(false);
  const [isInterest, setIsInterest] = useState(false);
  const [isBrochure, setIsBrochure] = useState(false);
  useEffect(() => {
    if (user) {
      const roles = user['http://schemas.aroyomatch.com/claims/roles'];
      if (roles?.includes('Advocate')) {
        setIsAdvocate(true);
      }
      if (roles?.includes('MPO')) {
        setIsMPO(true);
      }
      if (roles?.includes('UserAdmin')) {
        setIsUserAdmin(true);
      }
      if (roles?.includes('HousingLocator')) {
        setIsHousingLocator(true);
      }
    }
  }, [setIsAdvocate, setIsHousingLocator, setIsMPO, user]);
  let location = useLocation();
  useEffect(() => {
    if (location) {
      if (location.pathname.startsWith('/interest')) {
        setIsInterest(true);
      }
      if (location.pathname.startsWith('/brochure')) {
        setIsBrochure(true);
      }
    }
  }, [location]);
  return (
    <Switch>
      {(isInterest || isBrochure) && (
        <>
          <Route path="/interest">
            <Interest />
          </Route>
          <MuiThemeProvider theme={theme}>
            <Route path="/brochure">
              <Brochure />
            </Route>
          </MuiThemeProvider>
        </>
      )}
      {/* Available for both logged in and not */}
      {!isInterest && isAuthenticated && !isLoading && (
        <>
          <MainNavbar />
          {(isUserAdmin || isMPO || isAdvocate) && (
            <>
              <Route path="/participants" exact>
                <ParticipantDashboardView />
              </Route>
              <Route path="/participant/add">
                <AddParticipantView />
              </Route>
              <Route path="/participant/detail/:participantId">
                <ParticipantDetailView />
              </Route>
            </>
          )}
          {(isUserAdmin || isMPO || isHousingLocator) && (
            <>
              <Route path="/housing/detail/:houseUnitId">
                <DetailHousing />
              </Route>
              <Route path="/housing/edit/:houseUnitId">
                <AddHousingUnitView />
              </Route>
              <Route path="/housing/add/location" exact>
                <AddHousingUnitView />
              </Route>
              <Route path="/housing" exact>
                <UnitDashBoardView />
              </Route>
              <Route path="/" exact>
                <Redirect to="/housing" />
              </Route>
            </>
          )}
          {(isUserAdmin || isMPO) && (
            <>
              <Route path="/housing/match/:houseUnitId/" exact>
                <MatchPARtoUnit />
              </Route>
              <Route path="/housing/match/:houseUnitId/:participantId">
                <DetailMatch />
              </Route>
              <Route path="/housing/partopar/:houseUnitId">
                <ParToParSelectionView />
              </Route>
              <Route path="/housing/partopardetail/:houseUnitId">
                <ParToParDetailView />
              </Route>
            </>
          )}
          <>
            <MuiThemeProvider theme={theme}>
              <Route path="/admin/manage-staff" exact>
                <AdminManageStaff orgId={user?.['http://schemas.aroyomatch.com/claims/org']} />
              </Route>
            </MuiThemeProvider>
          </>
          {isAdvocate && (
            <Route path="/" exact>
              <Redirect to="/participants" />
            </Route>
          )}
        </>
      )}
      {!isInterest && !isAuthenticated && !isLoading && (
        <>
          <MainNavbar />
          <Route path="/">
            <AuthDashboardView />
          </Route>
        </>
      )}
    </Switch>
  );
}
