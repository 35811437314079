import { useState, useCallback, useLayoutEffect } from 'react';

export const useToggle = (initial) => {
  const [open, setOpen] = useState(initial);
  return [open, useCallback((force = false) => setOpen((status) => (force === true ? false : !status)), [setOpen])];
};

export const useWindowDimensions = () => {
  const [dimensions, setDimensions] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateDimensions() {
      setDimensions([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('updateDimensions', updateDimensions);
    updateDimensions();
    return () => window.removeEventListener('updateDimensions', updateDimensions);
  }, []);
  return dimensions;
};
