export const defaultState = {
  activeStatus: {
    open: false,
    selected: [0, 1, 2],
    controlled: true,
    limit: Infinity,
    filterType: 'ActiveStatus',
    display: 'Status',
    options: [
      [0, 'Moved In', 'movedIn'],
      [1, 'Assigned', 'assigned'],
      [2, 'Available', 'available'],
      [3, 'Inactive', 'inactive'],
    ],
  },
};
