import { gql } from '@apollo/client';

export const DeactivateUser = gql`
  mutation DeactivateUser($email: String!) {
    DeactivateUser(email: $email)
  }
`;

export const DeleteUser = gql`
  mutation DeleteUser($id: ID!) {
    DeleteUser(id: $id)
  }
`;

export const CreateOrUpdateUser = gql`
  mutation CreateOrUpdateUser($email: String!, $name: String!, $roles: [String], $orgId: ID!) {
    CreateOrUpdateUser(email: $email, name: $name, roles: $roles, orgId: $orgId)
  }
`;

export const GetUserAccounts = gql`
  query GetUserAccounts {
    UserAccount {
      id
      isDeletable
      ... on Advocate {
        id
        name
        email
        labels
        status {
          type
        }
        organization {
          id
        }
        participants {
          id
        }
        createdAt {
          formatted
        }
        updatedAt {
          formatted
        }
      }
      ... on MPO {
        id
        name
        email
        labels
        status {
          type
        }
        organization {
          id
        }
        createdAt {
          formatted
        }
        updatedAt {
          formatted
        }
      }
      ... on HousingLocator {
        id
        name
        email
        labels
        status {
          type
        }
        organization {
          id
        }
        createdAt {
          formatted
        }
        updatedAt {
          formatted
        }
      }
      ... on UserAdmin {
        id
        name
        email
        labels
        status {
          type
        }
        organization {
          id
        }
        createdAt {
          formatted
        }
        updatedAt {
          formatted
        }
      }
      ... on SurveyCreator {
        id
        name
        email
        labels
        status {
          type
        }
        createdAt {
          formatted
        }
        updatedAt {
          formatted
        }
      }
      ... on SurveyCreator {
        id
        name
        email
        labels
        status {
          type
        }
        createdAt {
          formatted
        }
        updatedAt {
          formatted
        }
      }
    }
  }
`;
