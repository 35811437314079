import React from 'react';
import { Col, Row } from 'reactstrap';
import { UploadRow, UploadAwesome } from './uploadListStyles';

export default function UploadList({ participants = [], images = [] }) {
  return (
    <Row>
      <Col md={{ size: 8, offset: 2 }}>
        {participants?.map((p) => {
          return (
            <UploadRow key={`${p.id}-added-par`}>
              <div>
                <UploadAwesome name="check-circle" /> {p.name}
              </div>
            </UploadRow>
          );
        })}
        {images?.map((i) => {
          return <UploadRow key={`${i}-added-image`}></UploadRow>;
        })}
      </Col>
    </Row>
  );
}
