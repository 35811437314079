import React from 'react';
import PropTypes from 'prop-types';
import {
  ActionMuiModal,
  ActionMuiBackdrop,
  ActionMuiHeader,
  ModalBodyContainer,
  ModalCloseButton,
} from './ActionModalStyles';
import { ModalButtonGroup, ModalButtonFilled } from './ModalStyles';

const ActionModal = ({ isOpen, handleCancelClick, header, modalButtons, children }) => {
  const backdropProps = { $opacity: '0.70' };

  const ModalButtons = modalButtons.map((button, i) => {
    return (
      <ModalButtonFilled
        key={`${button?.text}-btn-${i}`}
        onClick={button?.action}
        className={`action-btn ${button?.text?.toLowerCase()?.split(' ')[0]}-btn ${button?.isHidden && 'hidden'}`}
        disabled={button?.disabled}
      >
        {button?.text}
      </ModalButtonFilled>
    );
  });
  return (
    <ActionMuiBackdrop open={isOpen}>
      <ActionMuiModal
        open={isOpen}
        BackdropComponent={ActionMuiBackdrop}
        onBackdropClick={handleCancelClick}
        BackdropProps={backdropProps}
      >
        <ModalBodyContainer className="modal-body-container">
          <ModalCloseButton onClick={handleCancelClick}>x</ModalCloseButton>
          {header && <ActionMuiHeader className="action-modal-header">{header}</ActionMuiHeader>}
          {children}
          <ModalButtonGroup variant="text" orientation="vertical">
            {ModalButtons}
          </ModalButtonGroup>
        </ModalBodyContainer>
      </ActionMuiModal>
    </ActionMuiBackdrop>
  );
};

ActionModal.propTypes = {
  header: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  handleCancelClick: PropTypes.func,
  modalButtons: PropTypes.shape({
    text: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    isHidden: PropTypes.bool,
  }).isRequired,
  children: PropTypes.node,
};

export default ActionModal;
