import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import BrochureCardWrapper from './BrochureCardWrapper';
import { cardStyles } from './BrochureStyles';
import { makePetStr } from './housingUnitHelpers';
import { physicalAccessibilityOptionsDict } from '../../components/HousingUnitCreation/sections/AccessibilityByDegreesDict';
import KeyValPair from './BrochureKeyVal';
import PropTypes from 'prop-types';

const DetailsDisplay = (props) => {
  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <KeyValPair category="Total Bedrooms" value={props?.totalBedrooms} />
        <KeyValPair category="Rent Per Room" value={props?.rentPerRoom} />
        <KeyValPair category="Sq Ft" value={props?.sqFt} />
        <KeyValPair category="Utilities Included" value={props?.utilitiesIncluded} />
        <KeyValPair category="Amenities" value={props?.amenities} />
        <KeyValPair category="Parking Spots" value={props?.parkingSpots} />
        <KeyValPair category="Parking Spots Accessible" value={props?.parkingSpotsAdaAccessible} />
        <KeyValPair category="Accessibility" value={props?.accessibilities} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <KeyValPair category="Accepts Vouchers" value={props?.vouchers} />
        <KeyValPair category="Allows Pets" value={props?.allowedPets} />
        <KeyValPair category="Smoking Indoors" value={props?.allowsIndoorSmoking} />
        <KeyValPair category="Evictions Considered" value={props?.allowsEvictions} />
        <KeyValPair category="Convictions Considered" value={props?.allowsConvictions} />
        <KeyValPair category="Sex Offender Considered" value={props?.allowsSexOffenders} />
      </Grid>
    </Grid>
  );
};

const UnitDetails = ({ classes, hu }) => {
  const {
    bedrooms,
    totalBedrooms,
    utilities,
    housingUnitAmenities,
    vouchers,
    parkingSpots,
    parkingSpotsAdaAccessible,
    pets,
    region,
    accessibilities,
    allowsConvictions,
    allowsEvictions,
    allowsIndoorSmoking,
    allowsSexOffenders,
  } = hu;
  const boolToStr = (bool) => (bool ? 'Yes' : 'No');

  return (
    <BrochureCardWrapper>
      <Typography variant="h4" color="primary" className={classes.cardHeader}>
        Unit Details
      </Typography>
      <DetailsDisplay
        totalBedrooms={totalBedrooms}
        rentPerRoom={bedrooms?.map((b) => b?.rent.toLocaleString())?.join('/')}
        sqFt={bedrooms?.reduce((acc, cur) => (acc += cur?.squareFeet), 0).toLocaleString() || ''}
        regionName={region?.name || 'unknown'}
        utilitiesIncluded={utilities?.map((u) => u?.type).join(', ') || ''}
        amenities={housingUnitAmenities?.map((a) => a?.type).join(', ') || ''}
        accessibilities={
          accessibilities?.length
            ? physicalAccessibilityOptionsDict[accessibilities?.[0]?.degree]?.['HousingUnit']
            : 'More than 1 flight'
        }
        parkingSpots={parkingSpots || 0}
        parkingSpotsAdaAccessible={boolToStr(parkingSpotsAdaAccessible)}
        vouchers={vouchers?.length ? vouchers.join(', ') : 'No Vouchers Accepted'}
        allowedPets={pets?.length > 0 ? makePetStr(pets) : 'No Pets Allowed'}
        allowsConvictions={boolToStr(allowsConvictions)}
        allowsEvictions={boolToStr(allowsEvictions)}
        allowsIndoorSmoking={boolToStr(allowsIndoorSmoking)}
        allowsSexOffenders={boolToStr(allowsSexOffenders)}
      />
    </BrochureCardWrapper>
  );
};

UnitDetails.propTypes = {
  accessibilities: PropTypes.arrayOf(
    PropTypes.shape({
      degree: PropTypes.number,
      ADA: PropTypes.string,
    }),
  ),
  allowsConvictions: PropTypes.bool,
  allowsEvictions: PropTypes.bool,
  allowsIndoorSmoking: PropTypes.bool,
  allowsSexOffenders: PropTypes.bool,
  bedrooms: PropTypes.arrayOf(
    PropTypes.shape({
      amenities: PropTypes.array,
      rent: PropTypes.number,
      squareFeet: PropTypes.number,
    }),
  ),
  classes: PropTypes.object.isRequired,
  parkingSpots: PropTypes.number,
  parkingSpotsAdaAccessible: PropTypes.bool,
  pets: PropTypes.arrayOf(
    PropTypes.shape({
      species: PropTypes.string,
      size: PropTypes.string,
    }),
  ),
  totalBedrooms: PropTypes.number,
  utilities: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
    }),
  ),
  vouchers: PropTypes.arrayOf(PropTypes.string),
};

export default withStyles(cardStyles)(UnitDetails);
