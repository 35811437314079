import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import { Text } from '../../../styles/text';
import { MuiModalTemplate, MuiModalHeader, MuiBackdropTemplate } from '../../modals/ModalStyles';
import { typography, colors, links } from '../../../styles/constants';

export const ViewMatchHistoryContainer = styled(Container)`
  padding: 55px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
`;
export const MatchHistoryHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 2fr 1fr 1fr 0.75fr;
  margin-bottom: 15px;
`;

export const LabelText = styled(Text)`
  font-size: 20px;
  font-weight: bold;
`;

export const DateText = styled(Text)`
  font-size: 13px;
  color: ${typography.fontColor.grayText};
`;

export const BodyText = styled(Text)`
  font-size: ${(props) => props.fontSize || '15px'};
  ${(props) => (props.displayFlex ? 'display: flex' : '')};
  ${(props) => (props.flexDirCol ? 'flex-direction: column' : '')};
`;

export const HeaderLabelItem = styled.div`
  grid-column: ${(props) => props.gridColumn};
`;

export const MatchHistoryItemContainer = styled(MatchHistoryHeaderContainer)`
  grid-row-gap: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #a5c9ff;
  align-items: center;
  &:last-child {
    border-bottom: none;
  }
`;
export const MatchHistoryItem = styled.div`
  margin-bottom: 5px;
`;

export const VMHModal = styled(MuiModalTemplate)``;

export const VMHModalHeader = styled(MuiModalHeader)`
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 45px;
`;

export const VMHModalBackDrop = styled(MuiBackdropTemplate)``;

export const HUAddress = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HUAvatar = styled(HUAddress)`
  flex-direction: row;
  margin-right: 15px;
`;

export const ChangeButton = styled.button`
  border: none;
  background-color: transparent;
  padding-left: 0;
  outline: none;
  text-align: left;
  color: ${typography.fontColor.alertLinkColor};
  &:focus {
    outline: none !important;
  }
`;
