import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import SelectOneField from '../../buttons/SelectOneField';
import SelectMultiField from '../../buttons/SelectMultiField';
import FormButton from '../../buttons/FormButton';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  QueryHousingVoucherPet,
  UpdateHousingUnitAcceptances,
  AddHousingUnitVoucher,
  AddPetToHousingUnit,
  RemovePetFromHousingUnit,
  RemoveHousingUnitVoucher,
} from './UnitAcceptancesGQL.js';
import acceptancessFromHousingUnit from '../../../utilities/stateProcessing/acceptancessFromHousingUnit';
import { FormNavBar } from '../../navbars/FormNavBar';
import UnitCompleteModal from '../../modals/UnitCompleteModal';
import { LoadingSpinner, ErrorToast } from '../../Alerts';
import { CompletedSectionsShape } from '../../../views/AddHousing';

function UnitAcceptances({ isUnitActive, completedSections, toggle, modal }) {
  const { houseUnitId } = useParams();
  const history = useHistory();
  //state
  const [formState, setFormState] = React.useState({
    vouchers: [],
    originalVouchers: [],
    noSexOffender: null,
    noIndoorSmoking: null,
    noPriorEvictions: null,
    noPriorConvictions: null,
    pets: [],
    originalPets: [],
  });

  // Options for select one/many
  const [voucherOptions, setVoucherOptions] = React.useState(new Map());
  const [petOptions, setPetOptions] = React.useState(new Map());
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  const [hasErrors, setHasErrors] = React.useState(false);

  // queries
  const { data, error, loading, stopPolling } = useQuery(QueryHousingVoucherPet, { variables: { id: houseUnitId } });

  // mutations
  const [updateHousingUnitAcceptances] = useMutation(UpdateHousingUnitAcceptances);
  const [addHousingUnitVoucher] = useMutation(AddHousingUnitVoucher);
  const [removeHousingUnitVoucher] = useMutation(RemoveHousingUnitVoucher);
  const [addPetToHousingUnit] = useMutation(AddPetToHousingUnit);
  const [removePetFromHousingUnit] = useMutation(RemovePetFromHousingUnit);
  const notAllowedOptions = new Map([
    ['Does allow', false],
    ['Does not allow', true],
  ]);

  const checkRequiredFields = () => {
    return formState?.noSexOffender === null || formState?.noIndoorSmoking === null;
  };
  // initial effect for state, voucher types, and pet types
  useEffect(() => {
    if (data?.HousingUnit?.length && data?.Voucher && data?.Pet) {
      const { HousingUnit, Voucher, Pet } = data;
      const housingUnit = HousingUnit[0];
      const newState = acceptancessFromHousingUnit(housingUnit);
      setFormState(newState);
      const voucherOptions = new Map();
      for (const voucher of Voucher) {
        voucherOptions.set(voucher.type, voucher.id);
      }
      setVoucherOptions(voucherOptions);
      const petOptions = new Map();
      for (const pet of Pet) {
        const showSpecies = ['Dog', 'Cat'];
        if (showSpecies.includes(pet?.species)) {
          const petName = pet.size ? `${pet.size} ${pet.species}` : pet.species;
          petOptions.set(petName, pet.id);
        }
      }
      setPetOptions(petOptions);
      stopPolling();
    }
  }, [data, setFormState, setVoucherOptions, setPetOptions, stopPolling]);

  /// once again no processing
  const onChange = (id, value) => {
    setIsFormSubmitted(false);
    setHasErrors(false);
    const newState = { ...formState, [id]: value };
    setFormState(newState);
  };

  const handleSubmit = async (isSaveAndClose = false, isFixLater = false) => {
    const location = completedSections?.location;
    const rooms = completedSections?.rooms;
    const accessibility = completedSections?.accessibility;
    const otherSectionsComplete = location && rooms && accessibility;
    const requiredFieldErrors = checkRequiredFields();
    setHasErrors(requiredFieldErrors);
    setIsFormSubmitted(true);

    for (const voucher of formState.vouchers) {
      if (!formState.originalVouchers.includes(voucher)) {
        // add if we dont already have a relation
        try {
          await addHousingUnitVoucher({
            variables: {
              housingUnitId: houseUnitId,
              voucherId: voucher,
            },
          });
        } catch (err) {
          console.error(err);
        }
      }
      for (const voucher of formState.originalVouchers) {
        if (!formState.vouchers.includes(voucher)) {
          try {
            await removeHousingUnitVoucher({
              variables: {
                housingUnitId: houseUnitId,
                voucherId: voucher,
              },
            });
          } catch (err) {
            console.error(err);
          }
        }
      }
    }
    try {
      for (const pet of formState.pets) {
        if (!formState.originalPets.includes(pet)) {
          await addPetToHousingUnit({
            variables: { from: { id: houseUnitId }, to: { id: pet } },
          });
        }
      }
      for (const pet of formState.originalPets) {
        if (pet && !formState.pets.includes(pet)) {
          await removePetFromHousingUnit({
            variables: { from: { id: houseUnitId }, to: { id: pet } },
          });
        }
      }
    } catch (err) {
      console.error(err);
    }

    try {
      const date = new Date();
      const dateYear = date.getFullYear();
      const dateMonth = date.getMonth() + 1;
      const dateDay = date.getDate();
      const dateHour = date.getHours();
      const dateMinute = date.getMinutes();
      const dateSecond = date.getMinutes();
      const dateTimezone = moment.tz.guess();
      if (!requiredFieldErrors) {
        const { errors } = await updateHousingUnitAcceptances({
          variables: {
            id: houseUnitId,
            allowsSexOffenders: !formState.noSexOffender,
            allowsIndoorSmoking: !formState.noIndoorSmoking,
            allowsConvictions: !formState.noPriorConvictions,
            allowsEvictions: !formState.noPriorEvictions,
            updatedAt: {
              day: dateDay,
              month: dateMonth,
              year: dateYear,
              hour: dateHour,
              minute: dateMinute,
              second: dateSecond,
              timezone: dateTimezone,
            },
          },
        });
        if (errors) {
          console.error(errors);
        }
      }

      if (otherSectionsComplete && !isUnitActive && !requiredFieldErrors && isSaveAndClose) {
        toggle();
      } else if ((!isSaveAndClose && !requiredFieldErrors) || isFixLater) {
        history.push(`/housing/edit/${houseUnitId}/accessibility`);
      } else if (isSaveAndClose) {
        history.push('/housing/');
      }
    } catch (err) {
      <ErrorToast />;
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }
  if (error) {
    return <ErrorToast />;
  }
  // sexoffender queston
  return (
    <div>
      <FormNavBar onClick={handleSubmit} />
      <Col md={{ size: 12 }}>
        <h2 className="form-title center-block text-center">Unit Allowances and Acceptances</h2>
        <div className="question-area" id={`voucher-accepted`}>
          <h4 className="center-block text-center">Any of the following vouchers accepted?</h4>
          <p className="center-block text-center">Check all that apply</p>
          <SelectMultiField id="vouchers" onChange={onChange} value={formState?.vouchers} selections={voucherOptions} />
        </div>
        <div className="question-area" id={`sex-offender-accepted`}>
          <h4 className="center-block text-center">Does the unit allow sex offenders?*</h4>
          <p className="center-block text-center">Select the option that applies</p>
          {formState?.noSexOffender === null && isFormSubmitted ? (
            <div className="invalid-feedback" style={{ display: 'block', textAlign: 'center', marginBottom: 10 }}>
              Required Field. Please make a selection.
            </div>
          ) : null}
          <SelectOneField
            id="noSexOffender"
            onChange={onChange}
            value={formState.noSexOffender}
            selections={notAllowedOptions}
          />
        </div>
        <div className="question-area" id={`eviction-accepted`}>
          <h4 className="center-block text-center">Does the unit allow prior evictions?</h4>
          <p className="center-block text-center">Select the option that applies</p>
          <SelectOneField
            id="noPriorEvictions"
            onChange={onChange}
            value={formState.noPriorEvictions}
            selections={notAllowedOptions}
          />
        </div>
        <div className="question-area" id={`conviction-accepted`}>
          <h4 className="center-block text-center">Does the unit allow prior convictions?</h4>
          <p className="center-block text-center">Select the option that applies</p>
          <SelectOneField
            id="noPriorConvictions"
            onChange={onChange}
            value={formState.noPriorConvictions}
            selections={notAllowedOptions}
          />
        </div>

        <div className="question-area" id={`smoking-accepted`}>
          <h4 className="center-block text-center">Is indoor smoking allowed?*</h4>
          {formState?.noIndoorSmoking === null && isFormSubmitted ? (
            <div className="invalid-feedback" style={{ display: 'block', textAlign: 'center', marginBottom: 10 }}>
              Required Field. Please make a selection.
            </div>
          ) : null}
          <SelectOneField
            id="noIndoorSmoking"
            onChange={onChange}
            selections={notAllowedOptions}
            value={formState.noIndoorSmoking}
          />
        </div>
        <div className="question-area" id={`pets-accepted`}>
          <h4 className="center-block text-center">Are any of the following pets allowed? </h4>
          <p className="center-block text-center">Check all that apply</p>
          <SelectMultiField id="pets" onChange={onChange} selections={petOptions} value={formState?.pets} />
        </div>
      </Col>
      <div className="form-title center-block text-center">
        <FormButton error={hasErrors} submit={false} onClick={handleSubmit} id={`allowances`}></FormButton>
      </div>
      <UnitCompleteModal toggle={toggle} modal={modal} />
    </div>
  );
}

UnitAcceptances.propTypes = {
  isUnitActive: PropTypes.bool.isRequired,
  completedSections: PropTypes.shape({
    location: PropTypes.bool,
    description: PropTypes.bool,
    rooms: PropTypes.bool,
    acceptances: PropTypes.bool,
    accessibility: PropTypes.bool,
    viewing: PropTypes.bool,
  }).isRequired,
  toggle: PropTypes.func.isRequired,
  modal: PropTypes.bool.isRequired,
};

export default UnitAcceptances;
