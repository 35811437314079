import styled from 'styled-components';
import { Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { borders, links, typography } from '../styles/constants';
import FontAwesome from 'react-fontawesome';

export const MatchingContainer = styled.div`
  margin: 16px;
  padding: 16px;
  padding-bottom: 100px;
  max-width: 1200px;
  margin: 0 auto;

  .margin-right-removed {
    margin-right: 0;
  }
`;

export const FilterRow = styled(Row)`
  margin: 16px 0 16px 0;
`;

export const MatchLink = styled(Link)`
  margin: 0;
  padding: 0;
  color: ${links.nav};
  font-size: ${typography.fontSize.mdtext};
  text-decoration: none;
  :hover {
    color: gray;
    text-decoration: none;
  }
`;

export const MatchFontAwesome = styled(FontAwesome)`
  color: ${links.nav};
  margin: 0;
  padding: 0;
  :hover {
    color: gray;
  }
`;

export const HeadingDiv = styled.div`
  margin: 20px 0px 20px 0px;
`;
