// @refresh reset
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { GlobalStyle, MatchModal, MatchModalBody, MatchModalHeaderRow, MatchModalButton } from './ModalStyles';
import { Row, Col } from 'reactstrap';
import { PrimaryButton } from '../buttons/ButtonStyles';
import { useMutation } from '@apollo/react-hooks';
import { HugeText, HugeFontAwesome, SimpleTextField, CheckBoxButton } from './SendNotificationEmailModalStyles';
import { useAuth0 } from '@auth0/auth0-react';
import { ErrorToast, LoadingSpinner } from '../Alerts';
import handlebars from 'handlebars';
import { useToggle } from '../../utilities/hooks';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { MediumText, MediumLargeText, LargeText, GrayFontAwesome, SmallText } from '../../styles/text';
import { colors } from '../../styles/constants';
import { ReactComponent as Expand } from '../../assets/img/expand.svg';
import { InputDivHolder, LeftInputDiv } from '../panels/notes/NoteStyles';
import { CreateMatchingProcess, SendInitialEmail } from './SendNotificationEmailModalGQL';
import { ReactComponent as BoxChecked } from '../../assets/img/box-checked.svg';
import { ReactComponent as BoxUnchecked } from '../../assets/img/box-unchecked.svg';
import CloseIcon from '@material-ui/icons/Close';
import { v4 } from 'uuid';
import Email from '../Email';
import { isValueEmpty } from '../useSlate';
import valueAsHtml from '../../utilities/valueAsHtml';
import CheckboxComponent from '../buttons/Checkbox';
import { LinkItem } from '../panels/HousingStyles';

export default function SendNotificationEmailModal({
  toggleModal,
  openModal,
  size,
  checked,
  initialEmailObject,
  houseUnitId,
}) {
  const [sent, setSent] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [key, setKey] = useState(v4());
  const [sendEmail, { loading: loadingEmail, error: errorEmail }] = useMutation(SendInitialEmail);
  const [createMatchingProcess, { loading: loadingMP, error: errorMP }] = useMutation(CreateMatchingProcess);
  const [expanded, toggleExpanded] = useToggle(false);
  const [dangerousContent, setDangerousContent] = useState('');
  const scrollingRef = useRef();
  const [subject, setSubject] = useState('');
  const [isChecked, check] = useToggle(true);
  const [value, setValue] = useState([]);
  const [sendToPars, toggleSendToPars] = useToggle(true);
  const listingUrl = `${window.location.origin}/brochure?housingId=${houseUnitId}`;

  const { user } = useAuth0();
  const handleSend = async () => {
    let customNote = null;
    if (!isValueEmpty(value)) {
      customNote = valueAsHtml(value);
    }
    let customSubject = subject || null;
    try {
      const { data } = await sendEmail({
        variables: {
          id: houseUnitId,
          participants: checked.map((par) => par.id),
          fromEmail: user.email,
          customNote,
          customSubject,
          includeAddress: isChecked,
          sendToParticipants: sendToPars,
        },
      });
      const { SendInitialEmailToAdvocates } = data;
      // TODO: we need a negative flow here
      if (SendInitialEmailToAdvocates?.length) {
        const { data: mpData } = await createMatchingProcess({
          variables: {
            mpo: user.email,
            housingUnit: houseUnitId,
            participants: SendInitialEmailToAdvocates,
          },
        });
        const { CreateMatchingProcess } = mpData;
        // TODO: we need a negative flow here
        if (CreateMatchingProcess?.id) {
          setIsReady(true);
          setSubject('');
        }
      } else {
        setIsReady(true);
        setSubject('');
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (isReady && openModal) {
      setTimeout(() => {
        setSent(true);
        toggleModal(true);
        setKey(v4());
        setIsReady(false);
      }, 1500);
    }
  }, [isReady, openModal, toggleModal]);

  useEffect(() => {
    if (sent && !openModal) {
      setSent(false);
    }
  }, [sent, openModal, toggleModal]);

  useEffect(() => {
    if (Object.keys(initialEmailObject)?.length) {
      const template = handlebars.compile(initialEmailObject.emailTemplate);
      const htmlEmail = template({
        ...initialEmailObject,
        MPO_NAME: user.name,
        MPO_EMAIL: user.email,
      });
      setDangerousContent(htmlEmail);
    }
  }, [initialEmailObject, setDangerousContent]);

  const handleValueChange = useCallback(
    (value) => {
      setValue(value);
      if (value.length && Object.keys(initialEmailObject)?.length) {
        const htmlValue = valueAsHtml(value);
        const template = handlebars.compile(initialEmailObject.emailTemplate);
        let dataObject = { ...initialEmailObject, MPO_NAME: user.name, MPO_EMAIL: user.email };
        if (htmlValue !== '<p style="font-size: 20px; color: #06446a">&nbsp;</p>') {
          dataObject = {
            ...dataObject,
            CUSTOM_NOTE: htmlValue,
          };
        }
        const htmlEmail = template(dataObject);
        setDangerousContent(htmlEmail);
      }
    },
    [initialEmailObject, setDangerousContent, setValue],
  );
  const checkedHaveEmails = checked.filter((checkedPar) => checkedPar.contactEmail);

  return (
    <>
      <MatchModal
        size={size}
        isOpen={openModal && !sent}
        toggle={toggleModal}
        centered
        id="send-email-notification-modal"
      >
        <GlobalStyle />
        {!sent && !isReady && (
          <MatchModalHeaderRow>
            <Col style={{ marginTop: '20px' }}>
              <LargeText bold>Send Listing</LargeText>
            </Col>
            <Col sm="2" lg="1" className="cls-btn">
              <IconButton
                style={{ marginTop: '20px', padding: 0, borderRadius: 0, outline: 'none' }}
                onClick={() => {
                  toggleModal();
                }}
              >
                <LargeText color="#939393" fontSize={32}>
                  &times;
                </LargeText>
              </IconButton>
            </Col>
          </MatchModalHeaderRow>
        )}
        <MatchModalBody>
          {loadingMP || loadingEmail ? (
            <LoadingSpinner isStatic={true} />
          ) : openModal && !sent && !isReady ? (
            <Row>
              <Col sm={{ size: 12 }} lg={{ size: 6 }} xl={{ size: expanded ? 3 : 6, offset: 1 }}>
                <form style={{ marginTop: 30 }}>
                  <MediumLargeText inline bold>
                    To:
                  </MediumLargeText>
                  <MediumText lineHeight={1.2} marginTop={8}>
                    <strong>{checked.length}</strong> Advocate{checked.length > 1 ? 's' : ''}
                  </MediumText>
                  <div style={{ alignContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <IconButton
                      onClick={toggleSendToPars}
                      style={{ padding: 0, outline: 'none' }}
                      disabled={checkedHaveEmails.length === 0}
                    >
                      {sendToPars ? (
                        <BoxChecked
                          style={{
                            fill: checkedHaveEmails.length > 0 ? 'rgb(90,184,180)' : 'rgba(90, 184,180,0.5)',
                            width: '1rem',
                            height: '1em',
                          }}
                        />
                      ) : (
                        <BoxUnchecked style={{ stroke: 'rgb(228,228,228)', width: '1rem', height: '1rem' }} />
                      )}{' '}
                      &nbsp;
                    </IconButton>
                    <MediumText inline lineHeight={1.2}>
                      <strong>{checkedHaveEmails.length}</strong>{' '}
                      {checkedHaveEmails.length === 1 ? 'Participant' : 'Participants'}
                    </MediumText>
                  </div>
                  {checkedHaveEmails.length > 0 && (
                    <div style={{ width: '90%' }}>
                      <SmallText color="#888888" lineHeight={1.4}>
                        <strong>Note: </strong>
                        {checkedHaveEmails.length === 1 ? 'Participant' : 'Participants'} who{' '}
                        {checkedHaveEmails.length === 1 ? 'has' : 'have'} provided contact info will ONLY receive a{' '}
                        <LinkItem href={listingUrl} fontSize={0.8} target="_blank" rel="noopener noreferrer">
                          link to the listing
                        </LinkItem>{' '}
                        and no message.
                      </SmallText>
                    </div>
                  )}
                  {checkedHaveEmails.length === 0 && (
                    <SmallText color="#888888">
                      No contact information has been provided for the participant(s)
                    </SmallText>
                  )}
                  <div style={{ marginTop: 20 }}>
                    <MediumLargeText marginBottom={10} bold>
                      Subject
                    </MediumLargeText>
                    <SimpleTextField
                      variant="outlined"
                      className="w-100"
                      value={subject}
                      placeholder="Shared Housing App Unit Match"
                      onChange={(e) => setSubject(e?.target?.value)}
                      id="subject-field"
                    />
                  </div>
                  <div style={{ marginTop: 8 }}>
                    <IconButton onClick={check} style={{ padding: 0, outline: 'none' }}>
                      {isChecked ? (
                        <BoxChecked style={{ fill: 'rgb(90,184,180)', width: '16px', height: '16px' }} />
                      ) : (
                        <BoxUnchecked style={{ stroke: 'rgb(228,228,228)', width: '16px', height: '16px' }} />
                      )}{' '}
                    </IconButton>
                    <>&nbsp;&nbsp;</>
                    <MediumText lineHeight="24px" inline>
                      include unit address in subject{' '}
                    </MediumText>
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <MediumLargeText marginBottom={10} bold>
                      Message
                    </MediumLargeText>
                    <InputDivHolder minHeight={350} border="1px solid rgb(228,228,228)" marginTop={10}>
                      <Email onValueChange={handleValueChange} key={key} />
                    </InputDivHolder>
                  </div>
                </form>
                <PrimaryButton className="w-100" onClick={handleSend} id="send-email-notification-button">
                  Send
                </PrimaryButton>
              </Col>
              <Col sm={{ size: 12 }} lg={{ size: 6 }} xl={{ size: expanded ? 7 : 4 }}>
                {/* Really would have loved to do this with styled component, but the scale animations don't work */}
                <div
                  style={{ height: '600px', overflowY: expanded ? 'scroll' : 'hidden', marginTop: '20px' }}
                  ref={scrollingRef}
                >
                  <div
                    style={{
                      backgroundColor: colors.blueShade,
                      padding: '30px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      alignContent: 'center',
                      position: 'relative',
                    }}
                  >
                    <div style={{ backgroundColor: colors.whiteShade, padding: '10px' }}>
                      <div
                        dangerouslySetInnerHTML={{ __html: dangerousContent }}
                        className="w-100"
                        style={{
                          backgroundColor: 'white',
                          transformOrigin: 'left top',
                          transition: 'all .5s ease-in-out',
                          WebkitTransform: `scale(${expanded ? 0.9 : 0.45},${expanded ? 0.9 : 0.45})`,
                          MozTransform: `scale(${expanded ? 0.9 : 0.45},${expanded ? 0.9 : 0.45})`,
                          MsTransform: `scale(${expanded ? 0.9 : 0.45},${expanded ? 0.9 : 0.45})`,
                          transform: `scale(${expanded ? 0.9 : 0.45},${expanded ? 0.9 : 0.45})`,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <Button
                    onClick={() => {
                      toggleExpanded();
                      scrollingRef.current.scrollTop = 0;
                    }}
                    id="expand-contract"
                    style={{ color: colors.grayText, outline: 'none' }}
                  >
                    <Expand
                      style={{
                        width: 24,
                        height: 24,
                        marginRight: 10,
                        stroke: colors.grayText,
                      }}
                    />{' '}
                    {expanded ? 'Shrink' : 'Expand'} View
                  </Button>
                </div>
              </Col>
            </Row>
          ) : openModal && !sent && isReady ? (
            <HugeText className="text-center">
              <HugeFontAwesome name="check-circle" /> Email has been sent to advocates
            </HugeText>
          ) : (
            ''
          )}
          {(errorMP || errorEmail) && <ErrorToast />}
        </MatchModalBody>
      </MatchModal>
    </>
  );
}
