const createOptionsMap = (options) => {
  const optionsMap = new Map();
  for (const option of options) {
    optionsMap.set(option.reason, option.id);
  }
  return optionsMap;
};

const unassignmentOptions = [
  { reason: 'Application rejected by landlord', id: 'REJECTED_BY_LANDLORD' },
  { reason: 'Issue with proposed housemate(s) or house rules', id: 'HOUSEMATE_OR_RULES' },
  { reason: 'Changed mind about unit', id: 'CHANGED_MIND_ABOUT_UNIT' },
  { reason: 'Accidentally added to unit', id: 'ACCIDENTAL_ASSIGNMENT' },
];
export const unassignSelectionOptions = createOptionsMap(unassignmentOptions);

const moveOutOptions = [
  { reason: 'Conflict', id: 'CONFLICT' },
  { reason: 'Eviction', id: 'EVICTION' },
  { reason: 'Financial reasons', id: 'FINANCIAL' },
  { reason: 'Medical reasons', id: 'MEDICAL' },
  { reason: 'Was never moved in', id: 'NEVER_MOVED_IN' },
  { reason: 'Other', id: 'OTHER' },
];
export const moveOutSelectionOptions = createOptionsMap(moveOutOptions);

const deactivateOptions = [
  { reason: 'Changed mind about shared housing in general', id: 'CHANGED_MIND' },
  { reason: 'A significant change in needs or barriers (e.g., change in health)', id: 'LIFE_INCIDENT' },
  { reason: 'Exited from services', id: 'EXITED' },
  { reason: 'Found other permanent housing', id: 'FOUND_OTHER_PERMANENT' },
  { reason: 'Other', id: 'OTHER' },
];
export const deactivateOptionsEnumToStrDict = deactivateOptions.reduce((acc, cur) => {
  const v = cur.reason;
  const k = cur.id;
  acc[k] = v;
  return acc;
}, {});
export const deactivateSelectionOptions = createOptionsMap(deactivateOptions);
