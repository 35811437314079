import React, { useEffect, useState } from 'react';
import DashParticipantHeader from './DashParticipantHeader';
import DashParticipantItem from './DashParticipantItem';
import { sortByStatus, alphaSortByKey, advocateSort } from './parListSorting';

export const DashParticipants = function ({ participants }) {
  const [sort, setSort] = useState('');
  const [sortedPars, setSortedPars] = useState([]);

  useEffect(() => {
    setSortedPars(participants);
  }, [participants]);

  useEffect(() => {
    sortPars();
  }, [sort]);

  const handleSort = (sortType) => {
    setSort(sortType);
  };

  const sortPars = () => {
    let sorted;
    if (sort === 'status') {
      sorted = sortByStatus(sortedPars);
    } else if (sort === 'advocate') {
      sorted = advocateSort(sortedPars);
    } else if (sort === 'name') {
      sorted = alphaSortByKey(sortedPars, 'name');
    } else {
      sorted = [...sortedPars];
    }
    setSortedPars(sorted);
  };

  const handleReverse = () => {
    const reversedPars = [...sortedPars].reverse();
    setSortedPars(reversedPars);
  };

  return (
    <div>
      <DashParticipantHeader sort={sort} handleSort={handleSort} handleReverse={handleReverse} />
      {sortedPars?.map((p) => (
        <DashParticipantItem participant={p} key={`${p?.id}-dash-par`} />
      ))}
    </div>
  );
};
