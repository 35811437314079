import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import styled from 'styled-components';
import { borders, colors } from '../../styles/constants';

export const InterestContainer = styled.div`
  margin: -10;
  padding: 10;
  background-color: #abc9fa33;
  min-height: 100vh;
`;

export const InfoGrid = styled(Grid)`
  border: ${borders.border};
  border-radius: ${borders.borderRadius};
  box-shadow: ${borders.boxShadow};
`;

export const InfoPanel = styled(Grid)`
  background-color: ${colors.whiteShade};
  border-top-left-radius: ${borders.borderRadius};
  border-bottom-left-radius: ${borders.borderRadius};
  padding: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const InterestPanel = styled(Grid)``;

export const AvatarGrid = styled(Grid)`
  flex-direction: row;
  align-content: center;
  align-items: center;
`;
