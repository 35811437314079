import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import { colors, typography } from '../../styles/constants';

export const TextInput = styled(TextField)({
  backgroundColor: colors.whiteShade,
  '.MuiInputBase-input': {
    color: typography.fontColor.text,
    fontSize: '20px',
  },
  '.MuiOutlinedInput-root': {
    '&:hover fieldset': {
      border: `1px solid ${colors.selected}`,
    },
    '&.focused fieldset': {
      border: `1px solid ${colors.selected}`,
    },
  },
  '.MuiOutlinedInput-root.Mui-focused fieldset': {
    border: `1px solid ${colors.selected}`,
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${colors.disabledButton}`,
  },
  '.MuiFormHelperText-contained': {
    // This is the helper error text
    margin: '0px',
    fontSize: '14px',
  },
});

export const TextInputLabel = styled.div`
  margin-bottom: 8px;
`;
