import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { typography, colors } from '../../styles/constants';

export const StyledAddButton = styled(Button)`
  width: 100%;
  height: 100%;
  &.MuiButton-outlined {
    border-radius: 8px;
    border: 1px solid ${typography.fontColor.btnColor};
    color: ${typography.fontColor.btnColor};
    text-transform: none;
    font-family: ${typography.fontFamily.regular};
  }
  &.MuiButtonBase-root:hover {
    background-color: ${colors.selected};
    color: white;
    text-transform: none;
    font-family: ${typography.fontFamily.regular};
  }
`;
