import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography, Box } from '@material-ui/core';
import { FAIconContainer } from '../../components/icons/IconContainer';
import { cardStyles } from './BrochureStyles';
import { makeRentRangeStr } from './housingUnitHelpers';
import PropTypes from 'prop-types';

const UnitPanel = ({ classes, bedrooms, location, unitNumber, totalBathrooms, totalBedrooms, isAvailable }) => {
  const [rentRange, setRentRange] = useState('$0/mo');
  const [totalRent, setTotalRent] = useState(0);

  useEffect(() => {
    const rents = bedrooms?.map((bed) => bed.rent);
    if (rents?.length) {
      const newRentRange = makeRentRangeStr(rents);
      setRentRange(newRentRange);
      const totalRent = rents.reduce((acc, cur) => (acc += cur), 0);
      setTotalRent(totalRent.toLocaleString());
    }
  }, [bedrooms]);

  const availabilityMsg = isAvailable ? 'Unit Is Available' : 'Unit Is No Longer Available';

  return (
    <Paper className={classes.paper} elevation={0}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <FAIconContainer
          className={'icon'}
          color={'white'}
          backgroundColor={isAvailable ? 'green' : 'red'}
          iconName={isAvailable ? '' : 'exclamation'}
          fontSize={isAvailable ? '8px' : '11px'}
          width={'15px'}
        />
        <Typography color="primary" style={{ marginLeft: '8px', fontSize: '13px' }}>
          {availabilityMsg}
        </Typography>
      </div>
      <Box marginBottom={'8px'}>
        <Typography variant="h3" color="primary" className={classes.growingH3Typogoraphy}>
          {rentRange}
        </Typography>
      </Box>
      <Box marginBottom={unitNumber ? '2px' : '8px'}>
        <Typography color="secondary">{location}</Typography>
      </Box>
      {unitNumber && (
        <Box marginBottom={'8px'}>
          <Typography color="primary">{`Unit Number: ${unitNumber}`}</Typography>
        </Box>
      )}
      <Box className={classes.boxSpan} marginBottom={'8px'}>
        <Typography variant="subtitle1" color="primary">{`TotalRent `}</Typography>
        <Typography
          variant="subtitle1"
          color="primary"
          style={{ fontWeight: 600, marginLeft: '10px' }}
        >{`$${totalRent}`}</Typography>
      </Box>
      <Box>
        <Typography
          variant="subtitle1"
          color="primary"
        >{`${totalBedrooms} Bedroom / ${totalBathrooms} Bathroom`}</Typography>
      </Box>
    </Paper>
  );
};

UnitPanel.propTypes = {
  bedrooms: PropTypes.array,
  classes: PropTypes.object.isRequired,
  isAvailable: PropTypes.bool,
  location: PropTypes.string,
  totalBathrooms: PropTypes.number,
  totalBedrooms: PropTypes.number,
  unitNumber: PropTypes.string,
};

export default withStyles(cardStyles)(UnitPanel);
