import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import { SearchField } from './SearchField.jsx';
import AddButton from './buttons/AddButton';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { XLargeText } from '../styles/text.js';

function SectionHeader(props) {
  const { pathname } = useLocation();
  const participant_path_to =
    process.env.REACT_APP_FEATURE_KEEP_CSV === 'true'
      ? `/participant/add`
      : `${process.env.REACT_APP_VUE_SURVEY_BASE_URL}`;
  return (
    <div className="section-header">
      <Container>
        <Row>
          <Col md="6">
            <XLargeText bold marginLeft={-16}>
              {props.title}
            </XLargeText>
          </Col>
          <Col md="3">
            <SearchField content={props.placeholder} inputReactiveVariable={props.reactiveVar} />
          </Col>
          <Col md="3">
            <AddButton
              to={pathname.includes('/housing') ? '/housing/add/location' : participant_path_to}
              content={props.buttonText}
              type={pathname === '/housing' ? 'housing' : 'participant'}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

SectionHeader.propTypes = {
  title: PropTypes.string,
  placeholder: PropTypes.string,
  buttonText: PropTypes.string,
  reactiveVar: PropTypes.func,
};

export default SectionHeader;
