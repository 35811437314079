import React from 'react';
import {
  MarkInterestInHousingUnit,
  MarkDisinterestInHousingUnit,
} from '../HousingUnitCreation/ParticipantStatusUpdatesGQL.js';
import { useMutation } from '@apollo/react-hooks';
import { InterestButton, InterestIcon } from './ButtonStyles';
import {
  participantHasBeenNotified,
  participantIsInterested,
  participantIsDisinterested,
} from '../../utilities/participantStatusCheck';
import { RedFontAwesome, GreenFontAwesome } from '../../styles/text';
import PropTypes from 'prop-types';

const InterestButtonComponent = ({
  participant,
  housingUnit,
  buttonType,
  buttonIconName,
  buttonText,
  refetch,
  width,
}) => {
  const notifiedPar = participantHasBeenNotified(participant, housingUnit);
  const notifiedDate = notifiedPar ? notifiedPar.format('M/D') : false;
  const [isInterested, setIsInterested] = React.useState(false);
  const [isDisinterested, setIsDisinterested] = React.useState(false);
  const [addInterestInUnit, { loading: loadingAddInterest, error: errorAddInterest }] = useMutation(
    MarkInterestInHousingUnit,
    {
      onCompleted() {
        if (refetch) {
          refetch?.();
        }
      },
    },
  );
  const [addDisinterestInUnit, { loading: loadingAddDisinterest, error: errorAddDisinterest }] = useMutation(
    MarkDisinterestInHousingUnit,
    {
      onCompleted() {
        if (refetch) {
          refetch?.();
        }
      },
    },
  );

  const onInterestedClick = async () => {
    if (!!notifiedDate) {
      try {
        await addInterestInUnit({
          variables: {
            participants: [participant.id],
            housingUnit: housingUnit.id,
          },
        });
      } catch (err) {
        console.error(err);
      }
    }
  };

  const onNotInterestedClick = async () => {
    if (!!notifiedDate) {
      try {
        await addDisinterestInUnit({
          variables: {
            participants: [participant.id],
            housingUnit: housingUnit.id,
          },
        });
      } catch (err) {
        console.error(err);
      }
    }
  };

  const checkParsInterest = () => {
    setIsInterested(participantIsInterested(participant, housingUnit));
    setIsDisinterested(participantIsDisinterested(participant, housingUnit));
  };

  React.useEffect(() => {
    checkParsInterest();
  });

  return (
    <InterestButton
      width={width}
      disabled={!notifiedDate || isInterested || isDisinterested}
      onClick={buttonType === 'Interested' ? onInterestedClick : onNotInterestedClick}
      title={!notifiedDate || isInterested || isDisinterested ? 'To enable, first Send Listing' : ''}
    >
      <InterestIcon>
        {buttonType === 'Interested' ? (
          <GreenFontAwesome name={buttonIconName} />
        ) : (
          <RedFontAwesome name={buttonIconName} />
        )}
      </InterestIcon>
      {buttonText}
    </InterestButton>
  );
};

InterestButtonComponent.propTypes = {
  participant: PropTypes.object.isRequired,
  housingUnit: PropTypes.object.isRequired,
  buttonType: PropTypes.string.isRequired,
  buttonIconName: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  refetch: PropTypes.func,
  width: PropTypes.bool,
};

export default InterestButtonComponent;
