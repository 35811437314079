import { gql } from '@apollo/client';

export const AddNoteGQL = gql`
  mutation AddNote($content: String!, $noteFor: ID!) {
    CreateNote(content: $content, noteFor: $noteFor) {
      id
    }
  }
`;

export const EditHousingNote = gql`
  mutation UpdateNote($id: ID!, $content: String!) {
    UpdateNote(id: $id, content: $content) {
      id
      content
    }
  }
`;

export const DeleteHousingNote = gql`
  mutation DeleteNote($id: ID!) {
    DeleteNote(id: $id)
  }
`;
