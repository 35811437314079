import React, { useState } from 'react';
import { CloudinaryImage } from '../../components/CloudinaryImage';
import BrochureCardWrapper from './BrochureCardWrapper';
import { useWindowDimensions } from '../../utilities/hooks';
import { makeStyles, styled } from '@material-ui/core/styles';
import { CardMedia, IconButton } from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import houseDefault from '../../assets/img/house-clipart.jpg';

const getImageHeight = (width) => {
  if (width > 750) {
    return '350';
  } else if (width > 570) {
    return '300';
  } else {
    return '225';
  }
};

const CarouselBase = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  textAlign: 'center',
  display: 'grid',
  gridAutoFlow: 'column',
}));
const arrowStyles = makeStyles({
  arrow: ({ direction }) => ({
    position: 'absolute',
    top: '50%',
    fontSize: 30,
    color: '#ffffff',
    backgroundColor: '#06446A',
    borderRadius: '100%',
    outline: 'none',
    [direction]: '0.5em',
  }),
});

const Arrow = ({ direction, onClick, ...props }) => {
  const classes = arrowStyles({ direction, ...props });
  const Icon = direction === 'left' ? ChevronLeft : ChevronRight;
  return (
    <IconButton onClick={onClick} className={classes.arrow}>
      <Icon />
    </IconButton>
  );
};

const counterStyles = makeStyles({
  counter: {
    position: 'absolute',
    bottom: '5%',
    right: '4%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 15,
    height: '35px',
    width: '48px',
    color: 'black',
    backgroundColor: '#ffffff',
    opacity: '65%',
    outline: 'none',
    borderRadius: '7px',
    border: 'solid 1px rgb(6, 68, 106, .2)',
  },
});

const Counter = ({ total, current }) => {
  const classes = counterStyles();
  return (
    <div className={classes.counter}>
      <p style={{ margin: 0 }}>{`${current}/${total}`}</p>
    </div>
  );
};

export const Carousel = ({ images }) => {
  const [width, height] = useWindowDimensions();
  const [currentImage, setCurrentImage] = useState(0);
  const maxIndex = images?.length - 1;

  const handleNavigateLeft = () => {
    if (currentImage === 0) {
      setCurrentImage(maxIndex);
    } else {
      setCurrentImage(currentImage - 1);
    }
  };

  const handleNavigateRight = () => {
    if (currentImage === maxIndex) {
      setCurrentImage(0);
    } else {
      setCurrentImage(currentImage + 1);
    }
  };

  const displayImage = () => {
    if (images?.length > 0) {
      return <CloudinaryImage publicId={images?.[currentImage]?.publicId} height={getImageHeight(width)} />;
    } else {
      return <CardMedia component="img" image={houseDefault} title="listing-image" height="300" />;
    }
  };

  return (
    <div style={{ marginTop: '15px' }}>
      <BrochureCardWrapper>
        <CarouselBase className="carousel-base">
          {images?.length > 1 && <Arrow direction="left" onClick={() => handleNavigateLeft()} />}
          {displayImage()}
          {images?.length > 1 && <Arrow direction="right" onClick={() => handleNavigateRight()} />}
          {images?.length > 0 && (
            <div style={{ display: 'contents' }}>
              <Counter total={images?.length} current={currentImage + 1} />
            </div>
          )}
        </CarouselBase>
      </BrochureCardWrapper>
    </div>
  );
};
