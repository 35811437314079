import moment from 'moment';
import { createComparableDate } from '../../utilities/datetimeHelpers';

export const sortByUpdatedDate = (array) => {
  let nonNullArray = [];
  let nullArray = [];

  for (let i of array) {
    if (i?.createdAt?.formatted) {
      nonNullArray.push(i);
    } else {
      nullArray.push(i);
    }
  }

  const sortedArray = nonNullArray.sort((a, b) => {
    const dateA = createComparableDate(a?.updatedAt, a?.createdAt);
    const dateB = createComparableDate(b?.updatedAt, b?.createdAt);
    return dateB - dateA;
  });
  return sortedArray.concat(nullArray);
};

export const alphaSortByKey = (users, key) => {
  return [...users].sort((a, b) => {
    const aName = a?.[key]?.toLowerCase();
    const bName = b?.[key]?.toLowerCase();
    if (aName > bName) {
      return 1;
    } else if (aName < bName) {
      return -1;
    } else {
      return 0;
    }
  });
};
