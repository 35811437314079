import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import { borders } from '../../styles/constants';
import { SmallText } from '../../styles/text';

export const ParRow = styled(Row)`
  box-shadow: ${borders.boxShadow};
  margin-bottom: 8px;
  border-radius: ${borders.borderRadius};
  height: ${(props) => (props.$height ? props.$height : '97px')};
  border: ${(props) => (props.$opaque ? '1px solid #D8E8FD' : borders.border)};
  background-color: ${(props) => (props.$opaque ? '#FAFDFF' : '#FFFFFF')};
`;

export const VCenterCol = styled(Col)`
  display: flex;
  flex-direction: row;
  height: 97px;
  align-items: center;
`;

export const HeaderParRow = styled(Row)`
  border: 1px solid clear;
  margin-bottom: 8px;
  height: 32px;
`;

export const HCol = styled(Col)`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const CtaGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const HeaderSortDiv = styled.div`
  display: flex;
  align-items: baseline;
`;

export const HeaderSortText = styled(SmallText)`
  margin-right: 10px;
`;
