import styled from 'styled-components';
import { Container } from 'reactstrap';
import { typography, borders, links } from '../../styles/constants';
import { Link } from 'react-router-dom';

export const ParticipantsContainer = styled(Container)``;

export const ParticipantCheckboxHolder = styled.div`
  display: inline-block;
  margin-right: 20px;
  margin-left: 24px;
  line-height: 48px;
`;

export const ParticipantCol = styled.div`
  display: flex;
  flex-direction: 'row';
  align-content: center;
  align-items: center;
  padding: 0;
  text-align: left;
  line-height: 48px;

  .par-name {
    padding: 0 20px;
  }
`;

export const ParHeaderCol = styled.div`
  padding: 0;
  margin-bottom: 15px;
  ${(props) => props.clickable && 'cursor: pointer;'}// &:nth-child(2) {
`;

const ParticipantBaseRow = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding: 20px 5px 20px 5px;
`;

export const ParticipantHeaderRow = styled(ParticipantBaseRow)`
  font-size: ${typography.fontSize.mdtext};
  margin-bottom: 0px;
  margin: ${borders.headerMargin};
`;

export const ParticipantRow = styled(ParticipantBaseRow)`
  font-size: ${typography.fontSize.smtext};
  border: ${borders.border};
  border-radius: ${borders.borderRadius};
  margin: ${borders.rowMargin};
  box-shadow: ${borders.boxShadow};
`;

export const ParticipantLink = styled(Link)`
  color: ${links.listLink};
  text-decoration: none;
`;

export const ParticipantClickable = styled.p`
  color: ${links.listLink};
  padding: 0;
  margin: 0;
`;

export const cellWidths = {
  selectAll: '150px',
  name: '125px',
  advocate: '125px',
  income: '70px',
  regions: '100px',
  status: '110px',
  empty: '65px',
};
