import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { typography } from '../../../styles/constants';
import { Text } from '../../../styles/text';

export const ViewMatchingHistoryGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
`;
export const ViewMatchingHistoryLabelButton = styled.button`
  font-size: ${typography.fontSize.lgtext};
  color: #31a3a5;
  font-weight: bold;
  margin-bottom: 20px;
  background-color: transparent;
  border: none;
  outline: none;
  &:hover {
    cursor: pointer;
  }
`;

export const ViewMatchingHistoryIcon = styled.img`
  margin-right: 10px;
`;

export const StatusIndicatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
`;
export const StatusIndicatorIcon = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: ${(props) => props.backgroundColor};
  margin-right: 10px;
`;

export const StatusIndicatorText = styled.div`
  font-size: 13px;
  color: ${typography.fontColor.text};
  margin-right: 10px;
`;

export const DateNotified = styled(Text)`
  color: #707070;
  font-size: 13px;
  margin-bottom: 10px;
`;

export const HUNameLink = styled(Link)`
  color: ${typography.fontColor.text};
  &:hover {
    color: ${typography.fontColor.text};
  }
`;

export const InfoToolTip = styled.img``;
export const InfoToolTipContainer = styled.div`
  display: flex;
`;

export const CardContainer = styled.div`
  &[class*='hide'] {
    display: none;
    opacity: 0;
    transition: opacity 10s;
  }

  @keyframes example {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &.selected {
    opacity: 0;
    animation-name: example;
    animation-duration: 4s;
    animation-fill-mode: forwards;
  }
`;
