import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Modal, ModalBody, Spinner } from 'reactstrap';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';

export const UploadImage = gql`
  mutation UploadImage($file: Upload!, $to: String!) {
    UploadImage(file: $file, to: $to) {
      id
      url
    }
  }
`;

// TODO Make use UploadCSV mutation
export const UploadCSV = gql`
  mutation UploadCSV($file: Upload!) {
    UploadCSV(file: $file) {
      id
      name
      email
      age
      HMISID
      income
      evictions
    }
  }
`;

function FileUploadField({ housingUnitId, acceptedTypes, children, onUploaded }) {
  const [uploadImageMutation] = useMutation(UploadImage);
  const [uploadCsvMutation] = useMutation(UploadCSV);
  const [uploading, setUploading] = React.useState(false);
  const [numberfiles, setNumberfiles] = React.useState(0);
  const [success, setSuccess] = React.useState({
    images: [],
    participants: [],
  });

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      setNumberfiles(acceptedFiles.length);
      let i = 0;
      for (const file of acceptedFiles) {
        const reader = new FileReader();
        reader.onabort = () => console.error('file reading was aborted');
        reader.onerror = () => console.error('file reading has failed');
        setUploading(true);

        reader.onload = async () => {
          if (acceptedTypes === 'image/*') {
            try {
              const { data, errors } = await uploadImageMutation({
                variables: {
                  to: housingUnitId,
                  file: file,
                },
              });
              if (errors) {
                console.error(errors);
              } else {
                if (data?.UploadImage) {
                  const { UploadImage: UIMG } = data;
                  if (UIMG) {
                    const newSuccess = { ...success };
                    newSuccess.images.push(UIMG);
                    onUploaded(newSuccess);
                    setSuccess(newSuccess);
                  }
                }
              }
            } catch (err) {
              console.error('Error uploading images', err);
            }
          } else {
            try {
              const { data, errors } = await uploadCsvMutation({
                variables: { file: file },
              });
              if (errors) {
                console.error(errors);
              } else {
                if (data?.UploadCSV) {
                  const { UploadCSV: participants } = data;
                  if (participants) {
                    const newSuccess = { ...success };
                    newSuccess.participants.push(...participants);
                    onUploaded(newSuccess);
                    setSuccess(newSuccess);
                  }
                }
              }
            } catch (err) {
              console.error('Error uploading CSV', err);
              // should we display an error message?
            }
          }
        };
        reader.readAsArrayBuffer(file);
        if (acceptedFiles.length - 1 === i) {
          // execute last item logic
          const time = acceptedFiles.length * 2000;
          setTimeout(setUploading, time, false);
        }
        i += 1;
      }
    },
    [housingUnitId, uploadImageMutation, uploadCsvMutation, setSuccess, success, onUploaded, acceptedTypes],
  );
  const onDropRejected = useCallback(() => {
    // do something to show there was an error
  });
  // Require images, otherwise complications happen when adding non-images and system crashes
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedTypes,
    maxSize: 5 * 2 ** 20, // 5MB
    maxFiles: 5,
    onDropRejected,
  });
  return (
    <div className="upload-file-widget">
      <div className="file-drop-zone text-center" {...getRootProps()}>
        <input {...getInputProps()} />
        <p>
          <span style={{ fontWeight: 'bold' }}>Add {acceptedTypes === 'image/*' ? 'image' : 'CSV'} file </span>
          or drop {acceptedTypes === 'image/*' ? 'image' : 'CSV'} files here (max: 5MB each, max: 5 files)
        </p>
      </div>
      {children}

      <Modal isOpen={uploading} centered>
        <ModalBody>
          <Spinner color="success" size="sm" /> Uploading {numberfiles} files
        </ModalBody>
      </Modal>
    </div>
  );
}

FileUploadField.propTypes = {
  housingUnitId: PropTypes.string.isRequired,
  acceptedTypes: PropTypes.string.isRequired,
  onUploaded: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default FileUploadField;
