import { gql } from '@apollo/client';
export const QueryHousingUnitHousingLocator = gql`
  query QueryHousingUnitHousingLocator($id: ID!) {
    HousingUnit(id: $id) {
      id
      name
      status
      location
      unitNumber
      unitType
      deadline {
        formatted
      }
      viewingNotes {
        id
        createdAt {
          formatted
        }
        content
      }
      description
      accepts {
        id
        type
      }
      numNotifiedParticipants
      numAssignedParticipants
      numInterestedParticipants
      allowsEvictions
      allowsConvictions
      allowsSexOffenders
      allowsIndoorSmoking
      applicationFee
      parkingSpotsAdaAccessible
      accessibilitiesWithDegree {
        degree
        Accessibility {
          id
          ADA
          detail
        }
      }
      bedrooms {
        id
        rent
        squareFeet
        amenities {
          id
          type
        }
      }
      parkingSpots
      region {
        id
        name
      }
      SHOWS_rel(orderBy: order_asc) {
        Image {
          id
          url
          publicId
        }
      }
      nearSchool
      status
      supports {
        id
        ADA
        detail
      }
      allows {
        id
        species
        size
      }
      createdAt {
        formatted
      }
      updatedAt {
        formatted
      }
      housingUnitAmenities {
        id
        type
      }
      totalBathrooms
      totalBedrooms
      utilities {
        id
        type
      }
      notes {
        createdBy {
          id
          name
        }
        content
        createdAt {
          formatted
          timezone
        }
        noteFor {
          id
        }
      }
    }
    me {
      id
    }
  }
`;
export const QueryHousingUnitByIdAllData = gql`
  query QueryHousingUnitByIdAllData($id: ID!) {
    HousingUnit(id: $id) {
      id
      name
      location
      unitNumber
      unitType
      status
      deadline {
        formatted
      }
      viewingNotes {
        id
        createdAt {
          formatted
        }
        content
      }
      description
      accepts {
        id
        type
      }
      allowsEvictions
      allowsConvictions
      allowsSexOffenders
      allowsIndoorSmoking
      numNotifiedParticipants
      numAssignedParticipants
      numInterestedParticipants
      applicationFee
      ASSIGNED_TO_rel {
        createdAt {
          formatted
        }
        Participant {
          id
          name
        }
      }
      accessibilitiesWithDegree {
        degree
        Accessibility {
          id
          ADA
          detail
        }
      }
      assignedParticipants {
        id
        name
        age
        MOVED_IN_rel {
          date {
            formatted
          }
          HousingUnit {
            id
            name
            location
            unitNumber
          }
        }
        gender {
          name
        }
      }
      movedInParticipants {
        id
        name
        age
        gender {
          name
        }
      }
      parkingSpotsAdaAccessible
      bedrooms {
        id
        rent
        squareFeet
        amenities {
          id
          type
        }
      }
      parkingSpots
      region {
        id
        name
      }
      SHOWS_rel(orderBy: order_asc) {
        Image {
          id
          url
          publicId
        }
        order
      }
      nearSchool
      status
      supports {
        id
        ADA
        detail
      }
      allows {
        id
        species
        size
      }
      createdAt {
        formatted
      }
      updatedAt {
        formatted
      }
      housingUnitAmenities {
        id
        type
      }
      totalBathrooms
      totalBedrooms
      utilities {
        id
        type
      }
      matchingProcesses {
        notifiedParticipants {
          id
        }
      }
      interestedParticipants {
        id
      }
      notes {
        id
        createdBy {
          id
          name
        }
        content
        createdAt {
          formatted
          timezone
        }
        noteFor {
          id
        }
      }
    }
    me {
      id
    }
  }
`;

export const QueryHousingUnitByIdRequiredData = gql`
  query QueryHousingUnitByIdRequiredData($id: ID!) {
    HousingUnit(id: $id) {
      id
      name
      location
      unitType
      deadline {
        formatted
      }
      viewingNotes {
        id
        createdAt {
          formatted
        }
        content
      }
      region {
        id
        name
      }
      allowsEvictions
      allowsConvictions
      allowsSexOffenders
      allowsIndoorSmoking
      parkingSpotsAdaAccessible
      bedrooms {
        id
        rent
      }
      parkingSpots
      SHOWS_rel(orderBy: order_asc) {
        Image {
          id
          url
          publicId
        }
        order
      }
      status
      createdAt {
        formatted
      }
      updatedAt {
        formatted
      }
    }
    me {
      id
    }
  }
`;
