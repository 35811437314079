import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { FetchAdvocates, ReassignAdvocate } from './AdvocatesGQL';
import { Row, Col } from 'reactstrap';
import Avatar from 'react-avatar';
import FontAwesome from 'react-fontawesome';
import { LargeText } from '../../styles/text';
import {
  HorizontalHeaderContainer,
  VerticalHeader,
  ListGroupRow,
  SecondaryAvatarDiv,
  ViewParDetailsBtn,
  EmailCol,
  EmailTextField,
  EmailTextFieldLabel,
  EmailTextFieldError,
  EmailTextFieldContainer,
} from './ParticipantStyles';
import { HeaderLeftCol, ValueRightCol, ItemRow, PanelContainer, AvatarDiv, FontAwesomeWrapper } from './HousingStyles';
import ParDetailsModal from '../modals/ParDetailsModal';
import ParReasonsModal from '../modals/ParReasonsModal';
import ReassignParModal from '../modals/ReassignParModal';
import DeleteEditMenu from '../menus/DeleteEditMenu';
import { ErrorToast } from '../Alerts';
import useDropdown from '../useDropdown';
import { useToggle } from '../../utilities/hooks';
import { deactivateSelectionOptions } from '../../constants/reassignOptions';
import PreventActionModal from '../modals/PreventActionModal';
import ActionModal from '../modals/ActionModal';
import {
  ReactivateParticipant,
  DeactiveParticipant,
  UpdateParContactEmail,
} from '../../views/ParticipantDetailViewGQL';
import { IconButton, EditIcon } from '../AssignedParCardStyles';

const { REACT_APP_VUE_SURVEY_BASE_URL } = process.env;

export const ParticipantDetailsPanel = ({ modal, participant, toggle, handleUpdate, refetch }) => {
  const { user } = useAuth0();
  const { id, name, HMISID, age, income, currentIncome, currentLocation, advocate, status, SurveyTaker } = participant;
  const surveyResponseId = SurveyTaker?.surveyResponses?.[0]?.id;
  const [advocates, setAdvocates] = useState([]);
  const [menuOpen, toggleMenuOpen] = useToggle(false);
  const [reactivateModalOpen, setReactivateModalOpen] = useState(false);
  const [mpoOnlyAccess, setMpoOnlyAccess] = useState(false);
  const [selectedAdvocate, AdvocateDropdown] = useDropdown('Reassign', advocates, 'name');
  const [deactivateModalOpen, toggleDeactivateModal] = useToggle(false);
  const [canDeactivatePar, setCanDeactivatePar] = useState(false);
  const [emailEditMode, setEmailEditMode] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [contactEmail, setContactEmail] = useState('');
  const [togglePreventActionModal, setTogglePreventActionModal] = useToggle(false);
  const [toggleEmailModal, setToggleEmailmodal] = useToggle(false);
  const setAdvocateData = (data) => setAdvocates(data?.Advocate);
  const handleSuccess = (respData) => {
    if (respData?.ReactivateParticipant) {
      handleUpdate(`${participant?.name} has been reactivated.`);
      refetch();
    } else if (respData?.DeactivateParticipant) {
      handleUpdate(`${participant?.name} has been deactivated.`);
      refetch();
    } else if (respData?.ReassignAdvocate) {
      handleUpdate(`${participant?.name} has been reassigned.`);
      refetch();
    } else {
      handleUpdate('Something went wrong', 'error');
    }
  };
  const handleError = (err) => {
    console.error(err);
    handleUpdate('Something went wrong', 'error');
  };
  const { data, error, loading } = useQuery(FetchAdvocates, {
    onCompleted: setAdvocateData,
  });
  const [reassignAdvocate, { data: reassignData, loading: loadingReassign, error: errorReassign }] = useMutation(
    ReassignAdvocate,
    {
      onCompleted(reassignData) {
        handleSuccess(reassignData);
      },
      onError(err) {
        handleError(err);
      },
    },
  );
  const [reactiveParticipant] = useMutation(ReactivateParticipant, {
    onCompleted(reactData) {
      handleSuccess(reactData);
    },
    onError(err) {
      handleError(err);
    },
  });
  const [deactiveParticipant] = useMutation(DeactiveParticipant, {
    onCompleted(deactData) {
      handleSuccess(deactData);
    },
    onError(err) {
      handleError(err);
    },
  });
  const [updateParContactEmail] = useMutation(UpdateParContactEmail, {
    onCompleted() {
      setIsEmailInvalid(false);
      setToggleEmailmodal();
      refetch();
    },
    onError() {
      setIsEmailInvalid(true);
    },
  });

  useEffect(() => {
    const isParAssigned = participant?.assignedHousingUnits?.length;
    const isParMovedIn = participant?.movedIntoHousingUnits?.length;
    setCanDeactivatePar(!isParAssigned && !isParMovedIn);
  }, [participant, setCanDeactivatePar]);

  useEffect(() => {
    if (user) {
      const roles = user['http://schemas.aroyomatch.com/claims/roles'];
      if (roles?.includes('MPO') || roles?.includes('UserAdmin')) {
        setMpoOnlyAccess(true);
      }
    }
  }, [setMpoOnlyAccess, user]);

  useEffect(() => {
    const reassign = async () => {
      await reassignAdvocate({ variables: { id: participant.id, advocate: selectedAdvocate } });
    };
    if (mpoOnlyAccess && selectedAdvocate !== advocate?.id) {
      reassign();
    }
  }, [selectedAdvocate, reassignAdvocate]);

  useEffect(() => {
    setContactEmail(participant?.contactEmail);
  }, [participant, emailEditMode, setContactEmail]);

  const handleSelectInactive = () => {
    //TODO: either activate or deactivate
    if (canDeactivatePar) {
      toggleDeactivateModal();
    } else {
      setTogglePreventActionModal();
      console.error('Action cannot be taken since the par is either assigned or moved into a unit');
    }
  };

  const handleSelectReactivate = async () => {
    setReactivateModalOpen(true);
  };

  const handleSendReactivate = async () => {
    try {
      await reactiveParticipant({ variables: { id: participant?.id } });
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  const handleSubmitDeactivateReason = async (parId, reason) => {
    try {
      await deactiveParticipant({ variables: { id: parId, reason } });
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  const handlePreventActionOnCancel = () => {
    setTogglePreventActionModal();
  };

  const openEmailModal = (e, edit = false) => {
    setToggleEmailmodal();
    setEmailEditMode(edit);
  };

  const handleEmailInputChange = (e) => {
    if (isEmailInvalid && isEmailInvalid) {
      setIsEmailInvalid(false);
    }
    setContactEmail(e.target.value);
  };

  const handleParContactEmail = async (e, deleteEmail = false) => {
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
    let updatedEmail = deleteEmail ? null : contactEmail;
    const isEmailValid = contactEmail?.match(emailRegex);

    if (deleteEmail) {
      setContactEmail('');
    }

    if (!isEmailValid) {
      setIsEmailInvalid(true);
    }

    if (isEmailValid || deleteEmail) {
      try {
        await updateParContactEmail({ variables: { id: participant?.id, contactEmail: updatedEmail } });
      } catch (error) {
        console.error('Error: ', error);
      }
    }
  };

  const directPar = () => {
    if (surveyResponseId) {
      window.location.href = `${REACT_APP_VUE_SURVEY_BASE_URL}/survey/${surveyResponseId}?mode=UPDATE`;
    } else {
      toggle();
    }
  };

  if (errorReassign) {
    return <ErrorToast />;
  }

  const ActionModalButtons = [
    {
      text: 'Save',
      action: handleParContactEmail,
      disabled: !contactEmail?.length || isEmailInvalid,
      isHidden: false,
    },
    {
      text: 'Delete this email',
      action: (e) => handleParContactEmail(e, true),
      disabled: false,
      isHidden: !emailEditMode,
    },
  ];
  const ActionModalHeader = `${emailEditMode ? 'Edit' : 'Add'} Email Address`;

  return (
    <div>
      {deactivateModalOpen && (
        <ParReasonsModal
          participant={participant}
          toggleModalOpen={toggleDeactivateModal}
          modalOpen={deactivateModalOpen}
          handleSubmitReason={handleSubmitDeactivateReason}
          header={'Archive Participant'}
          subHeader={'Reason for Status Change'}
          reasons={deactivateSelectionOptions}
        />
      )}

      {!canDeactivatePar && (
        <PreventActionModal
          isOpen={togglePreventActionModal}
          handleCancelClick={handlePreventActionOnCancel}
          message="A participant can’t be deactivated if they are assigned to or living in a unit."
        />
      )}

      <ListGroupRow>
        <Col md="12">
          <PanelContainer>
            <Row>
              <Col md="10">
                <HorizontalHeaderContainer>
                  <AvatarDiv>
                    <Avatar size="55" round name={name} />
                  </AvatarDiv>
                  <div>
                    <LargeText bold>{name}</LargeText>
                  </div>
                </HorizontalHeaderContainer>
              </Col>
              {mpoOnlyAccess && (
                <div style={{ position: 'absolute', top: 0, right: 0 }}>
                  <DeleteEditMenu
                    type="par"
                    status={status?.type}
                    menuOpen={menuOpen}
                    toggleMenuOpen={toggleMenuOpen}
                    toggleArchive={status?.type === 'Active' ? handleSelectInactive : handleSelectReactivate}
                  />
                </div>
              )}
            </Row>
            <Row>
              <Col md="4">
                <ItemRow>
                  <HeaderLeftCol md="5">HMIS</HeaderLeftCol>
                  <ValueRightCol md="7">{HMISID}</ValueRightCol>
                </ItemRow>
                <ItemRow>
                  <HeaderLeftCol md="5">Age</HeaderLeftCol>
                  <ValueRightCol md="7">{age}</ValueRightCol>
                </ItemRow>
                <ItemRow>
                  <HeaderLeftCol md="5">Current Income</HeaderLeftCol>
                  <ValueRightCol md="7">{`$${
                    income?.toLocaleString('en') || currentIncome?.toLocaleString('en') || 0
                  }/month`}</ValueRightCol>
                </ItemRow>
                <ItemRow>
                  <HeaderLeftCol md="5">CurrentLocation</HeaderLeftCol>
                  <ValueRightCol md="7">{currentLocation}</ValueRightCol>
                </ItemRow>
                <ItemRow>
                  <HeaderLeftCol md="12">
                    <ViewParDetailsBtn onClick={directPar}>
                      <FontAwesomeWrapper>
                        <FontAwesome name="clipboard" />
                      </FontAwesomeWrapper>
                      View All Questionaire Answers
                    </ViewParDetailsBtn>
                  </HeaderLeftCol>
                </ItemRow>
              </Col>
              <EmailCol>
                <ItemRow>
                  <VerticalHeader>
                    <span className="emailCol-header">CONTACT INFO</span>
                    {participant?.contactEmail && (
                      <IconButton onClick={(e) => openEmailModal(e, true)} className="edit-btn-icon">
                        <EditIcon />
                      </IconButton>
                    )}
                  </VerticalHeader>
                </ItemRow>
                <div className="email-grid-container">
                  <div className="emailCol-label">
                    <span>Email Address</span>
                  </div>
                  <div className="email-display-container">
                    <span className="emailCol-email">
                      {participant?.contactEmail ? (
                        participant.contactEmail
                      ) : (
                        <button onClick={openEmailModal}>Add email address</button>
                      )}
                    </span>
                  </div>
                </div>
              </EmailCol>
              <Col>
                <ItemRow>
                  <VerticalHeader>Advocate</VerticalHeader>
                </ItemRow>
                <div style={{ display: 'flex' }}>
                  <SecondaryAvatarDiv>
                    <Avatar size="35" round name={advocate?.name || ''} />
                  </SecondaryAvatarDiv>
                  <div style={{ margin: 'auto 0' }}>
                    <div>{advocate?.name || ''}</div>
                    {mpoOnlyAccess && <AdvocateDropdown isLoading={loadingReassign} />}
                  </div>
                </div>
              </Col>
            </Row>
          </PanelContainer>
        </Col>
      </ListGroupRow>
      <ParDetailsModal participant={participant} modal={modal} toggle={toggle} />
      {reactivateModalOpen && (
        <ReassignParModal
          toggleModalOpen={setReactivateModalOpen}
          handleConfirm={handleSendReactivate}
          advocates={advocates}
          participant={participant}
          refetch={refetch}
        ></ReassignParModal>
      )}
      {toggleEmailModal && (
        <ActionModal
          isOpen={toggleEmailModal}
          header={ActionModalHeader}
          handleCancelClick={openEmailModal}
          modalButtons={ActionModalButtons}
        >
          <EmailTextFieldContainer>
            <EmailTextFieldLabel htmlFor="parEmailInput">Email Address</EmailTextFieldLabel>
            <EmailTextField
              type="email"
              id="parEmailInput"
              className={isEmailInvalid && 'email-error'}
              value={contactEmail}
              onChange={handleEmailInputChange}
            />
            {isEmailInvalid && <EmailTextFieldError>This is not a valid email.</EmailTextFieldError>}
          </EmailTextFieldContainer>
        </ActionModal>
      )}
    </div>
  );
};
