import React, { useState } from 'react';

import {
  Collapse,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  DropdownToggle,
  Dropdown,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { ParToParIds, ParToParData, AssignedParIds, HousingFilter, ParticipantFilter } from '../../apollo/cache';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { NavMain } from './NavBarStyles';
import FontAwesome from 'react-fontawesome';

import logoImg from '../../assets/img/LAFH_logo.svg';
import { colors } from '../../styles/constants';

// TODO : LOGIN USER HANDLE REDIRECT AFTER LOGIN

export const MainNavbar = (props) => {
  const { noUser = false, noBorder = false, noBackgroundColor = false, noItems = false } = props;
  const { isAuthenticated, user, logout } = useAuth0();
  const [isAdmin, setIsAdmin] = useState(false);
  const [showHousingUnits, setShowHousingUnits] = useState(false);
  const [showParticipants, setShowParticipants] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen((isOpen) => !isOpen);
  };
  React.useEffect(() => {
    if (user) {
      const roles = user['http://schemas.aroyomatch.com/claims/roles'];
      if (roles?.includes('HousingLocator') || roles?.includes('MPO') || roles?.includes('UserAdmin')) {
        setShowHousingUnits(true);
      }
      if (roles?.some((r) => r === 'UserAdmin' || r === 'MPO' || r === 'Advocate')) {
        setShowParticipants(true);
      }
      const hasAdminRole = roles?.includes('UserAdmin');
      setIsAdmin(hasAdminRole);
    }
  }, [user, setShowHousingUnits, setShowParticipants]);

  return (
    <>
      <NavMain
        expand="md"
        style={{
          borderBottom: noBorder ? 'none' : `1px solid ${colors.accentColor}`,
          backgroundColor: noBackgroundColor ? 'transparent' : 'white',
        }}
      >
        <>
          <a href={window.location.origin}>
            <img className="brand-logo" src={logoImg} alt="LA Family Housing Logo" />
          </a>
          <Collapse navbar>
            <Nav className="mr-auto" navbar>
              {showHousingUnits && !noItems && (
                <NavItem>
                  <NavLink
                    tag={Link}
                    className={!location.pathname.startsWith('/participant') ? 'active' : ''}
                    to="/housing"
                    onClick={() => {
                      ParToParIds(new Set());
                      ParToParData([]);
                      AssignedParIds(new Set());
                      HousingFilter('');
                      ParticipantFilter('');
                    }}
                  >
                    <FontAwesome name="home" />{' '}
                    <span className={!location.pathname.startsWith('/participant') ? 'active' : ''}>Housing</span>
                  </NavLink>
                </NavItem>
              )}
              {showParticipants && !noItems && (
                <NavItem>
                  <NavLink
                    tag={Link}
                    className={location.pathname.startsWith('/participant') ? 'active' : ''}
                    to="/participants"
                    onClick={() => {
                      ParToParIds(new Set());
                      ParToParData([]);
                      AssignedParIds(new Set());
                      HousingFilter('');
                      ParticipantFilter('');
                    }}
                  >
                    <FontAwesome name="users" />{' '}
                    <span className={location.pathname.startsWith('/participant') ? 'active' : ''}>Participants</span>
                  </NavLink>
                </NavItem>
              )}
            </Nav>
            <Nav className="ml-auto" navbar>
              {!noUser && (
                <Dropdown nav isOpen={isOpen} toggle={toggle}>
                  <DropdownToggle nav caret id="user-options-toggle">
                    <FontAwesome name="user" /> {isAuthenticated ? user.email : ''}
                  </DropdownToggle>
                  <DropdownMenu>
                    {isAdmin && (
                      <DropdownItem id="manage-staff-button" onClick={() => history.push('/admin/manage-staff')}>
                        Manage Staff
                      </DropdownItem>
                    )}

                    <DropdownItem
                      onClick={() => {
                        logout({ returnTo: window.location.origin });
                        history.push('/housing/');
                      }}
                      id="sign-out-button"
                    >
                      Sign out
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              )}
            </Nav>
          </Collapse>
        </>
      </NavMain>
    </>
  );
};
