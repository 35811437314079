import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import SideDrawer from '../components/navbars/SideDrawer';
import NoDataView from './NoDataView';
import { LoadingSpinner } from '../components/Alerts';

const errorMessage = 'This page is restricted.';

export const AdminDashboardWrapper = ({ currentPath, children }) => {
  const { user } = useAuth0();
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    if (user) {
      const hasAdminRole = user?.['http://schemas.aroyomatch.com/claims/roles']?.includes('UserAdmin');
      setIsAdmin(hasAdminRole);
    }
  }, [user]);

  const AdminDisplay = () => {
    return (
      <>
        <SideDrawer currentPath={currentPath} />
        <main style={{ padding: '45px', width: '100%' }}>{children}</main>
      </>
    );
  };

  return (
    <div style={{ display: 'flex' }}>
      {isAdmin ? <AdminDisplay /> : <LoadingSpinner />}
      {!user && !isAdmin ? <NoDataView message={errorMessage} /> : null}
    </div>
  );
};
