import { HousingUnitLocationState } from '../../components/HousingUnitCreation/sections/LocationDetail';
import moment from 'moment-timezone';
export default (housingUnit) => {
  const { location, name, LISTS_rel, region, unitNumber, unitType, nearSchool } = housingUnit;
  const listings = LISTS_rel?.map((listing) => {
    if (listing?.Listing) {
      return {
        ...listing.Listing,
        moment: moment(listing?.updatedAt?.formatted?.split('[')[0] || '1970-01-01'),
      };
    }
  })
    .filter((v) => v)
    .sort((a, b) => b?.moment.diff(a?.moment) || 0);
  const processedLocation = {
    location,
    name,
    unitNumber,
    unitType,
    originalListingUrl: listings?.[0]?.listingUrl || '',
    listingUrl: listings?.[0]?.listingUrl || '',
    listingId: listings?.[0]?.id || '',
    nearSchool,
    region: region.id,
    originalRegion: region.id,
  };
  return processedLocation;
};
