import { gql } from '@apollo/client';

export const QueryHousingAccessible = gql`
  query QueryHousingAccessible($id: ID!) {
    HousingUnit(id: $id) {
      parkingSpotsAdaAccessible
      parkingSpots
      notes {
        id
        createdBy {
          id
          name
        }
        content
        createdAt {
          formatted
          timezone
        }
        noteFor {
          id
        }
      }
      supports {
        id
      }
      accessibilitiesWithDegree {
        degree
        Accessibility {
          id
          ADA
          detail
        }
      }
    }
    Accessibility {
      id
      detail
      ADA
    }
    me {
      id
    }
  }
`;

export const UpdateHousingUnitParking = gql`
  mutation UpdateHousingUnitParking(
    $id: ID!
    $parkingSpots: Int
    $parkingSpotsAdaAccessible: Boolean
    $updatedAt: _Neo4jDateTimeInput
  ) {
    UpdateHousingUnit(
      id: $id
      parkingSpots: $parkingSpots
      parkingSpotsAdaAccessible: $parkingSpotsAdaAccessible
      updatedAt: $updatedAt
    ) {
      id
    }
  }
`;

export const UpdateHousingUnitAccessibilitiesWithDegree = gql`
  mutation UpdateHousingUnitAccessibilitiesWithDegree(
    $housingUnit: _HousingUnitInput!
    $accessibility: _AccessibilityInput!
    $data: _HU_SUPPORTS_ACCInput!
  ) {
    UpdateHousingUnitAccessibilitiesWithDegree(from: $housingUnit, to: $accessibility, data: $data) {
      from {
        id
      }
      to {
        id
      }
      degree
    }
  }
`;

export const AddAccessibilityWithDegreeHousingUnits = gql`
  mutation AddHousingUnitAccessibilitiesWithDegree(
    $housingUnit: _HousingUnitInput!
    $accessibility: _AccessibilityInput!
    $data: _HU_SUPPORTS_ACCInput!
  ) {
    AddHousingUnitAccessibilitiesWithDegree(from: $housingUnit, to: $accessibility, data: $data) {
      from {
        id
      }
      to {
        id
      }
      degree
    }
  }
`;

export const RemoveAccessibilityFromHousingUnit = gql`
  mutation RemoveAccessibilityFromHousingUnit($housingUnit: _HousingUnitInput!, $accessibility: _AccessibilityInput!) {
    RemoveAccessibilityHousingUnits(from: $housingUnit, to: $accessibility) {
      from {
        id
      }
      to {
        id
      }
    }
  }
`;
