export default function (pPets, hPets) {
  if (!pPets.length) {
    // shortcut if no pets
    return true;
  }
  // I don't just break down cats and dogs, because what if someone later has a pet teacup pig or something. I actually knew a homeless person with one
  // This is excessive
  for (let pPet of pPets) {
    const sizesHPets = hPets.filter((hPet) => hPet.species === pPet.species && hPet.size === pPet.size);
    if (!sizesHPets.length) {
      return false;
    }
  }
  return true;
}
