import React, { useState } from 'react';
import { AddContainer } from './ParticipantStyles';
import { MainNavbar } from '../components/navbars/MainNavBar';
import FileUploadField from '../components/Inputs/FileUploadField';
import { Col } from 'reactstrap';
import UploadList from '../components/Inputs/UploadList';

export const AddParticipantView = () => {
  const [success, setSuccess] = useState({ images: [], participants: [] });
  const handleUpload = (success) => {
    setSuccess(success);
  };
  return (
    <div>
      <AddContainer>
        <Col md={{ size: 8, offset: 2 }}>
          <h2 className="form-title center-block text-center">Add Participant Data</h2>
        </Col>
        <Col md={{ size: 10, offset: 1 }}>
          <FileUploadField acceptedTypes=".csv, text/csv" onUploaded={handleUpload}></FileUploadField>
        </Col>
      </AddContainer>
      <UploadList participants={success.participants} />;
    </div>
  );
};
