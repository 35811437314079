import { gql } from '@apollo/client';

export const FetchAdvocates = gql`
  query FetchAdvocates {
    Advocate(orderBy: name_asc, filter: { status: { type: Active } }) {
      id
      name
      organization {
        name
      }
    }
  }
`;

export const ReassignAdvocate = gql`
  mutation ReassignAdvocate($id: ID!, $advocate: ID!) {
    ReassignAdvocate(id: $id, advocate: $advocate) {
      id
      name
    }
  }
`;
