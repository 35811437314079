import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CloudinaryImage } from '../CloudinaryImage';
import {
  GlobalStyle,
  AssignmentModalHeader,
  AssignmentModalCloseButton,
  AssignmentModalBody,
  AssignmentModalRow,
  AssignmentModalCol,
  AssignmentModalFooter,
} from './AssignParsModalStyles';
import Avatar from 'react-avatar';
import AssignParsDetails from '../HousingUnitCreation/components/AssignParsDetails';
import HouseDefaultIcon from '../../assets/img/house-default.png';
import { Modal, Container, Row, Col } from 'reactstrap';
import { SubmitBtn } from '../buttons/ButtonStyles';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/react-hooks';
import { LoadingSpinner, ErrorToast } from '../Alerts';

const AssignParticipantsToUnit = gql`
  mutation AssignParticipantsToUnit($hu: ID!, $pars: [ID!]!) {
    AssignParticipantsToHousingUnit(housingUnit: $hu, participants: $pars) {
      id
      name
      location
    }
  }
`;

export default function AssignParsModal({ parsData, housingUnit, toggle, modal }) {
  let history = useHistory();
  const [assignPars, { loading: assignParsLoading, error: assignParsError }] = useMutation(AssignParticipantsToUnit);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const assignedParticipants = housingUnit?.assignedParticipants?.map((par) => {
    return par?.id;
  });
  const selectedPars = parsData?.map((selectedPar) => {
    return selectedPar?.id;
  });

  const parDetails = parsData.map((par) => {
    return <AssignParsDetails key={par.id} par={par} />;
  });

  const onSubmitClick = async () => {
    setIsFormSubmitted(true);
    const newPars = selectedPars.filter((selectedPar) => {
      return !assignedParticipants.includes(selectedPar);
    });
    try {
      await assignPars({
        variables: { hu: housingUnit?.id, pars: newPars },
      });
    } catch (error) {
      console.error(error);
      setIsFormSubmitted(false);
    }
    await toggle(!modal);
    history.push(`/housing/detail/${housingUnit?.id}`);
  };

  return (
    <Modal isOpen={modal} centered backdropClassName="customDrop">
      <GlobalStyle />
      <AssignmentModalHeader>
        Confirm Assigned Participants
        <AssignmentModalCloseButton color="clear" size="sm" onClick={toggle}>
          X
        </AssignmentModalCloseButton>
      </AssignmentModalHeader>
      <AssignmentModalBody>
        {assignParsLoading ? (
          <LoadingSpinner isStatic={true} />
        ) : (
          <Container>
            <h6>Selected Participants</h6>
            <AssignmentModalRow>{parDetails}</AssignmentModalRow>
            <h6>Unit</h6>
            <AssignmentModalRow>
              <AssignmentModalCol>
                <div className="avatar">
                  {housingUnit?.SHOWS_rel?.[0]?.Image?.publicId ? (
                    <CloudinaryImage publicId={housingUnit?.SHOWS_rel?.[0]?.Image?.publicId} width="36" height="36" />
                  ) : (
                    <Avatar alt={housingUnit?.name} src={HouseDefaultIcon} size="36" round />
                  )}
                </div>
                <div className="details">
                  <p>{housingUnit?.name}</p>
                  <p>{housingUnit?.location}</p>
                </div>
              </AssignmentModalCol>
            </AssignmentModalRow>
          </Container>
        )}
        {assignParsError && <ErrorToast />}
      </AssignmentModalBody>
      <AssignmentModalFooter>
        <SubmitBtn disabled={isFormSubmitted} onClick={onSubmitClick}>
          Submit
        </SubmitBtn>
      </AssignmentModalFooter>
    </Modal>
  );
}
