import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import { cardStyles } from './BrochureStyles';
import PropTypes from 'prop-types';

const KeyValPair = ({ classes, category, value }) => {
  return (
    <Box component="span" className={classes.keyValBox}>
      <Typography variant="body2" color="primary" style={{ flex: '50%' }}>
        {category}
      </Typography>
      <Typography style={{ flex: '50%' }} color="primary">
        {value || '--'}
      </Typography>
    </Box>
  );
};

KeyValPair.propTypes = {
  classes: PropTypes.object.isRequired,
  category: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.element)]),
};

export default withStyles(cardStyles)(KeyValPair);
