import { gql } from '@apollo/client';

export const UpdateUnitStatus = gql`
  mutation UpdateUnitStatus($id: ID!, $status: Status, $updatedAt: _Neo4jDateTimeInput) {
    UpdateHousingUnit(id: $id, status: $status, updatedAt: $updatedAt) {
      id
      name
      status
    }
  }
`;
