import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import moment from 'moment-timezone';
import {
  QueryHousingAccessible,
  AddAccessibilityWithDegreeHousingUnits,
  UpdateHousingUnitParking,
  UpdateHousingUnitAccessibilitiesWithDegree,
} from './AccessibilityGQL';
import { QueryHousingUnitByIdRequiredData } from '../QueryHousingByIdGQL';
import FormButton from '../../buttons/FormButton';
import CounterField from '../../Inputs/CounterField.jsx';
import SelectOneField from '../../buttons/SelectOneField';
import { FormNavBar } from '../../navbars/FormNavBar';
import UnitCompleteModal from '../../modals/UnitCompleteModal';
import { LoadingSpinner, ErrorToast, ContextFeedback } from '../../Alerts';
import { Col } from 'reactstrap';
import accessibilityStateFromHousingUnit from '../../../utilities/stateProcessing/accessibilityStateFromHousingUnit';
import {
  accessibilitiesWithDegreesOptions,
  accessibilitiesWithDegreesOptionText,
  parkingSpotAccessibilityOptions,
} from './AccessibilityByDegreesDict';
import PropTypes from 'prop-types';

function Accessibility({ isUnitActive, completedSections, toggle, modal }) {
  const { houseUnitId } = useParams();
  const history = useHistory();

  // state
  const [hu, setHu] = useState();
  const [formState, setFormState] = useState({
    accessibilitiesWithDegree: {},
    originalAccessibilitiesWithDegree: {},
    parkingSpots: null,
    parkingSpotsAdaAccessible: null,
  });

  const [accessibilityOptions, setAccessibilityOptions] = useState(new Map());
  const [hasErrors, setHasErrors] = useState(false);
  const [unitSectionsComplete, setUnitSectionsComplete] = useState(completedSections);

  const onChange = (id, value) => {
    setHasErrors(false);
    setFormState((formState) => ({ ...formState, [id]: value }));
  };

  const onDegreeChange = (id, selection) => {
    setHasErrors(false);
    const stateCopy = {
      ...formState,
      accessibilitiesWithDegree: {
        ...formState.accessibilitiesWithDegree,
      },
    };
    stateCopy.accessibilitiesWithDegree[id] = selection;
    setFormState(stateCopy);
  };

  // graphql
  const { data, loading, error, refetch: refetchAll } = useQuery(QueryHousingAccessible, {
    variables: { id: houseUnitId },
  });
  const [addAccessibilityToHousingUnit, { loading: loadingAAH, error: errorAAH }] = useMutation(
    AddAccessibilityWithDegreeHousingUnits,
  );
  const [updateAccessibilityToHousingUnit, { loading: loadingUAH, error: errorUAH }] = useMutation(
    UpdateHousingUnitAccessibilitiesWithDegree,
  );
  const { data: dataA, error: errorA, loading: loadingA, refetch } = useQuery(QueryHousingUnitByIdRequiredData, {
    variables: { id: houseUnitId },
  });
  const [updateHousingUnitParking] = useMutation(UpdateHousingUnitParking, {
    async onCompleted() {
      // for some reason it forgets the vars
      await refetchAll?.({ variables: { id: houseUnitId } });
      await refetch?.();
    },
  });

  const checkRequiredFields = () => {
    return (
      (formState.parkingSpots && formState.parkingSpotsAdaAccessible == null) ||
      !Object.keys(formState?.accessibilitiesWithDegree)?.length
    );
  };

  useEffect(() => {
    if (data?.HousingUnit?.length && data?.Accessibility) {
      const { Accessibility, HousingUnit, me } = data;
      const housingUnit = HousingUnit[0];
      if (housingUnit) {
        const newState = accessibilityStateFromHousingUnit(housingUnit);
        setFormState(newState);
      }
      if (Accessibility) {
        const newAccessibilityOptions = new Map();
        for (const accessibilityType of Accessibility) {
          if (accessibilityType) {
            newAccessibilityOptions.set(accessibilityType.ADA, accessibilityType.id);
          }
        }
        setAccessibilityOptions(newAccessibilityOptions);
      }
    }
  }, [data]);

  // Got all HU details
  useEffect(() => {
    if (dataA) {
      const { HousingUnit } = dataA;
      if (HousingUnit) {
        const housingUnit = HousingUnit[0];
        setHu(housingUnit);
      }
    }
  }, [dataA, setHu]);

  useEffect(() => {
    const isRequiredFieldsIncomplete = checkRequiredFields();
    if (isRequiredFieldsIncomplete) {
      setUnitSectionsComplete({ ...completedSections, accessibility: false });
    } else {
      setUnitSectionsComplete({ ...completedSections, accessibility: true });
    }
  }, [completedSections, setUnitSectionsComplete]);

  const handleSubmit = async (isSaveAndClose = false, isFixLater = false) => {
    const foundErrors = checkRequiredFields();
    const location = completedSections?.location;
    const rooms = completedSections?.rooms;
    const acceptances = completedSections?.acceptances;
    const viewing = completedSections?.viewing;
    const otherSectionsComplete = location && rooms && acceptances && viewing;
    setHasErrors(foundErrors || null);
    for (let id in formState.accessibilitiesWithDegree) {
      const degree = formState.accessibilitiesWithDegree[id];
      if (formState.originalAccessibilitiesWithDegree.hasOwnProperty(id)) {
        if (formState.originalAccessibilitiesWithDegree[id] !== degree) {
          await updateAccessibilityToHousingUnit({
            variables: {
              housingUnit: { id: houseUnitId },
              accessibility: { id },
              data: { degree },
            },
          });
        }
      } else {
        await addAccessibilityToHousingUnit({
          variables: {
            housingUnit: { id: houseUnitId },
            accessibility: { id },
            data: { degree },
          },
        });
      }
    }

    if (!foundErrors) {
      const date = new Date();
      const dateYear = date.getFullYear();
      const dateMonth = date.getMonth() + 1;
      const dateDay = date.getDate();
      const dateHour = date.getHours();
      const dateMinute = date.getMinutes();
      const dateSecond = date.getMinutes();
      const dateTimezone = moment.tz.guess();
      try {
        await updateHousingUnitParking({
          variables: {
            id: houseUnitId,
            parkingSpots: formState.parkingSpots,
            parkingSpotsAdaAccessible: formState.parkingSpotsAdaAccessible,
            updatedAt: {
              year: dateYear,
              month: dateMonth,
              day: dateDay,
              hour: dateHour,
              minute: dateMinute,
              second: dateSecond,
              timezone: dateTimezone,
            },
          },
        });
      } catch (err) {
        // TODO handle error
        console.error(err);
      }
    }
    if (otherSectionsComplete && !isUnitActive && isSaveAndClose) {
      toggle();
    } else if ((!foundErrors || isFixLater) && !isSaveAndClose) {
      history.push(`/housing/edit/${houseUnitId}/viewing-options/`);
    } else if (isSaveAndClose) {
      history.push('/housing/');
    }
  };

  const renderAccessibilityDegreesOptions = () => {
    return [...accessibilityOptions.entries()].map(([_, id]) => {
      const accessibilityOptionDegrees = accessibilitiesWithDegreesOptions[id];
      const accessibilityOptionText = accessibilitiesWithDegreesOptionText[id];
      const curDegree = formState.accessibilitiesWithDegree[id];
      return (
        <div key={id} className="question-area" id={'barriers'}>
          <h4 className="center-block text-center">
            {accessibilityOptionText}
            {hasErrors ? (
              <div className="invalid-feedback" style={{ display: 'block', textAlign: 'center', marginBottom: 10 }}>
                Required Field. Please make a selection.
              </div>
            ) : null}
          </h4>
          <SelectOneField id={id} onChange={onDegreeChange} selections={accessibilityOptionDegrees} value={curDegree} />
        </div>
      );
    });
  };

  if (loading || loadingA || loadingAAH || loadingUAH) {
    return <LoadingSpinner />;
  }

  if (error || errorA || errorAAH || errorUAH) {
    return <ErrorToast />;
  }

  if (!data) {
    return <ContextFeedback />;
  }

  return (
    <div>
      <FormNavBar onClick={handleSubmit} />
      <Col md={{ size: 12 }}>
        <h2 className="form-title center-block text-center">Accessibility</h2>
        {renderAccessibilityDegreesOptions()}
        <div className="question-area" id={'parking-spots'}>
          <h4 className="center-block text-center">How many parking spots are provided?</h4>
          <CounterField id="parkingSpots" onIncrease={onChange} onDecrease={onChange} value={formState?.parkingSpots} />
        </div>
        <div
          className="question-area"
          style={{ display: formState.parkingSpots ? 'block' : 'none' }}
          id={'parking-spots-accessible'}
        >
          <h4 className="center-block text-center">Are the parking spots provided ADA-accessible?</h4>
          {(hu?.parkingSpots || formState?.parkingSpots) && formState?.parkingSpotsAdaAccessible === null ? (
            <div className="invalid-feedback" style={{ display: 'block', textAlign: 'center', marginBottom: 10 }}>
              Required Field. Please make a selection.
            </div>
          ) : null}
          <SelectOneField
            id="parkingSpotsAdaAccessible"
            onChange={onChange}
            selections={parkingSpotAccessibilityOptions}
            value={formState?.parkingSpotsAdaAccessible}
          />
        </div>
      </Col>
      <div className="form-title center-block text-center">
        <FormButton
          completedSections={unitSectionsComplete}
          error={hasErrors}
          id={'accessibility'}
          submit={false}
          onClick={handleSubmit}
        >
          <p> Unit can be submitted for matching once all required fields are completed.</p>
        </FormButton>
      </div>
      <UnitCompleteModal toggle={toggle} modal={modal} />
    </div>
  );
}

Accessibility.propTypes = {
  isUnitActive: PropTypes.bool.isRequired,
  completedSections: PropTypes.shape({
    location: PropTypes.bool,
    description: PropTypes.bool,
    rooms: PropTypes.bool,
    acceptances: PropTypes.bool,
    accessibility: PropTypes.bool,
    viewing: PropTypes.bool,
  }).isRequired,
  toggle: PropTypes.func.isRequired,
  modal: PropTypes.bool.isRequired,
};

export default Accessibility;
