import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    maxWidth: '1010px',
    padding: '25px',
    height: '75vh',
    [theme.breakpoints.up('sm')]: {
      padding: 0,
    },
  },
  xxLargeTypography: {
    fontSize: '85px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '124px',
    },
  },
  xLargeTypography: {
    fontSize: '28px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '32px',
    },
  },
  box: {
    maxWidth: '885px',
    margin: 'auto',
  },
}));

export default function BrochureNoData() {
  const classes = useStyles();
  return (
    <>
      <Container className={classes.pageContainer}>
        <Box className={classes.box}>
          <Typography className={classes.xxLargeTypography}>Oh no...</Typography>
          <Typography className={classes.xLargeTypography}>
            We cannot find the unit details. Please double check your link or contact your advocate.
          </Typography>
        </Box>
      </Container>
    </>
  );
}
