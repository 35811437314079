import React from 'react';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import PropTypes from 'prop-types';
import { StyledAddButton } from './AddButtonStyles';
import { XMediumText } from '../../styles/text';

function AddButtonWithCB({ type, content, cb }) {
  return (
    <StyledAddButton onClick={cb} variant="outlined" id={`add-button-${type}`}>
      <ControlPointIcon /> &nbsp;
      <XMediumText color={'inherit'}>{content}</XMediumText>
    </StyledAddButton>
  );
}

AddButtonWithCB.propTypes = {
  cb: PropTypes.func,
  content: PropTypes.string,
  type: PropTypes.string.isRequired,
};

export default AddButtonWithCB;
