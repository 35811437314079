import React, { useEffect, useCallback, useState } from 'react';
import { MediumText } from '../../styles/text';
import { SelectWithCheckField, DescriptionWrapper } from './SelectWithCheckStyles';
import PropTypes from 'prop-types';
import { capitalize } from '../../utilities/stringProcessing';
import Checkbox from './Checkbox';

function SelectMultiFieldWithCheckbox({ selections, id, onChange, value }) {
  const [activeSelections, setActiveSelections] = useState([]);

  const handleCheckClick = useCallback(
    (selection) => {
      let currentSelections = [...activeSelections];
      if (!activeSelections.includes(selection)) {
        currentSelections = [...currentSelections, selection];
      } else {
        currentSelections = currentSelections.filter((item) => item !== selection);
      }
      setActiveSelections([...currentSelections]);
      onChange(id, currentSelections);
    },
    [activeSelections, setActiveSelections, onChange, id],
  );

  useEffect(() => {
    if (value) {
      setActiveSelections(value);
    }
  }, [value]);

  return (
    <div className="justify-content-center">
      {Array.from(selections).map((selection, index) => {
        const optionData = selection[0];
        return (
          <SelectWithCheckField key={`${selection[0]}_${index}}`}>
            <Checkbox
              checked={activeSelections.includes(selection[1])}
              onClick={() => handleCheckClick(selection[1])}
              name="role-check"
              id={`role-check-${index}`}
              $borderColor={true}
            />
            <div style={{ paddingLeft: '18px' }}>
              <DescriptionWrapper>
                <MediumText bold>{capitalize(optionData.name)}</MediumText>
                <MediumText style={{ paddingLeft: '3px' }}>{optionData.helperText}</MediumText>
              </DescriptionWrapper>
              <MediumText>{optionData.definition}</MediumText>
            </div>
          </SelectWithCheckField>
        );
      })}
    </div>
  );
}

SelectMultiFieldWithCheckbox.propTypes = {
  selections: PropTypes.instanceOf(Map),
  id: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.string),
};
export default SelectMultiFieldWithCheckbox;
