import { gql } from '@apollo/client';

export const SendInitialEmail = gql`
  mutation SendInitialEmailToAdvocates(
    $id: ID!
    $participants: [String]!
    $sendToParticipants: Boolean
    $fromEmail: String!
    $customNote: String
    $customSubject: String
    $includeAddress: Boolean
  ) {
    SendInitialEmailToAdvocates(
      id: $id
      participants: $participants
      fromEmail: $fromEmail
      customNote: $customNote
      customSubject: $customSubject
      includeAddress: $includeAddress
      sendToParticipants: $sendToParticipants
    )
  }
`;

export const CreateMatchingProcess = gql`
  mutation CreateMatchingProcess($mpo: String!, $housingUnit: ID!, $participants: [ID!]!) {
    CreateMatchingProcess(mpo: $mpo, housingUnit: $housingUnit, participants: $participants) {
      id
    }
  }
`;
