import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, Box } from '@material-ui/core';
import BrochureCardWrapper from './BrochureCardWrapper';
import { cardStyles } from './BrochureStyles';
import BrochureSlateParagraph from './BrochureSlateParagraph';
import useSlate from '../../components/useSlate';
import { isValueEmpty } from '../../components/useSlate';
import { makePetStr } from './housingUnitHelpers';
import { capitalize } from '../../utilities/stringProcessing';
import { ReactComponent as Check } from '../../assets/icons/icon-on-track.svg';
import PropTypes from 'prop-types';

const BrochureDescription = ({ classes, description }) => {
  const { value, Editor, setValue } = useSlate({
    placeholder: '',
    initialState: [{ type: 'paragraph', children: [{ text: '' }] }],
    DefaultElement: BrochureSlateParagraph,
    id: `slate-readonly-brochure`,
    readOnly: true,
  });

  useEffect(() => {
    if (description) {
      const parsed = JSON.parse(description);
      setValue(parsed);
    }
  }, [description, setValue]);

  return (
    <>
      <Typography variant="h4" color="primary" className={classes.cardHeader}>
        Description
      </Typography>
      {!isValueEmpty(value) && (
        <Box p={0} m={0}>
          {Editor}
        </Box>
      )}
    </>
  );
};

const AllowanceCheckSpan = ({ classes, allowStr }) => {
  return (
    <Box type="span" className={classes.boxSpan}>
      <Check style={{ width: '16px', height: '16px' }} />
      <Typography color="primary" style={{ marginLeft: '20px' }}>
        {allowStr}
      </Typography>
    </Box>
  );
};

const LandlordAllowances = ({ classes, allowedPets, allowances }) => {
  return (
    <>
      <Typography variant="h4" color="primary" className={classes.cardHeader}>
        Landlord Allowances
      </Typography>

      {allowedPets?.length > 0 && (
        <AllowanceCheckSpan classes={classes} allowStr={`Allows Pets: ${makePetStr(allowedPets)}`} />
      )}

      {Object.entries(allowances)?.map((allowance, ix) => {
        if (allowance[1] && allowance[0].slice(0, 5) === 'allow') {
          const allowStr = allowance[0].split(/(?=[A-Z])/).join(' ');
          return <AllowanceCheckSpan key={ix} classes={classes} allowStr={capitalize(allowStr)} />;
        }
      })}
    </>
  );
};

const ViewingOptions = ({ classes, viewingNotes }) => (
  <>
    <Typography variant="h4" color="primary" className={classes.cardHeader}>
      Viewing Options
    </Typography>
    <div className={classes.lowerCard}>
      {viewingNotes?.map((note, ix) => (
        <Typography key={ix} color="primary">
          {note?.content}
        </Typography>
      ))}
    </div>
  </>
);

const BrochureDescriptionCard = (props) => {
  const { classes, description, allowedPets, viewingNotes, ...rest } = props;
  return (
    <BrochureCardWrapper>
      <Grid container>
        <Grid item xs={12}>
          <Box className={classes.box}>
            <BrochureDescription classes={classes} description={description} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className={classes.box}>
            <LandlordAllowances classes={classes} allowedPets={allowedPets} allowances={rest} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className={classes.box}>
            <ViewingOptions viewingNotes={viewingNotes} classes={classes} />
          </Box>
        </Grid>
      </Grid>
    </BrochureCardWrapper>
  );
};

BrochureDescriptionCard.propTypes = {
  allowedPets: PropTypes.arrayOf(
    PropTypes.shape({
      size: PropTypes.string,
      species: PropTypes.string,
    }),
  ),
  allowsConvictions: PropTypes.bool,
  allowsEvictions: PropTypes.bool,
  allowsIndoorSmoking: PropTypes.bool,
  allowsSexOffenders: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  description: PropTypes.string,
  viewingNotes: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      createdAt: PropTypes.string,
    }),
  ),
};

export default withStyles(cardStyles)(BrochureDescriptionCard);
