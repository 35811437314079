import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import {
  MatchingContainer,
  FilterRow,
  MatchFontAwesome,
  MatchLink,
  HeadingDiv,
  MatchDisplay,
} from './MatchPARtoUnitStyles';
import { HousingInfoPanel } from '../components/panels/HousingInfoPanel';
import { GetInitialEmailForUnit, GetMatchingPARsForUnit } from './MatchPARtoUnitGQL';
import { Col, Row, Container } from 'reactstrap';
import { useQuery, useReactiveVar } from '@apollo/react-hooks';
import { MediumText, XLargeText, SmallText } from '../styles/text';
import { ListParticipants } from '../components/TableParticipant/ListParticipants';
import { links } from '../styles/constants';
import { PrimaryButton, SentIcon } from '../components/buttons/ButtonStyles';
import FontAwesome from 'react-fontawesome';
import SendNotificationEmailModal from '../components/modals/SendNotificationEmailModal';
import { LoadingSpinner, ErrorToast, ContextFeedback } from '../components/Alerts';
import Filter from '../components/filter';
import { getDisplayElement } from '../components/filter/getDisplayElement.js';
import { ParToUnitFiltersState, ParToUnitSortState, ParticipantFilter } from '../apollo/cache';
import { defaultState as ParToUnitFiltersDefaultState } from './ParToUnitFiltersDefaultState';
import {
  participantHasBeenNotified,
  participantIsInterested,
  participantIsDisinterested,
} from '../utilities/participantStatusCheck';
import { useToggle } from '../utilities/hooks';
import Tooltip from '../components/tooltip/index';
import { TextButton } from '../components/tooltip/ToolTipStyles';
import Search from '../utilities/search';
import applyFilters from '../utilities/applyFilters';
import { SearchField } from '../components/SearchField';
import { getNotifiedParticipants } from '../utilities/notifiedParticipants';

export default () => {
  const { houseUnitId } = useParams();
  const ParticipantSearchQuery = useReactiveVar(ParticipantFilter);
  const FilterState = useReactiveVar(ParToUnitFiltersState);
  const [housingUnit, setHousingUnit] = useState(null);
  const [checked, setChecked] = useState([]);
  const [openModal, toggleModal] = useToggle(false);
  const [initialEmailObject, setInitialEmailObject] = useState({});
  const [participants, setParticipants] = useState([]);
  const [filtersState, setFiltersState] = useState(
    Object.keys(FilterState).length ? FilterState : ParToUnitFiltersDefaultState,
  );
  const [filteredPARs, setFilteredPARs] = useState([]);
  const [searchedPARs, setSearchedPARs] = useState([]);
  const toggle = useCallback(
    (name, selected, savedValue, save = false) => {
      if (save) {
        // TODO do something to filter results
        const newFilterState = Object.assign(
          {},
          {
            ...filtersState,
            [name]: { ...filtersState[name], open: !filtersState[name].open, selected, savedValue },
          },
        );
        const filteringPARs = applyFilters(participants, newFilterState);
        setFiltersState(newFilterState);
        ParToUnitFiltersState(newFilterState);
        setFilteredPARs(filteringPARs);
        setSearchedPARs(Search(ParticipantSearchQuery, filteringPARs, ['name', 'ADVOCATES_FOR_rel.0.Advocate.name']));
      } else {
        setFiltersState((filtersState) =>
          Object.assign(
            {},
            {
              ...filtersState,
              [name]: {
                ...filtersState[name],
                open: !filtersState[name].open,
              },
            },
          ),
        );
      }
    },
    [
      setFiltersState,
      filtersState,
      participants,
      setFilteredPARs,
      ParToUnitFiltersState,
      ParticipantSearchQuery,
      setSearchedPARs,
    ],
  );

  const getSmokingIndoors = (par) => {
    if (par?.marijuanaPreference || par?.tobaccoPreference) {
      return 'Preference';
    } else if (par?.marijuanaSmokingIndoors || par?.tobaccoSmokingIndoors) {
      return 'Need';
    } else {
      return par?.smokingIndoorsNeeded;
    }
  };

  const getDisplay = useCallback((value) => {
    return getDisplayElement(value);
  }, []);

  const { data, loading, error, refetch, called } = useQuery(GetMatchingPARsForUnit, {
    variables: { id: houseUnitId },
    fetchPolicy: 'cache-and-network',
  });
  const { data: eData, loading: eLoading, error: eError } = useQuery(GetInitialEmailForUnit, {
    variables: { id: houseUnitId },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (data) {
      const { HousingUnit } = data;
      if (HousingUnit) {
        const house = HousingUnit?.[0];
        if (house) {
          setHousingUnit(house);
          const { participantsWithMetNeeds } = house;
          const notifiedParticipants = getNotifiedParticipants(house);
          const newParticipants = participantsWithMetNeeds
            .map((participant) => {
              if (
                (participant?.assignedHousingUnits?.length &&
                  !participant.assignedHousingUnits?.map((ahu) => ahu?.id).includes(houseUnitId)) ||
                participant?.status?.type === 'Inactive'
              ) {
                return null;
                // participant already assigned to some other housing unit, don't process them
              } else if (participant) {
                const notifiedDate = notifiedParticipants[participant.id];
                const isInterested = participantIsInterested(participant, house);
                const isDisinterested = participantIsDisinterested(participant, house);
                const newSmokingProp = getSmokingIndoors(participant);
                const newParticipantToSort = {
                  ...participant,
                  notifiedDate,
                  isInterested,
                  isDisinterested,
                  smokingIndoorsNeeded: newSmokingProp,
                };
                return newParticipantToSort;
              }
              return null;
            })
            .filter((v) => v);
          setParticipants(newParticipants);
          const filteringPARs = applyFilters(newParticipants, FilterState);
          setFilteredPARs(filteringPARs);
          setSearchedPARs(Search(ParticipantSearchQuery, filteringPARs, ['name', 'ADVOCATES_FOR_rel.0.Advocate.name']));
        }
      }
    }
  }, [data, FilterState, ParticipantSearchQuery, setFilteredPARs, setSearchedPARs]);

  useEffect(() => {
    if (eData) {
      const { GetInitialEmailForUnit } = eData;
      if (GetInitialEmailForUnit) {
        setInitialEmailObject({
          ...GetInitialEmailForUnit,
          emailTemplate: GetInitialEmailForUnit.emailTemplate,
        });
      }
    }
  }, [eData]);

  useEffect(() => {
    refetch();
  }, [openModal]);

  if (error) {
    return <ErrorToast />;
  }

  if (loading || eLoading) {
    return <LoadingSpinner />;
  }

  if (!housingUnit && !(loading || eLoading)) {
    return <ContextFeedback />;
  }
  const onCheck = (values) => {
    setChecked(values);
  };

  return (
    <>
      <div className="housing-detail">
        <MatchingContainer>
          <Row>
            <Col md={{ size: 12 }}>
              <Container>
                <MatchLink
                  to={houseUnitId ? `/housing/detail/${houseUnitId}` : ''}
                  onClick={() => {
                    ParToUnitFiltersState(ParToUnitFiltersDefaultState);
                    ParToUnitSortState({
                      sort: 'status',
                      lastSort: null,
                      direction: true,
                    });
                    ParticipantFilter('');
                  }}
                >
                  <MatchFontAwesome name="chevron-left" style={{ color: links.regularLink }} /> &nbsp; Back to unit
                </MatchLink>
              </Container>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 12 }}>
              <Container>
                <HeadingDiv>
                  <XLargeText>Send Unit Listing to Advocates</XLargeText>
                </HeadingDiv>
                <HousingInfoPanel housingUnit={housingUnit} />
              </Container>
            </Col>
          </Row>
          <FilterRow>
            <Col md={{ size: 8 }}>
              <Container>
                <MediumText style={{ display: 'flex' }}>
                  <MediumText bold>{filteredPARs.length} Participant Matches</MediumText>
                  <TextButton id="myTarget">How were they selected?</TextButton>
                  <Tooltip placement="right-end" target="myTarget">
                    Participants have automatically been screened out based on whether they’re actively seeking shared
                    housing, their ability to physically access the Unit, and their sex offender status. Additionally,
                    you can filter the list using the buttons provided below.
                  </Tooltip>
                </MediumText>
              </Container>
            </Col>
            <Col md={{ size: 4 }}>
              <Container>
                <SearchField content={'Search participants or advocates'} inputReactiveVariable={ParticipantFilter} />
              </Container>
            </Col>
          </FilterRow>
          <FilterRow>
            <Col md={{ size: 12 }}>
              <Container>
                <Row>
                  <Col md={{ size: 10 }}>
                    <SmallText fontSize={14} inline>
                      Filter by:{' '}
                    </SmallText>
                    {Object.entries(filtersState).map(([key, value], i) => {
                      return (
                        <Filter
                          key={`Filter-${key}-${value.selected.join('-')}`}
                          name={key}
                          display={getDisplay(value)}
                          options={value.options}
                          selected={value.selected}
                          toggle={toggle}
                          isOpen={value.open}
                          controlled={value.controlled}
                          limit={value.limit}
                          placeholder={value.placeholder}
                          filterType={value.filterType}
                          savedValue={value.savedValue}
                        />
                      );
                    })}
                  </Col>
                  <Col md={{ size: 2 }} style={{ textAlign: 'right' }}>
                    <PrimaryButton disabled={!checked.length} onClick={toggleModal}>
                      <SentIcon>
                        <FontAwesome name="paper-plane" />
                      </SentIcon>
                      Send listing
                    </PrimaryButton>
                  </Col>
                </Row>
              </Container>
            </Col>
          </FilterRow>
          <Row>
            <Col md={{ size: 12 }}>
              {loading ? (
                <LoadingSpinner />
              ) : (
                <ListParticipants
                  participants={searchedPARs}
                  matchingProcesses={housingUnit?.matchingProcesses}
                  interestedParticipants={housingUnit?.interestedParticipants}
                  disinterestedParticipants={housingUnit?.disinterestedParticipants}
                  housingUnit={housingUnit}
                  onCheck={onCheck}
                />
              )}
            </Col>
          </Row>
        </MatchingContainer>
      </div>
      <SendNotificationEmailModal
        size="xl"
        checked={checked}
        toggleModal={toggleModal}
        initialEmailObject={initialEmailObject}
        openModal={openModal}
        houseUnitId={houseUnitId}
      />
    </>
  );
};
