import React, { useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import ConfirmModal from '../modals/ConfirmModal';
import { Row, Col } from 'reactstrap';
import { MatchButtonRow, MatchMessage, ListGroupRow, PanelContainer, DivCol } from './HousingStyles';
import { PrimaryButton } from '../buttons/ButtonStyles';
import { useHistory } from 'react-router-dom';
import { isAllComplete } from '../../utilities/isComplete.js';
import { LargeText, MediumLargeText } from '../../styles/text';
import { ReactivateUnit } from '../TableHousing/TableHousingGQL';
import { useToggle } from '../../utilities/hooks';

export const HousingStatusPanel = ({ housingUnit }) => {
  const history = useHistory();
  const [numNotified, setNumNotified] = React.useState(0);
  const [numInterested, setNumInterested] = React.useState(0);
  const [disableParMatching, setDisableParMatching] = React.useState(true);
  const [numAssigned, setNumAssigned] = React.useState(0);
  const [modalReactivateOpen, toggleReactivateModalOpen] = useToggle(false);
  const [reactivateUnit] = useMutation(ReactivateUnit);
  useEffect(() => {
    if (housingUnit) {
      const { numNotifiedParticipants, numInterestedParticipants, numAssignedParticipants } = housingUnit;
      setNumNotified(numNotifiedParticipants);
      setNumInterested(numInterestedParticipants);
      setNumAssigned(numAssignedParticipants);
      setDisableParMatching(numInterestedParticipants < 1);
    }
  }, [housingUnit, setNumNotified, numInterested]);

  const matchMessage = <div>To begin matching, report interested Participants in Pool</div>;
  const isActive = housingUnit?.status !== 'Archived';

  const clickReactivate = () => {
    toggleReactivateModalOpen();
  };

  const submitReactivate = async () => {
    await reactivateUnit({
      variables: {
        id: housingUnit.id,
      },
    });
  };

  const modalBody = (
    <>
      <LargeText>{housingUnit?.name}</LargeText>
      <MediumLargeText>{housingUnit?.location}</MediumLargeText>
      {housingUnit?.unitNumber && (
        <MediumLargeText>
          {housingUnit?.unitNumber.toLowerCase().startsWith('unit') ? '' : 'Unit '}
          {housingUnit?.unitNumber}
        </MediumLargeText>
      )}
    </>
  );

  return (
    <>
      <ConfirmModal
        confirmationText="Are you sure you want to reactivate this unit?"
        submittedMsg="reactivating..."
        modalOpen={modalReactivateOpen}
        toggleModalOpen={toggleReactivateModalOpen}
        handleConfirm={submitReactivate}
      >
        {modalBody}
      </ConfirmModal>
      <ListGroupRow className={numAssigned > 0 && 'dual-panels-bottom'}>
        <Col md="12" className="housing-status-content">
          <PanelContainer>
            <Row>
              <DivCol md="6">
                <LargeText bold>{numAssigned > 0 && 'Matching '}Status</LargeText>
              </DivCol>
            </Row>
            <Row>
              <DivCol md="6">
                <MatchButtonRow>
                  <Col md="6">{isActive ? `${numNotified} Participants Notified` : 'Inactive'}</Col>
                  <Col md="6">
                    {isActive && (
                      <PrimaryButton
                        disabled={!isAllComplete(housingUnit)}
                        onClick={() => {
                          history.push(`/housing/match/${housingUnit?.id}`);
                        }}
                      >
                        View Participant Pool
                      </PrimaryButton>
                    )}
                    {!isActive && <PrimaryButton onClick={clickReactivate}>Reactivate Unit</PrimaryButton>}
                  </Col>
                </MatchButtonRow>
              </DivCol>
              <Col md="6">
                <MatchButtonRow>
                  <Col md="6">
                    <MatchMessage>{disableParMatching ? matchMessage : <></>}</MatchMessage>
                    {numInterested} Participants Interested
                  </Col>
                  <Col md="6">
                    <PrimaryButton
                      disabled={disableParMatching}
                      onClick={() => {
                        history.push(`/housing/partopar/${housingUnit?.id}`);
                      }}
                    >
                      Match Participants
                    </PrimaryButton>
                  </Col>
                </MatchButtonRow>
              </Col>
            </Row>
          </PanelContainer>
        </Col>
      </ListGroupRow>
    </>
  );
};
