import React, { useState, useMemo, useCallback, useRef } from 'react';
import { createEditor, Node, Transforms, Editor, Element as SlateElement } from 'slate';
import { Editable, ReactEditor, Slate, withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import PropTypes from 'prop-types';
import isHotkey from 'is-hotkey';
import { v4 } from 'uuid';
// You can either style BaseParagraph or supply a different DefaultElement component for a paragraph
import BaseParagraph from './BaseParagraph';
import BaseLeaf from './BaseLeaf';
import { Toolbar, ToolbarButton, isBlockActive, isMarkActive } from './Toolbar';

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
};

const DIRKEYS = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const defaultProps = {
  placeholder: null,
  initialState: [{ type: 'paragraph', children: [{ text: '' }] }],
  richText: false, // richText = true will allow rich text and display the menu
  DefaultElement: BaseParagraph,
  DefaultLeaf: BaseLeaf,
  readOnly: false,
};

export const isValueEmpty = (value) => {
  return value?.length === 1 && value[0].children?.length === 1 && value[0].children[0].text === '';
};

function useSlate(props) {
  const opts = { ...defaultProps, ...props };
  const [editor] = useState(withHistory(withReact(createEditor())));
  const [value, setValue] = useState(opts.initialState);
  // reset view
  const reset = useCallback(
    async (force = false) => {
      if (force) {
        Transforms.setSelection(editor, [0, 0]);
        setValue(defaultProps.initialState);
        Transforms.collapse(editor, { edge: 'start' });
      } else {
        Transforms.setSelection(editor, [0, 0]);
        setValue(opts.initialState);
        Transforms.collapse(editor, { edge: 'start' });
      }
    },
    [setValue, opts, defaultProps],
  );

  // these are paragrpahs
  const renderElement = useCallback(
    (props) => {
      switch (props.element.type) {
        default:
          return <opts.DefaultElement {...props} />;
      }
    },
    [opts],
  );

  const renderLeaf = useCallback((props) => <opts.DefaultLeaf {...props} />, [opts]);

  // Mostly taken from https://github.com/ianstormtaylor/slate/blob/master/site/examples/richtext.tsx
  // isBlockActive doesn't exactly work correctly when surrounded by empty paragraphs.
  const isBlockActive = (editor, format) => {
    const [match] = Editor.nodes(editor, {
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    });

    return !!match;
  };

  const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
      match: (n) => LIST_TYPES.includes(!Editor.isEditor(n) && SlateElement.isElement(n) && n.type),
      split: true,
    });

    const newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };

    Transforms.setNodes(editor, newProperties);

    if (!isActive && isList) {
      const block = { type: format, children: [] };
      Transforms.wrapNodes(editor, block);
    }
  };

  const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  };
  const focus = () => {
    ReactEditor.focus(editor);
  };
  return {
    value,
    setValue,
    reset,
    focus: () => {
      Transforms.select(editor, [0, 0]);
    },
    Editor: (
      <div style={{ padding: props?.readOnly ? '0' : '10px', width: '100%' }}>
        <Slate
          editor={editor}
          value={value}
          onChange={(newValue) => {
            if (isValueEmpty(newValue) && !isValueEmpty(value) && opts.onEmpty) {
              // empty new value and had text in it
              opts.onEmpty();
              return setValue(newValue);
            }
            return setValue(newValue);
          }}
          autoFocus
        >
          {opts.richText && (
            <Toolbar>
              <ToolbarButton icon="format_bold" mark="bold" toggle={toggleMark} focus={focus} />
              <ToolbarButton icon="format_italic" mark="italic" toggle={toggleMark} focus={focus} />
              <ToolbarButton icon="format_underline" mark="underline" toggle={toggleMark} focus={focus} />
              <ToolbarButton icon="format_list_bulleted" block="bulleted-list" toggle={toggleBlock} focus={focus} />
              <ToolbarButton icon="format_list_numbered" block="numbered-list" toggle={toggleBlock} focus={focus} />
            </Toolbar>
          )}
          <Editable
            id={opts.id || v4()}
            placeholder={opts.placeholder}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            data-testid="slate-editor"
            onBlur={() => {
              if (isValueEmpty(value) && opts.onEmpty) {
                opts.onEmpty();
              }
            }}
            readOnly={opts.readOnly}
            onKeyDown={(event) => {
              if (opts.richText) {
                for (const hotkey in HOTKEYS) {
                  if (isHotkey(hotkey, event)) {
                    event.preventDefault();
                    const mark = HOTKEYS[hotkey];
                    toggleMark(editor, mark);
                  }
                }
              }
            }}
            spellCheck
            autoFocus
          />
        </Slate>
      </div>
    ),
    editor,
  };
}

useSlate.propTypes = {
  placeholder: PropTypes.string,
  initialState: PropTypes.arrayOf(PropTypes.objectOf(Node)),
  richText: PropTypes.bool,
  DefaultElement: PropTypes.object,
  DefaultLeaf: PropTypes.object,
  onEmpty: PropTypes.func,
  id: PropTypes.string,
  readOnly: PropTypes.bool,
};

export default useSlate;
