export const isComplete = (section, housingUnit) => {
  if (!housingUnit) {
    return false;
  }
  switch (section) {
    case 'location':
      if (!housingUnit.location || housingUnit.location === '') {
        return false;
      }
      if (!housingUnit.unitType) {
        return false;
      }
      if (!housingUnit.name || housingUnit.name === '') {
        return false;
      }
      if (!housingUnit.region) {
        return false;
      }
      return true;
    case 'rooms':
      if (!housingUnit.bedrooms || !housingUnit.bedrooms.length) {
        return false;
      }
      if (
        housingUnit.bedrooms.some((b) => {
          if (!b) {
            return false;
          }
          return !b.rent;
        })
      ) {
        return false;
      }
      return true;
    case 'acceptances':
      if (housingUnit.allowsSexOffenders == null || housingUnit.allowsIndoorSmoking == null) {
        return false;
      }
      return true;
    case 'accessibility':
      if (
        (housingUnit?.parkingSpots && housingUnit?.parkingSpotsAdaAccessible == null) ||
        !Object.keys(housingUnit?.accessibilitiesWithDegree || {})?.length
      ) {
        return false;
      }
      return true;
    case 'viewing-options':
      if (!housingUnit?.viewingNotes?.length) {
        return false;
      }
      return true;
    default:
      // do nothing
      return false;
  }
};

export const isAllComplete = (housingUnit) => {
  return (
    isComplete('location', housingUnit) &&
    isComplete('rooms', housingUnit) &&
    isComplete('acceptances', housingUnit) &&
    isComplete('accessibility', housingUnit) &&
    isComplete('viewing-options', housingUnit)
  );
};

export const checkSectionsComplete = (currentSection, completedSections) => {
  const sectionsCopy = { ...completedSections };
  sectionsCopy[currentSection] = true;
  const completedVals = Object.values(sectionsCopy);
  return completedVals.every((val) => val);
};
