import React, { forwardRef, useImperativeHandle, useState } from 'react';
import Menu from '@material-ui/core/Menu';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { Editor, Element as SlateElement } from 'slate';
import { Button, Icon } from '@material-ui/core';
import { useSlate } from 'slate-react'; // confusing, eh?
import PropTypes from 'prop-types';

export const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

export const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  });
  return !!match;
};

function ToolbarButton(props) {
  const editor = useSlate();
  const { mark, block, toggle, focus } = props;
  return (
    <ToggleButton
      square
      onClick={() => {
        toggle(editor, mark ? mark : block);
        focus();
      }}
      selected={mark ? isMarkActive(editor, mark) : isBlockActive(editor, block)}
      style={{ outline: 'none', margin: 0, backgroundColor: 'none', borderRadius: '0' }}
    >
      <Icon>{props.icon}</Icon>
    </ToggleButton>
  );
}

ToolbarButton.propTypes = {
  mark: PropTypes.string,
  block: PropTypes.string,
  toggle: PropTypes.func.isRequired,
};

function Toolbar(props) {
  return (
    <div
      style={{
        margin: '-10px -10px 16px -10px',

        boxShadow: '0px 1px 6px lightgray',
        overflow: 'hidden',
      }}
      {...props}
    />
  );
}

export { ToolbarButton, Toolbar };
