import styled from 'styled-components';
import { Input } from 'reactstrap';
export const RoomInput = styled(Input)`
  height: 48px;

  && input.is-valid {
    border-color: #5bb8b4;
    background-image: none;
    box-shadow: none;
  }

  &&:not(.is-invalid) {
    box-shadow: none;
  }

  && input:focus {
    box-shadow: none;
  }
`;
