import styled from 'styled-components';
import { Popover } from 'reactstrap';
import { ClearButton } from '../buttons/ButtonStyles.js';
import { typography } from '../../styles/constants';

export const CustomPopover = styled(Popover)`
  & .popover {
    max-width: 550px;
  }
`;

export const TextButton = styled(ClearButton)`
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  height: fit-content;
  color: ${typography.fontColor.alertLinkColor};
`;
