import styled from 'styled-components';
import { Button, Col, Row, ModalHeader } from 'reactstrap';
import { typography, borders, links } from '../../styles/constants';
import FontAwesome from 'react-fontawesome';
const ColBase = styled(Col)`
  font-size: ${typography.fontSize.smtext};
`;

export const ColLeft = styled(ColBase)`
  font-weight: 700;
  font-size: ${typography.fontSize.xsmtext};
`;

export const ColRight = styled(ColBase)`
  font-weight: 400;
`;

export const SideBySideRow = styled(Row)`
  margin: 0px;
  min-height: 51px;
  padding: 16px 0px 16px 0px;
  /* border-bottom: 1px dashed #F0F0F0; */
`;

export const ScrollDiv = styled.div``;

export const SideBySideRowA = styled(SideBySideRow)`
  background: #fafdff;
`;
export const SideBySideRowB = styled(SideBySideRow)`
  background-color: white;
`;

export const SideBySideMatchDiv = styled.div`
  padding: 10px;
  margin: 0px 0px 0px 0px;
  height: 455px;
  overflow-y: scroll;
  border: ${borders.panelBorder};
  border-radius: ${borders.panelBorderRadius};
`;

export const ViewMoreButton = styled(Button)`
  background: clear,
  border: none;
  padding: 0;
  margin: 0 0 0 8px;
  color: ${links.listLink};
  font-size: ${typography.fontSize.smtext};
  font-weight: 700;
`;

export const ColRJ = styled(ColBase)`
  @media (max-width: 700px) {
    text-align: left;
    margin: 0px 0px 0px 0px;
    padding: 0px;
  }
  @media (min-width: 700px) {
    text-align: left;
    margin: 0px 0px 0px 0px;
    padding: 0px;
  }
`;

export const RedAwesome = styled(FontAwesome)`
  color: #ff8181;
  font-size: 16px;
`;

export const GreenAwesome = styled(FontAwesome)`
  color: #2dd389;
  font-size: 16px;
`;

export const BlackAwesome = styled(FontAwesome)`
  color: gray;
  font-size: 16px;
`;

export const QuestionDiv = styled.div`
  font-size: ${typography.fontSize.smtext};
  font-weight: bold;
`;

export const ResponseHolder = styled.div`
  font-size: ${typography.fontSize.mdtext};
  font-weight: light;
  padding: 0px 48px 48px 48px;
`;

export const ResponseDiv = styled.div``;

export const ResponseModalHeader = styled(ModalHeader)`
  border: none;
  margin: 0;
`;
