import styled from 'styled-components';
import { Col, Container, Row } from 'reactstrap';
import { borders, typography, colors, links } from '../../styles/constants';
export const MatchButtonRow = styled(Row)`
  margin-top: 24px;
  margin-bottom: 24px;
`;
export const MatchMessage = styled.div`
  position: absolute;
  top: -50px;
  font-size: ${typography.fontSize.mdtext};
  color: #939393;
`;
export const OpenHouseButtonContainer = styled(Container)`
  margin-top: 10px;
`;

export const PrimaryImage = styled.img`
  height: 160px;
  width: 160px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
`;

export const PanelContainer = styled.div`
  padding: ${(props) => (props.padding ? props.padding : '15px 20px 15px 20px')};
  margin: ${(props) => {
    return `${props.marginTop || 0}px ${props.marginRight || 0}px ${props.marginBottom || 0}px ${
      props.marginLeft || 0
    }px`;
  }};
  ${(props) => (props.border ? `border: ${borders.border}` : '')};
  ${(props) => (props.boxShadow ? `box-shadow: ${borders.boxShadow}` : '')};
  ${(props) => (props.borderRadius ? `border-radius: ${borders.borderRadius}` : '')};

  &.movedIn-view {
    max-width: 530px;
  }
  .par-response-header {
    font-size: 14px;
  }
`;

export const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InformationDiv = styled.div`
  ${(props) => (props.alignSelf ? `align-self: ${props.alignSelf}` : '')};
  & .par-details-rent {
    margin-bottom: 0;
    color: #707070;
  }
`;

export const AvatarDiv = styled.div`
  align-self: center;
  margin-right: 16px;
  margin-left: 16px;
`;

export const ListGroupRow = styled(Row)`
  color: ${typography.fontColor.title};
  border: ${borders.border};
  box-shadow: ${borders.boxShadow};
  border-radius: ${borders.borderRadius};
  margin-bottom: 24px;
  &.dual-panels-top {
    border-radius: 7px 7px 0 0;
    margin-bottom: 0;
    border-bottom: none;
  }
  &.dual-panels-bottom {
    border-radius: 0 0 7px 7px;
    background-color: white;
  }
`;

export const StreetText = styled.div`
  font-size: ${typography.fontSize.mdtext};
  &.par-detail-view {
    color: #707070;
  }
`;

export const LinkItem = styled.a`
  text-decoration: none;
  color: ${(props) => (props.color ? typography.fontColor.text : '#5bb8b4')};
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}rem` : typography.fontSize.lgtext)};
  font-weight: ${(props) => props.fontWeight};
  :hover {
    color: ${(props) => (props.color ? typography.fontColor.text : '#5bb8b4')};
    text-decoration: ${(props) => (props.color ? 'underline' : 'none')};
    cursor: pointer;
  }
  &.access:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  &.no-access:hover {
    text-decoration: none;
    cursor: default;
  }
`;
export const GalleryDiv = styled.div`
  text-decoration: none;
  cursor: pointer;
  color: #5bb8b4;
  font-size: ${typography.fontSize.mdtext};
  :hover {
    color: #5bb8b4;
    text-decoration: underline;
  }
`;

export const ItemRow = styled(Row)`
  margin-bottom: 12px;
`;
export const HeaderLeftCol = styled(Col)`
  font-size: ${typography.fontSize.smtext};
  font-weight: bold;
  align-items: center;
  line-height: ${typography.fontSize.mdtext};
`;
export const ValueRightCol = styled(Col)`
  font-size: ${typography.fontSize.mdtext};
  align-items: center;
  line-height: ${typography.fontSize.mdtext};
`;
export const DivCol = styled(Col)`
  border-right: ${borders.panelBorder};
`;
export const FontAwesomeWrapper = styled.span`
  display: inline-block;
  margin-right: 5px;
`;

export const PanelGrid = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.$gridTemplateColumns ? props.$gridTemplateColumns : 'auto auto')};
  grid-template-rows: ${(props) => (props.$gridTemplateRows ? props.$gridTemplateRows : 'auto')};
  grid-column-gap: ${(props) => props.$gridColumnGap};
  grid-row-gap: 5px;
  width: 100%;
  justify-content: ${(props) => (props.$justifyContent ? props.$justifyContent : 'center')};
  & .additionalInfoLabel,
  & .additionalInfo {
    color: ${typography.fontColor.text};
  }
  & .additionalInfoLabel {
    align-self: center;
    font-size: ${typography.fontSize.smtext};
    font-weight: ${typography.fontWeight.title};
  }
  & .additionalInfo {
    font-size: ${typography.fontSize.lgtext};
  }
`;

export const PanelGridItem = styled.div`
  text-align: ${(props) => props.$textAlign};
  width: 100%;
  ${(props) => (props.$position ? `position: ${props.$position}` : '')};
  ${(props) => (props.$justifySelf ? `justify-self: ${props.$justifySelf}` : '')};
  ${(props) => (props.$display ? `display: ${props.$display}` : '')};
  ${(props) => (props.$justifyContent ? `justify-content: ${props.$justifyContent}` : '')};
  ${(props) => (props.$padding ? `padding: ${props.$padding}` : '')};
  & span.changeLink {
    font-weight: bold;
    text-align: center;
  }

  & span.changeLink a {
    color: ${typography.fontColor.alertLinkColor} !important;
  }
  & span.changeLink a:hover {
    text-decoration: none;
  }

  & span.interestIcon {
    margin-right: 5px;
  }

  & .additional-options {
    display: flex;
    justify-content: flex-end;
    grid-column-start: 3;
    grid-column-end: 4;
  }

  & .hold-deadline {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    padding-left: 2rem;
  }

  & .hold-deadline .edit-btn-icon {
    display: flex;
    align-items: center;
  }

  & .hold-deadline-add {
    text-decoration: none;
    font-weight: bold;
    color: ${links.listLink};
  }
`;

export const AllowanceCheckMarkImg = styled.img`
  margin-right: 7px;
  padding-bottom: 4px;
`;

export const PanelCol = styled(Col)`
  display: ${(props) => props.$display};
  flex-direction: ${(props) => props.$flexDirection};
  padding-top: ${(props) => props.$paddingTop};
  padding-bottom: ${(props) => props.$paddingBottom};
  padding-left: ${(props) => props.$paddingLeft};
  padding-right: ${(props) => (props.$paddingRight ? props.$paddingRight : '0px')};
  justify-content: ${(props) => props.$justifyContent};
  background-color: ${(props) => props.$backgroundColor};
  & .panel-label {
    font-weight: bold;
  }
`;

export const PanelRow = styled(Row)`
  justify-content: ${(props) => props.$justifyContent};
  margin: ${(props) => {
    return `${props.$marginTop || 0}px ${props.$marginRight || 0}px ${props.$marginBottom || 0}px ${
      props.$marginLeft || 0
    }px`;
  }};
  & .allowances {
    font-size: 13px;
    font-weight: ${typography.fontWeight.title};
  }
`;
