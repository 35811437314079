import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Dropdown, DropdownItem, DropdownToggle } from 'reactstrap';
import { FilterDropdownMenu } from '../../filter/styles';
import { SmallText } from '../../../styles/text';

export default function DeleteEditMenu({
  type = 'house',
  menuOpen,
  toggleMenuOpen,
  handleClick,
  handleEditClick,
  isDeletable,
  toggleDelete,
  toggleArchive,
  toggleReactivateModalOpen,
  status,
  id,
}) {
  //TODO: to make housing units toggle to archive/activate
  const handleArchiveClick = () => {
    if (status === 'Archived') {
      // Only HUs have a status of Archived
      toggleReactivateModalOpen();
    } else {
      toggleArchive();
    }
  };

  const ParBody = (
    <>
      <DropdownItem
        onClick={() => {
          handleArchiveClick();
        }}
        id={`activation-${id}`}
      >
        <SmallText>{status !== 'Inactive' ? 'Deactivate' : 'Reactivate'}</SmallText>
      </DropdownItem>
    </>
  );

  const HouseBody = (
    <>
      <DropdownItem
        onClick={() => {
          handleClick('edit');
        }}
        id={`edit-${id}`}
      >
        <SmallText>Edit</SmallText>
      </DropdownItem>
      {isDeletable && (
        <DropdownItem
          onClick={() => {
            toggleDelete();
          }}
          id={`delete-${id}`}
        >
          <SmallText>Delete</SmallText>
        </DropdownItem>
      )}
      <DropdownItem
        onClick={() => {
          handleArchiveClick();
        }}
        id={`activation-${id}`}
      >
        <SmallText>{status === 'Archived' ? 'Reactivate' : 'Deactivate'}</SmallText>
      </DropdownItem>
    </>
  );

  const UserBody = (
    <>
      <DropdownItem
        onClick={() => {
          handleEditClick();
        }}
        id={`edit-user-${id}`}
      >
        <SmallText>Edit</SmallText>
      </DropdownItem>

      <DropdownItem
        onClick={() => {
          handleArchiveClick();
        }}
        id={`toggle-deactivate-user-${id}`}
      >
        <SmallText>{status === 'Active' ? 'Deactivate' : 'Reactivate'}</SmallText>
      </DropdownItem>

      {isDeletable && (
        <DropdownItem
          onClick={() => {
            toggleDelete();
          }}
          id={`delete-user-${id}`}
        >
          <SmallText>Delete</SmallText>
        </DropdownItem>
      )}
    </>
  );

  const getBody = () => {
    if (type === 'house') {
      return HouseBody;
    } else if (type === 'user') {
      return UserBody;
    } else {
      return ParBody;
    }
  };

  return (
    <Dropdown isOpen={menuOpen} toggle={toggleMenuOpen} id={id}>
      <DropdownToggle color="clear">
        <FontAwesome name="ellipsis-h" size="lg" className="chevron-right" style={{}} />
      </DropdownToggle>
      <FilterDropdownMenu right>{getBody()}</FilterDropdownMenu>
    </Dropdown>
  );
}
