import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, Spinner } from 'reactstrap';
import {
  filterDropdownToggleStyle,
  filterDropdownMenuStyle,
  filterDropdownFormStyle,
  DropdownInput,
} from './DropdownStyle';

const useDropdown = (header, options, nameKey) => {
  const [selection, setSelection] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => {
    if (options?.length > 0) {
      setDropdownOpen((prevState) => !prevState);
    }
  };

  const DropdownFilter = ({ isLoading }) => {
    const [filteredOptions, setFilteredOptions] = useState(options);
    const id = `use-dropdown-${header.replace(' ', '').toLowerCase()}`;

    const filterOptions = (filterStr) => {
      return options.filter((option) => option.name.toLowerCase().includes(filterStr.toLowerCase()));
    };

    const handleChangeFilter = (e) => {
      const filtered = filterOptions(e.target.value);
      setFilteredOptions(filtered);
    };

    return (
      <Dropdown id={id} isOpen={dropdownOpen} toggle={toggle} direction="up">
        <DropdownToggle tag="button" data-toggle="dropdown" style={filterDropdownToggleStyle}>
          {header}
        </DropdownToggle>
        {isLoading && <Spinner size="sm" color="dark" />}
        <DropdownMenu style={filterDropdownMenuStyle}>
          <Form style={filterDropdownFormStyle}>
            <DropdownInput autoFocus placeholder="Type to filter" onChange={handleChangeFilter}></DropdownInput>
          </Form>
          {filteredOptions.map((option) => {
            return (
              <DropdownItem key={option.id} onClick={(e) => setSelection(option.id)}>
                {option[nameKey]}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    );
  };

  return [selection, DropdownFilter];
};

export default useDropdown;
