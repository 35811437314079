import styled from 'styled-components';
import { FormGroup } from 'reactstrap';

export const LocationDetailsFormGroup = styled(FormGroup)`
  && input.is-valid {
    border-color: #5bb8b4;
    background-image: none;
    box-shadow: none;
  }

  &&:not(.is-invalid) {
    box-shadow: none;
  }

  && input:focus {
    box-shadow: none;
  }
`;
