import React, { useEffect, useState } from 'react';

import ParToParCardSmallComponent from '../../components/ParToParCardSmall';
import { ParToParCardContainer } from '../../components/ParToParStyles';
import { Row, Col } from 'reactstrap';
import { MediumText, MediumLargeText } from '../../styles/text';
import { ComparisonDiv, ViewButtonDiv } from './ComparisonPanelStyles';
import { PrimaryButton } from '../buttons/ButtonStyles';
import { useParams, useHistory } from 'react-router-dom';
import { ParToParIds, AllParsData, AssignedParIds } from '../../apollo/cache';
import { v4 } from 'uuid';
export default function ComparisonPanel({ numBedrooms, deselect }) {
  const { houseUnitId } = useParams();
  const history = useHistory();
  const [rows, setRows] = React.useState([]);
  const [enableClick, setEnableClick] = useState(false);
  const generateEmpty = () => {
    return (
      <ParToParCardContainer border={true} disabled={true} key={v4()}>
        <Row className="h-100">
          <Col md="12" className="align-self-center text-center">
            <MediumText className="align-self-center">Select Participant</MediumText>
          </Col>
        </Row>
      </ParToParCardContainer>
    );
  };
  const generatePar = (participant) => {
    return (
      <ParToParCardSmallComponent
        participant={participant}
        deselect={deselect}
        key={`${participant.id}-small-card`}
        isAssigned={AssignedParIds().has(participant.id)}
      />
    );
  };
  useEffect(() => {
    const toCompare = AllParsData().filter((par) => ParToParIds().has(par.id));
    const rowLength = Math.round(numBedrooms / 2);
    const rowsOfParsToCompare = [];
    for (let i = 0, j = 0; i < rowLength; i++, j += 2) {
      let row = [];
      if (toCompare.length > j) {
        row.push(generatePar(toCompare[j]));
      } else {
        row.push(generateEmpty());
      }
      if (toCompare.length > j + 1) {
        row.push(generatePar(toCompare[j + 1]));
      } else if (numBedrooms > j + 1) {
        row.push(generateEmpty());
      }
      rowsOfParsToCompare.push(
        <Row className="text-align-center col-center" key={`${i}-${j}`}>
          {row}
        </Row>,
      );
    }
    setRows(rowsOfParsToCompare);
    if (toCompare.length) {
      setEnableClick(true);
    }
  }, [ParToParIds, AllParsData, numBedrooms]);

  return (
    <ComparisonDiv>
      <div className="w-100 text-center">
        <MediumLargeText bold={true}>Select at least 2 Participants to View Comparisons</MediumLargeText>
      </div>
      {rows}
      <ViewButtonDiv className="text-center">
        <PrimaryButton
          disabled={!enableClick}
          onClick={() => {
            history.push(`/housing/partopardetail/${houseUnitId}`);
          }}
        >
          View Full Comparison
        </PrimaryButton>
      </ViewButtonDiv>
    </ComparisonDiv>
  );
}
