import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

function ToastAlert(props) {
  return <Alert elevation={5} {...props} />;
}

export default function MuiAlert({ isOpen, msg, handleCloseAlert, severity = 'error' }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
  });

  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    handleCloseAlert();
    setOpen(false);
  };

  return (
    <div>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <ToastAlert onClose={handleClose} severity={severity}>
          {msg}
        </ToastAlert>
      </Snackbar>
    </div>
  );
}
