import styled from 'styled-components';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { borders, colors, typography } from '../../styles/constants';
import Checkbox from './Checkbox';
import checkmark from '../../assets/img/box-checked-colored.svg';
import TextField from '@material-ui/core/TextField';

export const FilterDropdown = styled(UncontrolledDropdown)`
  display: ${(props) => (props.inline ? 'inline' : 'block')};
  margin-right: 20px;
  > button.btn {
    box-shadow: none;
    border: ${(props) => {
      return props.isOpen ? borders.filterToggleSelectedBorder : borders.filterToggleUnselectedBorder;
    }};
    text-decoration: none;
    input {
      .Mui-focused {
        border-color: ${colors.filterTextField};
        color: ${colors.filterTextField};
      }
    }
  }
  .MuiFormControl-root > .Mui-focused > fieldset {
    border-color: ${colors.filterTextField};
  }
`;

export const FilterDropdownToggle = styled(DropdownToggle)`
  border-color: ${(props) => (props.active ? '#5bb8b4 !important' : 'gray')};
  text-decoration: none;
  background: white !important;
  color: ${colors.selected};
  box-sizing: content-box;
  &:focus,
  &:hover,
  &:active {
    border-color: #5bb8b4 !important;
    box-shadow: none !important;
    outline: none;
  }
`;

export const FilterDropdownMenu = styled(DropdownMenu)`
  border: ${borders.filterMenuBorder};
  box-shadow: ${borders.filterMenuBoxShadow};
  margin: 5px 0 0 0;
  padding: 12px;
  text-decoration: none;
  &:focus,
  &:hover,
  &:active {
    border-color: #5bb8b4 !important;
    box-shadow: none !important;
    outline: none;
  }
`;

export const FilterDropdownActions = styled.div`
  position: relative;
  height: 40px;
  padding: 5px;
  margin-top: 10px;
`;

export const FilterTextField = styled(TextField)`
  margin: 0;
  fieldset {
    border-color: ${colors.filterTextField};
  }
  label {
    color: ${colors.grayText};
  }
  input {
    color: ${typography.fontColor.text};
  }
`;

export const FilterDropdownItem = styled(DropdownItem)`
  margin: 0;
  padding: 0.25rem;
  &:focus,
  &:active {
    background: white !important;
    border-color: gray !important;
    box-shadow: none !important;
    outline: none;
  }
`;

export const FilterCheckbox = styled(Checkbox)`
  position: relative;
  input[type='checkbox'] {
    & + label {
      min-width: 16px;
      min-height: 16px;
      &:before {
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        top: 0;
        left: 0;
        background-color: white;
        border: 1px solid ${colors.selected};
        border-radius: 2px;
        box-sizing: content-box;
      }
    }
    &:checked {
      & + label {
        &:before {
          color: white;
          background-color: ${colors.selected};
          background-image: url(${checkmark});
          background-position: center;
          background-size: cover;
          background-blend-mode: lighten;
        }
      }
    }
  }
`;
