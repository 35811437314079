import React from 'react';

export default function valueAsHtml(value, style = 'font-size: 20px; color: #06446a') {
  const childHtml = (child) => {
    let children = child.text ? child.text : `&nbsp;`;
    if (child.bold) {
      children = `<strong>${children}</strong>`;
    }
    if (child.italic) {
      children = `<em>${children}</em>`;
    }
    if (child.underline) {
      children = `<u>${children}</u>`;
    }
    return `<span>${children}</span>`;
  };

  const childReducer = (accChild, child) => {
    if (child.children?.length) {
      if (child.type == 'text') {
        return accChild + child.children.reduce(childReducer, '');
      } else if (child.type === 'list-item') {
        return (
          accChild +
          `<li><p style="${style}; margin-bottom: 16px; padding-left: 10px;">${child.children.reduce(
            childReducer, // ooh we have recursion (don't tell anyone)
            '',
          )}</li>`
        );
      }
    } else {
      return accChild + childHtml(child);
    }
  };

  const htmlValue = value.reduce((acc, para) => {
    if (para && para.children) {
      const children = para.children.reduce(childReducer, '');
      if (para.type === 'paragraph') {
        return acc + `<p style="${style}">${children}</p>`;
      } else if (para.type === 'bulleted-list') {
        return acc + `<ul style="${style}">${children}</ul>`;
      } else if (para.type === 'numbered-list') {
        return acc + `<ol style="${style}">${children}</ol>`;
      }
    }
    return acc;
  }, '');
  return htmlValue;
}
