import React from 'react';
import { useParams } from 'react-router-dom';
import Avatar from 'react-avatar';
import ParDetailsDisplay from '../HousingUnitCreation/components/ParDetailsDisplay';
import { GlobalStyle, ParDetailsModalContainer, StyledModalHeader } from './ParDetailsmodalStyles';
import { AssignmentModalCol } from '../modals/AssignParsModalStyles';
import { ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';

const ParDetailsModal = (props) => {
  const { participantId } = useParams();
  const parId = participantId || props?.participant?.id;
  return (
    <>
      <GlobalStyle opacity={0.85} />
      <ParDetailsModalContainer isOpen={props?.modal} className="customDrop">
        <StyledModalHeader toggle={props?.toggle}>Questionaire Answers</StyledModalHeader>
        <ModalBody>
          <AssignmentModalCol md="6" style={{ marginBottom: 40 }}>
            <div className="avatar">
              <Avatar
                alt={props?.participant?.name}
                name={props?.participant?.name}
                size="65"
                round
                style={{ marginRight: 5 }}
              />
            </div>
            <div className="details">
              <p className="parName">{props?.participant?.name}</p>
              <p>{props?.participant?.HMISID}</p>
              <p>
                {props?.participant?.gender?.name ? `${props?.participant?.gender?.name}, ` : ''}
                {props?.participant?.age ? `${props?.participant?.age} yrs old` : ''}
              </p>
            </div>
          </AssignmentModalCol>
          <ParDetailsDisplay participantId={parId} />
        </ModalBody>
      </ParDetailsModalContainer>
    </>
  );
};

ParDetailsModal.propTypes = {
  participant: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
};
export default ParDetailsModal;
