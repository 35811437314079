import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { colors } from '../constants';

//defaults: https://material-ui.com/customization/default-theme/
let theme = createMuiTheme({
  spacing: 6,
  width: 1,
  fontFamily: 'Lato',
  shape: {
    borderRadius: '7px',
  },
  borders: {},
  palette: {
    primary: {
      main: colors.blue,
    },
    secondary: {
      light: colors.lightBlueish,
      main: colors.lightBlueGreen,
    },
    error: {
      main: colors.red,
    },
    alert: {
      main: colors.alertBgColor,
    },
    success: {
      main: colors.green,
    },
    disabled: {
      main: colors.grayText,
      secondary: colors.disabledButton,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    borderColor: colors.lightBlueGreen,
    background: {
      paper: colors.whiteShade,
      default: colors.lightBlueish,
      card: colors.whiteShade,
    },
    action: {
      active: colors.selected,
      hover: colors.selected,
      hoverOpacity: 0.04,
      selected: colors.selected,
      selectedOpacity: 0.08,
      disabled: colors.grayText,
      disabledBackground: colors.disabledButton,
      disabledOpacity: 0.38,
      focus: colors.selected,
    },
  },
  shadows: ['0px 3px 16px #0A4B6D14'],
  typography: {
    fontFamily: 'Lato',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightMedium: 400,
    fontWeightBold: 700,
    h1: {
      fontSize: '5rem',
      lineHeight: 1.167,
      fontWeight: 300,
      letterSpacing: '-0.01562em',
    },
    h2: {
      fontSize: '3.75em',
      lineHeight: 1.2,
      fontWeight: 300,
      letterSpacing: '-0.00833em',
    },
    h3: {
      fontSize: '20px',
      lineHeight: 1.167,
      fontWeight: 'bold',
    },
    h4: {
      fontSize: '20px',
      fontWeight: 'bold',
      letterSpacing: '0em',
    },
    h5: {
      fontSize: '1.5em',
      letterSpacing: '0px',
    },
    h6: {
      fontSize: '16px',
      letterSpacing: '0px',
      fontWeight: 'bold',
    },
    subtitle1: {
      fontSize: '16px',
      letterSpacing: '0px',
    },
    body1: {
      fontSize: '16px',
    },
    body2: {
      fontSize: '12px',
      fontWeight: 600,
    },
  },
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
});

//TODO: might be a good idea to consider responsive font sizes but
// doesn't work w current design spec; egtheme = responsiveFontSizes(theme)
export default theme;
