import React from 'react';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/react-hooks';
import { CloudinaryImage } from '../CloudinaryImage';
import { Table, Input, Button } from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import { v4 } from 'uuid';
export const RemoveHousingUnitSHOWS_rel = gql`
  mutation RemoveHousingUnitSHOWS_rel($from: _ImageInput!, $to: _HousingUnitInput!) {
    RemoveHousingUnitSHOWS_rel(from: $from, to: $to) {
      from {
        id
      }
      to {
        id
      }
    }
  }
`;

export const ImageGallerySelectField = ({
  galleryImages,
  primaryImage,
  updateImageOrder,
  houseUnitId,
  triggerRefetch,
}) => {
  const [primaryImageId, setPrimaryImageId] = React.useState('');

  const [deleteImageMutation] = useMutation(RemoveHousingUnitSHOWS_rel);

  React.useEffect(() => {
    if (primaryImage?.Image?.id) {
      setPrimaryImageId(primaryImage.Image.id);
    }
  }, [galleryImages, primaryImage]);

  const onClick = async (selection, index) => {
    setPrimaryImageId(selection);
    updateImageOrder(index);
  };

  const onRemove = async (imageId) => {
    if (houseUnitId && !!imageId) {
      try {
        await deleteImageMutation({
          variables: {
            to: { id: houseUnitId }, // value for 'housingUnitId'
            from: { id: imageId }, // value for 'primaryImageId'
          },
        });
        triggerRefetch();
      } catch (err) {
        console.error(err);
      }
    }
  };

  if (galleryImages) {
    return (
      <div className="image-gallery-selectfield">
        <Table borderless>
          <tbody>
            {galleryImages.map((image, i) => {
              return (
                <tr key={image?.Image?.id || v4()}>
                  <th scope="row">
                    {image?.Image?.publicId && (
                      <CloudinaryImage publicId={image.Image.publicId} width="200" height="200" />
                    )}
                  </th>
                  <td>
                    <Input
                      type="radio"
                      value={image?.id}
                      checked={primaryImageId === image?.Image?.id ? true : false}
                      onChange={() => onClick(image?.Image?.id || '', i + 1)}
                    />{' '}
                    {primaryImageId === image?.Image?.id ? 'Current Profile Image' : null}
                  </td>
                  <td>
                    <Button
                      onClick={() => {
                        onRemove(image?.Image?.id || '');
                      }}
                      color="danger"
                      size="sm"
                    >
                      <FontAwesome name="remove" />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
  return <></>;
};
