import { lighten } from '@material-ui/core/styles';

export const cardStyles = (theme) => ({
  pageContainer: {
    margin: 'auto',
    maxWidth: '1010px',
    padding: 0,
  },
  gridItem: {
    marginBottom: '15px',
  },
  card: {
    boxShadow: theme.shadows[0],
    opacity: 1,
    [theme.breakpoints.up('md')]: {
      // border: '1px solid #A5C9FF',
      boxShadow: 'inset 0px 0px 1px 1px #A5C9FF',
    },
  },
  paper: {
    backgroundColor: theme.palette.background.default,
    padding: '22px 20px 0px',
    boxShadow: 'none',
    [theme.breakpoints.up('sm')]: {
      padding: '35px',
    },
  },
  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '20px',
    [theme.breakpoints.up('sm')]: {
      padding: '35px',
    },
  },
  cardHeader: { marginBottom: '14px' },
  box: {
    margin: '15px 0px',
  },
  boxSpan: {
    display: 'flex',
    alignItems: 'center',
  },
  cardHead: {
    height: '5%',
    padding: '10px',
  },
  cardBody: {},
  cardFooter: {
    borderTop: `1px solid ${lighten(theme.palette.secondary.main, 0.5)}`,
    padding: '20px',
  },
  lowerCard: {
    display: 'flex',
  },
  keyValBox: {
    display: 'flex',
    marginBottom: '10px',
    alignItems: 'center',
  },
  growingH3Typogoraphy: {
    [theme.breakpoints.up('sm')]: {
      fontSize: '35px',
    },
  },
  map: {
    height: '50vh',
    width: '100%',
    marginBottom: '75px',
    '& .map-marker-wrapper': {
      position: 'absolute',
      width: '3em',
      height: '3em',
      top: '100%',
      left: '50%',
      transform: 'translate(-50%, -100%)',
      '& .map-marker': {
        fontSize: '3em',
      },
    },
  },
  mapHeader: {
    marginBottom: '20px',
  },
});
