import FontAwesome from 'react-fontawesome';
import styled from 'styled-components';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import { colors } from '../../styles/constants';
import Button from '@material-ui/core/Button';

export const HugeFontAwesome = styled(FontAwesome)`
  font-size: 90px;
  color: gray;
  margin-right: 16px;
  vertical-align: middle;
  margin-top: -16px;
`;

export const HugeText = styled.div`
  font-size: 42px;
  line-height: 64px;
  font-weight: bold;
  vertical-align: middle;
  margin-top: -16px;
  padding: 32px 32px 32px 32px;
`;

export const SimpleTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgb(228,228,228)',
        borderRadius: '7px',
      },
      '&:hover fieldset': {
        borderColor: 'rgb(228,228,228)',
        borderRadius: '7px',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgb(228,228,228)',
        borderRadius: '7px',
      },
    },
  },
})(TextField);

export const CheckBoxButton = withStyles({
  root: {
    '&.MuiButton-root.MuiButton-text': {
      padding: 0,
      minWidth: '16px',
      width: '16px',
      height: '16px',
    },
    width: '16px',
    height: '16px',
    minWidth: '16px',
  },
})(Button);
