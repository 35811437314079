import React from 'react';
import { Form, Label, Input, FormFeedback, FormText, Col } from 'reactstrap';
import { LocationDetailsFormGroup } from './LocationDetails';
import moment from 'moment-timezone';
import { useParams, useHistory } from 'react-router-dom';
import { checkLength, isRequired } from '../../../utilities/validators';
import locationStateFromHousingUnit from '../../../utilities/stateProcessing/locationStateFromHousingUnit.js';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import SelectOneField from '../../buttons/SelectOneField';
import {
  CreateHousingUnit,
  UpdateHousingUnitLocation,
  QueryHousingLocation,
  QueryRegions,
  RemoveRegionFromHousingUnit,
  AddRegionToHousingUnit,
} from './LocationDetailGQL';
import { FormNavBar } from '../../navbars/FormNavBar';
import UnitCompleteModal from '../../modals/UnitCompleteModal';
import FormButton from '../../buttons/FormButton';
import { LoadingSpinner, ErrorToast, ContextFeedback } from '../../Alerts';
import PropTypes from 'prop-types';
import { TextInputField } from '../../Inputs/TextInputField';

import axios from 'axios';
const { REACT_APP_BASE_LAT, REACT_APP_BASE_LNG, REACT_APP_TOMTOM_APIKEY } = process.env;

function LocationDetail({ add, isUnitActive, completedSections, toggle, modal }) {
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  const { houseUnitId } = useParams();
  const [formState, setFormState] = React.useState({
    location: '',
    name: '',
    region: '',
    originalRegion: '',
    unitType: '',
    unitNumber: '',
    nearSchool: null,
  });

  const validationFunctions = {
    region: (value) => isRequired(value) && checkLength(value),
    name: (value) => isRequired(value) && checkLength(value, 10),
    location: (value) => isRequired(value) && checkLength(value, 10),
    unitType: (value) => isRequired(value),
    unitNumber: () => true,
    nearSchool: () => true,
  };

  const [validationState, setValidationState] = React.useState({
    region: false,
    name: false,
    location: false,
    unitType: false,
  });
  const history = useHistory();
  const [regions, setRegions] = React.useState(new Map());

  // properties for HousingUnits not nodes
  const [unitTypes] = React.useState(
    new Map([
      ['Apartment', 'apartment'],
      ['House', 'house'],
      ['Condominium', 'condo'],
      ['Townhome', 'townhome'],
    ]),
  );

  // retrieve data
  // will cause error if in add, could use a lazyQuery
  const [queryHousingLocation, { data: dataH, error: errorH, loading: loadingH }] = useLazyQuery(QueryHousingLocation);
  const { data: dataR, error: errorR, loading: loadingR } = useQuery(QueryRegions);
  const [hasErrors, setHasErrors] = React.useState(false);

  // only trigger query on correct page
  React.useEffect(() => {
    if (houseUnitId && !add) {
      queryHousingLocation({ variables: { id: houseUnitId } });
    }
  }, [houseUnitId, add, queryHousingLocation]);

  // Got HousingUnit
  React.useEffect(() => {
    if (dataH?.HousingUnit?.length) {
      const { HousingUnit } = dataH;
      const housingUnit = HousingUnit[0];
      if (housingUnit) {
        const newFormState = locationStateFromHousingUnit(housingUnit);
        setFormState(newFormState);
        setValidationState({
          name: newFormState.name ? true : false,
          location: newFormState.location ? true : false,
          region: newFormState.region ? true : false,
          unitType: newFormState.unitType ? true : false,
        });
      }
    }
  }, [dataH, setFormState, setValidationState]);

  // Got regions
  React.useEffect(() => {
    if (dataR?.Region) {
      const { Region } = dataR;
      const regions = new Map();
      for (const region of Region) {
        if (region) {
          regions.set(region.name, region.id);
        }
      }
      setRegions(regions);
    }
  }, [dataR, setRegions]);

  // only keys to check for valid submit
  const checkValid = () => {
    const ids = ['name', 'region', 'location', 'unitType'];
    for (const id of ids) {
      // the ids only have string values
      if (!validationFunctions[id](formState[id])) {
        return false;
      }
    }
    return true;
  };

  const onChangeNonValidated = (id, value) => {
    setHasErrors(false);
    setIsFormSubmitted(false);
    setFormState((formState) => ({ ...formState, [id]: value }));
  };

  const onChange = (id, value) => {
    if (!id) {
      return;
    }
    setHasErrors(false);
    setIsFormSubmitted(false);
    setFormState((formState) => ({ ...formState, [id]: value }));
    setValidationState((validationState) => ({
      ...validationState,
      [id]: validationFunctions[id](value),
    }));
  };

  const onChangeInput = (e) => {
    setHasErrors(false);
    setIsFormSubmitted(false);
    if (e?.currentTarget) {
      const { id, value } = e.currentTarget;
      onChange(id, value);
    }
  };

  const onChangeInputNonValidated = (e) => {
    if (e?.currentTarget) {
      const { id, value } = e.currentTarget;
      onChangeNonValidated(id, value);
    }
  };

  const [createHousingUnit] = useMutation(CreateHousingUnit);
  const [updateHousingUnit] = useMutation(UpdateHousingUnitLocation);
  const [addRegionToHousingUnit] = useMutation(AddRegionToHousingUnit);
  const [removeRegionFromHousingUnit] = useMutation(RemoveRegionFromHousingUnit);

  const onFormSubmit = async (isSaveAndClose = false, isFixLater = false) => {
    const rooms = completedSections?.rooms;
    const acceptances = completedSections?.acceptances;
    const accessibility = completedSections?.accessibility;
    const otherSectionsComplete = rooms && acceptances && accessibility;
    const isValid = checkValid();
    setIsFormSubmitted(true);
    setHasErrors(!isValid);
    if (add && isValid) {
      // create
      try {
        const { data: createHousingData } = await createHousingUnit({
          variables: {
            name: formState.name,
            location: formState.location,
            region: formState.region,
            unitNumber: formState.unitNumber,
            unitType: formState.unitType,
            nearSchool: formState.nearSchool,
          },
        });

        if (createHousingData.CreateHousingUnit) {
          history.push(`/housing/edit/${createHousingData.CreateHousingUnit.id}/description`);
        }
      } catch (err) {
        setHasErrors(true);
        // error
      }
    } else if ((isValid && houseUnitId) || isSaveAndClose || isFixLater) {
      // update
      try {
        const date = new Date();
        const dateYear = date.getFullYear();
        const dateMonth = date.getMonth() + 1;
        const dateDay = date.getDate();
        const dateHour = date.getHours();
        const dateMinute = date.getMinutes();
        const dateSecond = date.getSeconds();
        const dateTimezone = moment.tz.guess();
        const variables = {
          id: houseUnitId,
          name: formState.name,
          location: formState.location,
          unitType: formState.unitType,
          unitNumber: formState.unitNumber,
          nearSchool: formState.nearSchool,
          updatedAt: {
            year: dateYear,
            month: dateMonth,
            day: dateDay,
            hour: dateHour,
            minute: dateMinute,
            second: dateSecond,
            timezone: dateTimezone,
          },
        };
        if (isValid) {
          await updateHousingUnit({ variables });
        }
        if (formState.originalRegion !== formState.region) {
          // update region
          // What mutation? Merge??
          await removeRegionFromHousingUnit({
            variables: {
              from: { id: houseUnitId },
              to: { id: formState.originalRegion },
            },
          });
        }
        if (formState.region && formState.originalRegion !== formState.region) {
          await addRegionToHousingUnit({
            variables: {
              from: { id: houseUnitId },
              to: { id: formState.region },
            },
          });
        }
        if (otherSectionsComplete && !isUnitActive && isSaveAndClose) {
          toggle && toggle();
        } else if ((!isSaveAndClose && !hasErrors) || (isFixLater && hasErrors)) {
          history.push(`/housing/edit/${houseUnitId}/description`);
        } else if (isSaveAndClose) {
          history.push('/housing/');
        }
      } catch (err) {
        setHasErrors(true);
      }
    }
  };

  if ((loadingR || loadingH) && !add) {
    return <LoadingSpinner />;
  }

  if ((errorR || errorH) && !add) {
    return <ErrorToast />;
  }

  if (!dataH && !add) {
    return <ContextFeedback />;
  }

  return (
    <div>
      <FormNavBar onClick={onFormSubmit} />
      <Col md={{ size: 8, offset: 2 }}>
        <h2 className="form-title center-block text-center">{add ? 'Add New Housing Unit' : 'Edit Housing Unit'}</h2>
        <Form>
          <LocationDetailsFormGroup>
            <TextInputField
              id="name"
              label="Unit Name*"
              onChange={onChangeInput}
              value={formState.name}
              onBlur={onChangeInput}
              onBlurCapture={onChangeInput}
              valid={isFormSubmitted ? validationState.name : true}
              helperText="Required Field. Please add a unique unit name of at least ten characters."
              autoComplete={false}
              autoCompleteFn={null}
            />
            <FormText>Apartment complex name or cross streets</FormText>
          </LocationDetailsFormGroup>
          <LocationDetailsFormGroup>
            <TextInputField
              id="location"
              onChange={onChangeInput}
              value={formState.location}
              valid={isFormSubmitted ? validationState.location : true}
              onBlur={onChangeInput}
              label="Street Address*"
              helperText="Required Field. Please enter valid street address of at least ten characters."
              autoComplete={true}
              autoCompleteFn={async (setOptions, value) => {
                // a composable function, you can pass anything that retuns a list of options to setOptions
                const response = await axios.get(
                  `https://api.tomtom.com/search/2/search/${encodeURIComponent(
                    value,
                  )}.json?typeahead=true&lat=${REACT_APP_BASE_LAT}&lon=${REACT_APP_BASE_LNG}&key=${REACT_APP_TOMTOM_APIKEY}`,
                );
                const options = response.data?.results?.map((result) => result?.address?.freeformAddress);
                const found = options.some((option) => option === value);
                if (!found) {
                  options.push(value);
                }
                setOptions(options);
              }}
            />
          </LocationDetailsFormGroup>
          <LocationDetailsFormGroup id="unit-number">
            <Label for="unitNumber">Unit Number or Letter(optional)</Label>
            <Input
              id="unitNumber"
              bsSize="lg"
              onChange={onChangeInputNonValidated}
              value={formState.unitNumber || ''}
            />
          </LocationDetailsFormGroup>
          <LocationDetailsFormGroup id="location-form-region">
            <h4 className="center-block text-center">Region* </h4>
            {isFormSubmitted && !validationState.region ? (
              <div
                className={isFormSubmitted && !validationState.region ? 'invalid-feedback' : undefined}
                style={{ display: 'block', textAlign: 'center' }}
              >
                Required Field. Please make a selection.
              </div>
            ) : null}
            <SelectOneField id="region" onChange={onChange} value={formState.region} selections={regions} />
          </LocationDetailsFormGroup>
          <LocationDetailsFormGroup id="location-form-unit-type">
            <h4 className="center-block text-center">Unit Type* </h4>
            {isFormSubmitted && !validationState.unitType ? (
              <div
                className={isFormSubmitted && !validationState.unitType ? 'invalid-feedback' : undefined}
                style={{ display: 'block', textAlign: 'center' }}
              >
                Required Field. Please make a selection.
              </div>
            ) : null}
            <SelectOneField id="unitType" onChange={onChange} value={formState.unitType} selections={unitTypes} />
          </LocationDetailsFormGroup>
        </Form>
      </Col>
      <div className="form-title center-block text-center">
        <FormButton add={add} error={hasErrors} onClick={onFormSubmit} id="form-button-submit"></FormButton>
        {add ? <p>Please fill out Housing Details</p> : null}
      </div>

      <UnitCompleteModal toggle={toggle} modal={modal} />
    </div>
  );
}

LocationDetail.propTypes = {
  isUnitActive: PropTypes.bool,
  completedSections: PropTypes.shape({
    location: PropTypes.bool,
    description: PropTypes.bool,
    rooms: PropTypes.bool,
    acceptances: PropTypes.bool,
    accessibility: PropTypes.bool,
    viewing: PropTypes.bool,
  }).isRequired,
  toggle: PropTypes.func,
  modal: PropTypes.bool,
  add: PropTypes.bool,
};

export default LocationDetail;
