import React, { useState } from 'react';
import { AllParsData, ParToParIds } from '../apollo/cache';
import { MatchingContainer } from './MatchPARtoUnitStyles';
import { MatchFontAwesome, MatchLink } from './MatchPARtoUnitStyles';
import { ParToParComparisonTable } from '../components/ParToParComparisonTable/ParToParComparisonTable';
import { MediumText, XLargeText } from '../styles/text';
import {
  ResponseDetailLayoutObj,
  SampleParDataArr,
} from '../components/ParToParComparisonTable/CategoryToResponseMapObj';
import { Row, Col, Container } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { links } from '../styles/constants';
import { AssignBtn } from '../components/buttons/ButtonStyles';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';
import AssignParsModal from '../components/modals/AssignParsModal';
import { LoadingSpinner, ErrorToast } from '../components/Alerts';

const GetHouseUnitName = gql`
  query GetHouseUnitName($id: ID) {
    HousingUnit(id: $id) {
      id
      name
      location
      SHOWS_rel(orderBy: order_asc) {
        Image {
          url
          publicId
        }
      }
      assignedParticipants {
        id
        name
        age
        gender {
          name
        }
      }
    }
  }
`;

export default function ParToParDetailView() {
  const { houseUnitId } = useParams();
  const [modal, setModal] = useState(false);
  const { data, loading, error } = useQuery(GetHouseUnitName, {
    variables: { id: houseUnitId },
  });
  const housingUnit = data?.HousingUnit[0];
  /* Flag for using sample par data (from file) vs live from cache */
  const useCacheParData = true;
  const ParsData = useCacheParData ? AllParsData().filter((par) => ParToParIds().has(par.id)) : SampleParDataArr;
  const toggle = () => setModal(!modal);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <ErrorToast />;
  }
  return (
    <div>
      <Container>
        <MatchingContainer className="w-100 h-100">
          <Row>
            <Col md="6">
              <MatchLink to={`/housing/partopar/${houseUnitId}`}>
                <MatchFontAwesome name="chevron-left" style={{ color: links.regularLink }} /> &nbsp; Back to matching
                selection
              </MatchLink>
            </Col>
            <Col md="6">
              <AssignBtn disabled={!ParsData?.length} onClick={toggle}>
                Assign Participants to Unit
              </AssignBtn>
            </Col>
          </Row>
          <Row>
            <Col md="12" className="title">
              <XLargeText className="text-center">Roommate Match Comparison</XLargeText>
              <MediumText className="text-center">
                Review Participants&apos; responses to the roommate questionnaire
              </MediumText>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <ParToParComparisonTable
                categoriesObject={ResponseDetailLayoutObj}
                parsObject={ParsData}
                numRooms={ParsData.length}
              />
            </Col>
          </Row>
        </MatchingContainer>
      </Container>
      <AssignParsModal parsData={ParsData} modal={modal} housingUnit={housingUnit} toggle={toggle} />
    </div>
  );
}
