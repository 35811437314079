import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/main.scss';
import './assets/fonts/latofonts.scss';
import TagManager from 'react-gtm-module';
import AppWithClient from './apollo/client';

const { REACT_APP_GTM_ID, REACT_APP_GTM_AUTH, REACT_APP_GTM_PREVIEW } = process.env;

const tagManagerArgs = {
  gtmId: REACT_APP_GTM_ID || '',
  auth: REACT_APP_GTM_AUTH || '',
  preview: REACT_APP_GTM_PREVIEW || '',
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
    redirectUri={window.location.origin}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE || ''}
    cacheLocation="localstorage"
  >
    <AppWithClient />
  </Auth0Provider>,
  document.getElementById('root'),
);
