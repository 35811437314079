import { Modal as MuiModal, Backdrop as MuiBackdrop, ButtonGroup } from '@material-ui/core';
import styled, { createGlobalStyle } from 'styled-components';
import { typography, borders } from '../../styles/constants';
import { Modal, ModalBody, Row, Col, Button } from 'reactstrap';
import { ClearButton, PrimaryButton } from '../buttons/ButtonStyles';

export const GlobalStyle = createGlobalStyle`
  .cls-btn {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const MatchModal = styled(Modal)`
  color: ${typography.fontColor.primary};
  fontFamily: ${typography.fontFamily.regular}
  font-weight: ${typography.fontWeight.regular}
`;

export const MatchModalHeaderRow = styled(Row)`
  border: none;
  text-align: center;
  font-size: ${typography.fontSize.smtitle};
  font-weight: ${typography.fontWeight.title};
`;

export const MatchModalBody = styled(ModalBody)`
  font-size: ${typography.fontSize.mdtext};
`;

export const MatchModalBodyColTitle = styled(Col)`
  font-size: ${typography.fontSize.title};
  font-weight: ${typography.fontWeight.title};
  margin: 0px 0px 8px 0px;
  padding: 0px;
`;

export const MatchSpanTitle = styled.span`
  font-size: ${typography.fontSize.title};
  font-weight: ${typography.fontWeight.title};
  margin-right: 10px;
`;
export const MatchModalBodyColValue = styled(Col)`
  border: ${borders.border};
  border-radius: ${borders.borderRadius};
  margin: 0px;
  padding: 16px;
`;

export const MatchModalButton = styled(Button)`
  font-family: ${typography.fontFamily.close};
  background: clear;
  outline: none;
  color: ${typography.fontColor.link};
  font-size: ${typography.fontSize.title};
`;

/* MODAL TEMPLATES */

export const MuiModalTemplate = styled(MuiModal)`
  display: flex;
  justify-content: ${(props) => props.justifyContent || 'center'};
  align-items: ${(props) => props.alignItems || 'center'};
  background-color: ${(props) => props.backgroundColor || 'transparent'};
`;

export const MuiBackdropTemplate = styled(MuiBackdrop)`
  &.MuiBackdrop-root {
    background-color: #373a3c;
    opacity: 0.7 !important;
  }
`;

export const MuiModalHeader = styled.h2``;
export const MuiModalCloseButtonTemplate = styled.button`
  position: absolute;
  top: -15px;
  right: -15px;
  background-color: transparent;
  font-size: 21px;
  color: #939393;
  border: none;
  outline: none;
  &:focus {
    outline: none;
  }
`;

export const ModalButtonGroup = styled(ButtonGroup)`
  justify-content: center;
  &:focus {
    outline: none;
  }
`;
export const ModalButtonClear = styled(ClearButton)`
  margin-right: 15px;
  &&.MuiButtonGroup-groupedTextHorizontal {
    ${(props) => props.$border && `border: ${borders.buttonBorder};`}
    border-radius: ${borders.borderRadius}
  }
`;

export const ModalButtonFilled = styled(PrimaryButton)`
  &&.MuiButtonGroup-groupedTextHorizontal {
    border-radius: ${borders.borderRadius};
  }
`;
/* MODAL TEMPLATES */

export const ModalCloseButton = styled(MuiModalCloseButtonTemplate)``;
