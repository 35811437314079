import moment from 'moment-timezone';

export const convertToNeo4jDateTime = (moveInDate) => {
  return {
    year: moveInDate?.year(),
    month: moveInDate?.month() + 1,
    day: moveInDate?.date(),
    timezone: moment.tz.guess(),
  };
};

export const getMomentTimezoneObj = (timeData) => {
  if (timeData?.formatted !== null) {
    const { formatted } = timeData;
    const [date, timezone] = formatted?.split('[');
    if (timezone) {
      return moment(date, moment.ISO_8601, false).tz(timezone.split(']')?.[0]);
    } else {
      return moment(date, moment.ISO_8601, false);
    }
  }
};

export const createNeo4jDateTime = () => {
  const date = new Date();
  const dateYear = date.getFullYear();
  const dateMonth = date.getMonth() + 1;
  const dateDay = date.getDate();
  const dateHour = date.getHours();
  const dateMinute = date.getMinutes();
  const dateSecond = date.getSeconds();
  const dateTimezone = moment.tz.guess();
  return {
    year: dateYear,
    month: dateMonth,
    day: dateDay,
    hour: dateHour,
    minute: dateMinute,
    second: dateSecond,
    timezone: dateTimezone,
  };
};

export const createComparableDate = (updatedAt, createdAt) => {
  const date = updatedAt?.formatted ? updatedAt : createdAt;
  const dateStr = date?.formatted?.split('[')?.[0];
  return new Date(dateStr);
};

export const getDaysToDate = (momentDeadline) => {
  return momentDeadline?.endOf('day')?.diff(moment()?.endOf('day'), 'days');
};

export const createHoldDeadlineDisplay = (deadline, huStatus, isUnitFilled = false) => {
  if (huStatus === 'Archived' || isUnitFilled) {
    return 'N/A';
  }
  if (deadline?.formatted !== null) {
    const momentDeadline = getMomentTimezoneObj(deadline);
    const numDaysTilDeadline = getDaysToDate(momentDeadline);
    if (numDaysTilDeadline >= 7) {
      return momentDeadline.format('MM/DD');
    } else if (numDaysTilDeadline <= 6 && numDaysTilDeadline >= 1) {
      return `${numDaysTilDeadline} day${numDaysTilDeadline > 1 ? 's' : ''} left`;
    } else if (numDaysTilDeadline === 0) {
      return 'Today';
    } else if (numDaysTilDeadline < 0) {
      return 'Overdue';
    }
  }
};
