export default function Pluralizer(word) {
  if (typeof word !== 'string' || /[^iouy](s|[.])$/.test(word)) {
    // most likely already plural or not a string
    return word;
  } else if (/ss$|s$|sh$|ch$|x$|z$|es$/.test(word)) {
    return word + 'es';
  } else if (/[^aeiou]y$/.test(word)) {
    return word.slice(0, -1) + 'ies';
  }
  return word + 's';
}
