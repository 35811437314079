import styled from 'styled-components';
import { Col, Row } from 'reactstrap';
import { typography, borders } from '../../styles/constants';
import { TextField } from '@material-ui/core';

export const HorizontalHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;
export const VerticalHeader = styled(Col)`
  font-size: ${typography.fontSize.smtext};
  font-weight: bold;
  align-items: center;
  line-height: ${typography.fontSize.mdtext};
`;
export const AvatarSpan = styled(Row)`
  margin-bottom: 12px;
  margin-left: 0;
`;
export const SecondaryAvatarDiv = styled.div`
  margin-right: 15px;
  margin-left: 0px;
`;

export const ViewParDetailsBtn = styled.button`
  outline: none !important;
  border: none;
  background: none;
  padding: 0;
  color: ${typography.fontColor.btnColor};
`;

export const ListGroupRow = styled(Row)`
  color: ${typography.fontColor.title};
  background: white;
  border: ${borders.border};
  box-shadow: ${borders.boxShadow};
  border-radius: ${borders.borderRadius};
  margin-bottom: 24px;
  &.dual-panels-top {
    border-radius: 7px 7px 0 0;
    margin-bottom: 0;
    border-bottom: none;
  }
  &.dual-panels-bottom {
    border-radius: 0 0 7px 7px;
    background-color: white;
  }
`;

export const EmailCol = styled(Col)`
  font-size: ${typography.fontSize.smtext};
  color: ${typography.fontColor.text};

  & .emailCol-header {
    color: ${typography.fontColor.grayText};
  }

  & .email-grid-container {
    display: grid;
    grid-template-columns: 0.4fr 1fr;
  }

  & .emailCol-label {
    font-weight: bold;
    align-self: center;
  }

  & .emailCol-email {
    font-size: ${typography.fontSize.lgtext};
    font-weight: bold;
  }

  & .emailCol-email button {
    background: transparent;
    border: none;
    color: ${typography.fontColor.btnColor};
    font-weight: bold;
  }

  & .email-display-container {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const EmailTextFieldContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  min-width: 400px;
  margin: 0 auto;
`;

export const EmailTextFieldLabel = styled.label`
  font-weight: bold;
  text-align: left;
  margin-top: 30px;
`;

export const EmailTextField = styled(TextField)`
  &.email-error input[id='parEmailInput'] {
    border: ${borders.errorBorder} !important;
  }
`;

export const EmailTextFieldError = styled.div`
  text-align: left;
  font-size: 14px;
`;
