/**
 * @typedef FilterType
 * @property {boolean} open
 * @property {Array} selected
 * @property {number} limit
 * @property {string} display
 * @property {boolean} controlled
 * @property {string?} placeholder
 */

/**
 * @typedef {FilterType} RangeType
 * @property {[[number,string,[number]]]} options
 */

/**
 * @typedef {FilterType} NumericType
 * @property {number} savedValue
 * @property {[[number, string, number]]} options
 */

/**
 * @typedef FilterState
 * @property {RangeType} age
 * @property {NumericType} income
 */

/**
 * @param {*} listToFilter
 * @param {FilterState} filtersState
 */

export default function applyFilters(listToFilter, filtersState) {
  let filteringList = [...listToFilter];
  for (let [filterKey, filterValue] of Object.entries(filtersState)) {
    if (filterValue.filterType === 'range' && filterValue.selected.length) {
      const ranges = filterValue.options.filter((f) => filterValue.selected.includes(f[0])).map((option) => option[2]);
      filteringList = filteringList.filter((par) => {
        if (par?.[filterKey])
          for (let range of ranges) {
            if (par[filterKey] >= range[0] && par[filterKey] <= range[1]) {
              return true;
            }
          }
        return false;
      });
    } else if (filterValue.filterType === 'numeric' && filterValue.savedValue != null) {
      // right now our only numeric filter is a min filter... all numeric filters will have a savedValue even if it's 0
      let filterKeys = [];
      if (filterKey.includes('|')) {
        filterKeys = filterKey.split('|');
      } else {
        filterKeys = [filterKey];
      }
      filteringList = filteringList.filter((par) =>
        filterKeys.some((fKey) => par[fKey] != null && par[fKey] >= filterValue.savedValue),
      );
    } else if (filterValue.filterType === 'HabitLevel' && filterValue.selected.length) {
      const optionsToTest = filterValue.options.filter((option) => filterValue?.selected?.includes(option[0]));
      const valuesToTest = optionsToTest?.map((option) => option?.[2]);
      let filterKeys = filterKey.split('|');
      if (valuesToTest.length) {
        filteringList = filteringList.filter((par) => {
          const noHabit = !filterKeys.some((key) => par[filterKey]);
          if (valuesToTest?.includes('NotRequired') && noHabit) {
            return true;
          } else {
            return valuesToTest.some((valueToTest) => filterKeys.some((fKey) => valueToTest.includes(par?.[fKey])));
          }
        });
      }
    } else if (filterValue.filterType === 'id' && filterValue.selected.length) {
      const filterIds = filterValue.options
        .filter((option) => filterValue.selected.includes(option[0]))
        .map((option) => option[2]);
      filteringList = filteringList.filter((par) => {
        if (!par?.[filterKey]) {
          return false;
        }
        return filterIds.includes(par[filterKey].id);
      });
    } else if (filterValue.filterType === 'name' && filterValue.selected.length) {
      const filterNames = filterValue.options
        .filter((option) => filterValue.selected.includes(option[0]))
        .map((option) => option[2]);
      filteringList = filteringList.filter((par) => {
        if (!par?.[filterKey]) {
          return false;
        }
        return par[filterKey].some((pr) => filterNames.includes(pr.name));
      });
    } else if (filterValue.filterType === 'pets' && filterValue.selected.length) {
      const filterNames = filterValue.options
        .filter((option) => filterValue.selected.includes(option[0]))
        .reduce((acc, cur) => {
          const { species, size } = cur[2];
          if (!acc[species]) {
            acc[species] = [size];
          } else {
            acc[species] = [...acc[species], size];
          }
          return acc;
        }, {});
      filteringList = filteringList.filter(({ pets }) => {
        for (let { species, size } of pets) {
          if (filterNames[species] && filterNames[species].includes(size)) {
            return true;
          }
        }
        return false;
      });
    } else if (filterValue.filterType === 'mobility' && filterValue.selected.length) {
      const filterDegrees = filterValue.options
        .filter((option) => filterValue.selected.includes(option[0]))
        .map((option) => option[2]);

      filteringList = filteringList.filter(({ accessibilitiesWithDegree }) => {
        const degree = accessibilitiesWithDegree[0]?.degree || 0;
        return filterDegrees.includes(degree);
      });
    }
  }
  return filteringList;
}
