import React, { useCallback, useEffect } from 'react';
import { PossibleMatchIds, ParToParIds, AllParsData, AllParsIds, AssignedParIds } from '../apollo/cache';
import { MatchingContainer } from './MatchPARtoUnitStyles';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { ParticipantsInterestedInUnit, ParticipantPossibleMatches, queryPARs } from './ParToParGQL';
import { useParams } from 'react-router-dom';
import { LeftListPanel, TitleCol } from './ParToParStyles';
import { MatchFontAwesome, MatchLink } from './MatchPARtoUnitStyles';
import { Row, Col } from 'reactstrap';
import { links, typography } from '../styles/constants';
import { LargeText, MediumText, SmallText } from '../styles/text';
import ComparisonPanel from '../components/panels/ComparisonPanel';
import ParToParCardComponent from '../components/ParToParCard';
import { minArray, sumArray } from '../utilities/arrayOperations';
import { AllParticipants } from './ParticipantDashboardGQL';
import { LoadingSpinner, ErrorToast } from '../components/Alerts';

export default function ParToParSelectionView() {
  const { houseUnitId } = useParams();
  const { data: iData, loading: iLoading, error: iError } = useQuery(ParticipantsInterestedInUnit, {
    variables: { houseUnitId },
    fetchPolicy: 'no-cache',
  });

  const [numBedrooms, setNumBedrooms] = React.useState(0);
  const [bedrooms, setBedrooms] = React.useState([]);
  const [housingUnitName, setHousingUnitName] = React.useState('');
  const [housingUnitLocation, setHousingUnitLocation] = React.useState('');
  // we maintain 2 lists so we don't constantly have to reload the initial state when switching an initial Par
  const [excluded, setExcluded] = React.useState(new Set());
  const [getPossibleMatches, { data: mData, loading: mLoading, error: mError }] = useLazyQuery(
    ParticipantPossibleMatches,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
    },
  );

  useEffect(() => {
    if (iData) {
      const housingUnit = iData.HousingUnit?.[0];
      let assigned = [];
      if (housingUnit?.interestedParticipants?.length) {
        const ids = [...new Set(housingUnit.interestedParticipants?.map((p) => p.id) || [])];
        assigned = [...new Set(housingUnit.assignedParticipants?.map((p) => p.id) || [])];
        AllParsIds(ids);
        const interestedParsIds = [];
        const interestedParticipantsDeduped = [];
        housingUnit.interestedParticipants.forEach((iPar) => {
          if (!interestedParsIds.includes(iPar.id)) {
            interestedParticipantsDeduped.push(iPar);
            interestedParsIds.push(iPar.id);
          }
        });
        AllParsData(interestedParticipantsDeduped);
        PossibleMatchIds(new Set(ids));
        AssignedParIds(new Set(assigned));
        // If we had ParToParIds it was because we clicked the back arrow from the comparison screen
        ParToParIds(new Set([...assigned, ...ParToParIds()]));
      }
      if (housingUnit?.bedrooms?.length) {
        setNumBedrooms(housingUnit.bedrooms.length);
        setBedrooms(housingUnit.bedrooms.map((b) => b.rent));
      }
      if (housingUnit?.name) {
        setHousingUnitName(housingUnit.name);
      }
      if (housingUnit?.location) {
        setHousingUnitLocation(housingUnit.location);
      }
      if (assigned.length && housingUnit) {
        getPossibleMatches({
          variables: { par: assigned, hu: housingUnit.id },
        });
      }
    }
  }, [
    iData,
    AllParsData,
    AllParsIds,
    AssignedParIds,
    ParToParIds,
    PossibleMatchIds,
    setNumBedrooms,
    setBedrooms,
    setHousingUnitLocation,
    setHousingUnitName,
  ]);

  useEffect(() => {
    if (mData && ParToParIds().size) {
      if (mData.PossibleMatches?.length && mData.PossibleMatches[0]?.participants?.length) {
        const parIds = mData.PossibleMatches?.reduce((acc, pm) => {
          return [...acc, ...pm?.participants?.map((par) => par?.id)]; // flatten the participants into a single array
        }, []).concat(Array.from(AssignedParIds())); // combine with preselected
        let possibleIds = new Set(parIds);
        PossibleMatchIds(possibleIds);
        const excluded = new Set(AllParsIds().filter((o) => !possibleIds.has(o) && !ParToParIds().has(o)));
        setExcluded(excluded);
      }
    }
  }, [mData, setExcluded, AllParsIds, ParToParIds, PossibleMatchIds, AssignedParIds]);

  /**
   * Initially all participants
   */
  const selectParticipant = useCallback(
    (participantId) => {
      if (ParToParIds().has(participantId) || ParToParIds().size === numBedrooms) {
        return;
      }
      ParToParIds(ParToParIds().add(participantId));
      getPossibleMatches({
        variables: { par: Array.from(ParToParIds()), hu: houseUnitId },
      });
      // Sets are odd, add returns a new Set, but delete returns a boolean
    },
    [getPossibleMatches, numBedrooms, ParToParIds],
  );

  const deselectParticipant = useCallback(
    (participant) => {
      const parIdSet = ParToParIds();
      parIdSet.delete(participant.id);
      ParToParIds(parIdSet);
      if (parIdSet.size) {
        getPossibleMatches({
          variables: { par: Array.from(ParToParIds()), hu: houseUnitId },
        });
      } else {
        // no more selected participants, get list from original
        setExcluded(new Set());
      }
    },
    [getPossibleMatches, ParToParIds, houseUnitId],
  );

  // We don't use this, but maybe someday we'll  need a reset button rather than having to make someone click the x on every par
  const resetParticipants = () => {
    ParToParIds(new Set());
    PossibleMatchIds(new Set());
    setExcluded([]);
  };

  if (iLoading) {
    return <LoadingSpinner />;
  }
  if (iError || mError) {
    return <ErrorToast />;
  }

  return (
    <div className="housing-detail h-100">
      <MatchingContainer className="w-100 h-100">
        <Row>
          <Col md="12">
            <MatchLink
              to={`/housing/detail/${houseUnitId}`}
              onClick={() => {
                // we need to clear also on select of any of the menu buttons on the nav
                ParToParIds(new Set());
                AllParsData([]);
                AllParsIds([]);
                PossibleMatchIds(new Set());
              }}
            >
              <MatchFontAwesome name="chevron-left" style={{ color: links.regularLink }} /> &nbsp; Back to unit
            </MatchLink>
          </Col>
        </Row>
        <Row>
          <TitleCol md="6">
            <LargeText bold>Participants Interested In Unit</LargeText>
          </TitleCol>
          <Col md="6" className="text-right self-align-middle" style={{ paddingRight: '55px' }}>
            <MediumText bold lineHeight={typography.fontSize.xsmtitle}>
              {housingUnitName}
            </MediumText>
            <SmallText bold lineHeight={typography.fontSize.mdtext}>
              {housingUnitLocation}
            </SmallText>
            <SmallText>
              Total Rent ${sumArray(bedrooms)} / Lowest bedroom cost: ${minArray(bedrooms)}
            </SmallText>
          </Col>
        </Row>
        <Row style={{ marginBottom: '10px' }}>
          <Col>
            <MediumText>{PossibleMatchIds().size || 0} participants</MediumText>
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 5 }} style={{ paddingRight: 0, margin: 0 }}>
            <LeftListPanel className="w-100">
              {AllParsData().map((par) => {
                const exclude = excluded.has(par.id);
                const selected = ParToParIds().has(par.id);
                const assigned = AssignedParIds().has(par.id);
                return (
                  <ParToParCardComponent
                    key={`${par.id}-${exclude}-${selected}`}
                    participant={par}
                    excluded={exclude}
                    selected={selected}
                    select={selectParticipant}
                    deselect={deselectParticipant}
                    isAssigned={assigned}
                    size="large"
                  />
                );
              })}
            </LeftListPanel>
          </Col>
          <Col md={{ size: 7 }} className="h-100 w-100">
            <ComparisonPanel
              numBedrooms={numBedrooms}
              deselect={deselectParticipant}
              key={Array.from(ParToParIds()).join('-')}
            />
          </Col>
        </Row>
      </MatchingContainer>
    </div>
  );
}
