import { gql } from '@apollo/client';

export const QueryHousingLocation = gql`
  query QueryHousingLocation($id: ID!) {
    HousingUnit(id: $id) {
      location
      nearSchool
      name
      region {
        id
      }
      LISTS_rel {
        Listing {
          id
          listingUrl
        }
        updatedAt {
          formatted
        }
      }
      SHOWS_rel(orderBy: order_asc) {
        Image {
          id
          url
          publicId
        }
      }
      unitType
      unitNumber
    }
  }
`;
export const QueryRegions = gql`
  query QueryRegions {
    Region(orderBy: name_asc) {
      id
      name
    }
  }
`;
export const AddRegionToHousingUnit = gql`
  mutation AddRegionToHousingUnit($from: _HousingUnitInput!, $to: _RegionInput!) {
    AddRegionHousingUnits(from: $from, to: $to) {
      from {
        id
      }
      to {
        id
      }
    }
  }
`;

export const RemoveRegionFromHousingUnit = gql`
  mutation RemoveRegionFromHousingUnit($from: _HousingUnitInput!, $to: _RegionInput!) {
    RemoveRegionHousingUnits(from: $from, to: $to) {
      from {
        id
      }
      to {
        id
      }
    }
  }
`;

export const UpdateHousingUnitLocation = gql`
  mutation UpdateHousingUnitLocation(
    $id: ID!
    $location: String
    $name: String
    $unitType: String
    $unitNumber: String
    $nearSchool: Boolean
    $updatedAt: _Neo4jDateTimeInput
  ) {
    UpdateHousingUnit(
      id: $id
      location: $location
      name: $name
      unitNumber: $unitNumber
      unitType: $unitType
      nearSchool: $nearSchool
      updatedAt: $updatedAt
    ) {
      id
    }
  }
`;

export const CreateListing = gql`
  mutation CreateListing($listingUrl: String!, $id: ID!) {
    CreateListing(listingUrl: $listingUrl, housingUnit: $id) {
      id
    }
  }
`;

export const MergeListing = gql`
  mutation MergeListing($listingUrl: String!, $id: ID!) {
    MergeListing(listingUrl: $listingUrl, housingUnit: $id) {
      id
    }
  }
`;

export const CreateHousingUnit = gql`
  mutation CreateHousingUnit($name: String!, $location: String!, $region: ID!, $unitType: String, $unitNumber: String) {
    CreateHousingUnit(name: $name, location: $location, region: $region, unitType: $unitType, unitNumber: $unitNumber) {
      id
    }
  }
`;
