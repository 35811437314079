import React from 'react';
import PropTypes from 'prop-types';
import { SelectOneButton, SelectOneRow } from './SelectOneStyles';

function SelectOneField({ selections, id, onChange, value, $btnWidth, $margin, $btnMargin, $fontSize = '' }) {
  const [active, setActive] = React.useState(value);
  React.useEffect(() => {
    if (value != null) {
      setActive(value);
    }
  }, [value]);
  const onClick = (selection) => {
    onChange(id, selection);
    setActive(selection);
  };

  return (
    <SelectOneRow className={id !== 'par-reason' ? 'justify-content-center' : ''} id={id} $margin={$margin}>
      {Array.from(selections).map((selection) => {
        return (
          <SelectOneButton
            key={selection[0]}
            onClick={() => onClick(selection[1])}
            className={`btn-select-one ${active === selection[1] ? 'active' : ''} `}
            outline
            color="secondary"
            size="lg"
            $width={$btnWidth}
            $margin={$btnMargin}
            $fontSize={selection[0]?.length > 20 ? 'small' : $fontSize}
            $id={id}
          >
            {selection[0]}
          </SelectOneButton>
        );
      })}
    </SelectOneRow>
  );
}
SelectOneField.propTypes = {
  selections: PropTypes.instanceOf(Map),
  id: PropTypes.string,
  btnWidth: PropTypes.string,
  margin: PropTypes.string,
  btnMargin: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  onChange: PropTypes.func,
};

export default SelectOneField;
