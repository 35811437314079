import styled, { createGlobalStyle } from 'styled-components';
import { typography } from '../../styles/constants';
import { Modal, ModalHeader, ModalBody, ModalFooter, Container, Col, Row, Button } from 'reactstrap';

export const AssignmentModalRow = styled(Row)`
  margin-bottom: 20px;
`;

export const GlobalStyle = createGlobalStyle`
    .customDrop {
        background-color: #373a3c;
    }

    .modal-backdrop.show {
        opacity: .7;
    }

    .modal-content {
        padding: ${(props) => (props.padding ? props.padding : '10px')};
        width: ${(props) => props.width};
        align-items: ${(props) => (props.width == '665px' ? 'center' : '')};
    }

    .modal-dialog {
        display: ${(props) => (props.width == '665px' ? 'flex' : '')};
        flex-direction: column;
        align-items: center;
        max-width: ${(props) => (props.width == '665px' ? '665px' : '500px')};
    }

    button {
        outline: none;
    }

    ${AssignmentModalRow} {
        margin-bottom: ${(props) => (props.width == '665px' ? 0 : '20px')}   
    }

    .parReasonsModal.modal-dialog {
      max-width: 40% !important;
      margin: 3% auto;
    }

    #par-reason button {
      max-width: 85% !important;
      text-align: left;
      font-size: 15px;
    }

`;
export const AssignmentModalHeader = styled(ModalHeader)`
  border: none;
  margin: ${(props) => (props.margin ? props.margin : '0 auto')}
  padding: ${(props) => (props.padding ? props.padding : 'inherit')}
  color: ${typography.fontColor.title};
`;

export const AssignmentModalBody = styled(ModalBody)`
  color: ${typography.fontColor.text};
  font-size: ${typography.fontSize.smtext};

  .avatar {
    align-self: center;
    margin-right: 5px;
  }

  h6 {
    margin-bottom: 30px;
  }

  p {
    margin: 0;
  }
`;

export const AssignmentModalCol = styled(Col)`
  display: flex;
  flex-direction: row;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '15px')};
  padding: ${(props) => (props.padding ? props.padding : 'inherit')};
`;

export const AssignmentModalFooter = styled(ModalFooter)`
  border: none;
`;

export const AssignmentModalCloseButton = styled(Button)`
  font-family: ${typography.fontFamily.close};
  background: clear;
  border: none;
  outline: none !important;
  color: ${typography.fontColor.link};
  font-size: ${typography.fontSize.lgtext};
  font-weight: bold;
  position: absolute;
  top: 15px;
  right: 15px;
`;
