import React from 'react';
import FontAwesome from 'react-fontawesome';

export const SortIcon = (props) => {
  if (props.direction) {
    return <FontAwesome name="chevron-down" />;
  } else if (props.direction === false) {
    return <FontAwesome name="chevron-up" />;
  }
  return <></>;
};
