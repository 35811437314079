import React from 'react';
import Avatar from 'react-avatar';
import FontAwesome from 'react-fontawesome';
import { Row, Col } from 'reactstrap';
import { typography } from '../styles/constants';
import { MediumLargeText, RedFontAwesome, SmallText } from '../styles/text';
import { colorFromName } from '../utilities/hash';
import { ParToParCardContainer, PlusButton } from './ParToParStyles';
import { ViewProfile } from './buttons/ButtonStyles';

export default function ParToParCardComponent({ participant, select, deselect, excluded, selected }) {
  React.useEffect(() => {}, [participant]);
  return (
    <ParToParCardContainer size="large">
      <Row>
        <Col className="text-center">
          <SmallText>
            {excluded ? (
              <>
                <RedFontAwesome name="exclamation-circle" />
                &nbsp; incompatible housemate
              </>
            ) : (
              ' '
            )}
            &nbsp;
          </SmallText>
        </Col>
      </Row>
      <Row>
        <Col md="3" style={{ opacity: selected ? 0.5 : 1 }} className="align-self-center">
          <Avatar color={colorFromName(participant.name)} name={participant.name} round size="58" />
        </Col>
        <Col md="7" className="align-self-center">
          <Row>
            <Col>
              <MediumLargeText
                color={selected ? typography.fontColor.disabled : typography.fontColor.text}
                lineHeight={typography.fontSize.xsmtitle}
              >
                {participant.name}
              </MediumLargeText>
            </Col>
          </Row>
          <Row>
            <Col className="align-self-center">
              <SmallText
                color={selected ? typography.fontColor.disabled : typography.fontColor.text}
                lineHeight={typography.fontSize.mdtext}
              >
                {participant.gender.name}, {participant.age} years old
                <br />
                {participant.ADVOCATES_FOR_rel?.length
                  ? `${participant.ADVOCATES_FOR_rel[0]?.Advocate?.name}/${participant.ADVOCATES_FOR_rel[0]?.Advocate?.organization?.name}`
                  : `Self advocating`}
                <br />
              </SmallText>
              <SmallText color={typography.fontColor.link}>
                <ViewProfile target="_blank" to={`/participant/detail/${participant?.id}`}>
                  View Profile
                </ViewProfile>
              </SmallText>
            </Col>
          </Row>
        </Col>
        <Col md="2" className="align-self-center" style={{ margin: 0, padding: 0 }}>
          {!selected && (
            <PlusButton
              onClick={() => {
                select(participant.id);
              }}
            >
              +
            </PlusButton>
          )}
          {selected && (
            <SmallText color={typography.fontColor.text}>
              <FontAwesome name="check" /> Added
            </SmallText>
          )}
        </Col>
      </Row>
    </ParToParCardContainer>
  );
}
