import React, { useCallback, useState } from 'react';
import { v4 } from 'uuid';
import { ParticipantItem } from './ParticipantItem.jsx';
import { ParticipantsContainer, ParticipantHeaderRow, cellWidths, ParHeaderCol } from './ParticipantStyles';

import { SortIcon } from '../icons/SortIcon';
import { ParToUnitFiltersState, ParToUnitSortState, SortedParToUnitPars } from '../../apollo/cache.js';
import { MediumLargeText } from '../../styles/text';

export const ListParticipants = ({ participants, housingUnit, onCheck }) => {
  const [checked, setChecked] = useState([]);
  const [sortedPars, setSortedPars] = useState([]);
  const sortPars = useCallback(
    (parsToSort, initial) => {
      const sortAndDirection = ParToUnitSortState();

      let sortedPars = [...parsToSort];
      if (sortAndDirection.sort === 'status' && (sortAndDirection.lastSort !== 'status' || initial)) {
        sortedPars.sort((parA, parB) => {
          if (parA?.isInterested) {
            return -1;
          }
          if (parB?.isInterested) {
            return 1;
          }
          if (parA?.isDisinterested) {
            return 1;
          }
          if (parB?.isDisinterested) {
            return -1;
          }
          if (parA?.notifiedDate && !parB?.notifiedDate) {
            return -1;
          }
          if (parB?.notifiedDate && !parA?.notifiedDate) {
            return 1;
          }
          if (parA?.notifiedDate && parB?.notifiedDate) {
            return parB.notifiedDate.diff(parA.notifiedDate);
          }
          return 0;
        });
      } else if (sortAndDirection.sort === 'name' && (sortAndDirection.lastSort !== 'name' || initial)) {
        sortedPars.sort((a, b) => {
          if (!a?.name) {
            return 1;
          }
          if (!b?.name) {
            return -1;
          }
          if (a?.name > b?.name) {
            return 1;
          }
          if (b?.name > a?.name) {
            return -1;
          }
          return 0;
        });
      } else if (sortAndDirection.sort === 'advocate' && (sortAndDirection.lastSort !== 'advocate' || initial)) {
        sortedPars.sort((a, b) => {
          const advocateA = a?.ADVOCATES_FOR_rel?.[0]?.Advocate?.name;
          const advocateB = b?.ADVOCATES_FOR_rel?.[0]?.Advocate?.name;
          if (advocateA > advocateB) {
            return 1;
          }
          if (advocateA < advocateB) {
            return -1;
          }
          return 0;
        });
      } else if (sortAndDirection.sort === 'income' && (sortAndDirection.lastSort !== 'income' || initial)) {
        sortedPars.sort((a, b) => {
          if (b?.income == null && a?.income == null) {
            return 1;
          }
          if (a?.income == null) {
            return 1;
          }
          if (b?.income == null) {
            return -1;
          }
          return b?.income - a?.income;
        });
      }
      if (!sortAndDirection.direction || sortAndDirection.lastSort === sortAndDirection.sort) {
        sortedPars = sortedPars.reverse();
      }
      SortedParToUnitPars(sortedPars);
      setSortedPars(sortedPars);
    },
    [SortedParToUnitPars, ParToUnitSortState, setSortedPars],
  );

  const [allChecked, setAllChecked] = React.useState(
    !participants.filter((par) => !checked.some((parC) => parC.id === par.id)).length,
  );

  React.useEffect(() => {
    if (participants && housingUnit) {
      SortedParToUnitPars(participants);
      sortPars(participants, true);
    }
  }, [participants, housingUnit, ParToUnitSortState, SortedParToUnitPars, sortPars]);
  // Allows individual items to be checked and unchecked
  const checkHandler = (participant) => {
    if (!checked.some((checkedObj) => checkedObj.id === participant.id)) {
      const currentChecked = [...checked, participant];
      setChecked(currentChecked);
      onCheck(currentChecked);
      if (currentChecked.length === participants.length) {
        setAllChecked(true);
      }
    } else {
      const currentChecked = checked.filter((v) => v.id !== participant.id);
      setChecked(currentChecked);
      onCheck(currentChecked);
      setAllChecked(false);
    }
  };

  const changeSort = useCallback(
    (sort) => {
      const newSortState = {
        lastSort: ParToUnitSortState().sort,
        sort: sort,
        direction: ParToUnitSortState().sort === sort ? !ParToUnitSortState().direction : true,
      };
      ParToUnitSortState(newSortState);
      sortPars(SortedParToUnitPars(), false);
    },
    [ParToUnitSortState, sortPars, SortedParToUnitPars],
  );

  // to check all participants
  const checkAll = () => {
    if (allChecked) {
      setChecked([]);
      setAllChecked(false);
      onCheck([]);
      return;
    }
    setChecked(participants);
    onCheck(participants);
    setAllChecked(true);
  };
  if (!sortedPars.length) {
    return (
      <ParticipantsContainer>
        <MediumLargeText>
          No participants found
          {Object.entries(ParToUnitFiltersState()).filter((entry) => {
            return entry[1].selected.length || entry[1].savedValue != null;
          }).length
            ? ' matching your filters'
            : ''}
        </MediumLargeText>
      </ParticipantsContainer>
    );
  }
  return (
    <ParticipantsContainer id={'par-to-unit-parlist'}>
      <ParticipantHeaderRow>
        <ParHeaderCol style={{ width: cellWidths.selectAll }}>
          <input type="checkbox" checked={allChecked} onClick={checkAll} /> &nbsp;{' '}
          {!allChecked ? 'Select all' : 'Deselect'}
        </ParHeaderCol>
        <ParHeaderCol
          style={{ width: cellWidths['name'] }}
          clickable
          onClick={() => {
            changeSort('name');
          }}
        >
          Name {ParToUnitSortState().sort === 'name' && <SortIcon direction={ParToUnitSortState().direction} />}
        </ParHeaderCol>
        <ParHeaderCol
          style={{ width: cellWidths['advocate'] }}
          clickable
          onClick={() => {
            changeSort('advocate');
          }}
        >
          Advocate/Org{' '}
          {ParToUnitSortState().sort === 'advocate' && <SortIcon direction={ParToUnitSortState().direction} />}
        </ParHeaderCol>
        <ParHeaderCol
          style={{ width: cellWidths['income'] }}
          clickable
          onClick={() => {
            changeSort('income');
          }}
        >
          Income {ParToUnitSortState().sort === 'income' && <SortIcon direction={ParToUnitSortState().direction} />}
        </ParHeaderCol>
        <ParHeaderCol style={{ width: cellWidths['regions'] }}>Regions</ParHeaderCol>
        <ParHeaderCol
          clickable
          style={{ width: cellWidths['status'] }}
          onClick={() => {
            changeSort('status');
          }}
        >
          Status {ParToUnitSortState().sort === 'status' && <SortIcon direction={ParToUnitSortState().direction} />}
        </ParHeaderCol>
        <ParHeaderCol style={{ marginRight: '20px', width: cellWidths['empty'] }}>&nbsp;</ParHeaderCol>
      </ParticipantHeaderRow>
      <div>
        {sortedPars.map((participant) => {
          const uuidv4 = v4();
          const propForParticipantItem = {
            participant,
            housingUnit,
            checkHandler: checkHandler,
            isChecked: checked.some((checkedPar) => checkedPar.id === participant?.id),
          };
          return (
            <ParticipantItem
              key={`${participant?.id || uuidv4}-${JSON.stringify(participant)}`}
              {...propForParticipantItem}
            />
          );
        })}
      </div>
    </ParticipantsContainer>
  );
};
