import styled from 'styled-components';
import { Col } from 'reactstrap';
import { borders, typography } from '../styles/constants';

export const SideBySide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  color: #06446a;
`;

export const LeftColSideBySide = styled(Col)`
  border-top: 1px solid ${borders.border};
  border-left: 1px solid ${borders.border};
  border-bottom: 1px solid ${borders.border};
  border-radius: ${borders.borderRadius};
`;

export const RightColSideBySide = styled(Col)`
  border-top: 1px solid ${borders.border};
  border-right: 1px solid ${borders.border};
  border-bottom: 1px solid ${borders.border};
  border-radius: ${borders.borderRadius};
`;

export const NameCol = styled(Col)`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 42px;
  margin-bottom: 16px;
`;

export const NameDiv = styled.div`
  font-size: ${typography.fontSize.mdtext};
  font-weight: 700;
`;
export const DescriptionDiv = styled.div`
  font-size: ${typography.fontSize.xsmtext};
  display: inline;
  margin-right: 24px;
`;
export const NameDivHolder = styled.div`
  margin-left: 16px;
  font-weight: light;
`;

export const FlexRowDiv = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
`;

export const AdvocateDiv = styled.div`
  font-size: ${typography.fontSize.xsmtext};
`;
export const AdvocateTitleSpan = styled.span`
  font-weight: bold;
  margin-right: 8px;
`;
