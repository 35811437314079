import React, { useState } from 'react';
import { SortIcon } from '../icons/SortIcon';
import { UserRow, HeaderSortDiv, HeaderSortText } from './AdminDashStyles';

export default function UserTableHeader({ sort, handleSort, handleReverse }) {
  const [sortDirection, setSortDirection] = useState(true);
  return (
    <UserRow clear={true}>
      <div style={{ width: '5%', padding: '0 10px' }}></div>
      <div style={{ width: '20%', padding: '0 10px' }} className="h-100">
        <HeaderSortDiv
          md="2"
          onClick={() => {
            handleSort('name');
            handleReverse();
            sort === 'name' ? setSortDirection(!sortDirection) : setSortDirection(true);
          }}
        >
          <HeaderSortText>Name</HeaderSortText>
          {sort === 'name' && <SortIcon direction={sortDirection} />}
        </HeaderSortDiv>
      </div>
      <div style={{ width: '31%', padding: '0 10px' }}>
        <HeaderSortDiv
          md="2"
          onClick={() => {
            handleSort('email');
            handleReverse();
            sort === 'email' ? setSortDirection(!sortDirection) : setSortDirection(true);
          }}
        >
          <HeaderSortText>Email</HeaderSortText>
          {sort === 'email' && <SortIcon direction={sortDirection} />}
        </HeaderSortDiv>
      </div>
      <div style={{ width: '25%', padding: '0 10px' }}>
        <HeaderSortText>Roles</HeaderSortText>
      </div>
      <div style={{ width: '12%', padding: '0 10px' }}>
        <HeaderSortText>Status</HeaderSortText>
      </div>
      <div style={{ width: '7%', padding: '0 10px' }}></div>
    </UserRow>
  );
}
