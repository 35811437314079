import React, { useEffect, useCallback, useState } from 'react';
import { ApolloClient, InMemoryCache, ApolloLink, concat } from '@apollo/client';
import { ApolloProvider } from '@apollo/react-hooks';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from '@auth0/auth0-react';
import App from '../App';
import { ParticipantFilter } from './cache';
const excludedPaths = ['/interest', '/', '', '/brochure'];

export default function AppWithClient() {
  /**
   * https://github.com/johnymontana/willow-grandstack
   */
  const [accessToken, setAccessToken] = useState();
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0(); // with silently we then don't have to
  const fetchOptions = {};

  const getAccessToken = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
    } catch (err) {
      // TODO: Only force login when on production? Or should we always do it?
      if (process.env.NODE_ENV === 'production' && !excludedPaths.some((path) => window?.location?.pathname === path)) {
        loginWithRedirect();
      } else {
        console.error(err);
      }
    }
  }, [getAccessTokenSilently, setAccessToken]);

  useEffect(() => {
    getAccessToken();
  }, [getAccessToken]);

  const cache = new InMemoryCache({
    typePolicies: {
      Query: { fields: { getParFilter: () => ParticipantFilter() } },
    },
  });
  // The csrf stuff was a holdover from django which has a weird CSRF token name
  const authLink = setContext((_, { headers }) => {
    let newHeaders = headers;
    // Unsure if we still need a csrf header or where we'd even get a token to begin with on this
    if (accessToken) {
      newHeaders = { ...newHeaders, Authorization: `Bearer ${accessToken}` };
    }
    return { headers: newHeaders };
  });

  const client = new ApolloClient({
    cache,
    uri: process.env.REACT_APP_GRAPHQL_API,
    link: authLink.concat(createUploadLink({ uri: process.env.REACT_APP_GRAPHQL_API, fetchOptions })),
  });
  return (
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  );
}
