export const parToPar = {
  politicalInterest: {
    50: 'Moderately interested',
    100: 'Very interested',
  },
  bedtime: {
    3: 'Before 10 p.m.',
    5: '10 p.m. to midnight',
    7: '12 a.m. to 2 a.m.',
    9: 'After 2 a.m.',
    13: 'Night shift',
  },
  noiseSensitivity: {
    1: 'Regular noises don’t bother me at all',
    50: 'Noise during the day is no problem, but I need quiet at night',
    75: 'I can handle a little noise during the day, but need peace and quiet also',
    100: 'I need quiet all the time, no music or loud conversations',
  },
  sharingKitchen: {
    100: 'Anyone can use them any time',
    75: 'Whenever I’m not using them',
    25: 'Mine can be borrowed occasionally',
    1: 'Everyone should get their own items',
  },
  rejectsMeat: {
    1: "They wouldn't need to do anything special",
    50: "I don't want meat or animal products touching my kitchen items",
    100: "I can't have meat in my kitchen at all",
  },
  daytimeGuestsPlanned: {
    100: 'Every day or almost every day',
    75: '3-5 times per week',
    50: '1-2 times per week',
    10: 'Rarely',
    0: 'Never',
  },
  nighttimeGuestsPlanned: {
    100: 'Every night or almost every night',
    75: '3-5 times per week',
    50: '1-2 times per week',
    10: 'Rarely',
    0: 'Never',
  },
  personalityFreeSpirit: {
    1: '1 Very traditional',
    2: '2',
    3: '3 No pref',
    4: '4',
    5: '5 Very free spirited',
  },
  personalityPrefersCompany: {
    1: '1 - As much time alone as possible',
    2: '2',
    3: '3 - About the same',
    4: '4',
    5: '5 - As much time with others as possible',
  },
  dryHomePreference: {
    REQUIRES: 'I require it',
    PREFERS: 'I prefer it',
    CONSIDERS: 'I would consider it',
    REJECTS: 'Not okay with it',
  },
  alcoholToleranceDegree: {
    100: 'Frequent MODERATE drinking (4 or more drinks)',
    75: 'Occasional MODERATE drinking (4 or more drinks)',
    50: 'Frequent LIGHT drinking (1-2 wine or beer)',
    25: 'Occasional LIGHT drinking (1-2 wine or beer)',
    1: 'I prefer no drinking in the house',
  },
  alcoholConsumptionDegree: {
    100: 'Frequent MODERATE drinking (4 or more drinks)',
    75: 'Occasional MODERATE drinking (4 or more drinks)',
    50: 'Frequent LIGHT drinking (1-2 wine or beer)',
    25: 'Occasional LIGHT drinking (1-2 wine or beer)',
    1: 'I never drink alcohol',
  },
  struggledRecency: {
    100: '< 1 week',
    75: '1w-1m',
    50: '1m - 1y',
    25: '1-5 yrs',
    1: '> 5 yrs',
  },
  hardDrugsAcceptance: {
    ACCEPTS: 'Yes',
    TOLERATES: 'Occasionally',
    REJECTS: 'No',
  },
  tobaccoRejectionDegree: {
    100: 'Zero tolerance',
    75: 'Outdoor only',
    25: 'In their room',
    null: 'Anywhere',
  },
  marijuanaRejectionDegree: {
    100: 'Zero tolerance',
    75: 'Outdoor only',
    25: 'In their room',
    null: 'Anywhere',
  },
  personalityPrefersCleanliness: {
    5: 'Clean and spotless',
    3: 'Pretty clean with some things around',
    1: 'You don’t mind things left out',
  },
  personalityPrefersRelation: {
    5: 'Be good friends and hang out often in shared spaces',
    3: 'Friendly and have regular chats',
    1: 'Prefer for us to keep to ourselves most the time',
  },
  comfortWithHousemates: {
    1: '1 - Very uncomfortable',
    2: '2 - Uncomfortable',
    3: '3 - Neither',
    4: '4 - Comfortable',
    5: '5 - Very comfortable',
  },
  personalityTalkToStrangers: {
    1: '1 - Very unlikely',
    2: '2 - Unlikely',
    3: '3 - Not sure',
    4: '4 - Likely',
    5: '5 - Very likely',
  },
  personalityLendFood: {
    1: '1 - Not open/Never',
    2: '2 - Rarely open',
    3: '3 - Somewhat / If I know the person',
    4: '4 - Usually open',
    5: '5 - Always open',
  },
  personalityPayBills: {
    5: 'The day the bill arrives',
    3: 'Mail on due date',
    1: 'At end of grace',
  },
  timeUnhoused: {
    null: 'Not homeless yet',
    20: '<1 year',
    33: '1-2 years',
    66: '2-4 years',
    100: '5+ years',
  },
};
