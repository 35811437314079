import React, { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import Alert from '@material-ui/lab/Alert';

export default function MUISnackbar({ message, setShowToast, severity = 'success' }) {
  const [open, setOpen] = useState(true);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setShowToast(false);
  };

  return (
    <div>
      <Slide in={true} direction="left">
        <Snackbar
          key={message}
          style={{ top: '63px', right: '58px', width: '300px' }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={handleClose}
          autoHideDuration={4000}
        >
          <Alert severity={severity} style={{ width: '300px', border: '1px solid #64ad8b', padding: '3px' }}>
            {message}
          </Alert>
        </Snackbar>
      </Slide>
    </div>
  );
}
