import { gql } from '@apollo/client';

export const FetchParticipant = gql`
  query FetchParticipant($id: ID!) {
    Participant(id: $id) {
      id
      HMISID
      name
      age
      income
      currentIncome
      contactEmail
      status {
        id
        type
      }
      CHANGED_STATUS_rel {
        type
        reason
        createdAt {
          formatted
          timezone
        }
      }
      notifiedHousingUnits {
        id
        name
        location
        unitNumber
        status
        ASSIGNED_TO_rel {
          createdAt {
            formatted
          }
          Participant {
            id
            name
          }
        }
        interestedParticipants {
          id
          name
        }
        disinterestedParticipants {
          id
          name
        }
        assignedParticipants {
          id
          name
        }
        movedInParticipants {
          id
          name
        }
        matchingProcesses {
          id
          createdAt {
            month
            day
          }
          NOTIFIED_rel {
            createdAt {
              formatted
              month
              day
            }
            Participant {
              id
              HMISID
              name
            }
          }
          initiatedBy {
            id
          }
          notifiedParticipants {
            id
            name
          }
        }
        SHOWS_rel(orderBy: order_asc) {
          Image {
            id
            url
            publicId
          }
          order
        }
        bedrooms {
          id
          rent
          squareFeet
        }
        LISTS_rel {
          Listing {
            id
            listingUrl
          }
        }
      }
      interestedIn {
        id
        name
        location
        unitNumber
      }
      disinterestedIn {
        id
        name
        location
        unitNumber
      }
      assignedHousingUnits {
        id
        name
        location
        unitNumber
      }
      movedIntoHousingUnits {
        id
        name
        location
        unitNumber
      }
      MOVED_IN_rel {
        date {
          formatted
        }
        HousingUnit {
          id
          name
          location
          unitNumber
        }
      }
      gender {
        __typename
        id
        name
      }
      updatedAt {
        formatted
      }
      ADVOCATES_FOR_rel(orderBy: createdAt_desc, first: 1) {
        Advocate {
          id
          name
          organization {
            name
          }
        }
      }
      notes {
        id
        createdBy {
          id
          name
        }
        noteFor {
          id
          name
        }
        content
        createdAt {
          formatted
          timezone
        }
      }
    }
    SurveyTaker(id: $id) {
      id
      name
      surveyResponses {
        id
      }
    }
    me {
      id
    }
  }
`;

export const ReactivateParticipant = gql`
  mutation ReactivateParticipant($id: ID!) {
    ReactivateParticipant(id: $id) {
      id
      name
    }
  }
`;

export const DeactiveParticipant = gql`
  mutation DeactivateParticipant($id: ID!, $reason: DeactivationReason!) {
    DeactivateParticipant(id: $id, reason: $reason) {
      id
      name
    }
  }
`;

export const UpdateParContactEmail = gql`
  mutation UpdateParContactEmail($id: ID!, $contactEmail: String) {
    UpdateParticipant(id: $id, contactEmail: $contactEmail) {
      id
      name
      contactEmail
    }
  }
`;
