import React from 'react';
import { Image, CloudinaryContext, Transformation, Placeholder } from 'cloudinary-react';

export const CloudinaryImage = ({ publicId, height = '200', width = null, crop = 'scale', round = false }) => {
  return (
    <CloudinaryContext cloudName="citizentric">
      <Image publicId={publicId} alt="image">
        <Placeholder />
        <Transformation height={height} width={width} crop={crop} radius={round ? 'max' : 0} />
      </Image>
    </CloudinaryContext>
  );
};
