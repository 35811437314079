import React from 'react';

import SectionHeader from '../components/SectionHeader';
import { useQuery, useReactiveVar } from '@apollo/react-hooks';
import { LoadingSpinner, ErrorToast, ContextFeedback } from '../components/Alerts';
import { HousingFilter } from '../apollo/cache';
import { GET_HOUSING } from './UnitDashboardViewGQL';
import Search from '../utilities/search';
import ListHousing from '../components/TableHousing/ListHousingUnits';

export const UnitDashBoardView = () => {
  const filter = useReactiveVar(HousingFilter);
  const [units, setUnits] = React.useState([]);
  const [filteredUnits, setFilteredUnits] = React.useState([]);
  const { loading, error, data, refetch } = useQuery(GET_HOUSING, {
    fetchPolicy: 'cache-and-network',
  });

  React.useEffect(() => {
    if (units?.length && filter) {
      try {
        const searchedUnits = Search(filter, units, ['name', 'location']);
        if (searchedUnits?.length) {
          setFilteredUnits(searchedUnits);
        } else {
          setFilteredUnits([]);
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      setFilteredUnits(units);
    }
  }, [filter, units, setFilteredUnits]);

  React.useEffect(() => {
    if (data?.HousingUnit?.length) {
      setUnits(data.HousingUnit);
    }
  }, [data, setUnits]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <ErrorToast />;
  }
  if (!data) {
    return <ContextFeedback />;
  }
  return (
    <div id="view-unit-dashboard">
      <SectionHeader
        placeholder="Search Units"
        buttonText="Add New Unit"
        reactiveVar={HousingFilter}
        title="Shared Housing Units"
      />
      <ListHousing
        HousingUnit={filteredUnits}
        triggerRefetch={() => {
          refetch?.();
        }}
      />
    </div>
  );
};
