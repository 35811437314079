import React from 'react';
import FontAwesome from 'react-fontawesome';

const MapMarker = ({ lat, lng }) => {
  return (
    <div className="map-marker-wrapper">
      <FontAwesome name="map-marker" className="map-marker" />
    </div>
  );
};

export default MapMarker;
