import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { QueryHousingUnitByIdAllData } from '../HousingUnitCreation/QueryHousingByIdGQL';
import { LoadingSpinner, ErrorToast } from '../Alerts';
import AssignmentParCardComponent from '../AssignedParCard';
import { Row, Col } from 'reactstrap';
import { ListGroupRow } from './HousingStyles';
import { PanelContainer, LabelCol } from '../panels/AssignmentStatusPanelStyles';
import { AssignedParCardWrapper, AssignedParCard } from '../panels/AssignmentStatusPanelStyles';
import { AddParticipantsBtn } from '../buttons/ButtonStyles';
import { findUniqueIdsBetweenObjArrays } from '../../utilities/participantStatusCheck';
import { LargeText, MediumText } from '../../styles/text';
import { v4 } from 'uuid';

export const HousingAssignmentStatusPanel = ({ showViewPool, housingUnit, refetch }) => {
  const history = useHistory();
  const [assignedCount, setAssignedCount] = useState(-1);
  const [movedInCount, setMovedInCount] = useState(-1);
  const [bedroomCount, setBedroomCount] = useState(-1);
  const [assignedPars, setAssignedPars] = useState([]);
  const [emptyCount, setEmptyCount] = useState(-1);
  const [numParsNotMovedInButAssigned, setNumParsNotMovedInButAssigned] = useState(-1);

  useEffect(() => {
    if (housingUnit) {
      const brCount = housingUnit.bedrooms?.length || -1;
      setAssignedCount(housingUnit.numAssignedParticipants);
      setMovedInCount(housingUnit.movedInParticipants?.length || -1);
      setBedroomCount(brCount);
      setAssignedPars(housingUnit.assignedParticipants || []);
      setEmptyCount(brCount > 0 ? brCount - housingUnit.numAssignedParticipants : -1);
      setNumParsNotMovedInButAssigned(
        findUniqueIdsBetweenObjArrays(housingUnit?.movedInParticipants, housingUnit?.assignedParticipants)?.length,
      );
    }
  }, [housingUnit]);

  const handleEmptyClick = () => {
    history.push(`/housing/partopar/${housingUnit.id}`);
  };

  const generateEmpty = () => {
    return (
      <AssignedParCardWrapper key={v4()} className="empty-container">
        <AssignedParCard className="empty">
          <div className="details">
            <AddParticipantsBtn onClick={handleEmptyClick}>
              <MediumText className="align-self-center" style={{ color: '#5BB8B4' }}>
                + Add Participant
              </MediumText>
            </AddParticipantsBtn>
          </div>
        </AssignedParCard>
      </AssignedParCardWrapper>
    );
  };

  const generateVacancies = () => {
    let vacancies = [];
    for (let ix = 0; ix < emptyCount; ix++) {
      const empty = generateEmpty();
      vacancies.push(empty);
    }
    return vacancies;
  };

  const displayStatusMsg = () => {
    const movedInMsg = movedInCount > 0 ? `${movedInCount} Occupant${movedInCount !== 1 ? 's' : ''}` : '';
    const divider = assignedCount > 0 && movedInCount > 0 && numParsNotMovedInButAssigned > 0 ? ' / ' : '';
    const assignedMsg =
      numParsNotMovedInButAssigned > 0
        ? `${numParsNotMovedInButAssigned} Participant${numParsNotMovedInButAssigned !== 1 ? 's' : ''} Assigned`
        : '';
    return <>{movedInMsg + divider + assignedMsg}</>;
  };

  const assigned = (
    <Row>
      {assignedPars?.map((par) => {
        return (
          <AssignmentParCardComponent
            border
            boxShadow
            key={v4()}
            participant={par}
            housingUnit={housingUnit}
            refetchHuData={refetch}
          />
        );
      })}
      {generateVacancies()}
    </Row>
  );

  return (
    <ListGroupRow className={showViewPool && 'dual-panels-top'} style={{ backgroundColor: '#fff' }}>
      <Col md="12">
        <PanelContainer>
          {!housingUnit ? (
            <LoadingSpinner isStatic={true} />
          ) : (
            <>
              <Row style={{ marginBottom: 30 }}>
                <LabelCol md="12">
                  <LargeText marginBottom="20" bold>
                    Status
                  </LargeText>
                </LabelCol>
                <Col md="12">
                  <MediumText>{displayStatusMsg()}</MediumText>
                </Col>
              </Row>
              {assigned}
            </>
          )}
        </PanelContainer>
      </Col>
    </ListGroupRow>
  );
};
