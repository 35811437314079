import React, { useEffect, useState, useCallback } from 'react';

import SectionHeader from '../components/SectionHeader';
import { useLazyQuery, useReactiveVar } from '@apollo/react-hooks';
import { AllParticipants } from './ParticipantDashboardGQL';
import { DashParticipants } from '../components/DashParticipants';
import Filter from '../components/filter';
import { HeaderRow } from '../components/TableHousing/HousingStyles';
import { Container } from 'reactstrap';
import { LoadingSpinner, ErrorToast } from '../components/Alerts';
import { useAuth0 } from '@auth0/auth0-react';
import { ParticipantFilter, ParFiltersStateCache } from '../apollo/cache';
import { getDisplayElement } from '../components//filter/getDisplayElement.js';
import { SmallText } from '../styles/text';
import Search from '../utilities/search';
import { defaultState as ListParsDefaultState } from '../components/DashParticipants/ListParsDefaultState';

export const ParticipantDashboardView = () => {
  const [participants, setParticipants] = useState([]);
  const { user } = useAuth0();

  const [queryAllPars, { data, error, loading, refetch }] = useLazyQuery(AllParticipants);
  const searchFilter = useReactiveVar(ParticipantFilter);
  const [filteredParticipants, setFilteredParticipants] = useState(participants);
  const [searchFilteredParticipants, setSearchFilteredParticipants] = useState(participants);
  const ParFiltersStateCacheVar = useReactiveVar(ParFiltersStateCache);
  const [filtersState, setFiltersState] = useState(
    Object.keys(ParFiltersStateCacheVar).length ? ParFiltersStateCacheVar : ListParsDefaultState,
  );
  useEffect(() => {
    if (user) {
      const roles = user['http://schemas.aroyomatch.com/claims/roles'];
      if (roles) {
        if (roles.includes('MPO') || roles.includes('UserAdmin')) {
          queryAllPars({
            variables: { filter: null },
          });
        } else if (roles.includes('Advocate')) {
          queryAllPars({
            variables: { filter: { advocate: { email: user.email } } },
          });
        }
      }
    }
  }, [user]);

  useEffect(() => {
    if (filteredParticipants && searchFilter) {
      setSearchFilteredParticipants(
        Search(searchFilter, filteredParticipants, ['name', 'ADVOCATES_FOR_rel.0.Advocate.name', 'HMISID']),
      );
    } else if (filteredParticipants) {
      setSearchFilteredParticipants(filteredParticipants);
    }
  }, [filteredParticipants, searchFilter]);

  useEffect(() => {
    if (data?.Participant) {
      const { Participant } = data;
      if (Participant?.length) {
        const newFilteredPars = [];
        const selectedFilterNames = getSelectedFilterNames(filtersState, 'activeStatus');
        const newPars = Participant.map((par) => {
          const newPar = makeNewPar(par);
          const isNotFiltered = compareFilter(newPar, selectedFilterNames, 'activeStatus');
          if (isNotFiltered || !selectedFilterNames.length) {
            newFilteredPars.push(newPar);
          }
          return newPar;
        });
        setParticipants(newPars);
        setFilteredParticipants(newFilteredPars);
        setSearchFilteredParticipants(newFilteredPars);
      }
    }
  }, [data]);

  useEffect(() => {
    refetch?.();
  }, [refetch]);

  const getActiveStatus = (par, ix) => {
    const { assignedHousingUnits, movedIntoHousingUnits, interestedIn, notifiedHousingUnits, status } = par;
    const isActive = status?.type === 'Active';
    const assigned = assignedHousingUnits.length > 0;
    const movedIn = movedIntoHousingUnits.length > 0;
    const interested = interestedIn.length > 0;
    const notified = notifiedHousingUnits.length > 0;
    return {
      activeNoActivity: isActive && !interested && !notified && !assigned && !movedIn, //column
      assigned: isActive && assigned && !movedIn,
      available: isActive && !movedIn && !assigned,
      inactive: !isActive,
      interested: isActive && interested && !assigned && !movedIn,
      movedIn: isActive && movedIn,
      notified: isActive && notified && !interested && !movedIn,
    };
  };

  const makeNewPar = (par) => {
    return {
      activeStatus: getActiveStatus(par),
      ...par,
    };
  };

  const getDisplay = useCallback((value) => {
    return getDisplayElement(value);
  });

  const getSelectedFilterNames = (filterState, name) => {
    const categoryState = filterState[name];
    const selectedOptions = categoryState.options.filter((filter) => {
      return categoryState.selected.includes(filter[0]);
    });
    return selectedOptions.map((option) => option[2]);
  };

  const compareFilter = (par, selectedFilterNames, name) => {
    const parActiveStatus = par[name];
    const selectedParVals = [];
    Object.entries(parActiveStatus).forEach((property) => {
      if (selectedFilterNames.includes(property[0])) {
        selectedParVals.push(property[1]);
      }
    });
    return selectedParVals.some((property) => property);
  };

  const filterToggle = useCallback(
    (name, selected, savedValue, save = false) => {
      if (save) {
        let filteredPars;
        const newFilterState = Object.assign(
          {},
          {
            ...filtersState,
            [name]: { ...filtersState[name], open: !filtersState[name].open, selected, savedValue },
          },
        );
        if (selected.length) {
          const selectedFilterNames = getSelectedFilterNames(newFilterState, name);
          filteredPars = participants.filter((par) => {
            return compareFilter(par, selectedFilterNames, name);
          });
        } else {
          filteredPars = [...participants];
        }
        setFiltersState(newFilterState);
        setFilteredParticipants(filteredPars);
        ParFiltersStateCache(newFilterState);
      } else {
        setFiltersState((filtersState) =>
          Object.assign(
            {},
            {
              ...filtersState,
              [name]: {
                ...filtersState[name],
                open: !filtersState[name].open,
              },
            },
          ),
        );
      }
    },
    [participants, filtersState, setFiltersState],
  );

  if (error) {
    return <ErrorToast />;
  }
  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <div>
      <SectionHeader
        type="participant"
        placeholder="Search participants or advocates"
        buttonText="Add Participant"
        reactiveVar={ParticipantFilter}
        title="My Participants"
      />
      <Container>
        <HeaderRow style={{ alignItems: 'baseline' }}>
          <SmallText fontSize={14}>Filter by: </SmallText>
          {Object.entries(filtersState).map(([key, value], i) => {
            return (
              <Filter
                key={key}
                name={key}
                display={getDisplay(value)}
                selected={value.selected}
                options={value.options}
                isOpen={value.open}
                toggle={filterToggle}
                controlled={value.controlled}
                limit={value.limit}
                placeholder={value.placeholder}
                filterType={value.filterType}
                savedValue={value.savedValue}
              />
            );
          })}
        </HeaderRow>
        <DashParticipants participants={searchFilteredParticipants} />
      </Container>
    </div>
  );
};
