import React from 'react';
import Avatar from 'react-avatar';
import FontAwesome from 'react-fontawesome';
import { Row, Col } from 'reactstrap';
import { typography } from '../styles/constants';
import { MediumText, MediumLargeText, SmallText, LargeText } from '../styles/text';
import { colorFromName } from '../utilities/hash';
import { ParToParCardContainer, DeselectCircleButton } from './ParToParStyles';
import { ViewProfile } from './buttons/ButtonStyles';

export default function ParToParCardSmallComponent({ participant, deselect, isAssigned }) {
  return (
    <ParToParCardContainer border={true} className="align-self-center">
      {!isAssigned && (
        <DeselectCircleButton
          color="#fff"
          onClick={() => {
            deselect(participant);
          }}
        >
          <MediumLargeText className="align-self-center">X</MediumLargeText>
        </DeselectCircleButton>
      )}
      <Row className="h-100">
        <Col md="3" className="align-self-center">
          <Avatar color={colorFromName(participant.name)} name={participant.name} round size="40" />
        </Col>
        <Col md="9" className="align-self-center">
          <Row>
            <Col>
              <MediumText bold={true}>{participant.name}</MediumText>
            </Col>
          </Row>
          <Row>
            <Col>
              <SmallText color={typography.fontColor.link}>
                <ViewProfile target="_blank" to={`/participant/detail/${participant?.id}`}>
                  View Profile
                </ViewProfile>
              </SmallText>
            </Col>
          </Row>
        </Col>
      </Row>
    </ParToParCardContainer>
  );
}
