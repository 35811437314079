import React, { useState, useEffect } from 'react';
import { RemoveInterestInHousingUnit, RemoveDisinterestInHousingUnit } from '../ParticipantStatusUpdatesGQL.js';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import { PrimaryButton, SentIcon } from '../../buttons/ButtonStyles';
import { StatusPanelCol, StatusPanelRow, StatusPanelContainer } from './StatusPanelStyles';
import FontAwesome from 'react-fontawesome';
import {
  participantHasBeenNotified,
  participantIsInterested,
  participantIsDisinterested,
} from '../../../utilities/participantStatusCheck';
import SendNotificationEmailModal from '../../modals/SendNotificationEmailModal';
import { GetInitialEmailForUnit, GetMatchingPARsForUnit } from '../../../views/MatchPARtoUnitGQL';
import { LoadingSpinner } from '../../Alerts';
import { RedFontAwesome, GreenFontAwesome } from '../../../styles/text';
import { useToggle } from '../../../utilities/hooks';
import InterestButtonComponent from '../../buttons/InterestButtons';
export const StatusPanel = ({ className, participant, housingUnit }) => {
  const { houseUnitId } = useParams();
  const [compactDisplay, setCompactDisplay] = useState(false);
  const [isInterested, setIsInterested] = useState(false);
  const [isDisinterested, setIsDisinterested] = useState(false);
  const [initialEmailObject, setInitialEmailObject] = useState({});
  const [openModal, toggleModal] = useToggle(false);
  const { data, loading, error, refetch } = useQuery(GetMatchingPARsForUnit, {
    variables: { id: houseUnitId },
  });
  const { data: eData, loading: eLoading, error: eError } = useQuery(GetInitialEmailForUnit, {
    variables: { id: houseUnitId },
  });

  const [removeInterestInUnit, { loading: loadingRemoveInterest, error: errorRemoveInterest }] = useMutation(
    RemoveInterestInHousingUnit,
    {
      onCompleted() {
        refetch?.();
      },
    },
  );
  const [removeDisinterestInUnit, { loading: loadingRemoveDisinterest, error: errorRemoveDisinterest }] = useMutation(
    RemoveDisinterestInHousingUnit,
    {
      onCompleted() {
        refetch?.();
      },
    },
  );
  const notifiedPar = participantHasBeenNotified(participant, housingUnit);
  const notifiedDate = notifiedPar ? notifiedPar.format('M/D') : false;

  const checkParsInterest = () => {
    setIsInterested(participantIsInterested(participant, housingUnit));
    setIsDisinterested(participantIsDisinterested(participant, housingUnit));
  };

  useEffect(() => {
    checkParsInterest();
  }, [participant, housingUnit]);

  useEffect(() => {
    if (isInterested || isDisinterested) {
      setCompactDisplay(true);
    }
  }, [isInterested, isDisinterested, removeInterestInUnit, removeDisinterestInUnit]);

  useEffect(() => {
    if (eData) {
      const { GetInitialEmailForUnit } = eData;
      if (GetInitialEmailForUnit) {
        setInitialEmailObject({
          ...GetInitialEmailForUnit,
          emailTemplate: GetInitialEmailForUnit.emailTemplate,
        });
      }
    }
  }, [eData]);

  useEffect(() => {
    refetch?.();
  }, [isInterested, isDisinterested, openModal]);

  const handleChangeClick = async (e) => {
    e.preventDefault();

    isInterested
      ? await removeInterestInUnit({
          variables: {
            participant: { id: participant.id },
            housingUnit: { id: housingUnit.id },
          },
        })
      : await removeDisinterestInUnit({
          variables: {
            participant: { id: participant.id },
            housingUnit: { id: housingUnit.id },
          },
        });
    setCompactDisplay(false);
  };

  const displayStatus = () => {
    if (isInterested && !!notifiedDate) {
      return (
        <>
          <GreenFontAwesome name="check-circle" /> {`Interested`}
        </>
      );
    }

    if (isDisinterested && !!notifiedDate) {
      return (
        <>
          <RedFontAwesome name="times-circle" /> {`Not Interested`}
        </>
      );
    }

    if (!!notifiedDate) {
      return (
        <>
          <FontAwesome name="paper-plane" /> {`Sent Listing ${notifiedDate}`}
        </>
      );
    }
  };

  const handleErrorDisplay = () => {
    let msg = <>{'--'}</>;
    if (error || errorRemoveDisinterest || errorRemoveInterest || eError) {
      msg = (
        <>
          <RedFontAwesome name="times-circle" /> {` Something went wrong...`}
        </>
      );
    }
    return msg;
  };

  return (
    <StatusPanelContainer className={className}>
      <StatusPanelRow className={compactDisplay ? 'reverse' : 'show'}>
        <StatusPanelCol className={compactDisplay ? 'hide' : 'show'}>
          <p>Report Participant&apos;s Interest</p>
          <InterestButtonComponent
            participant={participant}
            housingUnit={housingUnit}
            buttonType={'Interested'}
            buttonIconName={'check-circle'}
            buttonText={'Interested'}
            refetch={refetch}
          />
          <InterestButtonComponent
            participant={participant}
            housingUnit={housingUnit}
            buttonType={'Not Interested'}
            buttonIconName={'times-circle'}
            buttonText={'Not Interested'}
            refetch={refetch}
          />
        </StatusPanelCol>
        <StatusPanelCol>
          <p>Status:</p>
          {loading || loadingRemoveDisinterest || loadingRemoveInterest ? (
            <LoadingSpinner isStatic={true} width="40px" containerWidth="60px" />
          ) : (
            <p>{!!notifiedDate || isInterested || isDisinterested ? displayStatus() : handleErrorDisplay()} </p>
          )}
          <span className={'changeLink'}>
            <a href="#" onClick={handleChangeClick} className={compactDisplay ? 'show' : 'hide'}>
              Change Response
            </a>
          </span>
          <PrimaryButton onClick={toggleModal}>
            <SentIcon>
              <FontAwesome name="paper-plane" />
            </SentIcon>
            {!!notifiedDate ? 'Send Follow Up Email' : 'Send Listing'}
          </PrimaryButton>
        </StatusPanelCol>
      </StatusPanelRow>
      <SendNotificationEmailModal
        size="xl"
        checked={[participant]}
        toggleModal={toggleModal}
        openModal={openModal}
        initialEmailObject={initialEmailObject}
        houseUnitId={houseUnitId}
      />
    </StatusPanelContainer>
  );
};
