import { gql } from '@apollo/client';

export const GetParById = gql`
  query GetParById($id: ID) {
    Participant(id: $id) {
      __typename
      name
      id
      age
      HMISID
      HMnames
      dryHomePref
      advocate {
        __typename
        id
        name
        organization {
          __typename
          id
          name
        }
      }
      gender {
        __typename
        id
        name
      }
      genderAversions {
        __typename
        id
        name
      }
      pets {
        __typename
        id
        size
        species
      }
      petsAversions {
        __typename
        id
        size
        species
      }
      income
      preferredRegions {
        __typename
        id
        name
      }
      smokingIndoorsNeeded
      tobaccoPreference
      marijuanaPreference
      marijuanaSmokingIndoors
      tobaccoSmokingIndoors
      politicalInterestsWithScore {
        __typename
        id
        type
        relType
        interest
      }
      politicsLeaning {
        __typename
        id
        type
      }
      politicsAversion {
        __typename
        id
        type
      }
      createdAt {
        formatted
      }
      updatedAt {
        formatted
      }
      row
    }
  }
`;
