import React from 'react';
import Avatar from 'react-avatar';
import moment from 'moment-timezone';
import { colorFromName } from '../../../utilities/hash';
import {
  ViewMatchHistoryContainer,
  MatchHistoryHeaderContainer,
  HeaderLabelItem,
  MatchHistoryItemContainer,
  MatchHistoryItem,
  HUAvatar,
  HUAddress,
  LabelText,
  DateText,
  BodyText,
  ChangeButton,
} from './ViewMatchHistoryStyles';
import { ModalCloseButton } from '../../modals/ModalStyles';
import { VMHModalHeader } from './ViewMatchHistoryStyles';
import { LinkItem } from '../../panels/HousingStyles';
import {
  participantHasBeenNotified,
  participantIsInterested,
  participantIsDisinterested,
  participantIsAssigned,
  participantIsMovedIn,
} from '../../../utilities/participantStatusCheck';
import { SelectedCard } from '../../../apollo/cache';
import PropTypes from 'prop-types';

const ViewMatchHistory = ({ role, participant, close, handleChangeClick }) => {
  const reducedAccessRoles = ['Advocate', 'HousingLocator'];
  const userHasAccess = !reducedAccessRoles.includes(role['__typename']);
  const handleVMHChangeClick = (e, participant, housingUnit, isInterestedInHu, isDisinterestedInHu) => {
    SelectedCard(`card${housingUnit?.id?.replaceAll('-', '')}`);
    document.querySelector(`div[class*="card${housingUnit?.id?.replaceAll('-', '')}"]`)?.classList.remove('hide');
    document.querySelector(`div[class*="card${housingUnit?.id?.replaceAll('-', '')}"]`)?.classList.add('selected');
    handleChangeClick(e, participant, housingUnit, isInterestedInHu, isDisinterestedInHu);
    close();
  };

  const checkHUStatus = (housingUnit, isAssignedToHu, isMovedInHu) => {
    let unitStatus = '--';
    const unitAvailable = housingUnit?.assignedParticipants?.length < housingUnit?.bedrooms?.length || false;
    const unitFilled = housingUnit?.bedrooms?.length === housingUnit?.movedInParticipants?.length || false;
    const unitRemoved = housingUnit?.status === 'Archived';
    const unitAssigned = housingUnit?.bedrooms?.length === housingUnit?.assignedParticipants?.length || false;
    if (unitRemoved) {
      return 'Unit Removed';
    }
    if (unitFilled && !isMovedInHu) {
      unitStatus = 'Unit Filled';
    } else if (unitAvailable) {
      unitStatus = 'Available';
    } else if (unitAssigned && !isMovedInHu) {
      unitStatus = 'Participants Assigned';
    } else if (isAssignedToHu && isMovedInHu) {
      unitStatus = 'Moved-in';
    }
    return unitStatus;
  };

  const hus = participant?.notifiedHousingUnits?.map((housingUnit, index) => {
    const notifiedDate = participantHasBeenNotified(participant, housingUnit)?.format('M/DD/YYYY');
    const isInterestedInHu = participantIsInterested(participant, housingUnit);
    const isDisinterestedInHu = participantIsDisinterested(participant, housingUnit);
    const isAssignedToHu = participantIsAssigned(participant, housingUnit);
    const isMovedInHu = participantIsMovedIn(participant, housingUnit);
    const isMovedInToAnyHu = participant?.movedIntoHousingUnits?.length;
    const huListingLink = `${window.location.origin}/brochure?housingId=${housingUnit?.id}`;
    const huStatus = checkHUStatus(housingUnit, isAssignedToHu, isMovedInHu);
    const unitAvailable = housingUnit?.status === 'Active';
    const unitFilled =
      housingUnit?.bedrooms?.length === housingUnit?.movedInParticipants?.length ||
      housingUnit?.bedrooms?.length === housingUnit?.assignedParticipants?.length ||
      false;
    return (
      <MatchHistoryItemContainer key={`${housingUnit?.name}-${index}`}>
        <MatchHistoryItem>
          <DateText>{notifiedDate}</DateText>
        </MatchHistoryItem>
        <MatchHistoryItem gridColumn={'span 2'}>
          <HUAvatar>
            <Avatar
              alt={housingUnit?.name}
              name={housingUnit?.name}
              size="36"
              round
              color={colorFromName(housingUnit?.name)}
              style={{ marginRight: 15 }}
            />
            <HUAddress>
              <BodyText bold>
                <LinkItem
                  href={huListingLink}
                  color
                  fontWeight="bold"
                  target="_blank"
                  className={userHasAccess ? 'access' : 'no-access'}
                  as={userHasAccess ? null : 'div'}
                >
                  {housingUnit?.name}
                </LinkItem>
              </BodyText>
              <BodyText fontSize="12px">{housingUnit?.location}</BodyText>
            </HUAddress>
          </HUAvatar>
        </MatchHistoryItem>
        <MatchHistoryItem>
          <BodyText>{huStatus}</BodyText>
        </MatchHistoryItem>
        <MatchHistoryItem>
          <BodyText displayFlex flexDirCol>
            {isDisinterestedInHu
              ? 'Not Interested'
              : isAssignedToHu
              ? 'Assigned'
              : isInterestedInHu
              ? 'Interested'
              : '--'}
            {isDisinterestedInHu && !unitFilled && unitAvailable && !isMovedInToAnyHu ? (
              <ChangeButton
                onClick={(e) =>
                  handleVMHChangeClick(e, participant, housingUnit, isInterestedInHu, isDisinterestedInHu)
                }
              >
                Change
              </ChangeButton>
            ) : null}
          </BodyText>
        </MatchHistoryItem>
        <MatchHistoryItem>
          <LinkItem href={huListingLink} fontWeight="bold" target="_blank">
            View Listing
          </LinkItem>
        </MatchHistoryItem>
      </MatchHistoryItemContainer>
    );
  });
  return (
    <ViewMatchHistoryContainer>
      <VMHModalHeader>
        <ModalCloseButton onClick={close}>X</ModalCloseButton>
        Unit Match History
      </VMHModalHeader>
      <MatchHistoryHeaderContainer>
        <HeaderLabelItem bold>
          <LabelText>Received</LabelText>
        </HeaderLabelItem>
        <HeaderLabelItem bold>
          <LabelText>Unit</LabelText>
        </HeaderLabelItem>
        <HeaderLabelItem bold>
          <LabelText>Status</LabelText>
        </HeaderLabelItem>
        <HeaderLabelItem bold>
          <LabelText>Participant Interest</LabelText>
        </HeaderLabelItem>
        <HeaderLabelItem>&nbsp;</HeaderLabelItem>
      </MatchHistoryHeaderContainer>
      {hus}
    </ViewMatchHistoryContainer>
  );
};

ViewMatchHistory.prototypes = {
  role: PropTypes.string.isRequired,
  participant: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  handleChangeClick: PropTypes.func.isRequired,
};

export default ViewMatchHistory;
