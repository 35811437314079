import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Link, useParams } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import { Row, Col, Container } from 'reactstrap';
import { ParticipantDetailsPanel } from '../components/panels/ParticipantDetailsPanel';
import { ParticipantHUMatchPanel } from '../components/panels/ParticipantHUMatches';
import { NotesPanel } from '../components/panels/notes';
import { FetchParticipant } from './ParticipantDetailViewGQL';
import { AddParNoteGQL, DeleteParNote, EditParNote } from '../components/panels/notes/ParticipantNoteGQL';
import { LoadingSpinner, ErrorToast, ContextFeedback } from '../components/Alerts';
import MUISnackbar from '../components/Alerts/MUISnackbar';

export const ParticipantDetailView = () => {
  const [participant, setParticipant] = useState({});
  const [notes, setNotes] = useState([]);
  const [currentUserId, setCurrentUserId] = useState([]);
  const [toastMessage, setToastMessage] = useState({ message: '', severity: 'success' });
  const [showToast, setShowToast] = useState(false);
  const { participantId } = useParams();
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };
  const parseParData = (data) => {
    const dataCopy = { ...(data?.Participant?.[0] || {}) };
    dataCopy['SurveyTaker'] = data?.SurveyTaker?.[0];
    dataCopy['advocate'] = dataCopy?.ADVOCATES_FOR_rel?.[0]?.Advocate;
    setParticipant(dataCopy);
    setNotes(dataCopy.notes);
    setCurrentUserId(data?.me?.id);
  };
  const { data, error, loading, refetch } = useQuery(FetchParticipant, {
    variables: {
      id: participantId,
    },
  });

  useEffect(() => {
    if (data) {
      parseParData(data);
    }
  }, [data]);

  const handleUpdate = (toastMsg, severity = 'success') => {
    setToastMessage({ message: toastMsg, severity });
    setShowToast(true);
  };

  if (loading) {
    return <LoadingSpinner />;
  }
  if (error) {
    return <ErrorToast />;
  }
  if (!data) {
    return <ContextFeedback />;
  }
  return (
    <>
      <div className="housing-detail">
        <Container>
          <Row className="return-units" tag={Link} to="/participants">
            <Col md="12">
              <FontAwesome name="chevron-left" className="chevron-right" /> Participants
            </Col>
          </Row>
          <Row>
            <Col>
              <ParticipantDetailsPanel
                participant={participant}
                toggle={toggle}
                modal={modal}
                handleUpdate={handleUpdate}
                refetch={refetch}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ParticipantHUMatchPanel refetch={refetch} participant={participant} role={data.me} />
            </Col>
          </Row>
          <Row>
            <Col style={{ width: '100%' }}>
              <NotesPanel
                currentUserId={currentUserId}
                forId={participantId}
                notes={notes}
                refetch={refetch}
                createNoteMutation={AddParNoteGQL}
                deleteNoteMutation={DeleteParNote}
                editNoteMutation={EditParNote}
              />
            </Col>
          </Row>
        </Container>
        {showToast && (
          <MUISnackbar message={toastMessage.message} setShowToast={setShowToast} severity={toastMessage.severity} />
        )}
      </div>
    </>
  );
};
