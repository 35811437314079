import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { MediumLargeText } from '../../../styles/text';
import { InputDivHolder, LeftInputDiv, RightSubmitButton } from './NoteStyles';
import { ErrorToast } from '../../Alerts';
import useSlate from '../../useSlate';
import PropTypes from 'prop-types';

function NoteInput({ forId, refetch, createNoteMutation }) {
  // Configure as needed. Options shown in hook defaultProps
  const { value, reset, Editor } = useSlate({
    placeholder: 'Add your notes...',
    initialState: [{ type: 'paragraph', children: [{ text: '' }] }],
    id: `notes-panel-${forId}`,
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [createNote, { loading, error }] = useMutation(createNoteMutation);

  const handleSubmit = async (evt) => {
    setIsFormSubmitted(true);
    const valueToSubmit = value.reduce((text, paragraph) => {
      return (text + '\n' + paragraph.children.reduce((para, child) => para + child.text, '').trim()).trim();
    }, '');
    if (valueToSubmit) {
      const { data } = await createNote({
        variables: { noteFor: forId, content: valueToSubmit },
      });
      reset();
      if (data && refetch && forId) {
        refetch({ variables: { forId } });
      }
    }
  };

  if (error) {
    return <ErrorToast />;
  }

  return (
    <InputDivHolder>
      <LeftInputDiv>{Editor}</LeftInputDiv>
      <RightSubmitButton disabled={isFormSubmitted && (error || loading)} onClick={handleSubmit}>
        <MediumLargeText bold color="white">
          Submit
        </MediumLargeText>
      </RightSubmitButton>
    </InputDivHolder>
  );
}

NoteInput.propTypes = {
  forId: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
  createNoteMutation: PropTypes.func.isRequired,
};

export default NoteInput;
