import React, { useState, useEffect } from 'react';
import Avatar from 'react-avatar';
import { colorFromName } from '../utilities/hash';
import {
  AssignedParCardWrapper,
  AssignedParCard,
  AssignedParAvatar,
  ParInfoPanel,
} from './panels/AssignmentStatusPanelStyles';
import { ViewProfile } from './buttons/ButtonStyles';
import { useToggle } from '../utilities/hooks';
import EditParModal from './modals/EditParModal';
import { IconButton, EditIcon } from './AssignedParCardStyles';
import { participantHasBeenAssigned, participantHasMovedIn } from '../utilities/participantStatusCheck';

export default function AssignmentParCardComponent({ participant, housingUnit, border, boxShadow, refetchHuData }) {
  const [editModal, toggleEditModal] = useToggle(false);
  const [parAssignedDate, setParAssignedDate] = useState(null);
  const [parMovedInDate, setParMovedInDate] = useState(null);

  const handleClickEdit = () => {
    toggleEditModal();
  };

  useEffect(() => {
    setParAssignedDate(participantHasBeenAssigned(participant, housingUnit)?.format('M/DD/YYYY'));
    setParMovedInDate(participantHasMovedIn(participant, housingUnit)?.format('M/DD/YYYY'));
  }, [participant, housingUnit, participantHasBeenAssigned, participantHasMovedIn]);

  return (
    <AssignedParCardWrapper>
      <AssignedParCard border={border} boxShadow={boxShadow}>
        <div style={{ marginRight: '10px' }}>
          <AssignedParAvatar>
            <Avatar
              alt={participant?.name}
              name={participant?.name}
              size="36"
              round
              color={colorFromName(participant?.name)}
            />
          </AssignedParAvatar>
        </div>
        <ParInfoPanel>
          <ViewProfile className="name" target="_blank" to={`/participant/detail/${participant?.id}`}>
            <p className="par-name">{participant?.name}</p>
          </ViewProfile>
          <p className="par-info-text">
            {parAssignedDate && !parMovedInDate && 'Assigned'}
            {parMovedInDate && `Occupant since ${parMovedInDate}`}
          </p>
        </ParInfoPanel>
        <div style={{ marginLeft: 'auto' }}>
          <IconButton onClick={handleClickEdit}>
            <EditIcon />
          </IconButton>
        </div>
      </AssignedParCard>
      {editModal && (
        <EditParModal
          participant={participant}
          toggleModalOpen={toggleEditModal}
          modalOpen={editModal}
          houseUnitId={housingUnit?.id}
          refetchHuData={refetchHuData}
        />
      )}
    </AssignedParCardWrapper>
  );
}
