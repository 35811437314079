import { parStatusSortOrder } from '../../constants/parSortOrder';

const getSortableStatusOrderIx = (statuses) => {
  return Object.entries(statuses).reduce((acc, [key, val]) => {
    const statusIx = parStatusSortOrder.indexOf(key);
    if (statusIx > -1 && val) {
      acc = statusIx;
    }
    return acc;
  }, Infinity);
};

export const sortByStatus = (participants) => {
  return [...participants].sort((a, b) => {
    const indexA = getSortableStatusOrderIx(a?.activeStatus);
    const indexB = getSortableStatusOrderIx(b?.activeStatus);
    return indexA - indexB;
  });
};

export const advocateSort = (participants) => {
  return [...participants].sort((a, b) => {
    const aAdvocate = a?.ADVOCATES_FOR_rel?.[0]?.Advocate;
    const bAdvocate = b?.ADVOCATES_FOR_rel?.[0]?.Advocate;
    const aName = aAdvocate?.name.toLowerCase();
    const bName = bAdvocate?.name.toLowerCase();
    if (aName > bName) {
      return 1;
    } else if (aName < bName) {
      return -1;
    } else {
      return 0;
    }
  });
};

export const alphaSortByKey = (participants, key) => {
  return [...participants].sort((a, b) => {
    const aName = a?.[key].toLowerCase();
    const bName = b?.[key].toLowerCase();
    if (aName > bName) {
      return 1;
    } else if (aName < bName) {
      return -1;
    } else {
      return 0;
    }
  });
};
