import React, { useEffect, useRef, useState } from 'react';
import { MediumText } from '../../styles/text';
import { PrimaryButton, ClearButton } from '../buttons/ButtonStyles';
import {
  FilterDropdown,
  FilterDropdownMenu,
  FilterDropdownToggle,
  FilterDropdownActions,
  FilterTextField,
  FilterDropdownItem,
  FilterCheckbox,
} from './styles';
import NumberFormat from 'react-number-format';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        });
      }}
      isNumericString
      prefix="$"
    />
  );
}

// options are a 2D array [['key', value], ['key2', value2]]
export default function Filter({
  name,
  display,
  options,
  selected,
  isOpen,
  controlled,
  filterType,
  limit,
  placeholder,
  toggle,
  savedValue,
}) {
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [value, setValue] = useState(null);
  const dropdownRef = useRef();

  useEffect(() => {
    if (selected?.length) {
      setSelectedKeys(selected);
      if (savedValue) {
        setValue(savedValue);
      }
    }
  }, [selected, setSelectedKeys, setValue, savedValue]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FilterDropdown
      ref={dropdownRef}
      toggle={(evt) => {
        setSelectedKeys(selected);
        setValue(savedValue);
        toggle(name, selected, savedValue);
      }}
      isOpen={isOpen}
      size="md"
      inline
      style={{ margin: '0 2px 0 9px' }}
    >
      <FilterDropdownToggle color="link" inline active={selected.length}>
        <span style={{ display: 'flex' }}>{display}</span>
      </FilterDropdownToggle>
      <FilterDropdownMenu>
        {!controlled && (
          <FilterDropdownItem toggle={false}>
            <FilterTextField
              variant="outlined"
              label={placeholder}
              value={value}
              onChange={handleChange}
              name={`${name}-filter-input`}
              id={`${name}-filter-input`}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          </FilterDropdownItem>
        )}
        {options.map((option, i) => {
          return (
            <FilterDropdownItem
              key={`${name}-key-${option[0]}`}
              toggle={false}
              onClick={() => {
                setSelectedKeys((selectedKeys) => {
                  let newSelectedKeys = [...selectedKeys];
                  if (newSelectedKeys.includes(option[0])) {
                    return newSelectedKeys.filter((v) => v !== option[0]);
                  }
                  newSelectedKeys = [...selectedKeys, option[0]];
                  // hella dangerous, right?
                  const numToTakeOff = newSelectedKeys.length > limit ? newSelectedKeys.length - limit : 0;
                  newSelectedKeys = newSelectedKeys.slice(numToTakeOff);
                  if (filterType === 'numeric') {
                    setValue(option[2]);
                  }
                  return newSelectedKeys;
                });
              }}
            >
              {/* This is a read only checkbox */}
              {controlled && <FilterCheckbox checked={selectedKeys.includes(option[0])} onChange={() => {}} />}
              &nbsp; <MediumText inline>{option[1]}</MediumText>
            </FilterDropdownItem>
          );
        })}
        <FilterDropdownActions className="w-100">
          <ClearButton
            color="link"
            inline
            onClick={() => {
              setSelectedKeys([]);
              setValue(null);
              toggle(name, [], null, true);
            }}
            size="small"
            style={{ position: 'absolute', bottom: '2px', left: '-6px' }}
          >
            <MediumText color={'#5BB8B4'} inline>
              {' '}
              Clear
            </MediumText>
          </ClearButton>
          <PrimaryButton
            size="small"
            inline
            onClick={() => {
              toggle(name, selectedKeys, value, true);
              setSelectedKeys([]);
            }}
            style={{ position: 'absolute', bottom: '2px', right: '6px' }}
          >
            Done
          </PrimaryButton>
        </FilterDropdownActions>
      </FilterDropdownMenu>
    </FilterDropdown>
  );
}
