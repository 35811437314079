import { getMomentTimezoneObj, getDaysToDate } from '../../utilities/datetimeHelpers';

export const sortDeadline = (a, b) => {
  const isAfilled = a?.bedrooms?.length === a?.movedInParticipants?.length && a?.bedrooms?.length > 0;
  const isBfilled = b?.bedrooms?.length === b?.movedInParticipants?.length && b?.bedrooms?.length > 0;
  const isAArchived = a?.status === 'Archived';
  const isBArchived = b?.status === 'Archived';
  const momentA = a?.deadline?.formatted ? getMomentTimezoneObj(a?.deadline) : a?.deadline?.formatted;
  const momentB = b?.deadline?.formatted ? getMomentTimezoneObj(b?.deadline) : b?.deadline?.formatted;

  if (isAArchived && !isBArchived) {
    return 1;
  }

  if (!isAArchived && isBArchived) {
    return -1;
  }

  if (isAArchived && isBArchived) {
    return -1;
  }

  if (momentA && isBfilled && !isAfilled && !momentB) {
    return -1;
  }
  if (momentB && isAfilled && !isBfilled && !momentA) {
    return 1;
  }
  if (momentA === null && momentB !== null) {
    return -1;
  }

  if (momentB === null && momentA !== null) {
    return 1;
  }

  if (isAfilled && !isBfilled && momentA === null && momentB === null) {
    return 1;
  }

  if (!isAfilled && isBfilled && momentA !== null) {
    return -1;
  }

  if (momentA === momentB) {
    return -1;
  }

  return momentA - momentB;
};
