import React from 'react';
import { Checkbox, CheckboxChecked } from './CheckboxStyles';
import FontAwesome from 'react-fontawesome';
import PropTypes from 'prop-types';

function CheckboxComponent({ checked, onClick, name, id }) {
  if (checked) {
    return (
      <CheckboxChecked onClick={onClick} id={id} name={name} className="checkbox-checked">
        <FontAwesome name="check" style={{ fontWeight: 200 }} />
      </CheckboxChecked>
    );
  }
  return <Checkbox onClick={onClick} id={id} name={name} className="checkbox-unchecked" />;
}

CheckboxComponent.propTypes = {
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.string,
};

export default CheckboxComponent;
