import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'reactstrap';
import { PrimaryButton } from '../components/buttons/ButtonStyles';

export default function AuthDashboardView() {
  const { loginWithRedirect } = useAuth0();
  return (
    <div className="text-center" style={{ margin: '30px' }}>
      <PrimaryButton
        onClick={() => {
          loginWithRedirect();
        }}
        data-testid="login-button"
        id="login-button"
      >
        Login to access AROYO site
      </PrimaryButton>
    </div>
  );
}
