import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import useSlate from '../../useSlate';
import { useToggle } from '../../../utilities/hooks';
import ConfirmModal from '../../modals/ConfirmModal';
import { MediumText, SmallText } from '../../../styles/text';
import Avatar from 'react-avatar';
import { ErrorToast } from '../../Alerts';
import PropTypes from 'prop-types';
import {
  ParagraphHolder,
  LinkButton,
  rightInputStyle,
  noteWrapperStyle,
  iconsWrapperStyle,
  NoteIconButton,
} from './NoteStyles';
import { PrimaryButton } from '../../buttons/ButtonStyles';
import BaseParagraph from '../../useSlate/BaseParagraph';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/feather-trash-2.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/feather-edit-2.svg';
import { getMomentTimezoneObj } from '../../../utilities/datetimeHelpers';

function Note({ noteData, refetch, deleteNoteMutation, editNoteMutation, currentUserId }) {
  const [isOwner, setIsOwner] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isEditing, setIsEditing] = useToggle(false);
  const [isEditSubmitted, setIsEditSubmitted] = useToggle(false);
  const [modalDeleteOpen, toggleDeleteModalOpen] = useToggle(false);
  const { id, content, createdAt, createdBy, noteFor } = noteData;
  const noteDate = getMomentTimezoneObj(createdAt);

  const SlateBaseParagraph = (props) => {
    return (
      <div style={{}} {...props}>
        {props.children}
      </div>
    );
  };

  const { value, Editor } = useSlate({
    placeholder: '',
    initialState: [{ children: [{ text: noteData.content }] }],
    DefaultElement: SlateBaseParagraph,
    id: `slate-${id}`,
  });

  const [deleteNote, { loading: delLoading, error: delError }] = useMutation(deleteNoteMutation);
  const [editNote, { loading: edLoading, error: edError }] = useMutation(editNoteMutation);

  useEffect(() => {
    if (currentUserId && createdBy?.id) {
      setIsOwner(currentUserId === createdBy.id);
    }
  }, [currentUserId, createdBy, setIsOwner]);

  const handleRefetch = () => refetch({ variables: { id: noteFor } });

  const handleEditClick = () => {
    if (isEditing) {
      setIsEditSubmitted(false);
    }
    setIsEditing((prev) => !prev);
  };

  const handleEditSubmit = async () => {
    setIsEditing(false);
    setIsEditSubmitted(true);
    const valueToSubmit = value.reduce((text, paragraph) => {
      return (text + '\n' + paragraph.children.reduce((para, child) => para + child.text, '').trim()).trim();
    }, '');
    if (valueToSubmit?.length > 0 && valueToSubmit !== noteData.content) {
      const { data } = await editNote({ variables: { id, content: valueToSubmit } });
      if (data && refetch) {
        setIsEditSubmitted(false);
        handleRefetch();
      } else {
        setIsError(true);
      }
    }
  };

  const handleDeleteSubmit = async () => {
    const { data } = await deleteNote({ variables: { id } });
    if (data && refetch) {
      handleRefetch();
    } else {
      setIsError(true);
    }
  };

  const handleDeleteClick = async () => {
    toggleDeleteModalOpen();
  };

  if (isError || delError || edError) {
    return <ErrorToast />;
  }

  return (
    <div style={noteWrapperStyle}>
      <div style={{ flexBasis: '120px' }}>
        <SmallText>{noteDate.fromNow().replace('hours', 'hrs')}</SmallText>
      </div>
      <div>
        <Avatar name={createdBy?.name || 'A'} size="30" round style={{ marginRight: '15px', marginTop: '10px' }} />
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <div style={{ flexBasis: '100%', margin: '0 0 20px 15px' }}>
          <MediumText bold>{createdBy?.name || 'null author'}</MediumText>
          {!isEditing && (
            <MediumText style={{ overflowWrap: 'anywhere' }}>
              <ParagraphHolder>
                {content.split(/\n/g).map((noteLine, i) => (
                  <BaseParagraph key={`note-content-${id}-${i}`} style={{ paddingBottom: '7px' }}>
                    {noteLine}
                  </BaseParagraph>
                ))}
              </ParagraphHolder>
            </MediumText>
          )}

          {isOwner && isEditing && (
            <>
              <div style={rightInputStyle}>{Editor}</div>
              <div>
                <LinkButton disabled={isEditSubmitted && edLoading} onClick={handleEditClick}>
                  <MediumText color={'#5BB8B4'} bold>
                    Cancel
                  </MediumText>
                </LinkButton>
                <PrimaryButton size={'small'} disabled={isEditSubmitted && edLoading} onClick={handleEditSubmit}>
                  <MediumText bold color="white">
                    Done
                  </MediumText>
                </PrimaryButton>
              </div>
            </>
          )}
        </div>
        {isOwner && !isEditing && (
          <div style={iconsWrapperStyle}>
            <NoteIconButton onClick={handleEditClick}>
              <EditIcon width="20px" height="20px" />
            </NoteIconButton>
            <NoteIconButton onClick={handleDeleteClick} style={{ marginLeft: '8px' }}>
              <DeleteIcon width="20px" height="20px" />
            </NoteIconButton>
          </div>
        )}
      </div>
      <ConfirmModal
        confirmationText="Are you sure you want to delete this note?"
        submittedMsg="deleting..."
        modalOpen={modalDeleteOpen}
        toggleModalOpen={toggleDeleteModalOpen}
        handleConfirm={handleDeleteSubmit}
      />
    </div>
  );
}

Note.propTypes = {
  noteData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    createdAt: PropTypes.object.isRequired,
    createdBy: PropTypes.object.isRequired,
    noteFor: PropTypes.object.isRequired,
  }),
  refetch: PropTypes.func.isRequired,
  deleteNoteMutation: PropTypes.func.isRequired,
  editNoteMutation: PropTypes.func.isRequired,
};

export default Note;
