import axios from 'axios';

const brochureBaseUrl = process.env.REACT_APP_BROCHURE_URL;

export const getBrochureData = async (housingId) => {
  const url = `${brochureBaseUrl}?housingId=${housingId}`;

  try {
    const response = await axios.get(url);
    return response.data.records[0];
  } catch (e) {
    return false;
  }
};
