import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { TextInput, TextInputLabel } from './TextInputFieldStyles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import debounce from 'lodash/debounce';

export const TextInputField = ({
  id,
  onChange,
  value,
  valid,
  onBlur,
  onBlurCapture,
  autoComplete,
  autoCompleteFn,
  helperText,
  label,
}) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;
  const [inputValue, setInputValue] = useState(value);
  const _autoCompleteFn = useRef(typeof autoCompleteFn === 'function' ? debounce(autoCompleteFn, 200) : () => {});
  useEffect(() => {
    if (autoComplete) {
      if (!inputValue) {
        setOptions([]);
        return undefined;
      }
      _autoCompleteFn.current(setOptions, inputValue);
    }
  }, [loading, autoComplete, inputValue, setOptions, _autoCompleteFn]);

  useEffect(() => {
    setError(!valid);
  }, [valid, setError]);

  if (!autoComplete) {
    return (
      <>
        <TextInputLabel>{label}</TextInputLabel>
        <TextInput
          name={id}
          id={id}
          style={{ width: '100%' }}
          onBlur={onBlur}
          onBlurCapture={onBlurCapture}
          onChange={onChange}
          helperText={error ? helperText : ''}
          variant="outlined"
          value={value}
        />
      </>
    );
  }
  return (
    <>
      <TextInputLabel>{label}</TextInputLabel>
      <Autocomplete
        id={id}
        style={{ width: '100%' }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        getOptionLabel={(option) => option}
        filterOptions={(x) => x}
        options={options}
        loading={loading}
        value={value}
        onChange={(event, newValue) => {
          setOptions((options) => (newValue ? [newValue, ...options] : options));
          onChange(id, newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextInput
            name={id}
            onBlur={onBlur}
            onBlurCapture={onBlurCapture}
            helperText={error ? helperText : ''}
            {...params}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: <>{loading ? <CircularProgress color="inherit" size={20} /> : null}</>,
            }}
          />
        )}
      />
    </>
  );
};
