import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { links, typography, colors, iconStyles } from '../../styles/constants';

export const PrimaryButton = styled.button`
  min-width: ${(props) => (props.size === 'small' ? 'none' : '170px')};
  border: none;
  background-color: ${(props) => (props.disabled ? colors.disabledButton : links.regularLink)};
  font-family: ${typography.fontFamily.regular};
  font-size: ${typography.fontSize.lgtext};
  color: white;
  border-radius: 6px;
  padding: 6px 12px;
  outline: none !important;
  height: 42px;
`;

export const ClearButton = styled(PrimaryButton)`
  background-color: transparent;
  color: ${links.regularLink};
  &:hover {
    opacity: 0.85;
  }
`;

export const InterestButton = styled(PrimaryButton)`
  border: 1px solid ${links.regularLink};
  background-color: white;
  margin-right: 10px;
  color: ${links.regularLink};
  width: ${(props) => (props.width ? '100%' : '')};
  &:disabled {
    opacity: 0.4;
  }

  &:disabled:hover {
    background-color: white;
  }

  &:hover {
    color: ${links.regularLink};
    background-color: ${colors.hover};
  }
`;

export const InterestIcon = styled.span`
  margin-right: ${iconStyles.iconSpacing.size};
`;

export const RemoveInterestedIcon = styled.span`
  margin-right: ${iconStyles.iconSpacing.size};
  color: ${iconStyles.iconColor.removeInterestIcon};
`;

export const SentIcon = styled.span`
  margin-right: ${iconStyles.iconSpacing.size};
`;

export const AssignBtn = styled(PrimaryButton)`
  font-size: 16px;
  padding: 5px;
  float: right;
`;

export const SubmitBtn = styled(PrimaryButton)`
  width: 100px;
  padding: 5px;
  margin: 0 auto;
`;

export const SubmitMoveinBtn = styled(PrimaryButton)`
  padding: 5px;
  margin: 0 auto;
  min-width: inherit;
`;

export const UnitFilledBtn = styled(InterestButton)`
  padding-left: 50px;
  padding-right: 50px;
`;

export const EditParticipantsBtn = styled.button`
  background: none;
  border: none;
  color: ${links.listLink};
  margin-left: 15px;
  outline: none !important;
`;

export const AddParticipantsBtn = styled.button`
  background: none;
  border: none;
  color: #000;
  outline: none !important;
`;

export const CheckedCircle = styled.span`
  margin-right: ${iconStyles.iconSpacing.size};
  color: ${iconStyles.iconColor.interestedIcon};
`;

export const ViewProfile = styled(Link)`
  display: inline-block;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '5px')};
  color: ${links.listLink};
  font-size: ${(props) => (props.fontSize ? props.fontSize : typography.fontSize.lgtext)};
  &:hover {
    color: ${links.listLink};
  }
  &.name {
    color: ${typography.fontColor.text};
  }
`;

export const RecordInterestBtn = styled(Link)`
  background-color: ${(props) => (props.disabled ? colors.disabledButton : links.regularLink)};
  font-family: ${typography.fontFamily.regular};
  font-size: ${typography.fontSize.lgtext};
  color: white;
  padding: 6px 12px;
  outline: none !important;
  min-width: fit-content;
  border-radius: 8px;
  border: 2px solid ${colors.selected};
  margin-right: 40px;

  &:hover {
    text-decoration: none;
    color: white;
    background-color: #57a8a5;
  }
`;
