import React, { ReactNode } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Alert } from 'reactstrap';
import { colors, typography } from '../../styles/constants';
import styled from 'styled-components';
import { RedFontAwesome } from '../../styles/text';
import PropTypes from 'prop-types';

const ContainerDiv = styled.div`
  .alertBox {
    background-color: ${colors.alertBgColor};
  }
`;

const FormButtonGroup = styled.div`
  position: relative;

  & p {
    margin-top: 25px;
  }
`;

const AlertMsg = styled.p`
  & {
    color: ${typography.fontColor.text};
  }
  a {
    color: ${typography.fontColor.alertLinkColor};
  }
`;

const SectionSeparator = styled.span``;

const FixLater = styled(Button)`
  font-size: ${(props) => props?.fontSize || '1.25rem'};
  line-height: 1.5;
  background-color: transparent !important;
  border: none;
  padding: 15px;
  color: #31a3a5;
  outline: none !important;
  &:link,
  &:focus,
  &:hover,
  &:active {
    background-color: transparent !important;
    color: #31a3a5 !important;
    outline: none !important;
    box-shadow: none;
  }
`;

function FormButton(props) {
  const [alert, setAlert] = React.useState(false);
  const { houseUnitId } = useParams();

  React.useEffect(() => {
    if (props?.error) {
      setAlert(true);
    } else {
      setAlert(false);
    }
  }, [props?.error]);

  const displayMsg = () => {
    const list = [];
    const keys = Object.keys(props?.completedSections || {});
    const sectionCount = keys?.length;

    for (let i = 0; i < sectionCount; i++) {
      const section = keys[i];
      let path = '';
      if (section === 'description') {
        path = 'description';
      } else if (section === 'rooms') {
        path = 'room-rent';
      } else if (section === 'acceptances') {
        path = 'unit-acceptances';
      } else if (section === 'images') {
        path = 'unit-images';
      } else if (section === 'accessibility') {
        path = 'accessibility';
      } else if (section === 'viewing') {
        path = 'viewing-options';
      }

      if (props?.completedSections && !props?.completedSections[section]) {
        list?.push(
          <Link to={`/housing/edit/${houseUnitId}/${path}`} key={`FormButton-link-${i}`}>
            {section.charAt(0).toUpperCase() + section.slice(1)}
          </Link>,
        );
        list?.push(<SectionSeparator key={`FormButton-separator-${i}`}>, </SectionSeparator>);
      }
    }

    if (list.length > 2) {
      list.pop();
      const lastSection = list.pop();
      list.pop();
      list.push(<SectionSeparator key={`FormButton-amp-sep`}> &amp; </SectionSeparator>);
      lastSection && list.push(lastSection);
    } else if (list.length === 2) {
      list.pop();
    }

    const msg = (
      <AlertMsg>
        <RedFontAwesome name="exclamation-circle" /> You did not fill out all required fields in the {list} section
        {list.length > 2 && 's'} to submit for matching
      </AlertMsg>
    );
    return msg;
  };

  if (!props.submit) {
    return (
      <ContainerDiv>
        <Alert className="alertBox" isOpen={(!props?.add && alert) || false}>
          <RedFontAwesome name="exclamation-circle" /> You did not fill out all required fileds for matching. Fill and
          continue or fix later.
        </Alert>
        <FormButtonGroup>
          <Button
            disabled={alert || false}
            onClick={() => {
              props.onClick();
            }}
            className="btn-continue"
            size="lg"
            id={`${props.id}-continue`}
          >
            Continue
          </Button>
          {alert && !props?.add && (
            <FixLater
              onClick={() => {
                props.onClick?.(false, true);
              }}
            >
              I&apos;ll fix later
            </FixLater>
          )}
        </FormButtonGroup>
      </ContainerDiv>
    );
  } else {
    return (
      <ContainerDiv>
        <Alert className="alertBox" isOpen={alert || false}>
          {displayMsg()}
        </Alert>
        <FormButtonGroup>
          <Button
            disabled={alert || false}
            onClick={() => {
              props.onClick();
            }}
            className="btn-continue"
            size="lg"
            id={`${props.id}-submit`}
          >
            {props?.status ? 'Update Listing' : 'Submit'}
          </Button>
          {alert && (
            <FixLater
              {...props}
              onClick={() => {
                props.onClick?.(false, true);
              }}
            >
              Save and Close
            </FixLater>
          )}
          {props.children}
        </FormButtonGroup>
      </ContainerDiv>
    );
  }
}

FormButton.propTypes = {
  add: PropTypes.bool,
  submit: PropTypes.bool,
  error: PropTypes.bool,
  children: PropTypes.node,
  completedSections: PropTypes.shape({
    location: PropTypes.bool,
    description: PropTypes.bool,
    rooms: PropTypes.bool,
    acceptances: PropTypes.bool,
    accessibility: PropTypes.bool,
    viewing: PropTypes.bool,
  }).isRequired,
  onClick: PropTypes.func,
  id: PropTypes.string,
  status: PropTypes.bool,
};

export default FormButton;
