import styled from 'styled-components';
import { colors, links, typography } from '../../styles/constants';
import { MediumText } from '../../styles/text';

export const ParsContainerYScrollableDiv = styled.div`
  width: auto;
  overflow: auto;
  box-sizing: border-box;
  margin-top: 30px;
  & div {
    box-sizing: border-box;
  }
`;

export const LabelsColDiv = styled.div`
  margin-top: 55px;
  width: 25%;
  float: left;

  .labels-container {
    float: inherit;
    margin-bottom: 15px;
    width: 100%;
  }
`;

export const TableCellDiv = styled.div`
  width: 280px;
  padding: 10px;
  height: 55px;
  float: left;
  background-color: #fff;
`;

export const CategoryHeaderLabelDiv = styled(TableCellDiv)`
  width: 100%;
  text-transform: uppercase;
  color: ${colors.grayText};
  cursor: pointer;
`;

// to fill empty 'row' in par data, next to Cat Header Label cells
export const CatHeaderRowExtensionDiv = styled(TableCellDiv)``;

export const LabelDiv = styled(TableCellDiv)``;

export const ResponseDataDiv = styled(TableCellDiv)`
  font-weight: 300;
`;

export const ParsDataFrameDiv = styled.div`
  float: left;
  width: 75%;
  overflow: auto;
  border-left: 2px solid #eff9ff;

  .category-data-container {
    margin-bottom: 15px;
    float: inherit;
    width: 100%;
  }
`;

export const DataColsDiv = styled.div`
  width: 1100px;
  float: left;
`;

export const ParCardsRowDiv = styled.div`
  float: left;
  background-color: #fff;
  padding-top: 10px;
`;

export const ParCardDiv = styled.div`
  float: left;
  padding: 0 10px;
  width: 280px;
  height: 45px;
  & .par-name {
    display: inline;
    margin-left: 10px;
    margin-top: 3px;
    font-size: 22px;
  }
`;

export const ViewMoreLabel = styled(MediumText)`
  display: inline;
  color: ${links.listLink};
  font-weight: 700;
`;
