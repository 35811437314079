import styled from 'styled-components';
import { Input, Button, Col, Row } from 'reactstrap';
import { borders } from '../../styles/constants';
import FontAwesome from 'react-fontawesome';

export const UploadRow = styled(Row)`
  height: 60px;
  border: ${borders.border};
  border-radius: ${borders.borderRadius};
  box-shadow: ${borders.boxShadow};
  margin-top: 8px;
  padding: 8px;
`;

export const UploadAwesome = styled(FontAwesome)`
  color: green;
`;
