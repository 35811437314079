export const physicalAccessibilityOptionsDict = {
  100: {
    Participant: 'Wheelchair / No steps',
    HousingUnit: 'Wheelchair accessible / no steps',
  },
  75: {
    Participant: 'Up to 3 steps',
    HousingUnit: '1-3 steps',
  },
  50: {
    Participant: '4 steps to 1 flight',
    HousingUnit: '4 steps to 1 flight',
  },
  0: {
    Participant: 'No Limitation',
    HousingUnit: 'More than 1 flight',
  },
};

const physicalAccessibilityOptions = Object.entries(physicalAccessibilityOptionsDict).reduce((acc, cur) => {
  acc.set(cur[1].HousingUnit, parseInt(cur[0]));
  return acc;
}, new Map());

const physicalAccessibilityText = 'Which best describes the physical barriers to the unit?*';

export const accessibilitiesWithDegreesOptions = {
  'ec19148b-0625-40ca-b912-9da3c611db0f': physicalAccessibilityOptions,
};

export const accessibilitiesWithDegreesOptionText = {
  'ec19148b-0625-40ca-b912-9da3c611db0f': physicalAccessibilityText,
};

export const parkingSpotAccessibilityOptions = new Map([
  ['Yes', true],
  ['No', false],
]);
