import styled from 'styled-components';

export const LargeBullet = styled.li`
  .suggestion-bullet-list {
    &:marker {
      font-size: 1.2em;
    }
  }
`;

export const rightInputStyle = {
  width: '100%',
  minHeight: '200px',
  background: 'white',
  margin: '35px 0 0',
  borderRadius: '2px',
  border: '1px solid #00000029',
  boxShadow: '0px 3px 16px #0A4B6D14',
};
