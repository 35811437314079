import React from 'react';
import Avatar from 'react-avatar';
import { AssignmentModalCol } from '../../modals/AssignParsModalStyles';
import { colorFromName } from '../../../utilities/hash';
import { LoadingSpinner } from '../../Alerts';

export default function AssignParsDetails({ par }) {
  if (!par) {
    return <LoadingSpinner isStatic={true} />;
  }
  return (
    <AssignmentModalCol md="6" padding={0}>
      <div className="avatar">
        <Avatar alt={par?.name} name={par?.name} size="36" round color={colorFromName(par?.name)} />
      </div>
      <div className="details" style={{ paddingLeft: '10px' }}>
        <p style={{ marginBottom: 0 }}>{par?.name}</p>
        <p>
          {par?.age} yrs old, {par?.gender?.name}
        </p>
      </div>
    </AssignmentModalCol>
  );
}
