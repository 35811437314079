import styled from 'styled-components';
import { Button } from 'reactstrap';

export const SelectMultiButton = styled(Button)`
  margin-bottom: 8px;
  height: 80px;
  width: 180px;
  margin-right: 10px;
  color: #06446a;
  border-color: #d8d8d8;
  background-color: #fff;

  &.active,
  &:focus,
  &:active {
    background-color: #5bb8b4 !important;
    border-color: #5bb8b4 !important;
    box-shadow: none !important;
    color: #fff !important;
  }

  &:hover {
    color: #06446a;
    border-color: #d8d8d8;
    background-color: #a5c9ff14;
  }

  &:active > div,
  &:focus > div,
  &.active > div {
    color: white !important;
  }
`;
