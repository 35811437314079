import React, { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import FontAwesome from 'react-fontawesome';
import {
  ParticipantCol,
  ParticipantRow,
  ParticipantCheckboxHolder,
  ParticipantLink,
  cellWidths,
  ParticipantClickable,
} from './ParticipantStyles';
import { colorFromName } from '../../utilities/hash';
import Checkbox from '../buttons/Checkbox';
import { useParams } from 'react-router-dom';
import { MediumText, SmallText } from '../../styles/text';
import { ContextFeedback } from '../Alerts';
import { getNullableType } from 'graphql';

export const ParticipantItem = (props) => {
  const { houseUnitId } = useParams();
  const [parAdvocate, setParAdvocate] = useState(null);

  const { participant, checkHandler, isChecked } = props;

  if (!participant) {
    return <ContextFeedback />;
  }

  const {
    id,
    name,
    ADVOCATES_FOR_rel,
    preferredRegions,
    income,
    currentIncome,
    isDisinterested,
    isInterested,
    notifiedDate,
  } = participant;

  if (!id) {
    return <></>;
  }

  useEffect(() => {
    if (ADVOCATES_FOR_rel?.[0]?.Advocate) {
      setParAdvocate(ADVOCATES_FOR_rel?.[0]?.Advocate);
    }
  }, [ADVOCATES_FOR_rel]);

  const displayStatus = () => {
    if (!!notifiedDate && !isInterested && !isDisinterested) {
      return (
        <>
          <FontAwesome name="paper-plane" /> &nbsp;{`Sent Listing ${notifiedDate.format('M/D')}`}
        </>
      );
    } else if (isInterested && !!notifiedDate && !isDisinterested) {
      return (
        <>
          <FontAwesome name="check-circle" style={{ color: 'green' }} /> {`Interested`}
        </>
      );
    } else if (isDisinterested && !!notifiedDate && !isInterested) {
      return (
        <>
          <FontAwesome name="times-circle" style={{ color: 'red' }} /> {`Not Interested`}
        </>
      );
    } else {
      return (
        <>
          <FontAwesome name="times-circle" style={{ color: 'red' }} /> {`ERROR`}
        </>
      );
    }
  };

  return (
    <ParticipantRow className="par-item" id={`par-item-${id}`}>
      <ParticipantCol style={{ width: cellWidths['selectAll'] }}>
        <ParticipantCheckboxHolder>
          <Checkbox
            checked={isChecked}
            onChange={() => {}}
            onClick={() => checkHandler(participant)}
            name={`checkbox-${id}`}
            id={`checkbox-${id}`}
          />
        </ParticipantCheckboxHolder>
        <Avatar name={name.split(' ').slice(0, 3).join(' ')} size="48" round color={colorFromName(name)} />{' '}
      </ParticipantCol>
      <ParticipantCol style={{ width: cellWidths['name'] }}>
        <MediumText style={{ padding: 0 }} className="par-name">
          {name}
        </MediumText>
      </ParticipantCol>
      <ParticipantCol style={{ display: 'grid', width: cellWidths['advocate'] }}>
        <div style={{ display: 'inline', lineHeight: '15px' }}>
          <ParticipantClickable>{parAdvocate ? `${parAdvocate?.name}` : '--'}</ParticipantClickable>
        </div>
        {parAdvocate?.organization?.name && (
          <div style={{ display: 'inline', lineHeight: '15px' }}>{`/${parAdvocate?.organization?.name}`}</div>
        )}
      </ParticipantCol>
      <ParticipantCol style={{ width: cellWidths['income'] }}>${income || currentIncome}</ParticipantCol>
      <ParticipantCol style={{ width: cellWidths['regions'] }}>
        <SmallText inline style={{ paddingRight: '24px' }}>
          {preferredRegions
            ?.map((pr) => {
              return pr?.name.split(' ')[1] || null;
            })
            .filter((v) => v)
            .sort()
            .join(', ')}
        </SmallText>
      </ParticipantCol>
      {!!notifiedDate || isInterested || isDisinterested ? (
        <ParticipantCol style={{ width: cellWidths['status'] }}>{displayStatus()}</ParticipantCol>
      ) : (
        <ParticipantCol md="2" style={{ paddingLeft: '30px', width: cellWidths['status'] }}>{`--`}</ParticipantCol>
      )}
      {/* <ParticipantCol md="1">{matchType === 'Good' ? <GoodDiv>Good</GoodDiv> : <BadDiv>Bad</BadDiv>}</ParticipantCol> */}
      <ParticipantCol style={{ marginRight: '20px', width: cellWidths['empty'] }}>
        <ParticipantLink to={`/housing/match/${houseUnitId}/${id}`}>View Match</ParticipantLink>
      </ParticipantCol>
    </ParticipantRow>
  );
};
