import { gql } from '@apollo/client';

export const QueryHousingVoucherPet = gql`
  query QueryHousingVoucherPet($id: ID!) {
    HousingUnit(id: $id) {
      accepts {
        id
      }
      allows {
        id
        species
        size
      }
      allowsIndoorSmoking
      allowsSexOffenders
      allowsEvictions
      allowsConvictions
    }
    Voucher {
      id
      type
    }
    Pet {
      id
      species
      size
    }
  }
`;

export const UpdateHousingUnitAcceptances = gql`
  mutation UpdateHousingUnitAcceptances(
    $id: ID!
    $allowsSexOffenders: Boolean!
    $allowsIndoorSmoking: Boolean!
    $allowsEvictions: Boolean
    $allowsConvictions: Boolean
    $updatedAt: _Neo4jDateTimeInput!
  ) {
    UpdateHousingUnitAllowances(
      id: $id
      allowsSexOffenders: $allowsSexOffenders
      allowsIndoorSmoking: $allowsIndoorSmoking
      allowsEvictions: $allowsEvictions
      allowsConvictions: $allowsConvictions
      updatedAt: $updatedAt
    ) {
      id
    }
  }
`;

export const AddHousingUnitVoucher = gql`
  mutation AddHousingUnitVoucher($housingUnitId: ID!, $voucherId: ID!) {
    AddVoucherHousingUnits(from: { id: $housingUnitId }, to: { id: $voucherId }) {
      from {
        _id
      }
      to {
        _id
      }
    }
  }
`;

export const RemoveHousingUnitVoucher = gql`
  mutation RemoveHousingUnitVoucher($housingUnitId: ID!, $voucherId: ID!) {
    RemoveVoucherHousingUnits(from: { id: $housingUnitId }, to: { id: $voucherId }) {
      from {
        _id
      }
      to {
        _id
      }
    }
  }
`;

export const AddPetToHousingUnit = gql`
  mutation AddPetToHousingUnit($from: _HousingUnitInput!, $to: _PetInput!) {
    MergePetHousingUnits(from: $from, to: $to) {
      from {
        id
      }
      to {
        id
      }
    }
  }
`;

export const RemovePetFromHousingUnit = gql`
  mutation RemovePetFromHousingUnit($from: _HousingUnitInput!, $to: _PetInput!) {
    RemovePetHousingUnits(from: $from, to: $to) {
      from {
        id
      }
      to {
        id
      }
    }
  }
`;
