import styled from 'styled-components';
import { Input, Button, Col, Row } from 'reactstrap';
import { borders } from '../../styles/constants';
import FontAwesome from 'react-fontawesome';

export const CounterCol = styled(Col)`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 40px;
`;

export const CounterButton = styled(Button)`
  border-radius: 20px;
  cursor: pointer;
`;

export const Counter = styled.div`
  min-width: 60px;
  text-align: center;
  font-weight: bold;
`;
