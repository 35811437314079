import styled from 'styled-components';

export const SelectWithCheckField = styled.div`
  display: flex;
  align-items: center;
  width: 420px;
  color: #06446a;
  border: 1px solid #d8d8d8;
  background-color: #fff;
  margin: 10px 0 20px;
  padding: 20px 15px;
  border-radius: 8px;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  padding-bottom: 7px;
`;
