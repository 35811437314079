import React, { useState, useCallback, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { DeleteUnitGQL, RemoveHousingUnitLISTS_rel, ArchiveUnit, ReactivateUnit } from './TableHousingGQL';
import { CloudinaryImage } from '../CloudinaryImage';
import {
  ItemRow,
  ItemCol,
  BoldH6,
  NotificationFontAwesome,
  LocationDiv,
  LocationInfo,
  GridContainer,
  GridItem,
} from './HousingStyles';
import { SmallText, LargeText, MediumLargeText } from '../../styles/text';
import { PrimaryButton } from '../buttons/ButtonStyles';
import { useMutation } from '@apollo/react-hooks';
import { DeleteBedroom } from '../HousingUnitCreation/sections/RentRoomGQL';
import { RemoveHousingUnitSHOWS_rel } from '../Inputs/ImageGallerySelectField.jsx';
import { useToggle } from '../../utilities/hooks';
import { createHoldDeadlineDisplay } from '../../utilities/datetimeHelpers';
import Avatar from '@material-ui/core/Avatar';
import FontAwesome from 'react-fontawesome';
import HouseDefault from '../../assets/img/house-default.png';
import ConfirmModal from '../modals/ConfirmModal';
import DeleteEditMenu from '../menus/DeleteEditMenu';
import { findUniqueIdsBetweenObjArrays } from '../../utilities/participantStatusCheck';

export const HousingItem = (props) => {
  const history = useHistory();
  const [menuOpen, toggleMenuOpen] = useToggle(false);
  const [modalDeleteOpen, toggleDeleteModalOpen] = useToggle(false);
  const [modalArchiveOpen, toggleArchiveModalOpen] = useToggle(false);
  const [modalReactivateOpen, toggleReactivateModalOpen] = useToggle(false);
  const {
    id,
    name,
    location,
    unitNumber,
    updated,
    SHOWS_rel,
    complete,
    numAssignedParticipants,
    numNotifiedParticipants,
    numInterestedParticipants,
    movedInParticipants,
    assignedParticipants,
    interestedParticipants,
    LISTS_rel,
    bedrooms,
    status,
    triggerRefetch,
    deadline,
  } = props;

  const daysAgo = updated.fromNow();
  const [deleteUnit] = useMutation(DeleteUnitGQL);
  const [archiveUnit] = useMutation(ArchiveUnit);
  const [reactivateUnit] = useMutation(ReactivateUnit);
  const [deleteBedroom] = useMutation(DeleteBedroom);
  const [deleteListing] = useMutation(RemoveHousingUnitLISTS_rel);
  const [deleteImage] = useMutation(RemoveHousingUnitSHOWS_rel);
  const [numParsNotMovedInButAssigned, setNumParsNotMovedInButAssigned] = useState(null);
  const [holdDeadlineDisplay, setHoldDeadlineDisplay] = useState(null);
  const getNumParsNotMovedInButAssigned = useCallback(findUniqueIdsBetweenObjArrays, [
    movedInParticipants,
    assignedParticipants,
  ]);

  //Housing Statuses
  const hasInterestedPars = numInterestedParticipants;
  const hasMovedInPars = movedInParticipants?.length;
  const isUnitActive = status === 'Active';
  const isUnitInactive = status === 'Inactive' || status === null;
  const isUnitArchived = status === 'Archived';
  const unitNotSubmitted = isUnitInactive && complete;
  const interestedOnly = !numAssignedParticipants && !hasMovedInPars && hasInterestedPars;
  const notifiedOnly =
    numNotifiedParticipants && bedrooms?.length && !hasMovedInPars && !numAssignedParticipants && !hasInterestedPars;
  const inMatchProcess =
    isUnitActive && !hasMovedInPars && !numAssignedParticipants && !numNotifiedParticipants && !hasInterestedPars;
  const allAssigned = numAssignedParticipants && bedrooms?.length && !hasMovedInPars;
  const someFilled = hasMovedInPars && bedrooms?.length && hasMovedInPars !== bedrooms?.length;
  const allRoomsFilled = !!(hasMovedInPars && bedrooms?.length && hasMovedInPars === bedrooms?.length);
  const huCB = useCallback(
    async (action) => {
      if (action === 'edit') {
        history.push(`/housing/edit/${id}/location`);
      } else if (action === 'archive') {
        await archiveUnit({
          variables: {
            id: id,
          },
        });
        triggerRefetch();
      } else if (action === 'reactivate') {
        await reactivateUnit({
          variables: {
            id: id,
          },
        });
        triggerRefetch();
      } else {
        if (bedrooms) {
          for (const bedroom of bedrooms) {
            await deleteBedroom({ variables: { id: bedroom.id } });
          }
        }
        if (LISTS_rel) {
          for (const listing of LISTS_rel) {
            await deleteListing({ variables: { from: { id: listing.Listing.id }, to: { id } } });
          }
        }
        if (SHOWS_rel) {
          for (const image of SHOWS_rel) {
            await deleteImage({ variables: { from: { id: image.Image.id }, to: { id } } });
          }
        }
        if (action === 'delete') {
          await deleteUnit({ variables: { id } });
        }
        triggerRefetch();
      }
    },
    [useHistory, deleteUnit, archiveUnit, reactivateUnit, triggerRefetch, id],
  );

  const modalBody = (
    <>
      <LargeText>{name}</LargeText>
      <MediumLargeText>{location}</MediumLargeText>
      {unitNumber && (
        <MediumLargeText>
          {unitNumber.toLowerCase().startsWith('unit') ? '' : 'Unit '}
          {unitNumber}
        </MediumLargeText>
      )}
    </>
  );

  const MeatballMenu = ({ id }) => (
    <DeleteEditMenu
      id={id}
      handleClick={huCB}
      status={status}
      isDeletable={!numNotifiedParticipants}
      menuOpen={menuOpen}
      toggleMenuOpen={toggleMenuOpen}
      toggleDelete={toggleDeleteModalOpen}
      toggleReactivateModalOpen={toggleReactivateModalOpen}
      toggleArchive={toggleArchiveModalOpen}
    />
  );
  useEffect(() => {
    setHoldDeadlineDisplay(createHoldDeadlineDisplay(deadline, status, allRoomsFilled));
  }, [deadline, status, allRoomsFilled, setHoldDeadlineDisplay]);
  useEffect(() => {
    setNumParsNotMovedInButAssigned(getNumParsNotMovedInButAssigned(movedInParticipants, assignedParticipants)?.length);
  }, [movedInParticipants, assignedParticipants, getNumParsNotMovedInButAssigned, setNumParsNotMovedInButAssigned]);
  return (
    <>
      <ConfirmModal
        confirmationText="Are you sure you want to delete this unit?"
        submittedMsg="deleting..."
        modalOpen={modalDeleteOpen}
        toggleModalOpen={toggleDeleteModalOpen}
        handleConfirm={huCB}
        actionType={'delete'}
      >
        {modalBody}
      </ConfirmModal>
      <ConfirmModal
        confirmationText="Are you sure you want to mark this unit as inactive?"
        submittedMsg="archiving..."
        modalOpen={modalArchiveOpen}
        toggleModalOpen={toggleArchiveModalOpen}
        handleConfirm={huCB}
        actionType={'archive'}
      >
        {modalBody}
      </ConfirmModal>
      <ConfirmModal
        confirmationText="Are you sure you want to reactivate this unit?"
        submittedMsg="reactivating..."
        modalOpen={modalReactivateOpen}
        toggleModalOpen={toggleReactivateModalOpen}
        handleConfirm={huCB}
        actionType={'reactivate'}
      >
        {modalBody}
      </ConfirmModal>
      <GridContainer key={`${id}-${complete}`} id={`hu-${name.split(' ').join('-')}`}>
        <GridItem>
          <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
            <div style={{ width: '96px', height: '96px', margin: '8px 16px 8px 8px' }}>
              {SHOWS_rel && SHOWS_rel?.[0]?.Image?.publicId ? (
                <CloudinaryImage publicId={SHOWS_rel?.[0]?.Image?.publicId} width="96" height="96" round />
              ) : (
                <Avatar
                  src={HouseDefault}
                  round
                  style={{ width: '96px', height: '96px', margin: '8px 16px 8px 8px' }}
                />
              )}
            </div>
            <LocationDiv>
              <BoldH6>{name}</BoldH6>
              <LocationInfo>{location}</LocationInfo>
            </LocationDiv>
          </div>
        </GridItem>
        <GridItem>
          <div style={{ fontSize: '12px' }}>{`Updated: ${daysAgo}`} </div>
        </GridItem>
        <GridItem>
          <div style={{ fontSize: '12px' }}>
            {holdDeadlineDisplay ? (
              holdDeadlineDisplay
            ) : (
              <Link to={`/housing/edit/${id}/viewing-options`} className="hold-deadline-add">
                Add Deadline
              </Link>
            )}
          </div>
        </GridItem>
        <GridItem>
          <GridContainer $gridTemplateColumns="1fr 1fr .5fr">
            <GridItem>
              {isUnitArchived && <SmallText inline>Inactive</SmallText>}
              {!complete && !isUnitArchived && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <NotificationFontAwesome name="exclamation-circle" />
                  <SmallText inline>Listing Incomplete</SmallText>
                </div>
              )}
              {complete && !isUnitArchived && (
                <div>
                  {!isUnitArchived && (
                    <>
                      {inMatchProcess && <SmallText>In Matching Process</SmallText>}
                      {allRoomsFilled ? <SmallText>All Rooms Filled</SmallText> : ''}
                      {someFilled ? (
                        <SmallText lineHeight="15px">
                          {hasMovedInPars} of {bedrooms?.length} Rooms Filled /<br /> {numParsNotMovedInButAssigned}{' '}
                          Room
                          {numParsNotMovedInButAssigned !== 1 ? 's' : ''} Assigned
                        </SmallText>
                      ) : (
                        ''
                      )}
                      {allAssigned ? (
                        <>
                          <SmallText>
                            {numAssignedParticipants === bedrooms?.length
                              ? 'All Rooms Assigned'
                              : `${numAssignedParticipants} of ${bedrooms?.length} Rooms Assigned`}
                          </SmallText>
                        </>
                      ) : (
                        ''
                      )}
                      {interestedOnly ? (
                        <>
                          <SmallText>
                            {numInterestedParticipants} Participant
                            {numInterestedParticipants !== 1 ? 's' : ''} Interested
                          </SmallText>
                        </>
                      ) : (
                        ''
                      )}
                      {notifiedOnly ? (
                        <>
                          <SmallText>
                            {numNotifiedParticipants} Participant{numNotifiedParticipants !== 1 ? 's' : ''} Notified
                          </SmallText>
                        </>
                      ) : (
                        ''
                      )}
                      {unitNotSubmitted && <SmallText inline>Not Submitted</SmallText>}
                    </>
                  )}
                </div>
              )}
            </GridItem>
            <GridItem>
              {complete && (
                <>
                  {isUnitInactive && (
                    <PrimaryButton
                      className="btn-action regular-text"
                      onClick={() => {
                        history.push(`/housing/edit/${id}/viewing-options`);
                      }}
                    >
                      Submit Listing
                    </PrimaryButton>
                  )}
                  {!numNotifiedParticipants && isUnitActive && (
                    <PrimaryButton
                      className="btn-action regular-text"
                      onClick={() => {
                        history.push(`/housing/detail/${id}`);
                      }}
                    >
                      Get Started
                    </PrimaryButton>
                  )}
                </>
              )}
              {!complete && !isUnitArchived && (
                <PrimaryButton
                  className="btn-action regular-text"
                  onClick={() => {
                    history.push(`/housing/edit/${id}/location`);
                  }}
                >
                  Complete Listing
                </PrimaryButton>
              )}
            </GridItem>
            <GridItem className="secondary-cta">
              {complete && numNotifiedParticipants > 0 && isUnitActive && (
                <Link to={`/housing/detail/${id}`}>
                  <FontAwesome name="chevron-right" className="chevron-right " size="2x" />
                </Link>
              )}
              {((complete && (!numNotifiedParticipants || isUnitArchived)) || !complete) && (
                <MeatballMenu id={`meatball-${name.split(' ').join('-')}`} />
              )}
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </>
  );
};
