import { gql } from '@apollo/client';

export const UpdateHousingUnitDescription = gql`
  mutation UpdateHousingUnitDescription($id: ID!, $description: String) {
    UpdateHousingUnit(id: $id, description: $description) {
      id
    }
  }
`;

export const QueryHousingDescription = gql`
  query QueryHousingDescription($id: ID!) {
    HousingUnit(id: $id) {
      id
      description
    }
    me {
      id
    }
  }
`;
