export const defaultState = {
  activeStatus: {
    open: false,
    selected: [0, 1, 2],
    controlled: true,
    limit: Infinity,
    filterType: 'ActiveStatus',
    display: 'Status',
    options: [
      [0, 'Incomplete', 'incomplete'],
      [1, 'In Match Process', 'inMatchProcess'],
      [2, 'Matched', 'matched'],
      [3, 'Inactive', 'inactive'],
    ],
  },
};
