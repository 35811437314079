import { gql } from '@apollo/client';
export const GetParFilter = gql`
  query getParFilter {
    getParFilter @client
  }
`;
export const AllParticipants = gql`
  query AllParticipants($filter: _ParticipantFilter) {
    Participant(filter: $filter) {
      id
      name
      HMISID
      email
      contactEmail
      disinterestedIn {
        id
        name
      }
      interestedIn {
        id
        name
      }
      assignedHousingUnits {
        id
        name
      }
      notifiedHousingUnits {
        id
        name
      }
      movedIntoHousingUnits {
        id
        name
      }
      createdAt {
        formatted
      }
      updatedAt {
        formatted
      }
      age
      income
      accessibility {
        id
        detail
        ADA
      }
      pets {
        id
        species
        size
      }
      preferredRegions {
        id
        name
      }
      gender {
        name
      }
      ADVOCATES_FOR_rel(orderBy: createdAt_desc, first: 1) {
        Advocate {
          name
          id
          organization {
            name
          }
          status {
            id
            type
          }
        }
      }
      status {
        id
        type
      }
      vouchers {
        id
        type
      }
    }
  }
`;
