import styled from 'styled-components';
import { Input } from 'reactstrap';

export const HCenterDiv = styled.div`
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;
`;

export const RentRoomInput = styled(Input)`
  height: 64px;

  && input.is-valid {
    border-color: #5bb8b4;
    background-image: none;
    box-shadow: none;
  }

  &&:not(.is-invalid) {
    box-shadow: none;
  }

  && input:focus {
    box-shadow: none;
  }
`;
