const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const urlRegex = new RegExp(
  '^(?!mailto:)(?:(?:http|https|ftp)://)?(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$',
  'i',
);

export const isRequired = (value) => !!value;

export const checkLength = (value, min = 10, max = null) => {
  if (typeof value !== 'string' || Array.isArray(value)) {
    return false;
  }
  const newMax = max || value.length;
  if (value?.length >= min && value.length <= newMax) {
    return true;
  } else {
    return false;
  }
};

export const compare = (string1, string2) =>
  typeof string1 === 'string' && typeof string2 === 'string' && string1 === string2;

export const isUrl = (str) => typeof str === 'string' && str.length < 2083 && urlRegex.test(str);
