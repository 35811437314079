import React, { useEffect } from 'react';
import styled from 'styled-components';

import { CloudinaryImage } from '../CloudinaryImage';
import { Navbar, NavLink, Nav, NavItem, Button } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import HouseDefaultIcon from '../../assets/img/house-default.png';
import { QueryNameImages } from './FormNavBarGQL';
import { useLazyQuery } from '@apollo/react-hooks';
import Avatar from '@material-ui/core/Avatar';
import { LoadingSpinner } from '../Alerts';
import { MediumLargeText } from '../../styles/text';

const ButtonContainer = styled.div`
  position: absolute;
  right: 30px;
`;

const SaveAndCloseBtn = styled(Button)`
  background-color: transparent !important;
  border: none;
  padding: 0;
  color: #31a3a5;

  &:link,
  &:focus,
  &:hover,
  &:active {
    background-color: transparent !important;
    color: #31a3a5 !important;
  }
`;

export const FormNavBar = ({ onClick }) => {
  const { houseUnitId } = useParams();
  const [primaryImageUrl, setPrimaryImageUrl] = React.useState('');
  const [housingUnitName, setHousingUnitName] = React.useState('');
  const [getNameImage, { data, loading, error }] = useLazyQuery(QueryNameImages);
  useEffect(() => {
    if (data?.HousingUnit?.length) {
      const housingUnit = data.HousingUnit[0];
      const { SHOWS_rel, name } = housingUnit;
      if (SHOWS_rel?.length && SHOWS_rel[0]?.Image?.publicId) {
        setPrimaryImageUrl(SHOWS_rel[0]?.Image?.publicId);
      }
      if (name) {
        setHousingUnitName(name);
      }
    }
  }, [data]);

  useEffect(() => {
    if (houseUnitId) {
      getNameImage({
        variables: { id: houseUnitId },
      });
    }
  }, [houseUnitId, getNameImage]);
  if (loading) {
    return <LoadingSpinner />;
  }
  if (error) {
    // if adding a unit, always errors
    return (
      <div className="navbar-assessment-form">
        <Navbar expand="md">
          <Nav className="mr-auto" navbar></Nav>
          <NavLink tag={Link} className="text-cancel-save" to="/housing">
            Cancel
          </NavLink>
        </Navbar>
      </div>
    );
  }

  return (
    <div className="navbar-assessment-form">
      <Navbar expand="md">
        <Nav className="mx-auto" navbar>
          <NavItem>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center' }}>
              {primaryImageUrl ? (
                <CloudinaryImage publicId={primaryImageUrl} width="40" height="40" round={true} />
              ) : (
                <Avatar size="40" round src={HouseDefaultIcon} />
              )}

              <MediumLargeText bold inline marginLeft={8}>
                {housingUnitName}
              </MediumLargeText>
            </div>
          </NavItem>
        </Nav>
        <ButtonContainer className="text-center btn-container">
          <SaveAndCloseBtn
            onClick={() => {
              onClick?.(true);
            }}
            className="btn-continue"
            size="lg"
          >
            Save and Close
          </SaveAndCloseBtn>
        </ButtonContainer>
      </Navbar>
    </div>
  );
};
