import styled from 'styled-components';
import { Col } from 'reactstrap';
import { typography } from '../../../styles/constants';

export const CategoryWrapper = styled.div`
  position: relative;
`;

export const Category = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 16px #0a4b6d14;
  border: 1px solid #a5c9ff;
  border-radius: 7px;
  font: normal normal normal 13px/16px Lato;
  letter-spacing: 0px;
  color: #939393;
  text-transform: uppercase;
  padding: 15px;
  margin-bottom: 5px;
  span.category {
    display: inline-block;
    margin-bottom: 15px;
  }
  span.chevron-right {
    font-size: ${typography.fontSize.smtext};
    margin-left: 5px;
  }

  span.chevron-down span {
    font-size: ${typography.fontSize.smtext};
    margin-left: 5px;
    transform: rotate(90deg);
  }

  .left-pane,
  .right-pane {
    margin-bottom: 8px;
  }
`;

export const Question = styled(Col)`
  padding: 0;
  color: ${typography.fontColor.text};
  font-size: ${typography.fontSize.smtext};
  text-transform: capitalize;
`;

export const Answer = styled(Col)`
  font-size: ${typography.fontSize.lgtext}
  padding: 0;
  color: ${typography.fontColor.text};
  text-transform: capitalize;
`;

export const EditBtn = styled.button`
  display: none; /*Remove when ready to implement Edit functionality*/
  outline: none;
  border: none;
  background-color: transparent;
  float: right;
  color: ${typography.fontColor.alertLinkColor};
  font-size: ${typography.fontSize.lgtext};
`;
