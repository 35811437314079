import React, { useEffect, useState } from 'react';
import { useQuery, useReactiveVar } from '@apollo/react-hooks';
import { GetUserAccounts } from './AdminManageStaffGQL';
import { AdminDashboardWrapper } from './AdminDashboardWrapper';

import { DashUsers } from '../components/AdminTable/DashUsers';
import { UserSectionHeader } from '../components/AdminTable/UserSectionHeader';
import UserModal from '../components/AdminTable/UserModal';
import { LoadingSpinner, ErrorToast } from '../components/Alerts';
import MUISnackbar from '../components/Alerts/MUISnackbar';

import { UserFilter } from '../apollo/cache';
import search from '../utilities/search.js';
import { useToggle } from '../utilities/hooks';

export const AdminManageStaffView = ({ orgId }) => {
  const { data, error, loading, refetch } = useQuery(GetUserAccounts);
  const searchFilter = useReactiveVar(UserFilter);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState(users);

  const [userModalOpen, toggleUserModalOpen] = useState(false);
  const [newOrUpdatedUser, setNewOrUpdatedUser] = useState(null);
  const [editUser, setEditUser] = useState(null);
  const [isChangingStatus, setIsChangingStatus] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState({ message: '', severity: 'success' });

  const searchUsers = (users) => {
    const usersWithRoles = users.map((user) => {
      const userCopy = { ...user };
      const rolesStr = user?.labels?.join(';');
      userCopy['roles'] = rolesStr;
      return userCopy;
    });
    return search(searchFilter, usersWithRoles, ['name', 'email', 'roles']);
  };
  useEffect(() => {
    if (data) {
      const { UserAccount } = data;
      setUsers(UserAccount);
      setFilteredUsers(searchUsers(UserAccount));
    }
  }, [data]);
  useEffect(() => {
    if (users && searchFilter) {
      setFilteredUsers(searchUsers(users));
    } else if (users) {
      setFilteredUsers(users);
    }
  }, [users, setFilteredUsers, searchFilter]);

  const handleAddUser = () => {
    setShowToast(false);
    setEditUser(null);
    toggleUserModalOpen(true);
  };

  const handleEditUser = (user) => {
    setIsChangingStatus(false);
    setShowToast(false);
    setEditUser(user);
    toggleUserModalOpen(true);
  };

  const handleReactivateUser = (user) => {
    setIsChangingStatus(true);
    setShowToast(false);
    setEditUser(user);
    toggleUserModalOpen(true);
  };

  const handleCreateOrUpdateUserSuccess = (user, msg) => {
    setNewOrUpdatedUser(user);
    if (user) {
      writeMessage(user?.name, msg);
    }
  };

  const handleToastErrorMessage = (msg) => {
    let errMsg = '';
    if (editUser && isChangingStatus) {
      errMsg = 'Reactivation was not successful';
    } else if (editUser) {
      errMsg = 'Update was not successful';
    } else {
      errMsg = 'User was not created';
    }
    const newToastMessage = { ...toastMessage, message: errMsg, severity: 'error' };
    setToastMessage(newToastMessage);
    setShowToast(true);
    toggleUserModalOpen(false);
    setNewOrUpdatedUser(null);
  };

  const writeMessage = (userName, msgType) => {
    let message;
    if (msgType === 'newUser') {
      message = `${userName}'s profile has been created`;
      setShowToast(true);
    } else if (msgType === 'updateUser') {
      message = `${userName}'s profile has been updated`;
      setShowToast(true);
    } else if (msgType === 'reactivate') {
      message = `${userName}'s profile has been reactivated`;
      setShowToast(true);
    } else if (msgType === 'deactivate') {
      message = `${userName}'s profile has been deactivated`;
      setShowToast(true);
    } else if (msgType === 'delete') {
      message = `${userName}'s profile has been deleted`;
      setShowToast(true);
    } else {
      message = '';
      setShowToast(false);
    }
    const newToastMessage = { ...toastMessage, message, severity: 'success' };

    setToastMessage(newToastMessage);
  };

  const checkNewUserIsUnique = (email) => {
    const existing = users.reduce((acc, cur) => {
      acc.add(cur?.email);
      return acc;
    }, new Set());
    return !existing.has(email);
  };

  if (error) {
    return <ErrorToast />;
  }
  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <div style={{ maxWidth: '950px' }}>
        <div style={{ textAlign: 'left' }}>
          <UserSectionHeader
            type="user"
            placeholder="Search staff"
            buttonText="Add New Staff"
            reactiveVar={UserFilter}
            title="Manage Staff"
            handleAddUser={handleAddUser}
          />
        </div>
        <DashUsers
          users={filteredUsers}
          handleEditUser={handleEditUser}
          newUserEmail={newOrUpdatedUser?.email}
          handleCreateOrUpdateUserSuccess={handleCreateOrUpdateUserSuccess}
          handleToastErrorMessage={handleToastErrorMessage}
          handleReactivate={handleReactivateUser}
          refetch={refetch}
        />
        {userModalOpen && (
          <UserModal
            user={editUser}
            isChangingStatus={isChangingStatus}
            checkUniqueEmail={checkNewUserIsUnique}
            toggleModalOpen={toggleUserModalOpen}
            refetch={refetch}
            orgId={orgId}
            handleCreateOrUpdateUserSuccess={handleCreateOrUpdateUserSuccess}
            handleToastErrorMessage={handleToastErrorMessage}
          />
        )}
        {showToast && (
          <MUISnackbar message={toastMessage.message} setShowToast={setShowToast} severity={toastMessage.severity} />
        )}
      </div>
    </>
  );
};

const MemoizedAdminManageStaffView = React.memo(AdminManageStaffView);

export const AdminManageStaff = ({ orgId }) => {
  return (
    <AdminDashboardWrapper currentPath="manageStaff">
      <MemoizedAdminManageStaffView orgId={orgId} />
    </AdminDashboardWrapper>
  );
};
