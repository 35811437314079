import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  UnitReadyModal,
  LargeText,
  UnitReadyModalFooter,
  UnitReadyModalBtn,
  UnitReadyModalBody,
  UnitReadyModalHeader,
  UnitReadyModalTextWrapper,
} from './UnitCompleteModalStyles';
import { GlobalStyle } from './AssignParsModalStyles';
import { useMutation } from '@apollo/react-hooks';
import { UpdateUnitStatus } from './UnitCompleteGQL';
import { LoadingSpinner, ErrorToast } from '../Alerts';
import Checkbox from '../buttons/Checkbox';
import { createNeo4jDateTime } from '../../utilities/datetimeHelpers';

export default function UnitCompleteModal({ modal, toggle }) {
  let history = useHistory();
  let { houseUnitId } = useParams();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [modalHeader, setModalHeader] = useState('default');
  const [modalBody, setModalBody] = useState('default');
  const [modalFooter, setModalFooter] = useState('default');
  const [isChecked, setIsChecked] = useState(false);
  const [hasViewedListing, setHasViewedListing] = useState(false);
  const [updateUnitStatus, { loading, error }] = useMutation(UpdateUnitStatus);

  const handleClick = async (e, ready = false) => {
    setIsFormSubmitted(true);
    const { year, month, day, hour, minute, second, timezone } = createNeo4jDateTime();
    try {
      if (ready) {
        setModalHeader('viewListing');
        setModalBody('viewListing');
        setModalFooter('viewListing');
      } else {
        await updateUnitStatus({
          variables: {
            id: houseUnitId,
            status: 'Inactive',
            updatedAt: {
              year,
              month,
              day,
              hour,
              minute,
              second,
              timezone,
            },
          },
        });
        await history.push('/housing/');
      }
      setIsFormSubmitted(false);
    } catch (error) {
      setIsFormSubmitted(false);
      console.error('Could not update: ', error);
    }
  };

  const handleCheckboxClick = () => {
    setIsChecked(!isChecked);
  };

  const handleSubmitUnit = async () => {
    const { year, month, day, hour, minute, second, timezone } = createNeo4jDateTime();
    try {
      await updateUnitStatus({
        variables: {
          id: houseUnitId,
          status: 'Active',
          updatedAt: {
            year,
            month,
            day,
            hour,
            minute,
            second,
            timezone,
          },
        },
      });
      setIsFormSubmitted(false);
      setIsChecked(false);
    } catch (error) {
      setIsFormSubmitted(false);
      setIsChecked(false);
      console.error('Could not update: ', error);
    }
    await history.push('/housing/');
  };

  const handleViewListingClick = () => {
    setHasViewedListing(true);
  };

  const handleClose = () => {
    setModalHeader('default');
    setModalBody('default');
    setModalFooter('default');
    setHasViewedListing(false);
    setIsChecked(false);
    toggle();
  };

  const unitReadyHeader = 'Ready for matching?';
  const unitReadyBody = (
    <UnitReadyModalTextWrapper $bold $marginBottom="22px">
      <p>Required fields for matching are complete.</p>
      <p>Do you want to release this Unit for matching?</p>
    </UnitReadyModalTextWrapper>
  );
  const unitReadyFooter = (
    <>
      <UnitReadyModalBtn
        disabled={isFormSubmitted || loading || error}
        $unfilled
        onClick={handleClick}
        id={'not-ready-for-match-button'}
      >
        No, Unit Not Ready
      </UnitReadyModalBtn>
      <UnitReadyModalBtn
        disabled={isFormSubmitted || loading || error}
        $filled
        onClick={(_) => handleClick(_, true, false)}
        id={'ready-for-match-button'}
      >
        Yes, it is Ready
      </UnitReadyModalBtn>
    </>
  );

  const viewListingHeader = 'Review Listing Page';
  const viewListingBody = (
    <UnitReadyModalTextWrapper>
      <p>Please check your new unit&#39;s listing page before releasing this unit for matching.</p>
      <p>High quality listings generate more interest and lead to better outcomes.</p>
    </UnitReadyModalTextWrapper>
  );
  const viewListingFooter = (
    <>
      <UnitReadyModalBtn
        as="a"
        href={`/brochure?housingId=${houseUnitId}`}
        target="_blank"
        $unfilled
        onClick={handleViewListingClick}
        id={'view-listing-button'}
      >
        View Listing
      </UnitReadyModalBtn>

      <div>
        <Checkbox
          checked={isChecked}
          onClick={handleCheckboxClick}
          name={`checkbox-${houseUnitId}`}
          id={`checkbox-${houseUnitId}`}
        />
        I&#39;ve reviewed the link and it&#39;s ready to share
      </div>

      {isChecked && (
        <div className="submit-for-matching-footer">
          <h3>Let&#39;s get it out there! </h3>
          <UnitReadyModalBtn disabled={!isChecked} $filled onClick={handleSubmitUnit} id={'submit-for-match-button'}>
            Submit for Unit Matching
          </UnitReadyModalBtn>
        </div>
      )}
    </>
  );

  return (
    <UnitReadyModal isOpen={modal} centered backdropClassName="customDrop" id={'unit-ready-modal'}>
      <GlobalStyle />
      <UnitReadyModalHeader toggle={handleClose}>
        {modalHeader === 'default' && unitReadyHeader}
        {modalHeader === 'viewListing' && viewListingHeader}
      </UnitReadyModalHeader>
      <UnitReadyModalBody>
        {loading ? (
          <LoadingSpinner isStatic={true} />
        ) : (
          <LargeText>
            {modalBody === 'default' && unitReadyBody}
            {modalBody === 'viewListing' && viewListingBody}
          </LargeText>
        )}
        {error && <ErrorToast />}
      </UnitReadyModalBody>
      <UnitReadyModalFooter $flexDirectionColumn={modalFooter === 'viewListing' ? true : false}>
        {modalFooter === 'default' && unitReadyFooter}
        {modalFooter === 'viewListing' && viewListingFooter}
      </UnitReadyModalFooter>
    </UnitReadyModal>
  );
}
