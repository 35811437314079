import styled from 'styled-components';
import { borders, typography } from '../styles/constants';
import { Input } from 'reactstrap';

export const filterDropdownToggleStyle = {
  color: typography.fontColor.btnColor,
  border: 'none',
  background: 'none',
  outline: 'none',
  fontSize: '15px',
  marginLeft: '-5px',
};

export const filterDropdownMenuStyle = {
  border: borders.filterMenuBorder,
  boxShadow: borders.filterMenuBoxShadow,
  marginTop: '8px',
};

export const filterDropdownFormStyle = { padding: '10px' };

export const DropdownInput = styled(Input)`
  color: black;
  &:focus,
  &:active {
    border-color: #5bb8b4 !important;
    box-shadow: none !important;
  }
`;
