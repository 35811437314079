import React, { useEffect, useState } from 'react';
import { ModalHeader, ModalBody, Spinner } from 'reactstrap';
import { GlobalStyle } from './AssignParsModalStyles';
import { UnitReadyModal, UnitReadyModalFooter, UnitReadyModalBtn } from './UnitCompleteModalStyles';
import PropTypes from 'prop-types';

function ConfirmModal({
  confirmationText,
  submittedMsg,
  helperText,
  modalOpen,
  toggleModalOpen,
  handleConfirm,
  actionType,
  children,
  waitForLoad,
  isLoading,
  isError,
}) {
  const [submitted, setSubmitted] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const id = `confirm-modal${confirmationText.replace(' ', '').toLowerCase()}`;

  useEffect(() => {
    if (isLoading) {
      setShowLoading(true);
    } else {
      setShowLoading(false);
    }
  }, [isLoading]);

  const handleConfirmClick = () => {
    setSubmitted(true);
    {
      if (!isLoading) {
        if (actionType) {
          handleConfirm(actionType);
        } else {
          handleConfirm();
        }
      }
      if (!waitForLoad) {
        toggleModalOpen(false);
      }
    }
  };

  return (
    <>
      <UnitReadyModal id={id} size="md" centered backdropClassName="customDrop" isOpen={modalOpen}>
        {' '}
        <GlobalStyle />
        <ModalHeader className="text-center">{confirmationText}</ModalHeader>
        {children && <ModalBody>{children}</ModalBody>}
        <div style={{ padding: '10px 40px 40px', textAlign: 'center' }}>{helperText || ''}</div>
        <UnitReadyModalFooter>
          <UnitReadyModalBtn $unfilled onClick={() => toggleModalOpen(false)} id="cancel">
            Cancel
          </UnitReadyModalBtn>
          <UnitReadyModalBtn
            $filled
            onClick={() => {
              handleConfirmClick();
            }}
            disabled={false}
            id="affirm"
          >
            {showLoading && (
              <>
                <Spinner style={{ width: '1rem', height: '1rem' }} /> {submittedMsg}
              </>
            )}
            {!submitted && <>&nbsp;Yes, I&apos;m sure</>}
          </UnitReadyModalBtn>
        </UnitReadyModalFooter>
      </UnitReadyModal>
    </>
  );
}

ConfirmModal.propTypes = {
  confirmationText: PropTypes.string.isRequired,
  submittedMsg: PropTypes.string.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  toggleModalOpen: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  actionType: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default ConfirmModal;
