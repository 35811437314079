import React from 'react';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-24px.svg';
import { DescModal } from './DescriptionModalStyles';
import { ModalBody } from 'reactstrap';
import { LargeText } from '../../styles/text';
import HouseExampleExterior from '../../assets/img/eg_home_trinity-nguyen-xQhqS7OWEqE-unsplash.jpg';
import HouseExampleInterior from '../../assets/img/eg_home_yehleen-gaffney-raxI_EcyfGw-unsplash.jpg';
import PropTypes from 'prop-types';

//TODO: awaiting finalized example text and pics
const d1 =
  "Corner unit filled with light and views of the San Bernadino mountains and DTLA! Move in ready with a ton of storage. Perched at the top of The Palms condominiums this unit is adjacent to walking trails galore and offers a beautiful community pool, gym, and clubhouse. A gated quiet community you'll never want to leave!";
const d2 =
  'Private room available in a modern apartment. The residence features furnished common areas and kitchen essentials. Walking distance to neighborhood shopping center and laundry-mat. 5 minute walk from public transit. Front yard with a private entrance.';

function DescriptionModal({ modalOpen, toggleModal }) {
  const id = `description-modal`;

  return (
    <>
      <DescModal id={id} size="lg" centered isOpen={modalOpen} toggle={toggleModal}>
        <CloseIcon
          onClick={toggleModal}
          style={{
            position: 'absolute',
            top: '18px',
            right: '21px',
            border: 'none',
            outline: 'none',
            fill: '#939393',
            width: '33px',
            height: '33px',
          }}
        />
        <ModalBody>
          <LargeText style={{ textAlign: 'center' }} fontSize={32} bold>
            Unit Description Examples
          </LargeText>
          <div style={{ marginTop: '50px' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '30px' }}>
              <img src={HouseExampleInterior} width="130" height="125" style={{ minWidth: '125px' }}></img>
              <p style={{ marginLeft: '25px' }}>{d1}</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <img src={HouseExampleExterior} width="130" height="125" style={{ minWidth: '125px' }}></img>
              <p style={{ marginLeft: '25px' }}>{d2}</p>
            </div>
          </div>
        </ModalBody>
      </DescModal>
    </>
  );
}

DescriptionModal.propTypes = {
  headerText: PropTypes.string.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default DescriptionModal;
