import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ReactEditor } from 'slate-react';
import useSlate from '../../../components/useSlate';
import { FormNavBar } from '../../navbars/FormNavBar';
import FormButton from '../../buttons/FormButton';
import ViewingOptionsDisplay from '../components/ViewingOptionsDisplay';
import { NotesPanel } from '../../panels/notes';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { AddNoteGQL, DeleteHousingNote, EditHousingNote } from '../../panels/notes/HousingNoteGQL';
import {
  CreateViewingNote,
  DeleteViewingNote,
  UpdateViewingNote,
  UpdateHousingUnitHoldDeadline,
} from '../components/ViewingOptionsGQL';
import { QueryHousingUnitByIdRequiredData } from '../QueryHousingByIdGQL';
import { QueryHousingAccessible } from './AccessibilityGQL';
import { TextField } from '@material-ui/core';
import { HoldDeadlineLabel } from '../components/ViewingOptionsStyles';
import { convertToNeo4jDateTime } from '../../../utilities/datetimeHelpers';
import Tooltip from '../../../components/tooltip/index';
import InfoToolTipImg from '../../../assets/icons/Icon-Info-tooltip.svg';
import { InfoToolTip } from '../../panels/ParticipantHUMatches/ParticipantHUMatchPanelStyles';
import { HoldDeadlineContainer } from '../../HousingUnitCreation/components/ViewingOptionsStyles';

const SlateBaseParagraph = (props) => {
  return (
    <div style={{ overflow: 'wrap' }} {...props}>
      {props.children}
    </div>
  );
};

const ViewingOptions = ({ toggle, isUnitActive, completedSections }) => {
  const history = useHistory();
  const today = moment().format('YYYY-MM-DD');
  const { houseUnitId } = useParams();
  const [currentUser, setCurrentUser] = useState([]);
  const [notes, setNotes] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [viewingNotes, setViewingNotes] = useState([]);
  const [viewingNoteContent, setViewingNoteContent] = useState('');
  const [showAddBtn, setShowAddBtn] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selecteNoteId, setSelectedNoteId] = useState(null);
  const [showInputPanel, setShowInputPanel] = useState(true);
  const [isOtherSectionsComplete, setIsOtherSectionsComplete] = useState(false);
  const [currentHoldDeadline, setCurrentHoldDeadline] = useState(null);
  const [selectedHoldDeadline, setSelectedHoldDeadline] = useState(null);
  const { value, setValue, reset, Editor, editor } = useSlate({
    initialState: [{ type: 'paragraph', children: [{ text: '' }] }],
    DefaultElement: SlateBaseParagraph,
    id: 'viewing-options-panel',
  });
  const { data: QHUAccessibleData, refetch: refetchAll } = useQuery(QueryHousingAccessible, {
    variables: { id: houseUnitId },
  });
  const { data: QHURequiredData, error, refetch } = useQuery(QueryHousingUnitByIdRequiredData, {
    variables: { id: houseUnitId },
  });

  const [createViewingNote] = useMutation(CreateViewingNote, {
    onCompleted() {
      refetch();
    },
  });
  const [deleteViewingNote] = useMutation(DeleteViewingNote, {
    onCompleted() {
      refetch();
    },
  });
  const [updateViewingNote] = useMutation(UpdateViewingNote, {
    onCompleted() {
      refetch();
    },
  });

  const [updateHousingUnitHoldDeadline] = useMutation(UpdateHousingUnitHoldDeadline, {
    onCompleted() {
      refetch();
    },
  });

  const checkOtherSections = () => {
    const { location, rooms, acceptances, accessibility } = completedSections;
    return location && rooms && acceptances && accessibility;
  };

  const handleSubmit = async (isSaveAndClose = false, isFixLater = false) => {
    setSubmitting(true);

    if (viewingNoteContent && !viewingNotes?.length) {
      await handleSaveViewing();
    }

    if (isEditing) {
      handleCancelViewingNote();
    }

    if (selectedHoldDeadline) {
      await updateHousingUnitHoldDeadline({
        variables: { id: houseUnitId, deadline: convertToNeo4jDateTime(selectedHoldDeadline) },
      });
    }

    if (isOtherSectionsComplete && !isUnitActive && (viewingNoteContent || viewingNotes?.length)) {
      toggle();
    } else if (isSaveAndClose || isFixLater || (!(isOtherSectionsComplete && viewingNoteContent) && isUnitActive)) {
      history.push('/housing/');
    }
  };

  const handleSaveViewing = async () => {
    setSubmitting(false);

    try {
      if (isEditing) {
        if (viewingNoteContent !== viewingNotes?.[0]?.content) {
          await updateViewingNote({ variables: { id: selecteNoteId, content: viewingNoteContent } });
        }
        setIsEditing(false);
        setShowInputPanel(false);
        setShowAddBtn(false);
      } else {
        await createViewingNote({ variables: { content: viewingNoteContent, noteFor: houseUnitId } });
        setIsEditing(false);
        setShowInputPanel(false);
        setShowAddBtn(false);
      }
      setTimeout(() => {
        ReactEditor.blur(editor);
        setValue([{ type: 'paragraph', children: [{ text: '' }] }]);
      }, 400);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteViewing = async (noteId) => {
    setSubmitting(false);
    try {
      await deleteViewingNote({ variables: { id: noteId } });
    } catch (error) {
      console.error(error);
    } finally {
      setValue([{ type: 'paragraph', children: [{ text: '' }] }]);
      setShowAddBtn(true);
      setShowInputPanel(false);
    }
  };

  const handleEditViewingNote = async (viewingNoteId) => {
    setSubmitting(false);
    const noteToEdit = viewingNotes.filter((note) => note.id === viewingNoteId);
    setSelectedNoteId(viewingNoteId);
    setShowInputPanel(!viewingNotes?.length);
    setIsEditing(true);
    setValue([{ children: [{ text: noteToEdit[0].content }] }]);
    autoFocus();
  };

  const handleCancelViewingNote = async () => {
    setSubmitting(false);
    ReactEditor.blur(editor);

    setValue([{ type: 'paragraph', children: [{ text: '' }] }]);
    if (isEditing) {
      setIsEditing(false);
      setShowInputPanel(false);
    } else {
      setShowInputPanel(false);
      setShowAddBtn(true);
    }
  };

  const handleAddTour = () => {
    setSubmitting(false);
    setShowInputPanel(true);
    setShowAddBtn(false);
    autoFocus();
  };

  const autoFocus = () => {
    setTimeout(() => {
      ReactEditor.focus(editor);
    }, 500);
  };

  const handleDateChange = (e) => {
    setSelectedHoldDeadline(moment(e.target.value));
  };

  useEffect(() => {
    if (QHUAccessibleData?.HousingUnit?.length && QHUAccessibleData?.Accessibility) {
      const { HousingUnit, me } = QHUAccessibleData;
      const housingUnit = HousingUnit[0];
      if (housingUnit) {
        setNotes(housingUnit.notes);
        if (me?.id) {
          setCurrentUser(me.id);
        }
      }
    }
  }, [QHUAccessibleData, setNotes]);

  useEffect(() => {
    setIsOtherSectionsComplete(checkOtherSections());
  }, [checkOtherSections, completedSections]);

  useEffect(() => {
    if (QHURequiredData) {
      setCurrentHoldDeadline(moment(QHURequiredData?.HousingUnit[0]?.deadline?.formatted, 'YYYY-MM-DDTHH:mm:ssZ'));
    }
  }, [QHURequiredData, selectedHoldDeadline, setSelectedHoldDeadline]);
  return (
    <Container>
      <FormNavBar onClick={handleSubmit} />
      <Col md={{ size: 12 }}>
        <h2 className="form-title center-block text-center">Viewing Options </h2>
      </Col>
      <Col md={{ size: 12 }}>
        <ViewingOptionsDisplay
          HURequiredData={{ QHURequiredData, error }}
          slate={{ value, reset, Editor }}
          state={{
            viewingNotes,
            viewingNoteContent,
            showAddBtn,
            isEditing,
            showInputPanel,
            setViewingNotes,
            setViewingNoteContent,
            setShowInputPanel,
          }}
          controls={{
            handleSaveViewing,
            handleEditViewingNote,
            handleCancelViewingNote,
            handleDeleteViewing,
            handleAddTour,
          }}
          SlateBaseParagraph={SlateBaseParagraph}
          setSubmitting={setSubmitting}
          autoFocus={autoFocus}
          submitting={submitting}
        />
        <HoldDeadlineContainer>
          <HoldDeadlineLabel>
            Hold Deadline <InfoToolTip src={InfoToolTipImg} id="holdDeadlineLabel" />
          </HoldDeadlineLabel>

          <Tooltip placement="right-end" target="holdDeadlineLabel">
            Enter the date by which the unit must be filled. It’s highly recommended that a hold fee be applied to the
            unit.
          </Tooltip>
          <p>Add the date by which the property owner requires move-in (often determined by a hold fee agreement)</p>
          <TextField
            value={selectedHoldDeadline?.format('yyy-MM-DD') || currentHoldDeadline?.format('yyy-MM-DD')}
            onChange={handleDateChange}
            inputProps={{ min: today }}
            type="date"
            className="hold-deadline"
          />
        </HoldDeadlineContainer>
      </Col>
      <div className="form-title center-block text-center">
        <FormButton
          completedSections={completedSections}
          error={(!isOtherSectionsComplete || (!viewingNoteContent && !viewingNotes?.length)) && submitting}
          id={'viewing-options'}
          submit={true}
          onClick={handleSubmit}
          status={isUnitActive}
        >
          <p> Unit can be submitted for matching once all required fields are completed.</p>
        </FormButton>
      </div>
      <Col>
        <NotesPanel
          forId={houseUnitId}
          currentUserId={currentUser}
          notes={notes}
          refetch={refetchAll}
          createNoteMutation={AddNoteGQL}
          deleteNoteMutation={DeleteHousingNote}
          editNoteMutation={EditHousingNote}
          align={'center'}
        />
      </Col>
    </Container>
  );
};

ViewingOptions.propTypes = {
  toggle: PropTypes.bool,
  isUnitActive: PropTypes.bool,
  completedSections: PropTypes.shape({
    location: PropTypes.bool,
    description: PropTypes.bool,
    rooms: PropTypes.bool,
    acceptances: PropTypes.bool,
    accessibility: PropTypes.bool,
    viewing: PropTypes.bool,
  }).isRequired,
};

export default ViewingOptions;
