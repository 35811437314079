import { gql } from '@apollo/client';

export const UnassignParticipant = gql`
  mutation RemoveAssignedParticipantFromHousingUnit($hu: ID!, $par: ID!, $reason: UnassignmentReason) {
    RemoveAssignedParticipantFromHousingUnit(housingUnit: $hu, participant: $par, reason: $reason) {
      id
    }
  }
`;

export const MoveOutParticipant = gql`
  mutation MoveOut($hu: ID!, $par: ID!, $moveoutReason: MoveOutReason!, $date: _Neo4jDateTimeInput!) {
    MoveOut(housingUnit: $hu, participant: $par, reason: $moveoutReason, date: $date, destination: UNKNOWN) {
      id
    }
  }
`;

export const GetParInfo = gql`
  query GetParInfo($id: ID!) {
    Participant(id: $id) {
      id
      name
      age
      gender {
        id
        name
      }
      movedIntoHousingUnits {
        id
      }
    }
  }
`;

export const SetMoveinDate = gql`
  mutation SetMoveInDate($participants: [ID!]!, $housingUnit: ID!, $date: _Neo4jDateTimeInput!) {
    SetMovedInDate(participants: $participants, housingUnit: $housingUnit, date: $date) {
      id
      movedInParticipants {
        id
        name
        createdAt {
          formatted
        }
        updatedAt {
          formatted
        }
      }
    }
  }
`;

export const UpdateMoveInDate = gql`
  mutation UpdateParticipantMOVED_IN_rel($participant: ID!, $housingUnit: ID!, $data: _MOVED_INInput!) {
    UpdateParticipantMOVED_IN_rel(from: { id: $participant }, to: { id: $housingUnit }, data: $data) {
      from {
        id
      }
      date {
        formatted
      }
    }
  }
`;
