import React, { useState } from 'react';
import { SortIcon } from '../icons/SortIcon';
import { SmallText } from '../../styles/text';
import { HeaderParRow, HCol, HeaderSortDiv, HeaderSortText } from './DashParticipantItemStyles';

export default function DashParticipantHeader({ sort, handleSort, handleReverse }) {
  const [sortDirection, setSortDirection] = useState(true);
  return (
    <HeaderParRow>
      <HCol md={{ size: 1 }}></HCol>
      <HCol md={{ size: 2 }} className="h-100">
        <HeaderSortDiv
          md="2"
          onClick={() => {
            handleSort('name');
            handleReverse();
            sort === 'name' ? setSortDirection(!sortDirection) : setSortDirection(true);
          }}
        >
          <HeaderSortText>Name</HeaderSortText>
          {sort === 'name' && <SortIcon direction={sortDirection} />}
        </HeaderSortDiv>
      </HCol>
      <HCol md="1">
        <SmallText>Age</SmallText>
      </HCol>
      <HCol md="2">
        <HeaderSortDiv
          md="2"
          onClick={() => {
            handleSort('advocate');
            handleReverse();
            sort === 'advocate' ? setSortDirection(!sortDirection) : setSortDirection(true);
          }}
        >
          <HeaderSortText>Advocate/Org</HeaderSortText>
          {sort === 'advocate' && <SortIcon direction={sortDirection} />}
        </HeaderSortDiv>
      </HCol>
      <HCol md="1">
        <div>
          <SmallText>Preferred</SmallText>
          <SmallText className="w-100 text-center">SPA</SmallText>
        </div>
      </HCol>

      <HCol md="2">
        <HeaderSortDiv
          onClick={() => {
            handleSort('status');
            handleReverse();
            sort === 'status' ? setSortDirection(!sortDirection) : setSortDirection(true);
          }}
        >
          <HeaderSortText>Status</HeaderSortText>
          {sort === 'status' && <SortIcon direction={sortDirection} />}
        </HeaderSortDiv>
      </HCol>

      <HCol md="2"></HCol>
      <HCol md="1"></HCol>
    </HeaderParRow>
  );
}
