import React from 'react';
import { ModalCloseButton } from '../modals/ModalStyles';
import { VMHModal, VMHModalHeader, VMHModalBackDrop } from '../HousingUnitCreation/components/ViewMatchHistoryStyles';
import ViewMatchHistory from '../HousingUnitCreation/components/ViewMatchHistory';
import PropTypes from 'prop-types';

const ViewMatchHistoryModal = ({ role, participant, open, close, handleChangeClick }) => {
  return (
    <VMHModalBackDrop open={open}>
      <VMHModal open={open} BackdropComponent={VMHModalBackDrop} onBackdropClick={close}>
        <ViewMatchHistory role={role} participant={participant} close={close} handleChangeClick={handleChangeClick} />
      </VMHModal>
    </VMHModalBackDrop>
  );
};

ViewMatchHistoryModal.propTypes = {
  role: PropTypes.shape({
    __typename: PropTypes.string.isRequired,
  }),
  participant: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  handleChangeClick: PropTypes.func.isRequired,
};
export default ViewMatchHistoryModal;
