export const sumArray = (arr) => {
  return arr.reduce((acc, v) => acc + (v || 0), 0);
};

export const minArray = (arr) => {
  return arr.reduce((acc, v) => {
    return acc < (v || Number.POSITIVE_INFINITY) ? acc : v || Number.POSITIVE_INFINITY;
  }, Number.POSITIVE_INFINITY);
};

export const maxArray = (arr) => {
  return arr.reduce((acc, v) => {
    return acc > (v || Number.NEGATIVE_INFINITY) ? acc : v || Number.NEGATIVE_INFINITY;
  }, Number.NEGATIVE_INFINITY);
};
