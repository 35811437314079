// non-complex hash (just character values)
// https://gist.github.com/hyamamoto/fd435505d29ebfa3d9716fd2be8d42f0#gistcomment-2775538
export function hashCode(s) {
  let h;
  for (let i = 0; i < s?.length; i++) h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
  return h;
}

// convert number to Hex, pad substring
// https://stackoverflow.com/a/3426956
export function colorFromHash(code) {
  var c = (code & 0x00ffffff).toString(16).toUpperCase();
  const color = 'FFFFFF'.substring(0, 6 - c.length) + c;
  return '#' + color;
}

export function colorFromName(name) {
  const n = hashCode(name);
  return colorFromHash(n);
}
