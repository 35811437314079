import { Col } from 'reactstrap';
import styled from 'styled-components';
import { borders } from '../styles/constants';

// height here is 560px because on a Surface screen, 643px is way below screen height
export const LeftListPanel = styled.div`
  border: ${borders.border};
  border-radius: ${borders.borderRadius};
  box-shadow: ${borders.boxShadow};
  margin: 0px;
  width: 100%;
  height: 560px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const TitleCol = styled(Col)`
  margin-top: 10px;
  margin-bottom: 10px;
`;
