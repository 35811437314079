import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Col, InputGroup, InputGroupAddon, InputGroupText, Label, Input, FormFeedback } from 'reactstrap';
import CounterField from '../../Inputs/CounterField.jsx';
import SelectMultiField from '../../buttons/SelectMultiField';
import FormButton from '../../buttons/FormButton';
import { HCenterDiv, RentRoomInput } from './RentRoomStyles';
import BedRoomDetailSection from '../components/BedRoomDetailSection';
import roomStateFromHousingUnit from '../../../utilities/stateProcessing/roomStateFromHousingUnit';
import housingUnitAmenitiesFromHousingUnit from '../../../utilities/stateProcessing/housingUnitAmenitiesFromHousingUnit';
import utilitiesFromHousingUnit from '../../../utilities/stateProcessing/utilitiesFromHousingUnit';
import { LocationDetailsFormGroup } from './LocationDetails';
import { QueryHousingLocation } from './LocationDetailGQL';
import locationStateFromHousingUnit from '../../../utilities/stateProcessing/locationStateFromHousingUnit.js';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import {
  CreateBedroom,
  DeleteBedroom,
  UpdateHousingUnit,
  GetOptionsHURent,
  AddAmenityToHousingUnit,
  RemoveAmenityFromHousingUnit,
  AddUtilityToHousingUnit,
  RemoveUtilityFromHousingUnit,
  AddAmenityToBedroom,
  RemoveAmenityFromBedroom,
  UpdateBedroom,
} from './RentRoomGQL';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { FormNavBar } from '../../navbars/FormNavBar';
import { isUrl, isRequired } from '../../../utilities/validators';
import UnitCompleteModal from '../../modals/UnitCompleteModal';
import { LoadingSpinner, ErrorToast, ContextFeedback } from '../../Alerts';
import { RemoveHousingUnitLISTS_rel } from '../../TableHousing/TableHousingGQL';

function RentRoom({ isUnitActive, completedSections, toggle, modal }) {
  const history = useHistory();
  const { houseUnitId } = useParams();

  // state
  const [formState, setFormState] = React.useState({
    bedrooms: [],
    bedroomsModified: [],
    removedBrs: [],
    totalBedrooms: 0,
    numBRRent: 0,
    totalBathrooms: 1,
    originalAmenities: [],
    amenitiesIncluded: [],
    originalUtilities: [],
    utilitiesIncluded: [],
    applicationFee: null,
  });
  // Options for  amenities and utilities
  const [amenityOptions, setAmenityOptions] = React.useState(new Map());
  const [utilityOptions, setUtilityOptions] = React.useState(new Map());
  const [validationState, setValidationState] = React.useState({});
  const [hasErrors, setHasErrors] = React.useState({
    bedroomErrors: false,
    urlErrors: false,
  });
  // Options and HU info
  const { data, error, loading } = useQuery(GetOptionsHURent, {
    variables: { id: houseUnitId },
  });

  // mutations for bedroom details
  const [createBedroom, { error: errorC, loading: loadingC }] = useMutation(CreateBedroom);
  const [deleteBedroom, { error: errorD, loading: loadingD }] = useMutation(DeleteBedroom);

  // mutations that take variables later
  const [updateHURoomRent] = useMutation(UpdateHousingUnit);
  const [addHousingUnitAmenity] = useMutation(AddAmenityToHousingUnit);
  const [addHousingUnitUtility] = useMutation(AddUtilityToHousingUnit);
  const [removeHousingUnitAmenity] = useMutation(RemoveAmenityFromHousingUnit);
  const [removeHousingUnitUtility] = useMutation(RemoveUtilityFromHousingUnit);
  const [addAmenityToBedroom] = useMutation(AddAmenityToBedroom);
  const [removeAmenityFromBedroom] = useMutation(RemoveAmenityFromBedroom);
  const [updateBedroom] = useMutation(UpdateBedroom);

  // Whatever is returned, we accepts. It's already in the correct format
  const onChange = (id, value) => {
    const newState = { ...formState, [id]: value };
    setHasErrors({
      bedroomErrors: false,
      urlErrors: false,
    });
    setFormState(newState);
  };

  const onChangeInput = (e) => {
    setHasErrors({
      bedroomErrors: false,
      urlErrors: false,
    });
    if (e?.currentTarget?.id) {
      const { id, value } = e.currentTarget;
      onChange(id, value);
    }
  };

  // Check Required Fields for errors
  const checkRequiredFields = async () => {
    const requiredErrors = {
      bedroomErrors: false,
    };

    for (const bedroom of formState?.bedrooms) {
      if (!bedroom.rent) {
        requiredErrors.bedroomErrors = true;
      }
    }

    for (const er in requiredErrors) {
      if (requiredErrors[er]) {
        setHasErrors(() => requiredErrors);
      }
    }
    return requiredErrors;
  };

  const generateEmpty = React.useCallback(
    () => ({
      id: null,
      index: 0,
      rent: 0,
      squareFeet: null,
      amenities: [],
      originalAmenities: [],
      createdAt: new Date(),
    }),
    [],
  );

  const addBedroom = () => {
    setHasErrors({
      bedroomErrors: false,
    });
    if (formState.bedrooms) {
      const newBrs = [generateEmpty(), ...formState.bedrooms].map((br, i) => ({
        ...br,
        index: i,
      }));
      const newBrsModified = [false, ...formState.bedroomsModified];
      setFormState({
        ...formState,
        bedrooms: newBrs,
        bedroomsModified: newBrsModified,
      });
    } else {
      setFormState({ ...formState, bedrooms: [generateEmpty()] });
    }
  };

  const removeBedroom = () => {
    setHasErrors({
      bedroomErrors: false,
    });
    if (formState.bedrooms?.length) {
      const newBrs = formState.bedrooms.slice(1).map((br, i) => ({ ...br, index: i }));
      const newRemovedBrs = [...(formState.removedBrs || []), formState.bedrooms[0]];
      setFormState((formState) => ({
        ...formState,
        bedrooms: newBrs,
        removedBrs: newRemovedBrs,
      }));
    }
  };

  const modifyBedroom = (brDetailState) => {
    setHasErrors({
      bedroomErrors: false,
    });
    const { index, id } = brDetailState;
    const bedrooms = formState.bedrooms;
    const bedroomsModified = formState.bedroomsModified;
    if (bedrooms && bedrooms[index].id === id) {
      bedrooms[index] = brDetailState;
      bedroomsModified[index] = true;
      setFormState((formState) => ({
        ...formState,
        bedrooms,
        bedroomsModified,
      }));
    }
  };

  const [queryHousingLocation, { data: dataH }] = useLazyQuery(QueryHousingLocation);
  // only trigger query on correct page
  React.useEffect(() => {
    if (houseUnitId) {
      queryHousingLocation({ variables: { id: houseUnitId } });
    }
  }, [houseUnitId, queryHousingLocation]);

  // Got HousingUnit
  React.useEffect(() => {
    if (dataH?.HousingUnit?.length) {
      const { HousingUnit } = dataH;
      const housingUnit = HousingUnit[0];
      if (housingUnit) {
        setFormState({ ...formState });
      }
    }
  }, [dataH, setFormState]);

  // Set up options for utilities and amenities
  React.useEffect(() => {
    if (data?.HousingUnit?.length) {
      const { HousingUnit, HousingUnitAmenity, Utility } = data;
      const housingUnit = HousingUnit[0];
      if (housingUnit) {
        const bedrooms = roomStateFromHousingUnit(housingUnit);
        setFormState((formState) => {
          return {
            ...formState,
            bedrooms: bedrooms.length ? bedrooms : [generateEmpty()],
            bedroomsModified: Array(bedrooms.length || 1).fill(false),
            removedBrs: [],
            numBRRent: bedrooms.length,
            amenitiesIncluded: housingUnitAmenitiesFromHousingUnit(housingUnit),
            originalAmenities: housingUnitAmenitiesFromHousingUnit(housingUnit),
            originalUtilities: utilitiesFromHousingUnit(housingUnit),
            utilitiesIncluded: utilitiesFromHousingUnit(housingUnit),
            totalBathrooms: housingUnit.totalBathrooms,
            totalBedrooms: housingUnit.totalBedrooms,
            applicationFee: housingUnit.applicationFee,
          };
        });
      }
      if (HousingUnitAmenity) {
        const newAmenityOptions = new Map();
        for (const amenity of HousingUnitAmenity) {
          if (amenity) {
            newAmenityOptions.set(amenity.type, amenity.id);
          }
        }
        setAmenityOptions(newAmenityOptions);
      }
      if (Utility) {
        const newUtilitiesOptions = new Map();
        for (const utility of Utility) {
          if (utility) {
            newUtilitiesOptions.set(utility.type, utility.id);
          }
        }
        setUtilityOptions(newUtilitiesOptions);
      }
    }
  }, [data]);

  const handleSubmit = React.useCallback(
    async (isSaveAndClose = false, isFixLater = false) => {
      let foundErrors = {};
      foundErrors = await checkRequiredFields();
      const location = completedSections?.location;
      const acceptances = completedSections?.acceptances;
      const accessibility = completedSections?.accessibility;
      const otherSectionsComplete = location && acceptances && accessibility;
      try {
        if (formState.amenitiesIncluded.length) {
          for (const amenityId of formState.amenitiesIncluded) {
            if (!formState.originalAmenities.includes(amenityId)) {
              // add the amenityId... no batch updates it seems
              await addHousingUnitAmenity({ variables: { from: { id: houseUnitId }, to: { id: amenityId } } });
            }
            for (const amenityId of formState.originalAmenities) {
              if (!formState.amenitiesIncluded.includes(amenityId)) {
                await removeHousingUnitAmenity({ variables: { from: { id: houseUnitId }, to: { id: amenityId } } });
              }
            }
          }
          if (formState.utilitiesIncluded.length) {
            for (const utilityId of formState.utilitiesIncluded) {
              if (!formState.originalUtilities.includes(utilityId)) {
                await addHousingUnitUtility({ variables: { from: { id: houseUnitId }, to: { id: utilityId } } });
              }
            }
            for (const utilityId of formState.originalUtilities) {
              if (!formState.utilitiesIncluded.includes(utilityId)) {
                await removeHousingUnitUtility({ variables: { from: { id: houseUnitId }, to: { id: utilityId } } });
              }
            }
          }
        }
      } catch (error) {
        console.error('Could not update', error);
      }
      if (!foundErrors?.bedroomErrors) {
        // So we can either programmatically update the values on the page as the person increases the bedrooms so too should the totalBedrooms increase
        // However, simpler and equally effective is to set them on save.
        // Bathrooms unchanged was NEVER given a value of 1! Because the check on data overwrites the 1 from the default value state
        const fstate = { ...formState };
        if (!fstate.totalBedrooms || fstate.totalBedrooms < fstate.bedrooms?.length) {
          fstate.totalBedrooms = formState.bedrooms?.length || 1;
        }
        if (!fstate.totalBathrooms) {
          fstate.totalBathrooms = 1;
        }
        try {
          const date = new Date();
          const dateYear = date.getFullYear();
          const dateMonth = date.getMonth() + 1;
          const dateDay = date.getDate();
          const dateHour = date.getHours();
          const dateMinute = date.getMinutes();
          const dateSecond = date.getSeconds();
          const timezone = moment.tz.guess(); // we weren't capturing the timezone before, just inputting a raw date at UTC
          await updateHURoomRent({
            variables: {
              id: houseUnitId,
              totalBedrooms: fstate.totalBedrooms,
              totalBathrooms: fstate.totalBathrooms,
              applicationFee: fstate.applicationFee,
              updatedAt: {
                day: dateDay,
                month: dateMonth,
                year: dateYear,
                hour: dateHour,
                minute: dateMinute,
                second: dateSecond,
                timezone,
              },
            },
          });
          for (const bedroom of formState.bedrooms || []) {
            if (formState.bedroomsModified[bedroom.index]) {
              // only update modified bedrooms
              let brId = bedroom.id;
              if (bedroom.id && !foundErrors?.bedroomErrors) {
                brId = bedroom.id;
                await updateBedroom({ variables: { id: brId, squareFeet: bedroom.squareFeet, rent: bedroom.rent } });
              } else if (bedroom.rent && !foundErrors?.bedroomErrors) {
                const { data, errors } = await createBedroom({
                  variables: { housingUnit: houseUnitId, squareFeet: bedroom.squareFeet, rent: bedroom.rent },
                });
                if (errors) {
                  // no brId for errors
                  console.error(errors);
                } else {
                  const { CreateBedroom } = data;
                  brId = CreateBedroom.id;
                }
              }
              if (brId) {
                for (const brAmenity of bedroom.amenities) {
                  if (!bedroom.originalAmenities.includes(brAmenity)) {
                    await addAmenityToBedroom({ variables: { bedroomId: brId, amenityId: brAmenity } });
                  }
                }
                for (const brAmenity of bedroom.originalAmenities) {
                  if (!bedroom.amenities.includes(brAmenity)) {
                    await removeAmenityFromBedroom({ variables: { bedroomId: brId, amenityId: brAmenity } });
                  }
                }
              }
            }
          }
          for (const bedroom of formState.removedBrs || []) {
            if (bedroom.id) {
              await deleteBedroom({ variables: { id: bedroom.id } });
            }
          }
        } catch (error) {
          console.error('Could not update ', error);
          foundErrors.bedroomErrors = true;
          setHasErrors(foundErrors);
        }
      }

      if (!isUnitActive && otherSectionsComplete && !foundErrors?.bedroomErrors && isSaveAndClose) {
        toggle();
      } else if ((!isSaveAndClose && !foundErrors?.bedroomErrors) || isFixLater) {
        history.push(`/housing/edit/${houseUnitId}/unit-acceptances`);
      } else if (isSaveAndClose) {
        history.push('/housing/');
      }
    },
    [formState, checkRequiredFields],
  );

  if (loadingC || loadingD || loading) {
    return <LoadingSpinner />;
  }

  if (!data) {
    return <ContextFeedback />;
  }
  if (errorC || errorD || error) {
    return <ErrorToast />;
  }
  return (
    <div>
      <FormNavBar onClick={handleSubmit} />
      <Col md={{ size: 12 }}>
        <h2 className="form-title center-block text-center">Rent / Room </h2>
        <div className="question-area text-center center-block">
          <h4 className="center-block text-center">Application fee </h4>
          <HCenterDiv>
            <InputGroup style={{ height: '64px', width: '50%' }} id="application-fee-group">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>$</InputGroupText>
              </InputGroupAddon>
              <RentRoomInput
                type="number"
                name="applicationFee"
                id="applicationFee"
                onChange={(e) => {
                  onChange('applicationFee', parseInt(e.currentTarget.value) * 100);
                }}
                value={formState.applicationFee ? `${formState.applicationFee / 100}` : ''}
                onBlur={(e) => {
                  onChange('applicationFee', parseInt(e.currentTarget.value) * 100);
                }}
              />
            </InputGroup>
          </HCenterDiv>
        </div>
        <div className="question-area">
          <h4 className="center-block text-center">Bedrooms Available for Shared Housing* </h4>
          <CounterField
            id="bedrooms"
            onDecrease={() => {
              removeBedroom();
            }}
            min={1}
            onIncrease={() => {
              addBedroom();
            }}
            value={formState.numBRRent || 1}
          />
        </div>
        <BedRoomDetailSection hasErrors={hasErrors} bedroomDetails={formState.bedrooms} modifyBedroom={modifyBedroom} />

        <div className="question-area">
          <h4 className="center-block text-center">Total Bedrooms In Unit </h4>
          <CounterField
            id="totalBedrooms"
            onIncrease={onChange}
            onDecrease={onChange}
            min={0}
            value={formState?.totalBedrooms}
          />
        </div>
        <div className="question-area">
          <h4 className="center-block text-center">Total Bathrooms In Unit </h4>
          <CounterField
            id="totalBathrooms"
            onIncrease={onChange}
            onDecrease={onChange}
            countBy={0.25}
            min={1}
            value={formState?.totalBathrooms}
          />
        </div>
        <div className="question-area">
          <h4 className="center-block text-center">Are any of the following utilities included?</h4>
          <SelectMultiField
            id="utilitiesIncluded"
            onChange={onChange}
            selections={utilityOptions}
            value={formState?.utilitiesIncluded || []}
          />
        </div>
        <div className="question-area">
          <h4 className="center-block text-center">Are any of the following amenities included?</h4>
          <SelectMultiField
            id="amenitiesIncluded"
            onChange={onChange}
            selections={amenityOptions}
            value={formState?.amenitiesIncluded || []}
          />
        </div>
        <LocationDetailsFormGroup style={{ display: 'none' }}>
          <Label for="listingUrl">Link to Listing*</Label>
          <Input
            id="listingUrl"
            type="url"
            bsSize="lg"
            onChange={onChangeInput}
            value={formState.listingUrl}
            valid={validationState.listingUrl}
            invalid={hasErrors?.urlErrors}
            onBlur={onChangeInput}
          />
          <FormFeedback>Required Field. Please enter a valid unique url.</FormFeedback>
        </LocationDetailsFormGroup>
      </Col>
      <div className="form-title center-block text-center">
        <FormButton
          id={'rent-room'}
          error={hasErrors?.bedroomErrors || hasErrors?.urlErrors}
          submit={false}
          onClick={handleSubmit}
        ></FormButton>
      </div>
      <UnitCompleteModal toggle={toggle} modal={modal} />
    </div>
  );
}

RentRoom.propTypes = {
  isUnitActive: PropTypes.bool.isRequired,
  completedSections: PropTypes.shape({
    location: PropTypes.bool,
    description: PropTypes.bool,
    rooms: PropTypes.bool,
    acceptances: PropTypes.bool,
    accessibility: PropTypes.bool,
    viewing: PropTypes.bool,
  }).isRequired,
  toggle: PropTypes.func.isRequired,
  modal: PropTypes.bool.isRequired,
};

export default RentRoom;
