export default {
  disabledButton: '#acdbd9',
  grayText: '#939393',
  green: '#2DD389',
  red: '#FF8181',
  hover: '#A5C9FF14',
  selected: '#5BB8B4',
  filterTextField: '#C2C2C280',
  accentColor: '#a5c9ff',
  whiteShade: '#ffffff',
  alertBgColor: '#ABC9FA3B',
  blueShade: '#f0f5fe',
  grayText: '#707070',
  darkBlue: '#103562',
  lightBlueGreen: '#31A3A5',
  gray: '#818181',
  blue: '#06446a',
  lightBlueish: '#f7fcff',
};
