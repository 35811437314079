import React from 'react';
import Avatar from 'react-avatar';
import FontAwesome from 'react-fontawesome';
import { links } from '../../styles/constants';
import { LargeText, MediumText } from '../../styles/text';
import { Link } from 'react-router-dom';
import { colorFromName } from '../../utilities/hash';
import { ParRow, VCenterCol, CtaGroup } from './DashParticipantItemStyles';
import { RecordInterestBtn } from '../buttons/ButtonStyles';

const assignedNotifiedOrInterested = function (
  assignedHousingUnits,
  interestedIn,
  notifiedHousingUnits,
  movedIntoHousingUnits,
  status,
) {
  if (status?.type === 'Inactive') {
    return 'Inactive';
  }
  if (movedIntoHousingUnits?.length) {
    return `Moved In`;
  }
  if (assignedHousingUnits?.length) {
    return `Assigned`;
  }
  if (interestedIn?.length) {
    return `Interested in ${interestedIn.length} Unit${interestedIn.length > 1 ? 's' : ''}`;
  }
  if (notifiedHousingUnits?.length) {
    const plural = notifiedHousingUnits.length > 1;
    return `Sent ${plural ? `${notifiedHousingUnits.length}` : ''} Unit Match${plural ? `es` : ''}`;
  }
  return '--';
};

export default function DashParticipantItem({ participant }) {
  const {
    id,
    name,
    age,
    gender,
    status,
    ADVOCATES_FOR_rel,
    preferredRegions,
    assignedHousingUnits,
    interestedIn,
    disinterestedIn,
    notifiedHousingUnits,
    movedIntoHousingUnits,
  } = participant;

  const [showRIBtn, setShowRIBtn] = React.useState(false);

  React.useEffect(() => {
    let parInterestCount;
    let parDisInterestCount;
    let parNotifiedCount;
    if (participant) {
      parNotifiedCount = participant?.notifiedHousingUnits?.length || 0;
      parInterestCount = participant?.interestedIn?.length || 0;
      parDisInterestCount = participant?.disinterestedIn?.length || 0;
    }
    setShowRIBtn(parNotifiedCount > parDisInterestCount + parInterestCount);
  }, [participant]);

  return (
    <ParRow $opaque={status?.type !== 'Active'}>
      <VCenterCol md={{ size: 1 }}>
        <Avatar color={colorFromName(name)} size="54" round name={name} />
      </VCenterCol>
      <VCenterCol md={{ size: 2 }} className="h-100">
        <div>
          <MediumText>{name}</MediumText>
        </div>
      </VCenterCol>
      <VCenterCol md="1">
        <MediumText>{age} yrs </MediumText>
      </VCenterCol>
      <VCenterCol md="2">
        {ADVOCATES_FOR_rel?.[0]?.Advocate?.name && (
          <div>
            <MediumText>{ADVOCATES_FOR_rel[0].Advocate.name} / </MediumText>
            <MediumText>{ADVOCATES_FOR_rel?.[0]?.Advocate?.organization?.name}</MediumText>
          </div>
        )}
      </VCenterCol>
      <VCenterCol md="1">
        <div>
          <MediumText>
            {preferredRegions
              .map((pr) => pr?.name?.split(' ')[1])
              .sort()
              .join(', ')}
          </MediumText>
        </div>
      </VCenterCol>
      <VCenterCol md="2">
        <div>
          <MediumText>
            {assignedNotifiedOrInterested(
              assignedHousingUnits,
              interestedIn,
              notifiedHousingUnits,
              movedIntoHousingUnits,
              status,
            )}
          </MediumText>
        </div>
      </VCenterCol>
      <VCenterCol md="3" style={{ justifyContent: 'flex-end' }}>
        <CtaGroup>
          {showRIBtn && <RecordInterestBtn to={`/participant/detail/${id}`}>Record Interest</RecordInterestBtn>}
          <Link to={`/participant/detail/${id}`}>
            <LargeText className="w-100 text-right pointer" color={links.regularLink}>
              <FontAwesome name="chevron-right" />
            </LargeText>
          </Link>
        </CtaGroup>
      </VCenterCol>
    </ParRow>
  );
}
