import React from 'react';
import styled from 'styled-components';
import FontAwesome from 'react-fontawesome';
import { colors } from '../../styles/constants';

export const IconContainer = styled.div`
  background-color: ${({ backgroundColor }) => (backgroundColor ? mapColors[backgroundColor] : 'black')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '13px')};
  height: ${({ width }) => (width ? width : '18px')};
  width: ${({ width }) => (width ? width : '18px')};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

const mapColors = {
  white: colors.whiteShade,
  green: colors.selected,
  red: colors.red,
  transparent: 'transparent',
};

export const FontAwesomeIcon = styled(FontAwesome)`
  color: ${({ color }) => (color ? mapColors[color] : 'white')};
`;

export const FAIconContainer = ({ color, backgroundColor, width, iconName, fontSize }) => {
  return (
    <>
      <IconContainer
        className={`container-${iconName}`}
        backgroundColor={backgroundColor}
        width={width}
        fontSize={fontSize}
      >
        <FontAwesomeIcon className={`icon-${iconName}`} color={color} name={iconName} />
      </IconContainer>
    </>
  );
};
