import { gql } from '@apollo/client';

export const DeleteUnitGQL = gql`
  mutation DeleteUnit($id: ID!) {
    DeleteHousingUnit(id: $id) {
      id
    }
  }
`;

export const RemoveHousingUnitLISTS_rel = gql`
  mutation RemoveHousingUnitLISTS_rel($from: _ListingInput!, $to: _HousingUnitInput!) {
    RemoveHousingUnitLISTS_rel(from: $from, to: $to) {
      from {
        id
      }
      to {
        id
      }
    }
  }
`;

export const ArchiveUnit = gql`
  mutation ArchiveUnit($id: ID!) {
    UpdateHousingUnit(id: $id, status: Archived) {
      id
      name
      status
    }
  }
`;

export const ReactivateUnit = gql`
  mutation ReactivateUnit($id: ID!) {
    UpdateHousingUnit(id: $id, status: Active) {
      id
      name
      status
    }
  }
`;
