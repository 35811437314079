import React from 'react';
import styled from 'styled-components';
import FontAwesome from 'react-fontawesome';
import { Col } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { typography } from '../styles/constants';

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${typography.fontColor.white};
  .isDisabled {
    color: ${typography.fontColor.white};
    font-weight: ${typography.fontWeight.light};
    opacity: 0.5;
    text-decoration: none;
  }
  .add.active.isDisabled {
    opacity: 1;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  font-size: 13px;

  &.add {
    font-size: 8px;
  }

  &.incomplete {
    background-color: #ff8181;
    border-radius: 50%;
    width: 18px;
    height: 18px;
  }

  &[class*='add'] {
    background-color: transparent;
  }
`;

export const GreenFontAwesomeIcon = styled(FontAwesome)`
  color: #5bb8b4;
`;

export const WhiteFontAwesomeIcon = styled(FontAwesome)`
  color: ${typography.fontColor.white};
`;

const SectionCol = styled(Col)`
  padding-left: 0;
  padding-right: 0;
`;

export const TopicLink = ({ path, complete, houseUnitId, children }) => {
  const { pathname } = useLocation();
  const completedSection = complete ? 'complete' : 'incomplete';
  const add = !houseUnitId;
  const activeLoc = pathname.includes(path) ? 'active' : 'isDisabled';
  const completeActiveLoc = complete && pathname.includes(path) ? 'active-complete' : '';
  const [fontIcon, setFontIcon] = React.useState('circle');

  React.useEffect(() => {
    if (completeActiveLoc || complete) {
      setFontIcon('check');
    } else if (add && !complete) {
      setFontIcon('circle');
    } else if (!add && !complete) {
      setFontIcon('exclamation');
    }
  }, [complete, pathname, path]);

  if (houseUnitId) {
    return (
      <>
        <li>
          <LinkContainer>
            <SectionCol md="2">
              <IconContainer className={`${completedSection} ${completeActiveLoc}`}>
                {complete ? <GreenFontAwesomeIcon name={fontIcon} /> : <WhiteFontAwesomeIcon name={fontIcon} />}
              </IconContainer>
            </SectionCol>
            <SectionCol md="10">
              <Link className={`${activeLoc}`} to={`/housing/edit/${houseUnitId}/${path}`}>
                {children}
              </Link>
            </SectionCol>
          </LinkContainer>
        </li>
      </>
    );
  } else {
    return (
      <>
        <li>
          <LinkContainer>
            <SectionCol md="2">
              <IconContainer className={`add ${completedSection} ${completeActiveLoc}`}>
                <WhiteFontAwesomeIcon name={fontIcon} />
              </IconContainer>
            </SectionCol>
            <SectionCol md="10" className={`add ${add ? 'isDisabled' : ''} ${activeLoc}`}>
              {children}
            </SectionCol>
          </LinkContainer>
        </li>
      </>
    );
  }
};
