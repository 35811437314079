import styled from 'styled-components';
import { typography, borders } from '../../styles/constants';
import { Modal, ModalFooter, Button, ModalHeader, ModalBody } from 'reactstrap';

export const UnitReadyModal = styled(Modal)`
  .modal-title {
    margin: 0 auto;
    font-size: ${typography.fontSize.title};
  }

  .modal-header {
    border: none;
  }

  .modal-header .close {
    padding: 0;
    margin: 0;
    outline: none;
    border: none;
  }

  &.modal-dialog {
    max-width: 600px !important;
  }
`;

export const LargeText = styled.div`
  font-size: ${typography.fontSize.lgtext};
  color: ${typography.fontColor.text};

  p {
    text-align: center;
  }

  span {
    font-weight: bold;
  }
`;

export const UnitReadyModalBody = styled(ModalBody)`
  padding: 0;
`;
export const UnitReadyModalTextWrapper = styled.div`
  margin-bottom: 10px;
  & p {
    ${(props) => (props.$marginBottom ? `margin-bottom: ${props.$marginBottom};` : 'margin-bottom: 0px;')}
  }
  & p:nth-child(2) {
    ${(props) => (props.$bold ? 'font-weight: bold;' : '')}
  }
`;
export const UnitReadyModalHeader = styled(ModalHeader)``;
export const UnitReadyModalFooter = styled(ModalFooter)`
  justify-content: center;
  border: none;
  padding: 0;
  flex-direction: ${(props) => (props.$flexDirectionColumn ? 'column' : 'row')};

  & button[name*='checkbox'] {
    margin-right: 14px;
  }

  & a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
    width: 200px;
    min-height: 50px;
    margin: 25px 0;
  }

  & .submit-for-matching-footer {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 10px;
  }

  & .submit-for-matching-footer h3 {
    font-size: 20px;
  }

  & .submit-for-matching-footer button {
    min-height: 48px;
    min-width: 320px;
  }

  & .submit-for-matching-footer button:active,
  & .submit-for-matching-footer button:focus {
  }
`;

export const UnitReadyModalBtn = styled(Button)`
  font-family: ${typography.fontFamily.regular};
  font-size: ${typography.fontSize.lgtext};
  color: ${(props) => (props.$unfilled ? typography.fontColor.btnColor : '#fff')};
  background-color: ${(props) => (props.$filled ? '#5bb8b4' : '#fff')};
  border-radius: 8px;
  border: 1px solid #5bb8b4;
  padding: 6px 12px;
  outline: none !important;

  &:active,
  &:focus {
    color: ${(props) => (props.$unfilled ? typography.fontColor.btnColor : '#fff')} !important;
    background-color: ${(props) => (props.$filled ? '#57A8A5' : '#fff')} !important;
    border: 1px solid #5bb8b4 !important;
    box-shadow: none !important;
  }

  &:hover {
    color: ${(props) => (props.$unfilled ? typography.fontColor.btnColor : '#fff')};
    background-color: ${(props) => (props.$filled ? '#57A8A5' : '#A5C9FF14')};
    border: 1px solid #5bb8b4;
  }
`;
