import React, { useState, useEffect } from 'react';

import { Row, FormGroup, Label, Col, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { RoomInput } from './bedroomDetailsStyles';
import SelectMultiField from '../../buttons/SelectMultiField';
import PropTypes from 'prop-types';

function BedRoomDetail({ hasErrors, roomDetails, amenities, modifyBedroom, id }) {
  const [formState, setFormState] = useState(
    roomDetails || {
      amenities: Array(),
      originalAmenities: Array(),
      squareFeet: 0,
      rent: 0,
    },
  );
  const [amenityOptions, setAmenityOptions] = useState(new Map());

  useEffect(() => {
    if (amenities) {
      setAmenityOptions(amenities);
    }
  }, [amenities]);

  useEffect(() => {
    if (roomDetails) {
      setFormState(roomDetails);
    }
  }, [roomDetails]);

  const onChangeAmenities = (id, value) => {
    const newState = { ...formState, amenities: value };
    setFormState(newState);
    modifyBedroom(newState);
  };

  const onChangeInput = (e) => {
    if (e?.currentTarget) {
      const { name, value } = e.currentTarget;
      const newState = {
        ...formState,
        [name]: parseInt(value),
      };
      setFormState(newState);
    }
  };

  const handleBlur = (e) => {
    if (e?.currentTarget) {
      const { name, value } = e.currentTarget;
      const newState = {
        ...formState,
        [name]: parseInt(value),
      };
      setFormState(newState);
      modifyBedroom(newState);
    }
  };

  return (
    <div className="bedroom-detail justify-content-center">
      <h4 className="center-block text-center">Bedroom {roomDetails?.index ? roomDetails?.index + 1 : 1} Details</h4>
      {hasErrors?.bedroomErrors && !formState?.rent ? (
        <div className="invalid-feedback" style={{ display: 'block', textAlign: 'center' }}>
          Required Field. Please enter an amount.
        </div>
      ) : null}
      <Row form className="justify-content-center">
        <Col md={3}>
          <Label for="roomRent">Room Rent*</Label>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>$</InputGroupText>
            </InputGroupAddon>
            <RoomInput
              type="number"
              name="rent"
              value={formState?.rent ? `${formState.rent}` : ''}
              valid={null}
              invalid={hasErrors?.bedroomErrors && !formState?.rent}
              onChange={onChangeInput}
              onBlur={handleBlur}
              id={`input-br-rent-${id}`}
            />
          </InputGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label for="squareFeet">Sq Ft.</Label>
            <RoomInput
              type="number"
              min={0}
              name="squareFeet"
              value={formState?.squareFeet ? `${formState?.squareFeet}` : ''}
              onChange={onChangeInput}
              onBlur={handleBlur}
              id={`input-br-sqft-${id}`}
            />
          </FormGroup>
        </Col>
      </Row>
      <h4 className="center-block text-center">Bedroom {roomDetails?.index ? roomDetails?.index + 1 : 1} Amenities</h4>
      <Row form className="justify-content-center">
        <SelectMultiField
          id="amenities"
          value={formState.amenities}
          selections={amenityOptions}
          onChange={onChangeAmenities}
        />
      </Row>
    </div>
  );
}

BedRoomDetail.propTypes = {
  hasErrors: PropTypes.shape({
    bedroomErrors: PropTypes.boolean,
    urlErrors: PropTypes.boolean,
  }),
  roomDetails: PropTypes.shape({
    id: PropTypes.string,
    index: PropTypes.number,
    rent: PropTypes.number,
    squareFeet: PropTypes.number,
    amenities: PropTypes.array,
    originalAmenities: PropTypes.array,
    createdAt: PropTypes.instanceOf(Date),
  }),
  amenities: PropTypes.instanceOf(Map),
  modifyBedroom: PropTypes.func,
  id: PropTypes.number,
};

export default BedRoomDetail;
