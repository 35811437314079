import { gql } from '@apollo/client';

export const ParticipantPossibleMatches = gql`
  query ParticipantPossibleMatches($par: [ID!]!, $hu: ID!) {
    PossibleMatches(housingUnit: $hu, participants: $par) {
      participants {
        id
      }
      score
    }
  }
`;

export const ParticipantsInterestedInUnit = gql`
  query ParticipantsInterestedInUnit($houseUnitId: ID) {
    HousingUnit(id: $houseUnitId) {
      id
      bedrooms {
        id
        rent
      }
      name
      location
      assignedParticipants {
        id
        name
        age
        gender {
          name
        }
      }
      interestedParticipants {
        __typename
        row
        name
        id
        age
        HMISID
        HMnames
        income
        currentIncome
        potentialIncome
        comfortWithHousemates
        understandsSharedHousing
        fiveYearGoal
        personalityTalkToStrangers
        personalityLendFood
        personalityPayBills
        timeUnhoused
        currentLivingSituation
        housingHistory
        substanceHistory
        medicalHistory
        traumaHistory
        otherHistory
        preferredRegions {
          id
          name
        }
        accessibilitiesWithDegree {
          degree
        }
        maxHousematesPref
        convictions {
          type
        }
        evictions
        dryHomePref
        dryHomePreference
        tobaccoRejectionDegree
        alcoholToleranceDegree
        alcoholConsumptionDegree
        hardDrugsAcceptance
        struggledRecency
        struggledWithSubstances {
          text_en
        }
        spokenLanguages {
          text_en
        }
        raceEthnicity {
          name
        }
        sexualOrientation {
          description
        }
        religion {
          name
        }
        tobaccoRejectionDegree
        marijuanaRejectionDegree
        ADVOCATES_FOR_rel(orderBy: createdAt_desc, first: 1) {
          Advocate {
            name
            id
            organization {
              name
            }
          }
        }
        ageRangePreference {
          low
          high
        }
        personalityPrefersCleanliness
        personalityPrefersRelation
        gender {
          id
          name
        }
        genderAversions {
          name
        }
        sexualOrientationAversions {
          id
          description
        }
        genderPref {
          name
        }
        religionAversions {
          name
        }
        daytimeGuestsTolerated
        nighttimeGuestsTolerated
        genderPref {
          id
          name
        }
        pets {
          id
          size
          species
        }
        raceEthnicityAversions {
          name
        }
        smokingIndoorsNeeded
        tobaccoPreference
        marijuanaPreference
        marijuanaSmokingIndoors
        tobaccoSmokingIndoors
        dryHomePref
        petsAversions {
          id
          size
          species
        }
        hobbies {
          type
        }
        bedtime
        noiseSensitivityDegree
        personalityKitchenSharing
        meatInTheHomeRejection
        daytimeGuestsPlanned
        nighttimeGuestsPlanned
        personalityFreeSpirit
        personalityPrefersCompany
        isSexOffender
        vouchers {
          type
        }
        politicsIntensity
        politicalInterestsWithScore {
          id
          type
          interest
          relType
        }
        politicsAversion {
          id
          type
        }
        politicsLeaning {
          id
          type
        }
        parkingNeed
        parkingPreference
      }
    }
  }
`;
