import styled from 'styled-components';
import { Col } from 'reactstrap';
import { typography, borders } from '../../styles/constants';

export const PanelContainer = styled.div`
  padding: 15px 15px 45px 20px;
  margin: 0px;
`;

export const AssignedParCardWrapper = styled.div`
  margin: 0 15px;
`;

export const AssignedParCard = styled.div.attrs((props) => ({
  border: props.border ? '1px solid #A5C9FF' : null,
  boxShadow: props.boxShadow ? '0px 3px 16px #0A4B6D14' : null,
}))`
  display: flex;
  flex-direction: row;
  align-content: center;
  box-shadow: ${(props) => props.boxShadow};
  border: ${(props) => props.border};
  border-radius: 7px;
  opacity: 1;
  padding: 10px;
  margin-bottom: 25px;
  width: 430px;

  .details p {
    margin-bottom: 0;
  }

  &.empty {
    border: ${borders.borderEmpty};
    justify-content: center;
    align-items: center;
    min-height: 70px;
    max-height: 70px;
  }
`;

export const ParInfoPanel = styled.div`
  display: flex;
  flex-direction: column;

  & .par-name {
    margin-bottom: 0;
  }

  & .par-info-text {
    font-size: ${typography.fontSize.mdtext};
    color: ${typography.fontColor.grayText};
  }
`;

export const UnitFilledPanel = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #fafdff;
  border: 1px solid #a5c9fd;
  border-radius: 7px;
  opacity: 1;
  max-height: 247px;
`;

export const SidePanelDisplayCol = styled(Col)`
  margin-top: 15px;
`;

export const AssignedParAvatar = styled.div`
  align-self: center;
  margin-right: 8px;
`;

export const LabelCol = styled(Col)`
  margin-bottom: 25px;
`;
