import React, { useEffect, useState } from 'react';
import { CloudinaryImage } from '../../CloudinaryImage';
import Avatar from 'react-avatar';
import { Col, Row, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import HouseDefault from '../../../assets/img/house-default.png';
import InfoToolTipImg from '../../../assets/icons/Icon-Info-tooltip.svg';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import moment from 'moment-timezone';
import { useToggle } from '../../../utilities/hooks/index';
import {
  PanelContainer,
  HorizontalContainer,
  InformationDiv,
  StreetText,
  AvatarDiv,
  LinkItem,
  PanelCol,
  PanelRow,
  PanelGrid,
  PanelGridItem,
} from '../HousingStyles';
import {
  StatusIndicatorContainer,
  StatusIndicatorIcon,
  StatusIndicatorText,
  CardContainer,
  InfoToolTip,
  InfoToolTipContainer,
  HUNameLink,
  DateNotified,
} from './ParticipantHUMatchPanelStyles';
import { participantInterestInfoByNotifedHU } from '../../../utilities/participantStatusCheck';
import { LargeText, MediumLargeText } from '../../../styles/text';
import { sumArray, minArray, maxArray } from '../../../utilities/arrayOperations';
import InterestButtonComponent from '../../buttons/InterestButtons';
import { GreenFontAwesome, RedFontAwesome } from '../../../styles/text';
import { SelectedCard } from '../../../apollo/cache';
import { getMomentTimezoneObj } from '../../../utilities/datetimeHelpers';
import { deactivateOptionsEnumToStrDict } from '../../../constants/reassignOptions';
import PropTypes from 'prop-types';

export const ParticipantHUItem = ({ participant, refetch, role, handleChangeClick }) => {
  const reducedAccessRoles = ['Advocate', 'HousingLocator'];
  const userHasAccess = !reducedAccessRoles?.includes(role?.['__typename']);
  const [parInfoByHu, setParInfoByHu] = useState(null);
  const [toolTipOpen, setToolTipOpen] = useToggle(false);

  useEffect(() => {
    if (participant) {
      setParInfoByHu(participantInterestInfoByNotifedHU(participant));
    }
  }, [participant]);

  const getHuInfo = ({ bedrooms, SHOWS_rel, LISTS_rel, id }) => {
    const totalRent = sumArray(bedrooms?.map((b) => b?.rent));
    const minRent = minArray(bedrooms?.map((b) => b?.rent));
    const maxRent = maxArray(bedrooms?.map((b) => b?.rent));

    const hasImage = SHOWS_rel && SHOWS_rel[0]?.Image?.publicId ? true : false;

    const listingUrl = `${window.location.origin}/brochure?housingId=${id}`;

    return {
      totalRent,
      minRent,
      maxRent,
      listingUrl,
      hasImage,
    };
  };

  const HUStatusIndicator = (housingUnit, isAssignedToHu) => {
    const isHuAvailable = housingUnit?.assignedParticipants?.length === 0;
    const availableIcon = <StatusIndicatorIcon backgroundColor={'#2DD389'} />;
    const otherParsAssignedIcon = <StatusIndicatorIcon backgroundColor={'#FFBD26'} />;
    return (
      <StatusIndicatorContainer>
        {isHuAvailable ? availableIcon : !isHuAvailable && !isAssignedToHu ? otherParsAssignedIcon : null}
        <StatusIndicatorText>
          {isHuAvailable ? 'Available Unit' : !isHuAvailable && !isAssignedToHu ? 'Participants Assigned' : null}
        </StatusIndicatorText>
        {!isHuAvailable && !isAssignedToHu && (
          <InfoToolTipContainer id="toolTip" onMouseOver={setToolTipOpen} onMouseOut={setToolTipOpen}>
            <InfoToolTip src={InfoToolTipImg} />
            <Popover placement="bottom-start" isOpen={toolTipOpen} target="toolTip">
              <PopoverBody>
                Participants have been assigned but have not moved in. This is still a potential unit for your
                participant.
              </PopoverBody>
            </Popover>
          </InfoToolTipContainer>
        )}
      </StatusIndicatorContainer>
    );
  };

  const sortCardsByDate = (a, b) => {
    const notifiedDateA = new Date(a?.notifiedInfo?.notifiedDate)?.getTime();
    const notifiedDateB = new Date(b?.notifiedInfo?.notifiedDate)?.getTime();
    return notifiedDateA - notifiedDateB;
  };

  const sortCardsByStatus = (a, b) => {
    const isAssignedA = a?.assignedInfo?.isAssignedTo;
    const isAssignedB = b?.assignedInfo?.isAssignedTo;
    if (isAssignedA && !isAssignedB) {
      return -1;
    } else if (!isAssignedA && isAssignedB) {
      return 1;
    } else if (isAssignedA && isAssignedB) {
      return 0;
    }
  };

  const makeInactiveMsg = () => {
    const inactiveData = participant?.CHANGED_STATUS_rel?.[0] || {};
    const { reason, createdAt } = inactiveData;
    const reasonStr = deactivateOptionsEnumToStrDict[reason];
    const formattedDate = getMomentTimezoneObj(createdAt)?.format('M/DD/YYYY');

    return (
      <div>
        <div>{`Inactive since ${formattedDate}`}</div>
        <div style={{ display: 'flex', marginTop: '8px' }}>
          <div style={{ fontWeight: 'bold' }}>Reason:&nbsp;</div>
          <div>{reasonStr}</div>
        </div>
      </div>
    );
  };

  const parStatusDisplay = () => {
    const parIsInactive = participant?.status?.type !== 'Active';
    const notifiedCount = participant?.notifiedHousingUnits?.length;
    const isAssigned = participant?.assignedHousingUnits?.length;
    const isMovedIn = participant?.movedIntoHousingUnits?.length;

    const notifiedMsg = notifiedCount ? `${notifiedCount} Unit Match${notifiedCount === 1 ? '' : 'es'}` : '';
    const assignedMsg = isAssigned ? 'Assigned to Unit / ' : '';
    const movedInMsg = isMovedIn ? (
      <>
        <CheckCircleIcon style={{ color: '#2DD389', fontSize: 15, marginRight: 2 }} /> Moved Into Unit
      </>
    ) : null;

    let statusMsg = 'Active';
    if (isMovedIn) {
      statusMsg = movedInMsg;
    } else if (parIsInactive && participant?.CHANGED_STATUS_rel?.[0]) {
      statusMsg = makeInactiveMsg();
    } else if (notifiedCount) {
      statusMsg = `${assignedMsg} ${notifiedMsg}`;
    }
    return statusMsg;
  };

  const housingUnitItem = parInfoByHu
    ?.filter((parDetailsByHu) => {
      const {
        hu,
        actionRequired,
        isInterestedIn,
        assignedInfo: { isAssignedTo },
        movedInInfo: { isMovedIn },
      } = parDetailsByHu;
      const huIsActive = hu?.status === 'Active';
      const unitFilledByOthers =
        (hu?.bedrooms?.length === hu?.assignedParticipants?.length &&
          hu?.bedrooms?.length === hu?.movedInParticipants?.length &&
          !isAssignedTo) ||
        false;
      return huIsActive && (actionRequired || isInterestedIn || isAssignedTo || isMovedIn) && !unitFilledByOthers;
    })
    ?.sort(sortCardsByDate)
    ?.sort(sortCardsByStatus)
    ?.map((parDetailsByHu, index) => {
      const {
        hu: housingUnit,
        isInterestedIn,
        isDisinterestedIn,
        notifiedInfo: { notifiedDate },
        movedInInfo: { isMovedIn, movedInDate },
        assignedInfo: { isAssignedTo, assignedToDate },
      } = parDetailsByHu;

      const housingInfo = getHuInfo(housingUnit);
      const huStatusIndicator = HUStatusIndicator(housingUnit, isAssignedTo);
      const movedIntoAnyUnit = participant?.movedIntoHousingUnits.length;
      const unitFilledByOthers =
        (housingUnit?.bedrooms?.length === housingUnit?.assignedParticipants?.length && !isAssignedTo) || false;
      const selectedCard = SelectedCard()?.includes(`card${housingUnit?.id?.replaceAll('-', '')}`);
      return (
        <CardContainer
          key={`${housingUnit?.name}-${index}`}
          className={`${
            (isInterestedIn && (!isMovedIn || !isAssignedTo) && movedIntoAnyUnit) ||
            isDisinterestedIn ||
            unitFilledByOthers
              ? 'hide'
              : ''
          } card${housingUnit?.id?.replaceAll('-', '')} ${selectedCard ? `selected` : ''}`}
        >
          <Row>
            <Col md="12">
              <DateNotified>
                {isMovedIn
                  ? `Moved-in ${movedInDate}`
                  : isAssignedTo
                  ? `Assigned ${assignedToDate}`
                  : `Received ${notifiedDate}`}
              </DateNotified>
            </Col>
          </Row>
          <PanelContainer
            key={`${housingUnit?.id}-${index}`}
            border={true}
            boxShadow={true}
            borderRadius={true}
            marginBottom={45}
            padding={'0px'}
            className={isMovedIn ? 'movedIn-view' : ''}
          >
            <HorizontalContainer>
              <PanelCol $display={'flex'} $flexDirection={'row'} $paddingTop={'15px'} $paddingBottom={'15px'}>
                <AvatarDiv>
                  {housingInfo?.hasImage ? (
                    <CloudinaryImage
                      publicId={housingUnit?.SHOWS_rel[0]?.Image?.publicId}
                      width="105"
                      height="105"
                      round={true}
                    />
                  ) : (
                    <Avatar size="105" round src={HouseDefault} />
                  )}
                </AvatarDiv>

                <InformationDiv alignSelf={'center'}>
                  {huStatusIndicator}
                  <LargeText fontSize={18} bold>
                    <HUNameLink to={`/housing/detail/${housingUnit?.id}`} as={userHasAccess ? null : 'div'}>
                      {housingUnit?.name}
                    </HUNameLink>
                  </LargeText>
                  <StreetText className="par-detail-view">{housingUnit?.location}</StreetText>
                  <p className="par-details-rent">{`Total Rent: $${housingInfo?.totalRent.toLocaleString()} / Cost per room $${housingInfo?.minRent.toLocaleString()}-${housingInfo?.maxRent.toLocaleString()}`}</p>
                  {housingInfo?.listingUrl ? (
                    <LinkItem href={housingInfo?.listingUrl} target="_blank" rel="noopener noreferrer">
                      View listing
                    </LinkItem>
                  ) : null}
                </InformationDiv>
              </PanelCol>
              {!isMovedIn ? (
                <PanelCol
                  md="4"
                  $display={'flex'}
                  $flexDirection={'column'}
                  $backgroundColor={'rgba(165, 201, 253, .21)'}
                  $justifyContent={'center'}
                >
                  <PanelContainer padding={'0px'}>
                    <PanelRow $justifyContent={'center'}>
                      <h6 className="par-response-header">
                        {isAssignedTo
                          ? `${participant?.name} Has Been Assigned To This Unit`
                          : isInterestedIn || isDisinterestedIn
                          ? `Participant's Response`
                          : `Report Participant's Interest`}
                      </h6>
                    </PanelRow>
                    {!isAssignedTo ? (
                      <PanelGrid $gridTemplateColumns="auto" $justifyContent="center">
                        {(isInterestedIn || isDisinterestedIn) & !isAssignedTo ? (
                          <>
                            <PanelGridItem>
                              {isInterestedIn ? (
                                <span className="interestIcon">
                                  <GreenFontAwesome name="check-circle" />
                                </span>
                              ) : (
                                <span className="interestIcon">
                                  <RedFontAwesome name="times-circle" />
                                </span>
                              )}
                              {isInterestedIn ? `Interested` : 'Not Interested'}
                            </PanelGridItem>
                            <PanelGridItem $textAlign="center">
                              <span className={'changeLink'}>
                                <a
                                  href="#"
                                  onClick={(e) =>
                                    handleChangeClick(e, participant, housingUnit, isInterestedIn, isDisinterestedIn)
                                  }
                                >
                                  Change
                                </a>
                              </span>
                            </PanelGridItem>
                          </>
                        ) : (
                          <>
                            <PanelGridItem>
                              <InterestButtonComponent
                                width={true}
                                participant={participant}
                                housingUnit={housingUnit}
                                buttonType={'Interested'}
                                buttonIconName={'check-circle'}
                                buttonText={'Interested in Unit'}
                                refetch={refetch}
                              />
                            </PanelGridItem>
                            <PanelGridItem>
                              <InterestButtonComponent
                                width={true}
                                participant={participant}
                                housingUnit={housingUnit}
                                buttonType={'Not Interested'}
                                buttonIconName={'times-circle'}
                                buttonText={'Not Interested in Unit'}
                                refetch={refetch}
                              />
                            </PanelGridItem>
                          </>
                        )}
                      </PanelGrid>
                    ) : null}
                  </PanelContainer>
                </PanelCol>
              ) : null}
            </HorizontalContainer>
          </PanelContainer>
        </CardContainer>
      );
    });

  return (
    <>
      <PanelRow $marginTop={20}>
        <PanelCol $paddingLeft={0}>
          <MediumLargeText fontSize={20} className="panel-label">
            Status
          </MediumLargeText>
        </PanelCol>
      </PanelRow>
      <PanelRow $marginTop={10} $marginBottom={25}>
        <PanelCol $paddingLeft={0}>
          <MediumLargeText fontSize={15} style={{ display: 'flex', alignItems: 'center' }}>
            {parStatusDisplay()}
          </MediumLargeText>
        </PanelCol>
      </PanelRow>
      <Row>
        <Col md="12">{housingUnitItem}</Col>
      </Row>
    </>
  );
};

ParticipantHUItem.propTypes = {
  participant: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
  role: PropTypes.shape({
    __typename: PropTypes.string.isRequired,
  }),
  handleChangeClick: PropTypes.func.isRequired,
};
