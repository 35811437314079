import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { getBrochureData } from './getBrochureData';
import { MainNavbar } from '../../components/navbars/MainNavBar.jsx';
import BrochureDescriptionCard from './BrochureDescriptionCard';
import BrochureDetailsCard from './BrochureDetailsCard';
import BrochureBedroomDetails from './BrochureBedDetails';
import UnitPanel from './BrochureUnitPanel';
import BrochureNoData from '../NoDataView';
import { LoadingSpinner } from '../../components/Alerts';
import { Carousel } from './Carousel';
import { Container, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { cardStyles } from './BrochureStyles';
import BrochureMap from './BrochureMap';

const Brochure = ({ classes }) => {
  const domLocation = useLocation();
  const { housingId } = qs.parse(domLocation.search);
  const [hu, setHousingUnit] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (housingId) {
      setLoading(true);
      getBrochureData(housingId)
        .then((data) => {
          data.pets = data?.pets?.filter((p) => p.species); //rm empty objs
          data.images = data?.images?.filter((i) => i.publicId); //rm empty objs
          setHousingUnit(data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setError(true);
        });
    } else {
      setLoading(true);
    }
  }, [housingId]);

  if (loading) {
    return <LoadingSpinner />;
  }
  if (error) {
    return <BrochureNoData />;
  }
  const HousingBrochureDisplay = () => {
    return (
      <>
        <Container className={classes.pageContainer}>
          <Grid container>
            <Grid item xs={12} md={5} className={classes.gridItem}>
              <UnitPanel
                bedrooms={hu?.bedrooms || []}
                location={hu?.location || ''}
                unitNumber={hu?.unitNumber || ''}
                totalBathrooms={hu?.totalBathrooms || 0}
                totalBedrooms={hu?.totalBedrooms || 0}
                isAvailable={hu?.totalBedrooms > hu?.participantsMovedIntoUnitCount || false}
              />
            </Grid>
            <Grid item xs={12} md={7} className={classes.gridItem}>
              <Carousel images={hu?.images?.sort((a, b) => a.order - b.order)} />
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <BrochureDescriptionCard
                description={hu?.description}
                allowedPets={hu?.pets}
                allowsConvictions={hu?.allowsConvictions}
                allowsEvictions={hu?.allowsEvictions}
                allowsIndoorSmoking={hu?.allowsIndoorSmoking}
                allowsSexOffenders={hu?.allowsSexOffenders}
                viewingNotes={hu?.viewingNotes}
              />
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <BrochureDetailsCard hu={hu} />
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <BrochureBedroomDetails bedrooms={hu?.bedrooms || []} />
            </Grid>
            {hu?.lat && hu?.lng ? (
              <Grid item xs={12} className={classes.gridItem}>
                <BrochureMap lat={hu?.lat} lon={hu?.lng} />
              </Grid>
            ) : null}
          </Grid>
        </Container>
      </>
    );
  };
  return (
    <>
      <MainNavbar noUser noItems />
      {hu ? <HousingBrochureDisplay /> : <BrochureNoData />}
    </>
  );
};

export default withStyles(cardStyles)(Brochure);
