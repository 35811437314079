export const defaultState = {
  //** TK: age must be reconciled with DoB
  // age: {
  //   open: false,
  //   selected: [],
  //   limit: Infinity,
  //   display: 'Age Range',
  //   controlled: true,
  //   filterType: 'range',
  //   options: [
  //     [0, '18-25 yrs', [18, 25]],
  //     [1, '26-35yrs', [26, 35]],
  //     [2, '36-40yrs', [36, 40]],
  //     [3, '50+ yrs', [50, Infinity]],
  //   ],
  // },
  //
  'income|currentIncome': {
    open: false,
    selected: [],
    limit: 1,
    display: 'Income min.',
    controlled: false,
    filterType: 'numeric',
    placeholder: 'Min Income',
    savedValue: 0,
    options: [
      [500, '$500+', 500],
      [600, '$600+', 600],
      [700, '$700+', 700],
      [800, '$800+', 800],
      [900, '$900+', 900],
      [1000, '$1000+', 1000],
      [1100, '$1100+', 1100],
      [1200, '$1200+', 1200],
    ],
  },
  preferredRegions: {
    open: false,
    selected: [],
    limit: Infinity,
    controlled: true,
    display: 'Regions',
    filterType: 'name',
    options: [
      [1, 'SPA 1', 'SPA 1'],
      [2, 'SPA 2', 'SPA 2'],
      [3, 'SPA 3', 'SPA 3'],
      [4, 'SPA 4', 'SPA 4'],
      [5, 'SPA 5', 'SPA 5'],
      [6, 'SPA 6', 'SPA 6'],
      [7, 'SPA 7', 'SPA 7'],
      [8, 'SPA 8', 'SPA 8'],
    ],
  },
  gender: {
    open: false,
    selected: [],
    controlled: true,
    limit: Infinity,
    filterType: 'id',
    display: 'Genders',
    options: [
      [0, 'Trans Female', 'gender00-tran-0000-0000-female000000'],
      [1, 'Trans Male', 'gender00-tran-0000-0000-male00000000'],
      [2, 'Cis Female', 'gender00-cis0-0000-0000-female000000'],
      [3, 'Cis Male', 'gender00-cis0-0000-0000-male00000000'],
      [4, 'Non-binary', 'gender00-0000-0000-0000-nonbinary000'],
    ],
  },
  'smokingIndoorsNeeded|marijuanaSmokingIndoors|tobaccoSmokingIndoors': {
    open: false,
    selected: [],
    controlled: true,
    limit: Infinity,
    filterType: 'HabitLevel',
    display: 'Indoor Smoking Req.',
    options: [
      [0, 'Indoor Smoking Req.', ['Need', 'NEEDS', 'Needs']],
      [1, 'Indoor Smoking Pref.', ['Preference', 'Prefers', 'PREFERS']],
      [2, 'Indoor Smoking Not Req.', ['NotRequired']],
    ],
  },
  pets: {
    open: false,
    selected: [],
    controlled: true,
    limit: Infinity,
    filterType: 'pets',
    display: 'Pets',
    options: [
      [
        0,
        'Cats',
        {
          species: 'Cat',
          size: null,
        },
      ],
      [
        1,
        'Small Dogs',
        {
          species: 'Dog',
          size: 'Small',
        },
      ],
      [
        2,
        'Large Dogs',
        {
          species: 'Dog',
          size: 'Big',
        },
      ],
    ],
  },
  accessibilities: {
    open: false,
    selected: [],
    controlled: true,
    limit: Infinity,
    filterType: 'mobility',
    display: 'Mobility',
    options: [
      [0, 'Wheelchair / No steps', 100],
      [1, 'Up to 3 steps', 75],
      [2, '4 steps to 1 flight', 50],
      [3, 'No Limitation', 0],
    ],
  },
};
