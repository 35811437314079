import React, { useEffect, useState } from 'react';

import { useParams, Link } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import { Row, Col, Container } from 'reactstrap';
import { AssignedParIds, ParToParData, ParToParIds } from '../apollo/cache';
import { HousingInfoPanel } from '../components/panels/HousingInfoPanel';
import { HousingStatusPanel } from '../components/panels/HousingStatusPanel';
import { HousingAssignmentStatusPanel } from '../components/panels/HousingAssignmentStatusPanel';
import HousingMoreDetailsPanel from '../components/panels/HousingMoreDetailsPanel';
import { NotesPanel } from '../components/panels/notes';
import { useLazyQuery, useReactiveVar } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { LoadingSpinner, ErrorToast, ContextFeedback } from '../components/Alerts';
import {
  QueryHousingUnitByIdAllData,
  QueryHousingUnitHousingLocator,
} from '../components/HousingUnitCreation/QueryHousingByIdGQL';
import { AddNoteGQL, DeleteHousingNote, EditHousingNote } from '../components/panels/notes/HousingNoteGQL';
import { useCallback } from 'react';

export const DetailHousing = () => {
  const { user } = useAuth0();
  const { houseUnitId } = useParams();
  const [showViewPool, setShowViewPool] = useState(false);
  const [showRemovePar, setShowRemovePar] = useState(false);
  const [useAll, setUseAll] = useState(false);
  const [housingUnit, setHousingUnit] = useState(null);
  const [housingUnitHL, setHousingUnitHL] = useState(null);
  const [currentUserId, setCurrentUserId] = useState(false);
  const [queryAll, { data, loading, error, refetch: _refetch }] = useLazyQuery(QueryHousingUnitByIdAllData, {
    variables: { id: houseUnitId },
    fetchPolicy: 'network-only', // network only because of weird cache handling
  });

  // query for Housing Locators who cannot see user info (restricted on the backend, but we'd crash if they tried to load it)
  const [queryHL, { data: dataHL, loading: loadingHL, error: errorHL, refetch: _refetchHL }] = useLazyQuery(
    QueryHousingUnitHousingLocator,
    {
      variables: { id: houseUnitId },
      fetchPolicy: 'network-only', // network only because of weird cache handling
    },
  );

  const refetch = useCallback(() => {
    if (_refetch || _refetchHL) {
      const roles = user['http://schemas.aroyomatch.com/claims/roles'];
      if (roles.includes('UserAdmin') || roles.includes('Advocate') || roles.includes('MPO')) {
        _refetch();
      } else {
        _refetchHL();
      }
    }
  }, [user, _refetch, _refetchHL]);

  useEffect(() => {
    if (user) {
      const roles = user['http://schemas.aroyomatch.com/claims/roles'];
      if (roles.includes('UserAdmin') || roles.includes('Advocate') || roles.includes('MPO')) {
        queryAll();
        setUseAll(true);
      } else {
        queryHL();
      }
    }
  }, [user]);

  // Cleans up selection, so if you go to another unit the people selected for the other one don't show up
  useEffect(() => {
    if (ParToParIds().size) {
      ParToParIds(new Set());
      ParToParData([]);
      AssignedParIds(new Set());
    }
  }, [ParToParIds, ParToParData, AssignedParIds]);

  useEffect(() => {
    if (data?.HousingUnit?.[0]) {
      const housingUnit = data.HousingUnit[0];
      const assignedParCount = housingUnit?.assignedParticipants?.length;
      const bedroomCount = housingUnit?.bedrooms?.length;
      const parAssignmentComplete = bedroomCount === housingUnit.numAssignedParticipants;
      setHousingUnit(housingUnit);
      setShowViewPool(!parAssignmentComplete || assignedParCount <= 0);
    }
    if (data?.me) {
      setCurrentUserId(data.me.id);
    }
  }, [data]);

  useEffect(() => {
    if (dataHL?.HousingUnit?.[0]) {
      const { HousingUnit } = dataHL;
      const housingUnit = HousingUnit[0];
      setHousingUnitHL(housingUnit);
    }
    if (dataHL?.me) {
      setCurrentUserId(dataHL.me.id);
    }
  }, [dataHL, setCurrentUserId, setHousingUnitHL]);

  const handleShowRemovePar = () => {
    setShowRemovePar(true);
  };

  if (loading || loadingHL) {
    return <LoadingSpinner />;
  }

  if (error || errorHL) {
    return <ErrorToast />;
  }

  if (!data && !dataHL) {
    return <ContextFeedback />;
  }

  return (
    <div className="housing-detail">
      <Container>
        <Row className="return-units" tag={Link} to={{ pathname: '/housing' }}>
          <Col md="12">
            <FontAwesome name="chevron-left" className="chevron-right" /> Units
          </Col>
        </Row>
        <Row>
          <Col>
            <HousingInfoPanel
              housingUnit={useAll ? housingUnit : housingUnitHL}
              handleShowRemovePar={handleShowRemovePar}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            {!!housingUnit?.assignedParticipants?.length && (
              <HousingAssignmentStatusPanel showViewPool={showViewPool} housingUnit={housingUnit} refetch={refetch} />
            )}
            {showViewPool && <HousingStatusPanel housingUnit={housingUnit} />}
          </Col>
        </Row>
        <Row>
          <Col>
            <HousingMoreDetailsPanel housingUnit={useAll ? housingUnit : housingUnitHL} />
          </Col>
        </Row>
        <Row>
          <Col>
            <NotesPanel
              currentUserId={currentUserId}
              forId={useAll ? housingUnit?.id : housingUnitHL?.id}
              notes={useAll ? housingUnit?.notes : housingUnitHL?.notes}
              refetch={refetch}
              createNoteMutation={AddNoteGQL}
              deleteNoteMutation={DeleteHousingNote}
              editNoteMutation={EditHousingNote}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
