import React, { useCallback, useEffect } from 'react';
import { Col } from 'reactstrap';

import { useParams, useHistory } from 'react-router-dom';

import FormButton from '../../buttons/FormButton';

import FileUploadField from '../../Inputs/FileUploadField';
import { ImageGallerySelectField } from '../../Inputs/ImageGallerySelectField';

import { QueryImages, UpdateImageSHOWS_relOrder } from './UnitImagesGQL';
import { useMutation, useQuery } from '@apollo/react-hooks';
import UploadList from '../../Inputs/UploadList';
import { FormNavBar } from '../../navbars/FormNavBar';
import UnitCompleteModal from '../../modals/UnitCompleteModal';
import { LoadingSpinner, ErrorToast } from '../../Alerts';

export const UnitImages = ({ isUnitActive, completedSections, toggle, modal }) => {
  const { houseUnitId } = useParams();
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  const history = useHistory();
  // state
  const [images, setImages] = React.useState([]);
  const [uploaded, setUploaded] = React.useState([]);
  // graphql, keep checking if new images
  const { data, loading, error, refetch } = useQuery(QueryImages, {
    variables: { id: houseUnitId },
    fetchPolicy: 'no-cache',
  });
  const [modOrder] = useMutation(UpdateImageSHOWS_relOrder);
  const onFormSubmit = async () => {
    setIsFormSubmitted(true);
    await history.push(`/housing/edit/${houseUnitId}/room-rent`);
  };
  const updateImageOrder = React.useCallback(
    async (index) => {
      const imagesToPushInOrder = images.slice(0, index - 1).map((image) => image?.Image?.id);
      // cannot do a for await with a standard indexed i
      const imageToMoveToPrimary = images[index - 1]?.Image?.id;
      let i = 2;
      for await (const imageId of imagesToPushInOrder) {
        await modOrder({ variables: { from: { id: imageId }, to: { id: houseUnitId }, order: i } });
        i++;
      }
      await modOrder({ variables: { from: { id: imageToMoveToPrimary }, to: { id: houseUnitId }, order: 1 } });
      await refetch?.();
    },
    [images],
  );
  useEffect(() => {
    if (data) {
      const { HousingUnit } = data;
      if (HousingUnit) {
        const { SHOWS_rel } = HousingUnit[0];
        if (SHOWS_rel) {
          setImages(SHOWS_rel);
        }
      }
    }
  }, [data, setImages]);

  const handleUpload = useCallback(
    (success) => {
      setUploaded(success.images);
      refetch?.();
    },
    [setUploaded, refetch],
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <ErrorToast />;
  }

  const handleSaveAndClose = () => {
    const location = completedSections?.location;
    const rooms = completedSections?.rooms;
    const acceptances = completedSections?.acceptances;
    const accessibility = completedSections?.accessibility;
    const otherSectionsComplete = location && rooms && acceptances && accessibility;
    if (otherSectionsComplete && !isUnitActive) {
      toggle();
    } else {
      history.push('/housing/');
    }
  };

  return (
    <div>
      <FormNavBar onClick={handleSaveAndClose} />
      <Col md={{ size: 8, offset: 2 }}>
        <h2 className="form-title center-block text-center">Housing Unit Images</h2>
      </Col>
      <Col md={{ size: 10, offset: 1 }}>
        <h3 className="form-title center-block text-center">Upload images </h3>
        <FileUploadField housingUnitId={houseUnitId} acceptedTypes="image/*" onUploaded={handleUpload}>
          {images?.length > 0 ? (
            <ImageGallerySelectField
              galleryImages={images}
              primaryImage={images[0]}
              houseUnitId={houseUnitId}
              triggerRefetch={() => {
                refetch?.();
              }}
              updateImageOrder={updateImageOrder}
            />
          ) : null}
        </FileUploadField>
        <div className="form-title center-block text-center">
          <FormButton onClick={() => onFormSubmit()} id="unit-images-submit"></FormButton>
        </div>
      </Col>
      <UnitCompleteModal toggle={toggle} modal={modal} />
    </div>
  );
};
