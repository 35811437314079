export const sortHousingStatus = (a, b) => {
  if (!a?.complete) {
    return -1;
  }
  if (!b?.complete) {
    return 1;
  }
  if (a?.status === 'Inactive') {
    return -1;
  }
  if (b?.status === 'Inactive') {
    return 1;
  }
  if (a?.status === 'Active') {
    return -1;
  }
  if (b?.status === 'Active') {
    return 1;
  }
  if (a?.movedInParticipants?.length && a.movedInParticipants.length === a.bedrooms?.length) {
    return 1;
  }
  if (b?.movedInParticipants?.length && b.movedInParticipants.length === b.bedrooms?.length) {
    return -1;
  }
  if (
    (a?.numAssignedParticipants && !b?.numAssignedParticipants) ||
    (a?.numAssignedParticipants &&
      b?.numAssignedParticipants &&
      a?.numAssignedParticipants > b?.numAssignedParticipants)
  ) {
    return -1;
  }
  if (
    (b?.numAssignedParticipants && !a?.numAssignedParticipants) ||
    (a?.numAssignedParticipants &&
      b?.numAssignedParticipants &&
      b?.numAssignedParticipants > a?.numAssignedParticipants)
  ) {
    return 1;
  }
  if (
    (a?.interestedParticipants?.length && !b?.interestedParticipants?.length) ||
    (a?.interestedParticipants?.length &&
      b?.interestedParticipants?.length &&
      a?.interestedParticipants.length > b.interestedParticipants.length)
  ) {
    return -1;
  }
  if (
    (!a?.interestedParticipants?.length && b?.interestedParticipants?.length) ||
    (a?.interestedParticipants?.length &&
      b?.interestedParticipants?.length &&
      b?.interestedParticipants.length > a.interestedParticipants.length)
  ) {
    return 1;
  }
  if (
    (a?.numNotifiedParticipants && !b?.numNotifiedParticipants) ||
    (a?.numNotifiedParticipants && b?.numNotifiedParticipants && a.numNotifiedParticipants > b.numNotifiedParticipants)
  ) {
    return -1;
  }
  if (
    (a?.numNotifiedParticipants && !b?.numNotifiedParticipants) ||
    (a?.numNotifiedParticipants && b?.numNotifiedParticipants && b.numNotifiedParticipants > a.numNotifiedParticipants)
  ) {
    return 1;
  }

  return 0;
};
