import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { GetHousingParticipant } from './DetailMatchGQL';
import { MatchingContainer, MatchFontAwesome, MatchLink } from './MatchPARtoUnitStyles';
import { MediumText, XLargeText } from '../styles/text';
import { ViewProfile } from '../components/buttons/ButtonStyles';
import { Row, Col, Container } from 'reactstrap';
import { links } from '../styles/constants';
import Avatar from 'react-avatar';
import HouseDefaultImg from '../assets/img/house-default.png';
import {
  NameCol,
  NameDiv,
  NameDivHolder,
  DescriptionDiv,
  FlexRowDiv,
  AdvocateDiv,
  AdvocateTitleSpan,
} from './DetailMatchStyles';
import { colorFromName } from '../utilities/hash';
import MatchSideBySidePanel from '../components/panels/MatchSideBySidePanel';
import { minArray } from '../utilities/arrayOperations';
import { StatusPanel } from '../components/HousingUnitCreation/components/StatusPanel';
import { LoadingSpinner, ErrorToast } from '../components/Alerts';

export default function () {
  const { houseUnitId, participantId } = useParams();
  const { data, loading, error } = useQuery(GetHousingParticipant, {
    variables: { houseUnitId, participantId },
  });
  const [house, setHouse] = useState({});
  const [participant, setParticipant] = useState({});
  const [matchType, setMatchType] = useState('');
  useEffect(() => {
    if (data) {
      let housingUnit, par;
      const { HousingUnit, Participant } = data;
      if (HousingUnit) {
        housingUnit = HousingUnit?.[0];
        if (housingUnit) {
          setHouse(housingUnit);
        }
      }
      if (Participant) {
        par = Participant?.[0];
        if (par) {
          setParticipant(par);
        }
      }
      if (housingUnit && par) {
        const minBRRent = minArray(housingUnit.bedrooms?.map((b) => b?.rent) || []);
        const mType = (par.income || par.currentIncome || 0) > minBRRent ? 'Good' : 'Bad';
        setMatchType(mType);
      }
    }
  }, [data, setMatchType]);
  if (loading) {
    return <LoadingSpinner />;
  }
  if (error) {
    return <ErrorToast />;
  }
  return (
    <>
      <MatchingContainer>
        <Row>
          <Col>
            <Container>
              <MatchLink to={`/housing/match/${houseUnitId}`}>
                <MatchFontAwesome name="chevron-left" style={{ color: links.regularLink }} /> &nbsp; Participants
              </MatchLink>
            </Container>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center">
              <FlexRowDiv>
                <XLargeText>Location Match Comparison</XLargeText>
                {/* <MatchIndicator matchRating={matchType} /> */}
              </FlexRowDiv>
            </div>
            <div className="text-center">
              <MediumText>Review potential problem areas listed below</MediumText>
            </div>
          </Col>
        </Row>
        <Row>
          <NameCol md={{ size: 4, offset: 2 }}>
            <Avatar
              name={participant.name}
              round
              size={58}
              color={participant.name ? colorFromName(participant.name) : '#000000'}
            />
            <NameDivHolder>
              <NameDiv>
                <ViewProfile className="name" target="_blank" to={`/participant/detail/${participant?.id}`}>
                  {participant.name}
                </ViewProfile>
              </NameDiv>
              <DescriptionDiv>
                {participant.gender?.name}, {participant.age} years old
              </DescriptionDiv>
              <DescriptionDiv>HMIS#{participant.HMISID}</DescriptionDiv>
              <AdvocateDiv>
                <AdvocateTitleSpan>Advocate/org</AdvocateTitleSpan>
                {participant?.ADVOCATES_FOR_rel?.[0]?.Advocate?.name}/
                {participant?.ADVOCATES_FOR_rel?.[0]?.Advocate?.organization?.name}
              </AdvocateDiv>
              <ViewProfile
                fontSize="13px"
                marginTop="0px"
                target="_blank"
                to={`/participant/detail/${participant?.id}`}
              >
                View Profile
              </ViewProfile>
            </NameDivHolder>
          </NameCol>
          <NameCol md={{ size: 4 }}>
            <Avatar src={house.SHOWS_rel?.[0]?.Image.url || HouseDefaultImg} round size={58} />
            <NameDivHolder>
              <NameDiv>{house.name}</NameDiv>
              <DescriptionDiv>
                {house.location} {house.unitNumber}
              </DescriptionDiv>
              <AdvocateDiv>
                <AdvocateTitleSpan>Bedrooms/bathrooms</AdvocateTitleSpan>
                {house.bedrooms?.length}/{house.totalBathrooms}
              </AdvocateDiv>
              <AdvocateDiv>
                <AdvocateTitleSpan>SPA</AdvocateTitleSpan>
                {house.region?.name}
              </AdvocateDiv>
            </NameDivHolder>
          </NameCol>
        </Row>
        <Row>
          <Col>
            <MatchSideBySidePanel participant={participant} housingUnit={house} />
          </Col>
        </Row>
      </MatchingContainer>
      <StatusPanel participant={participant} housingUnit={house} />
    </>
  );
}
