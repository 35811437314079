import styled from 'styled-components';
import { Button } from 'reactstrap';
import { borders } from '../../styles/constants';

export const GlobalCheckBoxStyles = styled(Button)`
  width: 24px;
  height: 24px;
  line-height: 24px;
  padding: 0px;
  border-radius: 7px;
  border: ${borders.border};

  :active,
  :focus {
    color: #fff;
    background-color: #fff;
  }
`;

export const Checkbox = styled(GlobalCheckBoxStyles)`
  background-color: white;
  color: white;
  :hover {
    background-color: lightgray;
    border: ${borders.border};
  }
`;

export const CheckboxChecked = styled(GlobalCheckBoxStyles)`
  font-size: 24px;
  background-color: #5bb8b4;
  color: white;
  :hover {
    background-color: #5bb8b4;
  }
`;
