import moment from 'moment';
export const getNotifiedParticipants = (housingUnit) => {
  const notifiedParticipants = {};
  if (housingUnit?.matchingProcesses?.length) {
    for (const mp of housingUnit.matchingProcesses) {
      if (mp?.NOTIFIED_rel?.length) {
        for (const notified of mp?.NOTIFIED_rel) {
          const isNotified = notified?.Participant?.id in notifiedParticipants;
          const formattedDate = moment(notified.createdAt.formatted.split('[')[0]);
          if (!isNotified || formattedDate.isAfter(notifiedParticipants[notified?.Participant.id])) {
            notifiedParticipants[notified?.Participant.id] = formattedDate;
          }
        }
      }
    }
  }
  return notifiedParticipants;
};

export const getAssignedParticipants = (housingUnit) => {
  const assignedParticipants = {};
  if (housingUnit?.ASSIGNED_TO_rel?.length) {
    for (const rel of housingUnit.ASSIGNED_TO_rel) {
      const isAssigned = rel?.Participant?.id in assignedParticipants;
      if (!isAssigned) {
        const formattedDate = moment(rel.createdAt.formatted?.split('[')[0]);
        assignedParticipants[rel?.Participant.id] = formattedDate;
      }
    }
  }
  return assignedParticipants;
};
