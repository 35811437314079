import React from 'react';
import { Row } from 'reactstrap';
import { MediumText } from '../../styles/text';
import { SelectMultiButton } from './SelectMultiStyles';
import PropTypes from 'prop-types';
import { capitalize } from '../../utilities/stringProcessing';

function SelectMultiField({ selections, id, onChange, value }) {
  // selections is a map that when turned into an array is [[stringKey, stringValue]]
  const [activeSelections, setActiveSelections] = React.useState([]);

  const onClick = React.useCallback(
    (selection) => {
      let currentSelections = [...activeSelections];
      if (!activeSelections.includes(selection)) {
        currentSelections = [...currentSelections, selection];
      } else {
        currentSelections = currentSelections.filter((item) => item !== selection);
      }
      setActiveSelections([...currentSelections]);
      onChange(id, currentSelections);
    },
    [activeSelections, setActiveSelections, onChange, id],
  );

  React.useEffect(() => {
    if (value) {
      setActiveSelections(value);
    }
  }, [value]);

  return (
    <Row className="select-multi-field justify-content-center" style={{ margin: '8px' }}>
      {Array.from(selections).map((selection, index) => {
        return (
          <SelectMultiButton
            key={`${selection[0]}_${index}_${activeSelections.includes(selection[1])}`}
            onClick={() => onClick(selection[1])}
            className={`btn-select-one ${activeSelections.includes(selection[1]) ? 'active' : ''} `}
            outline
            color="secondary"
            size="md"
          >
            <MediumText>{capitalize(selection[0])}</MediumText>
          </SelectMultiButton>
        );
      })}
    </Row>
  );
}

SelectMultiField.propTypes = {
  selections: PropTypes.instanceOf(Map),
  id: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.string),
};
export default SelectMultiField;
