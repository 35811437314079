import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/react-hooks';
import UserTableHeader from './UserTableHeader';
import { UserTableRow } from './UserTableRow';
import { sortByUpdatedDate, alphaSortByKey } from './userSorting';
import { UserSort } from '../../apollo/cache';

export const DashUsers = function ({
  users,
  handleEditUser,
  newUserEmail,
  handleCreateOrUpdateUserSuccess,
  handleReactivate,
  handleToastErrorMessage,
  refetch,
}) {
  const sortTypeCached = useReactiveVar(UserSort);
  const [sort, setSort] = useState(sortTypeCached || 'updatedAt');
  const [sortedUsers, setSortedUsers] = useState([]);

  const handleSort = (sortType) => {
    setSort(sortType);
    UserSort(sortType);
  };
  const sortUsers = () => {
    let sorted;
    if (sort === 'email') {
      sorted = alphaSortByKey(users, 'email');
    } else if (sort === 'name') {
      sorted = alphaSortByKey(users, 'name');
    } else if ('updatedAt') {
      sorted = sortByUpdatedDate(users);
    } else {
      sorted = [...sortedUsers];
    }
    setSortedUsers(sorted);
  };

  useEffect(() => {
    sortUsers();
  }, [users]);

  useEffect(() => {
    sortUsers();
  }, [sort]);

  const handleReverse = () => {
    const reversedUsers = [...sortedUsers].reverse();
    setSortedUsers(reversedUsers);
  };

  return (
    <div>
      <UserTableHeader sort={sort} handleSort={handleSort} handleReverse={handleReverse} />
      {sortedUsers?.map((user) => (
        <UserTableRow
          user={user}
          key={`${user?.id}-dash-user`}
          handleEditUser={handleEditUser}
          handleCreateOrUpdateUserSuccess={handleCreateOrUpdateUserSuccess}
          handleReactivate={handleReactivate}
          handleToastErrorMessage={handleToastErrorMessage}
          isNew={user?.email === newUserEmail}
          refetch={refetch}
        />
      ))}
    </div>
  );
};
