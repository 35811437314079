const iconColor = {
  interestedIcon: '#2DD389',
  notInterestedIcon: '#FF8181',
  removeInterestIcon: '#FF8181',
};

const iconSpacing = {
  size: '10px',
};

export default {
  iconColor,
  iconSpacing,
};
