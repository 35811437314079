import { makeVar } from '@apollo/client';

export const ParToParIds = makeVar(new Set());
export const ParToParData = makeVar([]);
export const AllParsData = makeVar([]);
export const AllParsIds = makeVar([]); // an array, different
export const PossibleMatchIds = makeVar(new Set());
export const AssignedParIds = makeVar(new Set());
export const ParticipantFilter = makeVar('');
export const HousingFilter = makeVar('');
export const ParToUnitFiltersState = makeVar({});
export const ParToUnitSortState = makeVar({
  sort: 'status',
  lastSort: null,
  direction: true,
});
export const FilteredParToUnitPARs = makeVar([]);
export const SortedParToUnitPars = makeVar([]);
export const SelectedCard = makeVar('');
export const HUFiltersStateCache = makeVar({});
export const ParFiltersStateCache = makeVar({});

export const UserFilter = makeVar('');
export const UserSort = makeVar('');
