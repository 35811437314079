import { gql } from '@apollo/client';

export const GetHousingParticipant = gql`
  query GetHousingParticipant($houseUnitId: ID!, $participantId: ID!) {
    HousingUnit(id: $houseUnitId) {
      __typename
      name
      location
      SHOWS_rel(orderBy: order_asc) {
        Image {
          id
          url
          publicId
        }
        order
      }
      id
      allowsSexOffenders
      region {
        id
        name
      }
      allows {
        id
        species
        size
      }
      accessibilitiesWithDegree {
        degree
        Accessibility {
          id
          ADA
          detail
        }
      }
      supports {
        id
        ADA
        detail
      }
      allowsIndoorSmoking
      accepts {
        id
        type
      }
      bedrooms {
        id
        rent
      }
      totalBathrooms
      allowsEvictions
      allowsConvictions
      parkingSpots
      parkingSpotsAdaAccessible
      matchingProcesses {
        id
        createdAt {
          month
          day
        }
        NOTIFIED_rel {
          createdAt {
            formatted
            month
            day
          }
          Participant {
            id
            HMISID
            name
          }
        }
        initiatedBy {
          id
        }
        notifiedParticipants {
          id
          name
        }
      }
      interestedParticipants {
        id
        name
      }
      disinterestedParticipants {
        id
        name
      }
    }

    Participant(id: $participantId) {
      __typename
      name
      row
      age
      contactEmail
      email
      id
      isSexOffender
      preferredRegions {
        id
        name
      }
      HMISID
      ADVOCATES_FOR_rel(orderBy: createdAt_desc, first: 1) {
        Advocate {
          name
          id
          organization {
            name
          }
        }
      }
      needToVisitWhoWhat
      needVisitFrequency
      needVisitLocation
      income
      currentIncome
      potentialIncome
      convictions {
        id
        type
      }
      pets {
        id
        species
        size
      }
      evictions
      convictionType
      accessibility {
        id
        ADA
        detail
      }
      accessibilitiesWithDegree {
        degree
        Accessibility {
          id
          ADA
          detail
        }
      }
      smokingIndoorsNeeded
      marijuanaSmokingIndoors
      tobaccoSmokingIndoors
      tobaccoPreference
      marijuanaPreference
      vouchers {
        id
        type
      }
      needsToVisit {
        detail
        frequency
        name
      }
      parkingNeed
      parkingPreference
      gender {
        name
      }
      HMnames
      needToVisitWhoWhat
      needVisitFrequency
      needVisitLocation
      interestedIn {
        id
      }
      disinterestedIn {
        id
      }
    }
  }
`;
