import React, { useEffect } from 'react';
import { Container, Col, Navbar } from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import { useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { TopicLink } from '../TopicLink';
import { isComplete } from '../../utilities/isComplete.js';
import { QueryHousingUnitByIdAllData, QueryHousingUnitHousingLocator } from './QueryHousingByIdGQL';
import { useLazyQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';

export const AssessmentTopicBar = () => {
  const { houseUnitId } = useParams();
  const { pathname } = useLocation();
  const match = useRouteMatch();
  const { user } = useAuth0();
  const add = !houseUnitId;

  const [getHousingInfoAll, { data: dataAll, refetch: refetchAll, called: calledAll }] = useLazyQuery(
    QueryHousingUnitByIdAllData,
  );
  const [getHousingInfoLoc, { data: dataLoc, refetch: refetchLoc, called: calledLoc }] = useLazyQuery(
    QueryHousingUnitHousingLocator,
  );
  useEffect(() => {
    if (pathname.startsWith('/housing/edit') && user) {
      if (!user['http://schemas.aroyomatch.com/claims/roles'].includes('HousingLocator')) {
        if (calledAll && houseUnitId) {
          refetchAll?.({ variables: { id: houseUnitId } });
        } else if (houseUnitId) {
          getHousingInfoAll({ variables: { id: houseUnitId } });
        }
      } else {
        if (calledLoc && houseUnitId) {
          refetchLoc?.({ variables: { id: houseUnitId } });
        } else if (houseUnitId) {
          getHousingInfoLoc({ variables: { id: houseUnitId } });
        }
      }
    }
  }, [pathname, refetchAll, refetchLoc, user, houseUnitId]);

  const [assessmentState, setAssessmentState] = React.useState({
    location: false,
    description: false,
    rooms: false,
    acceptances: false,
    requirements: false,
    accessibility: false,
    propertyContact: false,
    viewing: false,
  });

  useEffect(() => {
    if (dataAll?.HousingUnit?.length || dataLoc?.HousingUnit?.length) {
      const housingUnit = dataAll ? dataAll.HousingUnit[0] : dataLoc.HousingUnit[0];
      if (housingUnit) {
        setAssessmentState({
          location: isComplete('location', housingUnit),
          description: isComplete('description', housingUnit),
          rooms: isComplete('rooms', housingUnit),
          acceptances: isComplete('acceptances', housingUnit),
          requirements: isComplete('requirements', housingUnit),
          accessibility: isComplete('accessibility', housingUnit),
          propertyContact: isComplete('propertyContact', housingUnit),
          viewing: isComplete('viewing-options', housingUnit),
        });
      }
    }
  }, [dataLoc, dataAll, setAssessmentState]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [match]);
  return (
    <>
      <Navbar className="form-topic-side " expand="md">
        <div className="title-section">
          <FontAwesome name="home" /> <span className="title-text">Add Unit</span>
        </div>
      </Navbar>
      <Container className="topic-details">
        <Col md="12" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <ul className={add ? 'add' : undefined}>
            <TopicLink path={'location'} complete={assessmentState?.location} houseUnitId={houseUnitId}>
              <span>Location</span>
            </TopicLink>
            <TopicLink path={'description'} complete={!add} houseUnitId={houseUnitId}>
              <span>Description</span>
            </TopicLink>
            <TopicLink path={'unit-images'} complete={!add} houseUnitId={houseUnitId}>
              <span>Unit Images</span>
            </TopicLink>
            <TopicLink path={'room-rent'} complete={assessmentState?.rooms} houseUnitId={houseUnitId}>
              <span>Rent/Room</span>
            </TopicLink>
            <TopicLink path={'unit-acceptances'} complete={assessmentState?.acceptances} houseUnitId={houseUnitId}>
              <span>Unit Acceptances</span>
            </TopicLink>
            <TopicLink path={'accessibility'} complete={assessmentState?.accessibility} houseUnitId={houseUnitId}>
              <span>Accessibility</span>
            </TopicLink>
            <TopicLink path={'viewing-options'} complete={assessmentState?.viewing} houseUnitId={houseUnitId}>
              <span>Viewing Options</span>
            </TopicLink>
          </ul>
        </Col>
      </Container>
    </>
  );
};
