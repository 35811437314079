import React, { useState, useEffect } from 'react';
import FontAwesome from 'react-fontawesome';
import { Row } from 'reactstrap';
import { CounterCol, CounterButton, Counter } from './counterFieldStyles';
import { cypressFriendlyId } from '../../utilities/cypressFriendlyId';
import PropTypes from 'prop-types';

function CounterField({ id, onIncrease, onDecrease, value, min = 0, countBy = 1 }) {
  const idCypressFriendly = cypressFriendlyId(id);
  console.error(idCypressFriendly);
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    if (value != null) {
      setCounter(value);
    }
  }, [value]);
  const handleIncrease = () => {
    const counterCurrent = counter;
    setCounter(counterCurrent + countBy);
    onIncrease(id, counterCurrent + countBy);
  };

  const handleDecrease = () => {
    const counterCurrent = counter;
    setCounter(counterCurrent - countBy);
    onDecrease(id, counterCurrent - countBy);
  };

  return (
    <Row>
      <CounterCol md={{ size: '6', offset: '3' }}>
        <CounterButton
          color="primary"
          disabled={counter - countBy < min}
          onClick={handleDecrease}
          id={`${idCypressFriendly}-decrease`}
        >
          <FontAwesome name="minus" />
        </CounterButton>
        <Counter id={`${idCypressFriendly}-value`}>{counter}</Counter>
        <CounterButton color="primary" onClick={handleIncrease} id={`${idCypressFriendly}-increase`}>
          <FontAwesome name="plus" />
        </CounterButton>
      </CounterCol>
    </Row>
  );
}

CounterField.propTypes = {
  id: PropTypes.string.isRequired,
  onIncrease: PropTypes.func.isRequired,
  onDecrease: PropTypes.func.isRequired,
  value: PropTypes.number,
  min: PropTypes.number,
  countBy: PropTypes.number,
};

export default CounterField;
