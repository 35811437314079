import styled from 'styled-components';
import { Navbar } from 'reactstrap';
import { colors } from '../../styles/constants';

export const NavMain = styled(Navbar)`
  border-bottom: 1px solid ${colors.accentColor};
  padding-bottom: 8px;
  img.brand-logo {
    width: 128px;
    height: auto;
  }
`;
