import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment-timezone';
import { HousingItem } from './HousingItem.jsx';
import Filter from '../filter';
import { getDisplayElement } from '../filter/getDisplayElement.js';
import { SortIcon } from '../icons/SortIcon';
import { Container, Row } from 'reactstrap';
import { HeaderCol, HeaderRow, ListCol, GridContainer, GridItem } from './HousingStyles';
import { HUFiltersStateCache } from '../../apollo/cache';
import { useReactiveVar } from '@apollo/client';
import { defaultState as HousingFiltersDefaultState } from './ListHousingDefaultState';
import { isAllComplete } from '../../utilities/isComplete';
import { sortHousingStatus } from './housingSortCb';
import { SmallText } from '../../styles/text';
import { sortDeadline } from './sortHousingDeadline';
import PropTypes from 'prop-types';

function ListHousing({ triggerRefetch, HousingUnit }) {
  const HUFiltersStateCacheVar = useReactiveVar(HUFiltersStateCache);
  const [sort, setSort] = useState('deadline');
  const [sortDirection, setSortDirection] = useState(true);
  const [housingUnits, setHousingUnits] = useState([]);

  const [filtersState, setFiltersState] = useState(
    Object.keys(HUFiltersStateCacheVar).length ? HUFiltersStateCacheVar : HousingFiltersDefaultState,
  );

  const [filteredHUs, setFilteredHUs] = useState([]);

  const getParStatus = (housingUnit) => {
    return {
      assignedParticipants: housingUnit.numAssignedParticipants,
      interestedParticipants: housingUnit.interestedParticipants?.length || 0,
      notifiedParticipants: housingUnit.numNotifiedParticipants,
    };
  };

  const getActiveStatus = (housingUnit) => {
    const { numAssignedParticipants, movedInParticipants, status } = housingUnit;
    const isAssigned = numAssignedParticipants?.length > 0;
    const isMovedIn = movedInParticipants?.length > 0;
    const isArchived = status === 'Archived';
    const isInactive = status === 'Inactive';
    const isComplete = isAllComplete(housingUnit) && !isInactive;
    return {
      inMatchProcess: isComplete && !isMovedIn && !isAssigned && !isArchived,
      matched: (isAssigned || isMovedIn) && !isArchived,
      incomplete: !isComplete && !isArchived,
      inactive: isArchived,
    };
  };

  const getSelectedFilterNames = (filterState, name) => {
    const categoryState = filterState[name];
    const selectedOptions = categoryState.options.filter((filter) => {
      return categoryState.selected.includes(filter[0]);
    });
    return selectedOptions.map((option) => option[2]);
  };

  const compareFilter = (hu, selectedFilterNames, name) => {
    const huActiveStatus = hu[name];
    const selectedUnitVals = [];
    Object.entries(huActiveStatus).forEach((property) => {
      if (selectedFilterNames.includes(property[0])) {
        selectedUnitVals.push(property[1]);
      }
    });
    return selectedUnitVals.some((property) => property);
  };

  const makeNewUnit = (hu, formatted) => {
    return {
      updated: moment(formatted.split('[')[0]),
      parStatus: getParStatus(hu),
      complete: isAllComplete(hu),
      activeStatus: getActiveStatus(hu),
      ...hu,
    };
  };

  useEffect(() => {
    if (HousingUnit && HousingUnit?.length) {
      const newFilteredUnits = [];
      const selectedFilterNames = getSelectedFilterNames(filtersState, 'activeStatus');
      const newHousingUnits = HousingUnit.map((hu) => {
        const formatted = hu?.updatedAt?.formatted || hu?.createdAt?.formatted;
        if (formatted) {
          const newUnit = makeNewUnit(hu, formatted);
          const isNotFiltered = compareFilter(newUnit, selectedFilterNames, 'activeStatus');
          if (isNotFiltered || !selectedFilterNames.length) {
            newFilteredUnits.push(newUnit);
          }
          return newUnit;
        } else {
          return;
        }
      });
      setHousingUnits(newHousingUnits);
      setFilteredHUs(newFilteredUnits);
    }
  }, [HousingUnit, setHousingUnits, setFilteredHUs]);

  useEffect(() => {
    setFilteredHUs((filteredHUs) => {
      let sortedHousingUnits = [...filteredHUs];
      if (sort === 'update') {
        sortedHousingUnits.sort((a, b) => {
          return b?.updated.diff(a?.updated) || 0;
        });
      }
      if (sort === 'status') {
        if (!sortDirection) {
          sortedHousingUnits.reverse();
        }
        sortedHousingUnits.sort(sortHousingStatus);
      }
      if (sort === 'deadline') {
        if (!sortDirection) {
          sortedHousingUnits.reverse();
        }
        sortedHousingUnits.sort(sortDeadline);
      }
      if (!sortDirection) {
        sortedHousingUnits = sortedHousingUnits.reverse();
      }
      return sortedHousingUnits;
    });
  }, [sort, sortDirection, setFilteredHUs, HousingUnit, setHousingUnits]);

  const toggle = useCallback(
    (name, selected, savedValue, save = false) => {
      if (save) {
        let filteredUnits;
        const newFilterState = Object.assign(
          {},
          {
            ...filtersState,
            [name]: { ...filtersState[name], open: !filtersState[name].open, selected, savedValue },
          },
        );

        if (selected.length) {
          const selectedFilterNames = getSelectedFilterNames(newFilterState, name);
          filteredUnits = housingUnits.filter((unit) => {
            return compareFilter(unit, selectedFilterNames, name);
          });
        } else {
          filteredUnits = [...housingUnits];
        }
        setFiltersState(newFilterState);
        HUFiltersStateCache(newFilterState);
        setFilteredHUs(filteredUnits);
      } else {
        setFiltersState((filtersState) =>
          Object.assign(
            {},
            {
              ...filtersState,
              [name]: {
                ...filtersState[name],
                open: !filtersState[name].open,
              },
            },
          ),
        );
      }
    },
    [housingUnits, housingUnits, filtersState, setFiltersState, HUFiltersStateCache],
  );

  const getDisplay = useCallback((value) => {
    return getDisplayElement(value);
  }, []);

  return (
    <Container className="list-items">
      <HeaderRow style={{ alignItems: 'baseline' }}>
        <SmallText fontSize={14}>Filter by: </SmallText>
        {Object.entries(filtersState).map(([key, value], i) => {
          return (
            <Filter
              key={`Filter-${key}-${value.selected.join('-')}`}
              name={key}
              display={getDisplay(value)}
              options={value.options}
              selected={value.selected}
              toggle={toggle}
              isOpen={value.open}
              controlled={value.controlled}
              limit={value.limit}
              placeholder={value.placeholder}
              filterType={value.filterType}
              savedValue={value.savedValue}
            />
          );
        })}
      </HeaderRow>
      <HeaderRow>
        <GridContainer>
          <GridItem className="column-header-1">
            <div
              onClick={() => {
                setSort('update');
                sort === 'update' ? setSortDirection(!sortDirection) : setSortDirection(true);
              }}
            >
              Updated {sort === 'update' && <SortIcon direction={sortDirection} />}
            </div>
          </GridItem>
          <GridItem className="column-header-2">
            <div
              onClick={() => {
                setSort('deadline');
                sort === 'deadline' ? setSortDirection(!sortDirection) : setSortDirection(true);
              }}
            >
              Hold Deadline {sort === 'deadline' && <SortIcon direction={sortDirection} />}
            </div>
          </GridItem>
          <GridItem className="column-header-3" $gridTemplateColumns="1fr 1fr .5fr">
            <div
              onClick={() => {
                setSort('status');
                sort === 'status' ? setSortDirection(!sortDirection) : setSortDirection(true);
              }}
            >
              Status {sort === 'status' && <SortIcon direction={sortDirection} />}
            </div>
          </GridItem>
        </GridContainer>
      </HeaderRow>
      {filteredHUs.map((unit) => {
        if (unit) {
          return (
            <Row key={unit?.id || 'undefkey'}>
              <ListCol>
                <HousingItem {...unit} triggerRefetch={triggerRefetch} />
              </ListCol>
            </Row>
          );
        }
        return <></>;
      })}
    </Container>
  );
}

ListHousing.propTypes = {
  HousingUnit: PropTypes.arrayOf(
    PropTypes.shape({
      numAssignedParticipants: PropTypes.number,
      movedInParticipants: PropTypes.array,
      interestedParticipants: PropTypes.array,
      numNotifiedParticipants: PropTypes.number,
      status: PropTypes.string,
      createdAt: PropTypes.object,
      updatedAt: PropTypes.object,
    }),
  ).isRequired,
  triggerRefetch: PropTypes.func.isRequired,
};

export default ListHousing;
