import React from 'react';
import styled from 'styled-components';
import FontAwesome from 'react-fontawesome';
import loadingImg from '../../assets/img/loading.gif';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import { MediumLargeText, LargeText } from '../../styles/text';
import { useToggle } from '../../utilities/hooks';

export const SpinnerDiv = styled.div`
  position: relative;
  width: ${(props) => props?.containerWidth || '100%'};
  z-index: 99;
`;

export const ImgContainer = styled.div`
  position: ${(props) => (props?.isStatic ? 'static' : 'absolute')};
  top: ${(props) => (props?.isStatic ? null : '25vh')};
  left: ${(props) => (props?.isStatic ? null : '50%')};
  margin: 0 auto;
  width: ${(props) => props?.width || '75px'};
  height: auto;
  overflow: hidden;
  img {
    width: 100%;
  }
`;

export const FilterAlertContainer = styled.div`
  text-align: center;
`;

export const ToastContainer = styled.div`
  ${(props) => (props.width ? 'width: 100%' : null)};
  display: flex;
  justify-content: center;
  align-items: center;
  .toast {
    flex-basis: 400px;
  }
  & .toast-body div {
    color: white;
  }
`;

export const LoadingSpinner = (props) => {
  return (
    <>
      <SpinnerDiv {...props} id="spinner">
        <ImgContainer {...props}>
          <img src={loadingImg} alt="Loading Spinner" />
        </ImgContainer>
      </SpinnerDiv>
    </>
  );
};

export const ErrorToast = (props) => {
  const [toggle, handleToggle] = useToggle(true);
  return (
    <ToastContainer {...props} id="error-toast">
      <Toast className="bg-warning" isOpen={toggle}>
        <ToastHeader
          toggle={handleToggle}
          icon={<FontAwesome name="exclamation-circle" size="lg" style={{ color: '#dc3545' }} />}
        >
          <LargeText>Error</LargeText>
        </ToastHeader>
        <ToastBody>
          <MediumLargeText>Something went wrong...</MediumLargeText>
        </ToastBody>
      </Toast>
    </ToastContainer>
  );
};

export const ContextFeedback = ({ msg = 'No Data Found' }) => {
  return (
    <>
      <FilterAlertContainer>
        <MediumLargeText>{msg}</MediumLargeText>
      </FilterAlertContainer>
    </>
  );
};
