import moment from 'moment';
import { getMomentTimezoneObj } from '../utilities/datetimeHelpers';
import { getNotifiedParticipants, getAssignedParticipants } from '../utilities/notifiedParticipants';

export const participantHasBeenNotified = (participant, housingUnit) => {
  const notifiedParticipants = getNotifiedParticipants(housingUnit);
  let isNotified = participant?.id in notifiedParticipants;
  if (isNotified) {
    let dateNotified = notifiedParticipants[participant?.id];
    return dateNotified;
  }
  return null;
};

export const participantHasBeenAssigned = (participant, housingUnit) => {
  const assignedParticipants = getAssignedParticipants(housingUnit);
  let isAssigned = participant?.id in assignedParticipants;
  if (isAssigned) {
    let dateAssigned = assignedParticipants[participant?.id];
    return dateAssigned;
  }
  return null;
};

export const participantHasMovedIn = (participant, housingUnit) => {
  let isMovedIn = participantIsMovedIn(participant, housingUnit);
  if (isMovedIn) {
    let dateMovedIn = moment(participant?.MOVED_IN_rel[0]?.date?.formatted?.split('[')[0]);
    return dateMovedIn;
  }
  return null;
};

export const participantIsInterested = (participant, housingUnit) => {
  return housingUnit?.interestedParticipants?.map((ip) => ip?.id).includes(participant.id);
};
export const participantIsDisinterested = (participant, housingUnit) => {
  return housingUnit?.disinterestedParticipants?.map((dp) => dp?.id).includes(participant.id);
};

export const participantIsAssigned = (participant, housingUnit) => {
  return housingUnit?.assignedParticipants?.map((dp) => dp?.id).includes(participant.id);
};

export const participantIsMovedIn = (participant, housingUnit) => {
  return housingUnit?.movedInParticipants?.map((dp) => dp?.id).includes(participant.id);
};
export const participantInterestInfoByNotifedHU = (participant) => {
  const { notifiedHousingUnits } = participant;

  const InfoByHu = notifiedHousingUnits?.map((hu) => {
    const parProfileByHu = {
      hu: null,
      isInterestedIn: null,
      isDisinterestedIn: null,
      notifiedInfo: {
        notified: null,
        notifiedDate: null,
      },
      movedInInfo: {
        isMovedIn: null,
        movedInDate: null,
      },
      assignedInfo: {
        isAssignedTo: null,
        assignedToDate: null,
      },
      actionRequired: null,
    };

    parProfileByHu.hu = hu;
    parProfileByHu.notifiedInfo = {
      notified: !!participantHasBeenNotified(participant, hu),
      notifiedDate: participantHasBeenNotified(participant, hu)?.format('M/DD/YYYY'),
    };
    parProfileByHu.isInterestedIn = participantIsInterested(participant, hu);
    parProfileByHu.isDisinterestedIn = participantIsDisinterested(participant, hu);
    parProfileByHu.assignedInfo = {
      isAssignedTo: participantIsAssigned(participant, hu),
      assignedToDate: participantHasBeenAssigned(participant, hu)?.format('M/DD/YYYY'),
    };
    parProfileByHu.movedInInfo = {
      isMovedIn: participantIsMovedIn(participant, hu),
      movedInDate: participantHasMovedIn(participant, hu)?.format('M/DD/YYYY'),
    };
    parProfileByHu.actionRequired = !parProfileByHu.isInterestedIn && !parProfileByHu.isDisinterestedIn;
    return parProfileByHu;
  });
  return InfoByHu;
};

export const findUniqueIdsBetweenObjArrays = (collectionToExcludeInResults, findUniqueItems) => {
  // Takes an array of objects (must contain id)
  // Returns all unique ids that aren't in first collection

  const itemsToExcludeSet = new Set(collectionToExcludeInResults?.map?.((i) => i.id) || []);
  const itemsToFilter = findUniqueItems?.map?.((i) => i.id) || [];
  const remainingItemsById = itemsToFilter.filter((item) => {
    return !itemsToExcludeSet.has(item?.id);
  });

  return remainingItemsById;
};
