import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import PropTypes from 'prop-types';
import { StyledAddButton } from './AddButtonStyles';
import { XMediumText } from '../../styles/text';
import { typography, colors } from '../../styles/constants';

function AddButton(props) {
  const history = useHistory();
  return (
    <StyledAddButton
      onClick={() => {
        if (props.to.startsWith('http')) {
          window.location = props.to;
        } else {
          history.push(props.to);
        }
      }}
      variant="outlined"
      id={`add-button-${props.type}`}
    >
      <ControlPointIcon /> &nbsp;
      <XMediumText color={'inherit'}>{props.content}</XMediumText>
    </StyledAddButton>
  );
}

AddButton.propTypes = {
  content: PropTypes.string,
  to: PropTypes.string,
  type: PropTypes.string.isRequired,
};

export default AddButton;
