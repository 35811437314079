import React from 'react';
import PropTypes from 'prop-types';
import {
  PreventActionMuiModal,
  PreventActionMuiBackdrop,
  PreventActionMuiHeader,
  PreventActionMsg,
  ModalBodyContainer,
  ModalCloseButton,
} from './PreventActionModalStyles';
import { ModalButtonGroup, ModalButtonClear, ModalButtonFilled } from './ModalStyles';

const PreventActionModal = ({
  handleClose,
  isOpen,
  handleCancelClick,
  handleActionButton,
  header,
  actionBtnText,
  cancelBtnText,
  message,
}) => {
  const msgStyleOptions = { $bold: true, $fontSize: '24px' };
  return (
    <PreventActionMuiBackdrop open={isOpen}>
      <PreventActionMuiModal
        open={isOpen}
        BackdropComponent={PreventActionMuiBackdrop}
        onBackdropClick={handleCancelClick}
      >
        <ModalBodyContainer>
          <ModalCloseButton onClick={handleCancelClick}>x</ModalCloseButton>
          {header && <PreventActionMuiHeader>{header}</PreventActionMuiHeader>}
          <PreventActionMsg {...msgStyleOptions}>{message}</PreventActionMsg>
          <ModalButtonGroup variant="text">
            {handleCancelClick && cancelBtnText && (
              <ModalButtonClear $border onClick={handleCancelClick}>
                {cancelBtnText}
              </ModalButtonClear>
            )}
            {handleActionButton && <ModalButtonFilled onClick={handleActionButton}>{actionBtnText}</ModalButtonFilled>}
          </ModalButtonGroup>
        </ModalBodyContainer>
      </PreventActionMuiModal>
    </PreventActionMuiBackdrop>
  );
};

PreventActionModal.defaultProps = {
  message: 'The action you are trying to perform is not allowed',
};

PreventActionModal.propTypes = {
  header: PropTypes.string,
  actionBtnText: PropTypes.string,
  cancelBtnText: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  handleClose: PropTypes.func,
  handleCancelClick: PropTypes.func,
  handleActionButton: PropTypes.func,
};

export default PreventActionModal;
