import { useQuery } from '@apollo/react-hooks';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  GrayFontAwesome,
  GreenFontAwesome,
  MediumLargeText,
  MediumText,
  RedFontAwesome,
  SmallText,
  XLargeText,
} from '../../styles/text';
import { LinkItem, PanelContainer } from '../../components/panels/HousingStyles';
import { LoadingSpinner } from '../../components/Alerts';
import { MainNavbar } from '../../components/navbars/MainNavBar';
import { InfoPanel, InfoGrid, InterestContainer, InterestPanel, AvatarGrid } from './styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { typography } from '../../styles/constants';
import { useLocation } from 'react-router-dom';
import houseDefault from '../../assets/img/house-default.png';

export default function Interest() {
  const domLocation = useLocation();
  const [submitted, setSubmitted] = useState(false);
  const [success, setSuccess] = useState(null);
  const [housingResult, setHousingResult] = useState({});
  const [totalRent, setTotalRent] = useState(0);
  const [interest, setInterest] = useState(false);
  const [housingId, setHousingId] = useState('');

  useEffect(() => {
    if (domLocation?.search) {
      const { housingId, participantId, interested } = qs.parse(domLocation.search);
      setHousingId(housingId);
      axios
        .get(`${process.env.REACT_APP_INTEREST_URL}?housingId=${housingId}&participantId=${participantId}`)
        .then((data) => {
          setHousingResult(data.data);
          const totalRent = data.data.bedrooms.reduce((acc, rent) => {
            return acc + rent;
          }, 0);
          setTotalRent(totalRent);
          setInterest(interested === '1');
        })
        .catch((e) => {
          console.error(e);
          setSuccess(false);
        });
    }
  }, [domLocation, setHousingResult, setTotalRent, setInterest, setSuccess]);
  useEffect(() => {
    if (Object.keys(housingResult).length && domLocation?.search) {
      const { housingId, participantId, interested } = qs.parse(domLocation.search);
      axios
        .post(process.env.REACT_APP_INTEREST_URL, { housingId, participantId, interested })
        .then((data) => {
          setSuccess(true);
        })
        .catch((err) => {
          setSuccess(false);
        });
    }
  }, [housingResult, domLocation]);

  return (
    <InterestContainer className="w-100">
      <MainNavbar noBorder noUser noItems noBackgroundColor />
      <PanelContainer marginTop={85}>
        {success == null && (
          <>
            <LoadingSpinner isStatic={true} data-testid="loading" />
            <XLargeText style={{ marginBottom: '48px', marginTop: '26px' }} className="text-center">
              Marking participant&apos;s response
            </XLargeText>
          </>
        )}
        {success === false && (
          <Container>
            <XLargeText marginBottom={20} className="text-center" fontSize={48} data-testid="failed">
              <RedFontAwesome name="times-circle" size="2x" className="text-center" />
            </XLargeText>
            <XLargeText className="text-center" marginBottom={20}>
              We&apos;re sorry, something went wrong.
            </XLargeText>
            <Container maxWidth="md">
              <MediumLargeText className="text-center" marginBottom={48}>
                Please return to your email and click the link again. If issues continue, you can reply to that email to
                let the matcher know about your participant’s interest in the unit.
              </MediumLargeText>
            </Container>
          </Container>
        )}
        {success && (
          <>
            <XLargeText marginBottom={20} className="text-center" fontSize={48} data-testid="success">
              <GrayFontAwesome name="check-circle" size="2x" className="text-center" />
            </XLargeText>
            <XLargeText className="text-center" marginBottom={24}>
              You’ve marked the participant’s response. Thank you!
            </XLargeText>
            <Container maxWidth="sm">
              <MediumLargeText className="text-center" marginBottom={42}>
                You can close this browser tab now.
              </MediumLargeText>
            </Container>
          </>
        )}
        <Container>
          <Container maxWidth="xs">
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <div
                  style={{
                    width: '100%!important',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}
                >
                  <SmallText marginRight={8}>
                    <Avatar
                      style={{ width: 42, height: 42, color: '#06446A', backgroundColor: 'rgba(195,195,195,0.3)' }}
                    />
                  </SmallText>
                  <SmallText marginBottom={36}>Participant</SmallText>
                </div>
              </Grid>
              <Grid item xs={6}>
                <MediumText bold marginLeft={20} marginTop={8}>
                  {housingResult.par}
                </MediumText>
              </Grid>
            </Grid>
          </Container>
          <InfoGrid container spacing={0}>
            <InfoPanel item sm={8}>
              {housingResult && Object.keys(housingResult).length && (
                <div data-testid="resolved-housing" style={{ display: 'flex', flexDirection: 'row' }}>
                  <Avatar style={{ width: 100, height: 100 }} src={housingResult?.images?.[0] || houseDefault} />
                  <div style={{ marginLeft: '20px' }}>
                    <MediumLargeText bold>{housingResult?.name}</MediumLargeText>
                    <MediumText lineHeight={typography.fontSize.smtitle}>{housingResult?.location}</MediumText>
                    <MediumText lineHeight={typography.fontSize.smtitle}>
                      Total Rent: ${totalRent}/ Avg Rent per room: $
                      {Math.round(totalRent / housingResult?.bedrooms?.length)}
                    </MediumText>
                    <LinkItem href={`${window.location.origin}/brochure?housingId=${housingId}`}>View Listing</LinkItem>
                  </div>
                </div>
              )}
            </InfoPanel>
            <InterestPanel item sm={4}>
              <SmallText bold marginLeft={50} marginTop={30}>
                Response
              </SmallText>
              <MediumText marginLeft={50} marginBottom={50} lineHeight={typography.fontSize.smtitle}>
                {interest ? (
                  <>
                    <GreenFontAwesome name="check-circle-o" /> Interested
                  </>
                ) : (
                  <>
                    {' '}
                    <RedFontAwesome name="times-circle-o" /> Not Interested
                  </>
                )}
              </MediumText>
            </InterestPanel>
          </InfoGrid>
        </Container>
      </PanelContainer>
    </InterestContainer>
  );
}
