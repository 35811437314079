import React from 'react';
import BedRoomDetail from './BedRoomDetail';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';
import { LoadingSpinner, ErrorToast } from '../../Alerts';
import PropTypes from 'prop-types';

const GetAmenityTypes = gql`
  query GetAmenityTypes {
    Amenity {
      id
      type
    }
  }
`;

function BedRoomDetailSection({ hasErrors, bedroomDetails, modifyBedroom }) {
  const [formState, setFormState] = React.useState([]);
  const [amenities, setAmenities] = React.useState(new Map());
  const { data, loading, error } = useQuery(GetAmenityTypes);
  React.useEffect(() => {
    if (data?.Amenity) {
      for (const datum of data.Amenity) {
        const { id, type } = datum;
        amenities.set(type, id);
      }
      setAmenities(amenities);
    }
  }, [data, amenities, setAmenities]);
  React.useEffect(() => {
    if (bedroomDetails) {
      setFormState(bedroomDetails);
    }
  }, [setFormState, bedroomDetails]);
  if (loading) {
    return <LoadingSpinner />;
  }
  if (error) {
    return <ErrorToast />;
  }
  return (
    <div className={'bedroom-detail-section'}>
      {formState.map((br, i) => {
        return (
          <div key={`bedroomDetail_${br.id}-${i}`} className="question-area">
            <BedRoomDetail
              hasErrors={hasErrors}
              roomDetails={br}
              amenities={amenities}
              modifyBedroom={modifyBedroom}
              id={i}
            />
          </div>
        );
      })}
    </div>
  );
}

BedRoomDetailSection.propTypes = {
  modifyBedroom: PropTypes.func,
  hasErrors: PropTypes.shape({
    bedroomErrors: PropTypes.boolean,
    urlErrors: PropTypes.boolean,
  }),
  roomDetails: PropTypes.shape({
    id: PropTypes.string,
    index: PropTypes.number,
    rent: PropTypes.number.isRequired,
    squareFeet: PropTypes.number.isRequired,
    amenities: PropTypes.array,
    originalAmenities: PropTypes.array,
    createdAt: PropTypes.instanceOf(Date),
  }),
};

export default BedRoomDetailSection;
