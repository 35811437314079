export const adminStyles = (theme) => ({
  textField: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#d8d8d8',
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.secondary.main,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.secondary.main,
      },
      '&.Mui-disabled': {
        backgroundColor: '#E0E0E0',
      },
      '&.Mui-disabled:hover': {
        borderColor: '#D8D8D8',
      },
    },
  },
  header: {
    textAlign: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#06446A',
  },
  label: {
    color: theme.palette.disabled.main,
    fontSize: '15px',
  },
  directions: { color: theme.palette.disabled.main },
  subdirections: { color: theme.palette.disabled.main, opacity: 0.5, fontSize: '15px' },
});
