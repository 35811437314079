import React from 'react';
import MapMarker from './BrochureMapMarker';
import { Typography } from '@material-ui/core';
import { cardStyles } from './BrochureStyles';
import { withStyles } from '@material-ui/core/styles';
import GoogleMapReact from 'google-map-react';

const BrochureMap = ({ classes, lat, lon }) => {
  return (
    <div className={classes.map}>
      <Typography variant="h3" color="primary" className={classes.mapHeader}>
        Location
      </Typography>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GMAPS_API,
        }}
        defaultCenter={{
          lat: lat,
          lng: lon,
        }}
        defaultZoom={14}
      >
        <MapMarker lat={lat} lng={lon} />
      </GoogleMapReact>
    </div>
  );
};

export default withStyles(cardStyles)(BrochureMap);
