import React from 'react';
import { MediumText } from '../../../styles/text';
import { NotesHeaderDiv, NotesContainer } from './NoteStyles';
import NoteInput from './NoteInput';
import Note from './Note';
import PropTypes from 'prop-types';
import { getMomentTimezoneObj } from '../../../utilities/datetimeHelpers';

function NotesPanel({
  currentUserId,
  forId,
  notes,
  refetch,
  createNoteMutation,
  deleteNoteMutation,
  editNoteMutation,
  align,
}) {
  const sortedNotes = notes?.slice().sort((a, b) => {
    const aTime = getMomentTimezoneObj(a.createdAt);
    const bTime = getMomentTimezoneObj(b.createdAt);
    return bTime.diff(aTime);
  });

  return (
    <NotesContainer align={align}>
      <NotesHeaderDiv>
        <MediumText>{`Notes (${notes?.length || 0})`}</MediumText>
      </NotesHeaderDiv>
      <NoteInput forId={forId} refetch={refetch} createNoteMutation={createNoteMutation} />
      {sortedNotes?.map((note) => {
        return (
          <Note
            key={note.id}
            currentUserId={currentUserId}
            noteData={note}
            deleteNoteMutation={deleteNoteMutation}
            editNoteMutation={editNoteMutation}
            refetch={refetch}
            id={note.id}
          />
        );
      })}
      <div>&nbsp;</div>
    </NotesContainer>
  );
}

NotesPanel.propTypes = {
  currentUserId: PropTypes.string.isRequired,
  forId: PropTypes.string.isRequired,
  notes: PropTypes.array.isRequired,
  refetch: PropTypes.func.isRequired,
  createNoteMutation: PropTypes.func.isRequired,
  deleteNoteMutation: PropTypes.func.isRequired,
  editNoteMutation: PropTypes.func.isRequired,
  align: PropTypes.string,
};

export default NotesPanel;
