export default (housingUnit) => {
  const newState = {
    accessibilitiesWithDegree: {},
    parkingSpots: 0,
    parkingSpotsAdaAccessible: false,
  };
  if (housingUnit?.accessibilitiesWithDegree) {
    newState.accessibilitiesWithDegree = housingUnit.accessibilitiesWithDegree.reduce(
      (acc, { Accessibility: { id }, degree }) => {
        return { ...acc, [id]: degree };
      },
      {},
    );
  }
  newState.originalAccessibilitiesWithDegree = { ...newState.accessibilitiesWithDegree };
  newState.parkingSpots = housingUnit.parkingSpots;
  newState.parkingSpotsAdaAccessible = housingUnit.parkingSpotsAdaAccessible;
  return newState;
};
