import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { ReassignAdvocate } from '../panels/AdvocatesGQL';
import { ModalHeader, ModalBody, Spinner } from 'reactstrap';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Form } from 'reactstrap';
import { GlobalStyle } from './AssignParsModalStyles';
import { UnitReadyModal, UnitReadyModalFooter, UnitReadyModalBtn } from './UnitCompleteModalStyles';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';

function ReassignParModal({ participant, advocates, toggleModalOpen, handleConfirm, isLoading, isError, refetch }) {
  const [submitted, setSubmitted] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [selectedAdvocate, setSelectedAdvocate] = useState(false);
  const [filteredAdvocates, setFilteredAdvocates] = useState(advocates);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filterStr, setFilterStr] = useState('');

  const [reassignAdvocate, { loading: loadingReassign, error: errorReassign }] = useMutation(ReassignAdvocate, {
    onCompleted() {
      refetch?.();
    },
  });
  const id = 'reactivate-par-modal';

  useEffect(() => {
    if (isLoading) {
      setShowLoading(true);
    } else {
      setShowLoading(false);
    }
  }, [isLoading]);

  const toggle = () => {
    if (advocates?.length > 0) {
      setDropdownOpen((prevState) => !prevState);
    }
  };

  const filterOptions = (filterStr) => {
    const newFilterStr = filterStr?.toLowerCase();
    setFilterStr(newFilterStr);
    return advocates.filter((advocate) => advocate.name.toLowerCase().includes(newFilterStr));
  };

  const handleChangeFilter = (e) => {
    const filtered = filterOptions(e.target.value);
    setFilteredAdvocates(filtered);
  };

  const handleSelectAdvocate = (selectedAdvocate) => {
    setSelectedAdvocate(selectedAdvocate);
    setFilterStr(selectedAdvocate?.name);
    const filtered = advocates.filter((a) => a?.name?.toLowerCase().includes(selectedAdvocate?.name?.toLowerCase()));
    setFilteredAdvocates(filtered);
  };

  useEffect(() => {
    const reassign = async () => {
      await reassignAdvocate({ variables: { id: participant.id, advocate: selectedAdvocate.id } });
    };
    if (selectedAdvocate?.id) {
      reassign();
    }
  }, [selectedAdvocate, reassignAdvocate]);

  const handleConfirmClick = () => {
    setSubmitted(true);
    {
      if (!isLoading) {
        handleConfirm();
      }
      toggleModalOpen(false);
    }
  };

  const AdvocateDropdown = () => (
    <Dropdown id={id} isOpen={dropdownOpen} toggle={toggle} direction="down" style={{ width: '300px' }}>
      <DropdownToggle tag="form" data-toggle="dropdown">
        <Form style={{ width: '300px' }}>
          <Input autoFocus placeholder="Type to filter" onChange={handleChangeFilter} value={filterStr}></Input>
        </Form>
      </DropdownToggle>
      {isLoading && <Spinner size="sm" color="dark" />}
      <DropdownMenu style={{ maxHeight: '400px', overflowY: 'auto' }}>
        {filteredAdvocates.map((advocate) => {
          return (
            <DropdownItem key={advocate.id} onClick={(e) => handleSelectAdvocate(advocate)}>
              {advocate.name}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );

  return (
    <>
      <UnitReadyModal id={id} size="md" centered backdropClassName="customDrop" isOpen={true}>
        {' '}
        <GlobalStyle />
        <ModalHeader className="text-center">Assign Advocate to Reactivate Participant</ModalHeader>
        <div style={{ padding: '10px 40px 0', textAlign: 'left' }}>
          Participants need to have an advocate assigned in order to be reactivated.
        </div>
        <div style={{ margin: '20px auto 30px' }}>
          <div style={{ fontSize: '14px' }}>Selecte an Advocate</div>
          <AdvocateDropdown />
        </div>
        <UnitReadyModalFooter>
          <UnitReadyModalBtn $unfilled onClick={() => toggleModalOpen(false)} id="cancel">
            Cancel
          </UnitReadyModalBtn>
          <UnitReadyModalBtn
            $filled
            onClick={() => {
              handleConfirmClick();
            }}
            disabled={false}
            id="affirm"
          >
            {showLoading && (
              <>
                <Spinner style={{ width: '1rem', height: '1rem' }} /> reactivating participant...
              </>
            )}
            {!submitted && <>Submit</>}
          </UnitReadyModalBtn>
        </UnitReadyModalFooter>
      </UnitReadyModal>
    </>
  );
}

export default ReassignParModal;
