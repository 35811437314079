import { gql } from '@apollo/client';

export const GET_HOUSING = gql`
  query AllHousing {
    HousingUnit {
      id
      __typename
      unitNumber
      location
      name
      deadline {
        formatted
      }
      unitType
      viewingNotes {
        id
        createdAt {
          formatted
        }
        content
      }
      LISTS_rel {
        Listing {
          id
          listingUrl
        }
        createdAt {
          formatted
          timezone
        }
      }
      SHOWS_rel(orderBy: order_asc) {
        Image {
          id
          url
          publicId
        }
        order
      }
      status
      description
      region {
        id
        name
      }
      bedrooms {
        id
        rent
        createdAt {
          formatted
        }
        updatedAt {
          formatted
        }
      }
      allowsIndoorSmoking
      allowsSexOffenders
      accessibilitiesWithDegree {
        degree
        Accessibility {
          id
          ADA
          detail
        }
      }
      parkingSpotsAdaAccessible
      parkingSpots
      status
      createdAt {
        formatted
        timezone
      }
      updatedAt {
        formatted
        timezone
      }
      movedInParticipants {
        id
        createdAt {
          formatted
        }
      }
      interestedParticipants {
        createdAt {
          formatted
        }
      }
      numNotifiedParticipants
      numAssignedParticipants
      numInterestedParticipants
    }
  }
`;
