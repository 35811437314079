import { Button, Row, Col } from 'reactstrap';
import styled from 'styled-components';

export const SelectOneButton = styled(Button)`
  height: 80px;
  color: #06446a;
  border-color: #d8d8d8;
  background-color: #fff;
  margin: ${(props) => (props.$margin ? props.$margin : '0 10px 10px 0')};
  width: ${(props) => (props.$width ? props.$width : '180px')};
  font-size: ${(props) => (props.$fontSize === 'small' ? '16px' : '1.25rem')};

  &.active,
  &:focus,
  &:active {
    background-color: ${(props) => (props.$id === 'par-reason' ? 'white' : '#5bb8b4')} !important;
    border-color: #5bb8b4 !important;
    box-shadow: none !important;
    color: ${(props) => props.$id === 'par-reason' && '#06446a !important'};
  }

  &:hover {
    color: #06446a;
    border-color: #d8d8d8;
    background-color: #a5c9ff14;
  }
`;

export const SelectOneRow = styled(Row)`
  text-align: center;
  margin: ${(props) => (props.$margin ? props.$margin : 'inherit')};
`;

export const SelectOneCol = styled(Col)``;
