import styled from 'styled-components';
import {
  MuiModalTemplate,
  MuiBackdropTemplate,
  MuiModalHeader,
  MuiModalCloseButtonTemplate,
  ModalButtonGroup,
} from './ModalStyles';
import { typography } from '../../styles/constants';

export const ActionMuiModal = styled(MuiModalTemplate)``;
export const ActionMuiBackdrop = styled(MuiBackdropTemplate)`
  &.MuiBackdrop-root {
    ${(props) => (props.$opacity ? `opacity: ${props.$opacity} !important;` : null)}
  }
`;
export const ActionMuiHeader = styled(MuiModalHeader)`
  &.action-modal-header {
    font-weight: bold;
    color: ${typography.fontColor.text};
  }
`;
export const ModalBodyContainer = styled.div`
  position: relative;
  width: 100vw;
  max-width: 500px;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-itmes: center;
  text-align: center;
  padding: 30px;
  &:focus-visible {
    outline: 0 !important;
  }

  & #parEmailInput,
  & .action-btn {
    max-width: 400px;
  }

  & .action-btn {
    width: 100%;
    min-height: 48px;
    min-width: 32px;
    border-radius: 8px;
  }

  & .action-btn[class*='save'] {
    margin-top: 30px;
    margin-bottom: 10px;
  }

  & .action-btn[class*='delete'] {
    background-color: transparent;
    color: ${typography.fontColor.alertLinkColor};
    font-size: ${typography.fontSize.lgtext};
    width: max-content;
    min-height: max-content;
  }

  & .action-btn[class*='hidden'] {
    display: none;
  }

  .MuiButtonGroup-groupedTextVertical:not(:last-child),
  .MuiButtonGroup-groupedVertical:not(:first-child) {
    border-bottom: unset;
  }

  .MuiButtonGroup-groupedVertical:not(:last-child),
  .MuiButtonGroup-groupedVertical:not(:first-child) {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  #parEmailInput {
    border: 1px solid #357e7e;
    border-radius: 4px;
    height: 56px;
    max-height: 56px;
    padding: 0 0 0 15px;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before,
  .MuiInput-underline:before,
  .MuiInput-underline:after {
    display: none;
  }

  .MuiButtonGroup-root {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ActionModalButtonGroup = styled(ModalButtonGroup)``;

export const ModalCloseButton = styled(MuiModalCloseButtonTemplate)`
  top: 0;
  right: 5px;
`;
