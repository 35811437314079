import React from 'react';
import { Typography } from '@material-ui/core';

export default function BrochureSlateParagraph({ attributes, children, element }) {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return (
        <Typography color="primary" variant="h5" {...attributes}>
          {children}
        </Typography>
      );
    case 'heading-two':
      return (
        <Typography color="primary" variant="h6" {...attributes}>
          {children}
        </Typography>
      );
    case 'list-item':
      return (
        <li style={{ marginBottom: '12px', paddingLeft: '8px' }}>
          <Typography variant="body1" color="primary" {...attributes}>
            {children}
          </Typography>
        </li>
      );
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    default:
      return (
        <Typography variant="body1" color="primary" {...attributes}>
          {children}
        </Typography>
      );
  }
}
