import { gql } from '@apollo/client';

export const MarkInterestInHousingUnit = gql`
  mutation MarkInterestInHousingUnit($participants: [ID!]!, $housingUnit: ID!) {
    MarkInterest(participants: $participants, housingUnit: $housingUnit) {
      id
      interestedParticipants {
        id
        name
      }
    }
  }
`;

export const RemoveInterestInHousingUnit = gql`
  mutation RemoveInterestInHousingUnit($participant: _ParticipantInput!, $housingUnit: _HousingUnitInput!) {
    RemoveHousingUnitInterestedParticipants(from: $participant, to: $housingUnit) {
      from {
        id
      }
      to {
        id
      }
    }
  }
`;

export const MarkDisinterestInHousingUnit = gql`
  mutation MarkDisinterestInHousingUnit($participants: [ID!]!, $housingUnit: ID!) {
    MarkDisinterest(participants: $participants, housingUnit: $housingUnit) {
      id
      interestedParticipants {
        id
        name
      }
    }
  }
`;

export const RemoveDisinterestInHousingUnit = gql`
  mutation RemoveDisinterestInHousingUnit($participant: _ParticipantInput!, $housingUnit: _HousingUnitInput!) {
    RemoveHousingUnitDisinterestedParticipants(from: $participant, to: $housingUnit) {
      from {
        id
      }
      to {
        id
      }
    }
  }
`;
