import styled from 'styled-components';
import { Button } from 'reactstrap';
import { borders, links, typography } from '../styles/constants';
export const DeselectCircleButton = styled(Button)`
  position: absolute;
  top: -15px;
  right: -15px;
  width: 39px;
  height: 39px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid #ff8181;
  color: #ff8181;
  padding: 0px;
  margin: 0px;
`;
export const ParToParCardContainer = styled.div`
  margin-left: 10px;
  ${(props) => (props.size === 'large' ? 'padding: 0 24px 24px 24px;' : 'padding: 24px;')}
  ${(props) =>
    props.border
      ? `border:  ${props.disabled ? borders.borderDisabled : borders.border}`
      : `border-bottom: ${borders.border}`};
  position: relative;
  width: ${(props) => (props.size === 'large' ? '100%' : '264px')};
  ${(props) => (props.size === 'large' ? 'margin: 0px 10px 0px 10px;' : 'margin: 30px 10px 30px 15px;')}
  ${(props) => (props.size === 'large' ? '' : `border-radius: ${borders.borderRadius};`)}
  ${(props) => (props.size === 'large' ? '' : `box-shadow: ${borders.boxShadow};`)}
  height: ${(props) => (props.size === 'large' ? '120px' : '80px')};
`;

export const PlusButton = styled(Button)`
  border-radius: 50%;
  background-color: ${links.regularLink};
  width: 40px;
  height: 40px;
  line-height: 30px;
  padding: 0px;
  margin: 0px;
  font-size: ${typography.fontSize.title};
  font-weight: 500;
  font-color: ${typography.fontColor.white};
  border: none;
  box-shadow: 0px 3px 6px #31a3a5;
`;
