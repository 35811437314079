const createOptionsMap = (options) => {
  const optionsMap = new Map();
  for (const option of options) {
    optionsMap.set(option.role, option.id);
  }
  return optionsMap;
};

const userRoleOptions = [
  { role: { name: 'Locator', helperText: '', definition: 'View/edit access to Housing Units' }, id: 'HousingLocator' },
  {
    role: {
      name: 'Advocate',
      helperText: '',
      definition: 'View/edit access to Participants + Match notifications',
    },
    id: 'Advocate',
  },
  {
    role: {
      name: 'MPO',
      helperText: '(Matching Process Owner)',
      definition: 'All Unit + All Participant + Admin Access',
    },
    id: 'MPO',
  },
  {
    role: { name: 'User Admin', helperText: '', definition: 'Manage Staff within Organization' },
    id: 'UserAdmin',
  },
];
export const userRoleSelectionOptions = createOptionsMap(userRoleOptions);
