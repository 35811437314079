import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardContent } from '@material-ui/core';
import { cardStyles } from './BrochureStyles';
import PropTypes from 'prop-types';

const BrochureCardWrapper = ({ classes, children }) => {
  return (
    <Card elevation={0} className={classes.card}>
      <CardContent className={classes.content}>{children}</CardContent>
    </Card>
  );
};

BrochureCardWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default withStyles(cardStyles)(BrochureCardWrapper);
