export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const capitalizeSentence = (s) => {
  var re = /(\b[a-z](?!\s))/g;
  return s.replace(re, (x) => {
    return x.toUpperCase();
  });
};
