import React, { useEffect, useState, useCallback } from 'react';
import { CloudinaryImage } from '../CloudinaryImage';
import HouseDefault from '../../assets/img/house-default.png';
import { DeleteBedroom } from '../HousingUnitCreation/sections/RentRoomGQL';
import { RemoveHousingUnitSHOWS_rel } from '../Inputs/ImageGallerySelectField.jsx';
import {
  DeleteUnitGQL,
  RemoveHousingUnitLISTS_rel,
  ArchiveUnit,
  ReactivateUnit,
} from '../TableHousing/TableHousingGQL';
import ConfirmModal from '../modals/ConfirmModal';
import PreventActionModal from '../modals/PreventActionModal';
import { sumArray, minArray, maxArray } from '../../utilities/arrayOperations';
import {
  ListGroupRow,
  StreetText,
  LinkItem,
  PanelContainer,
  AvatarDiv,
  InformationDiv,
  HorizontalContainer,
  PanelCol,
  PanelRow,
  AllowanceCheckMarkImg,
  PanelGrid,
  PanelGridItem,
} from './HousingStyles';
import { useToggle } from '../../utilities/hooks';
import Avatar from 'react-avatar';
import { LargeText, MediumLargeText, MediumText } from '../../styles/text';
import { ContextFeedback } from '../Alerts';
import { useMutation } from '@apollo/react-hooks';
import { useRouteMatch, useHistory, Link } from 'react-router-dom';
import DeleteEditMenu from '../menus/DeleteEditMenu';
import { physicalAccessibilityOptionsDict } from '../HousingUnitCreation/sections/AccessibilityByDegreesDict';
import AllowanceCheckMark from '../../assets/img/icon-on-track.svg';
import { createHoldDeadlineDisplay } from '../../utilities/datetimeHelpers';
import { IconButton, EditIcon } from '../AssignedParCardStyles';
import { checkPropTypes } from 'prop-types';

export const HousingInfoPanel = ({ housingUnit }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [modalDeactivateOpen, toggleDeactivateModal] = useToggle(false);
  const [modalDeleteOpen, toggleDeleteModalOpen] = useToggle(false);
  const [modalArchiveOpen, toggleArchiveModalOpen] = useToggle(false);
  const [modalReactivateOpen, toggleReactivateModalOpen] = useToggle(false);
  const [menuOpen, toggleMenuOpen] = useToggle(false);
  const [deleteUnit] = useMutation(DeleteUnitGQL);
  const [archiveUnit] = useMutation(ArchiveUnit);
  const [reactivateUnit] = useMutation(ReactivateUnit);
  const [deleteBedroom] = useMutation(DeleteBedroom);
  const [deleteListing] = useMutation(RemoveHousingUnitLISTS_rel);
  const [deleteImage] = useMutation(RemoveHousingUnitSHOWS_rel);
  const [hasAllowances, setHasAllowances] = useState(false);
  const [isHousingUnitMatch, setIsHousingUnitMatch] = useState(false);
  const [isHousingUnitDetails, setIsHousingUnitDetails] = useState(false);
  const [canDeactivate, setCanDeactivate] = useState(false);
  const [isHuFilled, setIsHuFilled] = useState(false);

  useEffect(() => {
    if (housingUnit) {
      if (
        housingUnit.allows.length ||
        housingUnit.allowsConvictions ||
        housingUnit.allowsEvictions ||
        housingUnit.allowsIndoorSmoking ||
        housingUnit.allowsSexOffenders
      ) {
        setHasAllowances(true);
      }
      const { movedInParticipants, assignedParticipants, bedrooms } = housingUnit;
      const isVacant = movedInParticipants?.length + assignedParticipants?.length === 0;
      const isFilled = bedrooms?.length === movedInParticipants?.length;
      setCanDeactivate(isVacant);
      setIsHuFilled(isFilled);
    }
  }, [housingUnit, setHasAllowances, setCanDeactivate, setIsHuFilled]);

  useEffect(() => {
    if (match) {
      if (match?.url?.includes('/housing/detail/')) {
        setIsHousingUnitDetails(true);
        setIsHousingUnitMatch(false);
      } else if (match?.url?.includes('/housing/match/')) {
        setIsHousingUnitDetails(false);
        setIsHousingUnitMatch(true);
      }
    }
  }, [match, setIsHousingUnitMatch, setIsHousingUnitDetails]);

  const huCB = useCallback(
    async (action) => {
      if (housingUnit) {
        const { LISTS_rel, SHOWS_rel, bedrooms } = housingUnit;
        if (action === 'edit') {
          history.push(`/housing/edit/${housingUnit.id}/location`);
        } else if (action === 'archive') {
          await archiveUnit({
            variables: {
              id: housingUnit.id,
            },
          });
          history.push('/housing/');
        } else if (action === 'unarchive') {
          await reactivateUnit({
            variables: {
              id: housingUnit.id,
            },
          });
          history.push('/housing/');
        } else {
          if (bedrooms) {
            for (const bedroom of bedrooms) {
              if (bedroom) {
                await deleteBedroom({ variables: { id: bedroom.id } });
              }
            }
          }
          if (SHOWS_rel) {
            for (const image of SHOWS_rel) {
              if (image?.Image) {
                await deleteImage({ variables: { from: { id: image.Image.id }, to: { id: housingUnit.id } } });
              }
            }
          }
          if (action === 'delete') {
            await deleteUnit({ variables: { id: housingUnit.id } });
          }
          history.push('/housing/');
        }
      }
    },
    [useHistory, deleteUnit, archiveUnit, reactivateUnit, housingUnit],
  );
  if (!housingUnit) {
    return <ContextFeedback />;
  }
  const { name, location, SHOWS_rel, bedrooms, unitNumber, numNotifiedParticipants } = housingUnit;

  if (!bedrooms?.length) {
    return <ContextFeedback msg="No bedrooms" />;
  }
  const totalRent = sumArray(bedrooms?.map((b) => b?.rent));
  const minRent = minArray(bedrooms?.map((b) => b?.rent));
  const maxRent = maxArray(bedrooms?.map((b) => b?.rent));

  const listingUrl = housingUnit?.id ? `${window.location.origin}/brochure?housingId=${housingUnit?.id}` : '';

  const modalBody = (
    <>
      <LargeText>{name}</LargeText>
      <MediumLargeText>{location}</MediumLargeText>
      {unitNumber && (
        <MediumLargeText>
          {unitNumber.toLowerCase().startsWith('unit') ? '' : 'Unit '}
          {unitNumber}
        </MediumLargeText>
      )}
    </>
  );

  const createAllowances = () => {
    const allowedPets = {
      dog: [],
      cat: false,
    };
    const petSizeDict = {
      // needs updating if property capitalization changes
      small: 'sm',
      big: 'lg',
    };

    for (let pet of housingUnit?.allows) {
      pet.species.toLowerCase() === 'Dog'.toLowerCase() && allowedPets.dog.push(petSizeDict[pet.size?.toLowerCase()]);
      pet.species.toLowerCase() === 'Cat'.toLowerCase() && (allowedPets.cat = true);
    }

    const petDisplay = `Pets: ${
      allowedPets.dog.length > 1 || allowedPets.dog.includes('lg')
        ? `dogs`
        : allowedPets.dog.length == 1 && allowedPets.dog.includes('sm')
        ? `sm. dogs`
        : ''
    }${allowedPets.cat ? `${allowedPets.dog.length ? ' &' : ''} cats` : ''}`;

    const allowsSmoking = housingUnit?.allowsIndoorSmoking ? (
      <PanelGridItem>
        <AllowanceCheckMarkImg src={AllowanceCheckMark} alt="Allowance Checkmark" />
        Smoking
      </PanelGridItem>
    ) : null;
    const allowsEvictions = housingUnit?.allowsEvictions ? (
      <PanelGridItem>
        <AllowanceCheckMarkImg src={AllowanceCheckMark} alt="Allowance Checkmark" />
        Evictions
      </PanelGridItem>
    ) : null;
    const allowsSexOffenders = housingUnit?.allowsSexOffenders ? (
      <PanelGridItem>
        <AllowanceCheckMarkImg src={AllowanceCheckMark} alt="Allowance Checkmark" />
        Sex Offenders
      </PanelGridItem>
    ) : null;
    const allowsConvictions = housingUnit?.allowsConvictions ? (
      <PanelGridItem>
        <AllowanceCheckMarkImg src={AllowanceCheckMark} alt="Allowance Checkmark" />
        Convictions
      </PanelGridItem>
    ) : null;
    const allowsPets = housingUnit?.allows?.length ? (
      <PanelGridItem>
        <AllowanceCheckMarkImg src={AllowanceCheckMark} alt="Allowance Checkmark" />
        {petDisplay}
      </PanelGridItem>
    ) : null;
    return (
      <PanelGrid $gridColumnGap="15px">
        {allowsSmoking}
        {allowsEvictions}
        {allowsSexOffenders}
        {allowsConvictions}
        {allowsPets}
      </PanelGrid>
    );
  };

  const handleSelectArchive = () => {
    if (canDeactivate) {
      toggleArchiveModalOpen();
    } else {
      toggleDeactivateModal();
    }
  };

  const handleClickEdit = () => {
    history.push(`/housing/edit/${housingUnit?.id}/viewing-options`);
  };

  return (
    <>
      <PreventActionModal
        isOpen={modalDeactivateOpen}
        handleCancelClick={toggleDeactivateModal}
        message="A unit can't be deactivated while a participant is assigned to it or living there."
      />
      <ConfirmModal
        confirmationText="Are you sure you want to delete this unit?"
        submittedMsg="deleting..."
        modalOpen={modalDeleteOpen}
        toggleModalOpen={toggleDeleteModalOpen}
        handleConfirm={huCB}
        actionType={'delete'}
      >
        {modalBody}
      </ConfirmModal>
      <ConfirmModal
        confirmationText="Are you sure you want to mark this unit as inactive?"
        submittedMsg="archiving..."
        modalOpen={modalArchiveOpen}
        toggleModalOpen={toggleArchiveModalOpen}
        handleConfirm={huCB}
        actionType={'archive'}
      >
        {modalBody}
      </ConfirmModal>
      <ConfirmModal
        confirmationText="Are you sure you want to mark this unit as active?"
        submittedMsg="removing archive status..."
        modalOpen={modalReactivateOpen}
        toggleModalOpen={toggleReactivateModalOpen}
        handleConfirm={huCB}
        actionType={'unarchive'}
      >
        {modalBody}
      </ConfirmModal>
      <ListGroupRow>
        <PanelGrid $gridTemplateColumns=".75fr 1fr">
          <PanelGridItem $padding="12px 0">
            <HorizontalContainer>
              <AvatarDiv>
                {housingUnit && housingUnit?.SHOWS_rel[0]?.Image?.publicId ? (
                  <CloudinaryImage
                    publicId={housingUnit?.SHOWS_rel[0]?.Image?.publicId}
                    width="115"
                    height="115"
                    round={true}
                  />
                ) : (
                  <Avatar size="115" round src={HouseDefault} />
                )}
              </AvatarDiv>
              <InformationDiv>
                <LargeText bold>{name}</LargeText>
                <StreetText>{location}</StreetText>
                {unitNumber && (
                  <StreetText>
                    {unitNumber.toLowerCase().startsWith('unit') ? unitNumber : `Unit ${unitNumber}`}
                  </StreetText>
                )}
                <p className="rent">{`Total Rent: $${totalRent.toLocaleString()} / Cost per room $${minRent.toLocaleString()}-${maxRent.toLocaleString()}`}</p>
                {listingUrl ? (
                  <LinkItem href={listingUrl} target="_blank" rel="noopener noreferrer">
                    Link to listing
                  </LinkItem>
                ) : null}
              </InformationDiv>
            </HorizontalContainer>
          </PanelGridItem>
          <PanelGridItem>
            {isHousingUnitMatch ? (
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', height: '100%' }}>
                <PanelCol $display={'flex'} $flexDirection={'column'} $justifyContent={'center'}>
                  <PanelContainer padding={`0px`}>
                    <PanelGrid $justifyContent={'normal'}>
                      <PanelGridItem className="additionalInfoLabel">Region</PanelGridItem>
                      <PanelGridItem className="additionalInfo">
                        {housingUnit?.region?.name?.replace('SPA', '')}
                      </PanelGridItem>
                      <PanelGridItem className="additionalInfoLabel">Accessibility</PanelGridItem>
                      <PanelGridItem className="additionalInfo">
                        {housingUnit?.accessibilitiesWithDegree?.length
                          ? physicalAccessibilityOptionsDict[housingUnit?.accessibilitiesWithDegree?.[0]?.degree]?.[
                              'HousingUnit'
                            ]
                          : 'More than 1 flight'}
                      </PanelGridItem>
                    </PanelGrid>
                  </PanelContainer>
                </PanelCol>
                <PanelCol
                  $display={'flex'}
                  $flexDirection={'column'}
                  $backgroundColor={'rgba(165, 201, 253, .21)'}
                  $justifyContent={'center'}
                >
                  <PanelContainer padding={'0px'}>
                    <PanelRow $justifyContent={'center'}>
                      <h6 className="allowances">{hasAllowances ? 'Landlord Allowances' : 'No Allowances'}</h6>
                    </PanelRow>
                    {createAllowances()}
                  </PanelContainer>
                </PanelCol>
              </div>
            ) : isHousingUnitDetails ? (
              <PanelGrid $gridTemplateColumns="1fr 1fr 1fr" style={{ height: '100%' }}>
                <PanelGridItem>
                  <div className="hold-deadline">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div>Hold Deadline</div>
                      {housingUnit?.deadline?.formatted && (
                        <IconButton onClick={handleClickEdit} className="edit-btn-icon">
                          <EditIcon />
                        </IconButton>
                      )}
                    </div>
                    <div>
                      {housingUnit?.deadline?.formatted ? (
                        createHoldDeadlineDisplay(housingUnit?.deadline, housingUnit?.status, isHuFilled)
                      ) : (
                        <Link to={`/housing/edit/${housingUnit?.id}/viewing-options`} className="hold-deadline-add">
                          Add Deadline
                        </Link>
                      )}
                    </div>
                  </div>
                </PanelGridItem>
                <PanelGridItem className="additional-options">
                  <DeleteEditMenu
                    handleClick={huCB}
                    status={housingUnit.status}
                    isDeletable={!numNotifiedParticipants}
                    menuOpen={menuOpen}
                    toggleMenuOpen={toggleMenuOpen}
                    toggleDelete={toggleDeleteModalOpen}
                    toggleArchive={handleSelectArchive}
                    toggleReactivateModalOpen={toggleReactivateModalOpen}
                  />
                </PanelGridItem>
              </PanelGrid>
            ) : null}
          </PanelGridItem>
        </PanelGrid>
      </ListGroupRow>
    </>
  );
};
