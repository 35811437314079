/* eslint-disable */
// The foreachs are giving eslint ts an issue
export default (housingUnit) => {
  let bedrooms = [];
  housingUnit.bedrooms?.forEach((br) => {
    if (br) {
      const { id, rent, squareFeet, amenities, createdAt } = br;
      const amenityIds = [];
      amenities?.forEach((a) => {
        if (a?.id) {
          amenityIds.push(a.id);
        }
      });
      let date = new Date();
      if (createdAt?.formatted) {
        new Date(createdAt.formatted);
      }
      const state = {
        id,
        rent,
        squareFeet,
        index: 0, // no real indexing here, see sort
        amenities: amenityIds,
        originalAmenities: amenityIds,
        createdAt: date,
      };
      bedrooms.push(state);
    }
  });
  bedrooms = bedrooms
    .sort((a, b) => {
      return b.createdAt.getTime() - a.createdAt.getTime();
    })
    .map((br, i) => {
      return { ...br, index: i };
    });
  return bedrooms;
};
