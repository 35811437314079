import React, { Fragment } from 'react';
import Pluralizer from '../../utilities/pluralizer.js';
import { SmallText } from '../../styles/text';

export const getDisplayElement = (value) => {
  let text = '';
  let boldText = '';
  if (value.filterType === 'numeric' && value.savedValue) {
    boldText = `$${value.savedValue}+`;
  } else if (value?.selected?.length === 0) {
    text = value.display;
  } else {
    if (value.selected.length === 1) {
      text = value.options.filter((v) => v[0] === value.selected[0])[0][1];
    } else if (value.selected.length === value.options.length) {
      boldText = `All ${Pluralizer(value.display)}`;
    } else {
      text = value.display;
      boldText = `(${value.selected.length})`;
    }
  }
  return (
    <Fragment>
      <SmallText style={{ margin: '3px 0' }} fontSize={14}>
        {text}
      </SmallText>
      {text && boldText && (
        <SmallText style={{ margin: '3px 0' }} fontSize={14} bold>
          &nbsp;
          {boldText}
        </SmallText>
      )}
      {!(text && boldText) && (
        <SmallText style={{ margin: '3px 0' }} fontSize={14} bold>
          {boldText}
        </SmallText>
      )}
    </Fragment>
  );
};
