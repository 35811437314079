import React, { useState, useEffect } from 'react';
import { gql } from '@apollo/client';
import { useLazyQuery } from '@apollo/react-hooks';
import { Modal } from 'reactstrap';
import { SubmitBtn } from '../buttons/ButtonStyles';
import AssignParsDetails from '../HousingUnitCreation/components/AssignParsDetails';
import { LoadingSpinner, ErrorToast } from '../Alerts';
import MuiAlert from '../Alerts/MuiAlert';
import SelectOneField from '../buttons/SelectOneField.jsx';
import { GlobalStyle, AssignmentModalCloseButton, AssignmentModalFooter } from './AssignParsModalStyles';

const GetParNameGender = gql`
  query GetParNameGender($id: ID!) {
    Participant(id: $id) {
      id
      name
      age
      gender {
        id
        name
      }
    }
  }
`;

export default function ParReasonsModal({
  participant,
  toggleModalOpen,
  modalOpen,
  handleSubmitReason,
  reasons,
  header,
  subHeader,
}) {
  const [getParInfo, { data, error, loading }] = useLazyQuery(GetParNameGender);
  const [par, setPar] = useState(null);
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  const [curReason, setCurReason] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    if (data?.Participant?.length) {
      setPar(data.Participant[0]);
    }
  }, [data, setPar]);

  useEffect(() => {
    if (participant) {
      getParInfo({
        variables: { id: participant?.id },
      });
    }
  }, [participant, getParInfo]);

  const onReasonChange = (id, value) => {
    setCurReason(value);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const onSubmitClick = async () => {
    if (curReason) {
      setIsFormSubmitted(true);
      try {
        await handleSubmitReason(participant?.id, curReason);
        setOpenAlert(false);
      } catch (error) {
        setIsFormSubmitted(false);
        console.error(error);
      }
      await toggleModalOpen();
    } else {
      setOpenAlert(true);
    }
  };

  return (
    <>
      <GlobalStyle padding={'40px'} />
      <Modal isOpen={modalOpen} backdropClassName="customDrop" className="parReasonsModal">
        <h3 style={{ fontSize: '20px', marginBottom: '20px' }}>
          {header}
          <AssignmentModalCloseButton color="clear" size="sm" onClick={toggleModalOpen}>
            X
          </AssignmentModalCloseButton>
        </h3>
        <div>
          {participant && loading && !error ? <LoadingSpinner isStatic={true} /> : <AssignParsDetails par={par} />}
          {participant && error && !loading && <ErrorToast width={true} />}
        </div>
        <h3 style={{ fontSize: '20px' }}>{subHeader}</h3>
        <SelectOneField
          id="par-reason"
          onChange={onReasonChange}
          selections={reasons}
          value={curReason}
          style={{ display: 'grid' }}
          $btnWidth="100%"
          $btnMargin="10px 0"
          $margin="0"
          $fontSize="small"
        />
        <AssignmentModalFooter>
          <SubmitBtn disabled={isFormSubmitted || error} onClick={onSubmitClick}>
            {'Submit'}
          </SubmitBtn>
        </AssignmentModalFooter>
        {openAlert && <MuiAlert msg={'Please select a reason.'} isOpen={true} handleCloseAlert={handleCloseAlert} />}
      </Modal>
    </>
  );
}
