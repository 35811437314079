import React from 'react';
import { SearchField } from '../SearchField.jsx';
import { Container } from 'reactstrap';
import AddButtonWithCB from '../buttons/AddButtonWithCB';
import { XLargeText } from '../../styles/text.js';

export const UserSectionHeader = ({ title, placeholder, reactiveVar, buttonText, handleAddUser }) => {
  return (
    <div className="user-section-header" style={{ paddingBottom: '35px' }}>
      <Container>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div style={{ marginRight: 'auto' }}>
            <XLargeText bold marginLeft={-16}>
              {title}
            </XLargeText>
          </div>
          <div style={{ width: '280px' }}>
            <SearchField content={placeholder} inputReactiveVariable={reactiveVar} />
          </div>
          <div style={{ paddingLeft: '25px', width: '240px' }}>
            <AddButtonWithCB content={buttonText} cb={handleAddUser} type={'newUser'} />
          </div>
        </div>
      </Container>
    </div>
  );
};
