import { gql } from '@apollo/client';

export const QueryNameImages = gql`
  query QueryNameImages($id: ID!) {
    HousingUnit(id: $id) {
      name
      SHOWS_rel(orderBy: order_asc) {
        Image {
          id
          url
          publicId
        }
        order
      }
    }
  }
`;
