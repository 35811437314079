import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { QueryHousingDescription, UpdateHousingUnitDescription } from './UnitDescriptionGQL';
import FormButton from '../../buttons/FormButton';
import { ClearButton } from '../../buttons/ButtonStyles.js';
import { FormNavBar } from '../../navbars/FormNavBar';
import UnitCompleteModal from '../../modals/UnitCompleteModal';
import DescriptionModal from '../../modals/DescriptionModal';
import { LoadingSpinner, ErrorToast } from '../../Alerts';
import useSlate from '../../useSlate';
import { isValueEmpty } from '../../useSlate';
import EmailRichParagraph from '../../Email/EmailRichParagraph';
import { useToggle } from '../../../utilities/hooks';
import { checkSectionsComplete } from '../../../utilities/isComplete';
import { Col } from 'reactstrap';
import { SmallText, MediumText } from '../../../styles/text';
import { rightInputStyle, LargeBullet } from './UnitDescriptionStyle';
import PropTypes from 'prop-types';

const initialSlateState = {
  placeholder: 'Add your description here.',
  initialState: [{ children: [{ text: '' }] }],
  richText: true,
  DefaultElement: EmailRichParagraph,
};

const recommendations = [
  'Neighborhood details',
  'Transit options nearby',
  'Walkability',
  'Features of unit',
  "Landlord's strictness on evictions",
  "Landlord's strictness on income",
];

//TODO: modal awaiting finalized example text and pics
function UnitDescription({ isUnitActive, completedSections, toggle, modal }) {
  const { houseUnitId } = useParams();
  const [descriptionModalOpen, toggleDescriptionModal] = useToggle(false);
  const [isError, setIsError] = useState(false);
  const history = useHistory();

  const { data, loading, error, refetch } = useQuery(QueryHousingDescription, {
    variables: { id: houseUnitId },
  });
  const [addDescription, { loading: loadingAdd, error: errorAdd }] = useMutation(UpdateHousingUnitDescription);

  const { value, setValue, Editor } = useSlate(initialSlateState);

  useEffect(() => {
    if (data) {
      const { HousingUnit } = data;
      if (data.HousingUnit) {
        const housingUnit = HousingUnit[0];
        if (housingUnit?.description) {
          const descriptionString = housingUnit.description;
          const descriptionRich = JSON.parse(descriptionString);
          setValue(descriptionRich);
        }
      }
    } else if (error) {
      setIsError(true);
    }
  }, [data]);

  if (loading || loadingAdd) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <ErrorToast />;
  }

  const onFormSubmit = async (close = false) => {
    setIsError(false);

    const saved = handleSave();
    if (saved) {
      const sectionsComplete = checkSectionsComplete('description', completedSections);
      if (close && sectionsComplete && !isUnitActive) {
        toggle();
      } else {
        close ? history.push('/housing/') : history.push(`/housing/edit/${houseUnitId}/unit-images`);
      }
    } else {
      setIsError(true);
    }
  };

  const handleSave = async () => {
    const valueToSubmit = JSON.stringify(value);
    const { data } = await addDescription({ variables: { id: houseUnitId, description: valueToSubmit } });
    if (data && refetch) {
      refetch();
      return true;
    } else if (errorAdd) {
      setIsError(true);
    }
    return false;
  };

  return (
    <div>
      <FormNavBar onClick={() => onFormSubmit(true)} />
      <Col md={{ size: 8, offset: 2 }}>
        <h2 className="form-title center-block text-center">UnitDescription</h2>
        <MediumText className="center-block text-center" bold fontSize={18} marginTop={31} marginBottom={10}>
          Lets get the participant excited with a great description!*
        </MediumText>
        <div style={{ margin: '0 auto 40px' }}>
          {/* ClearButton onClick={toggleDescriptionModal} style={{ margin: 'auto', display: 'block' }}>
              View Examples of Descriptions
          </ClearButton>
        */}
        </div>
        <MediumText bold fontSize={15} style={{ textAlign: 'center' }}>
          Recommended features to include:
        </MediumText>
        <div style={{ margin: '15px auto', width: '575px' }}>
          <ul style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
            {recommendations.map((recommendation, ix) => {
              return (
                <LargeBullet key={`recommendation-${ix}`} className="suggestion-bullet-list">
                  {recommendation}
                </LargeBullet>
              );
            })}
          </ul>
        </div>
      </Col>
      <Col md={{ size: 10, offset: 1 }}>
        <div style={rightInputStyle}>{Editor}</div>

        <div className="form-title center-block text-center" style={{ paddingTop: '35px' }}>
          <FormButton id="unit-description-button-submit" onClick={onFormSubmit}></FormButton>
        </div>
      </Col>
      <UnitCompleteModal toggle={toggle} modal={modal} />
      <DescriptionModal modalOpen={descriptionModalOpen} toggleModal={toggleDescriptionModal} />
    </div>
  );
}

UnitDescription.propTypes = {
  isUnitActive: PropTypes.bool,
  completedSections: PropTypes.shape({
    location: PropTypes.bool,
    description: PropTypes.bool,
    rooms: PropTypes.bool,
    acceptances: PropTypes.bool,
    accessibility: PropTypes.bool,
    viewing: PropTypes.bool,
  }).isRequired,
  toggle: PropTypes.func,
  modal: PropTypes.bool,
};

export default UnitDescription;
