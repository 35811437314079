import React, { useState, useEffect, useRef, useMemo } from 'react';
import useSlate, { isValueEmpty } from '../useSlate';
import { useToggle } from '../../utilities/hooks';
import EmailRichParagraph from './EmailRichParagraph';

const Email = function ({ onValueChange }) {
  const [showEditor, toggleShowEditor] = useToggle(false);

  useEffect(() => {}, [showEditor]);
  const { value, reset, Editor } = useSlate({
    placeholder: '',
    id: 'email-editor',
    onEmpty: () => {
      toggleShowEditor();
    },
    readOnly: false,
    initialState: [
      { type: 'paragraph', children: [{ text: 'Hi,' }] },
      { type: 'paragraph', children: [{ text: '' }] },
      {
        type: 'paragraph',
        children: [
          {
            text:
              'There is a new shared housing unit available that may be a good fit for your participant, based on their answers to the Shared Housing Questionnaire.',
          },
        ],
      },
      { type: 'paragraph', children: [{ text: '' }] },
      { type: 'paragraph', children: [{ text: 'Please follow the instructions below to pursue this opportunity.' }] },
      { type: 'paragraph', children: [{ text: '' }] },
      { type: 'paragraph', children: [{ text: 'Thanks!' }] },
    ],
    DefaultElement: EmailRichParagraph,
    richText: true,
  });
  useEffect(() => {
    if (value && onValueChange) {
      onValueChange(value);
    }
  }, [value, onValueChange]);

  return Editor;
};

export default Email;
