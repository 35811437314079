import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { CreateOrUpdateUser } from '../../views/AdminManageStaffGQL';
import { withStyles } from '@material-ui/core/styles';
import { adminStyles } from './AdminDashMUIStyles';
import { Modal } from 'reactstrap';
import { SubmitBtn } from '../buttons/ButtonStyles';
import { LoadingSpinner } from '../Alerts';
import MuiAlert from '../Alerts/MuiAlert';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { FAIconContainer } from '../icons/IconContainer';
import SelectMultiFieldWithCheckbox from '../buttons/SelectMultiFieldWithCheckbox';
import { GlobalStyle, AssignmentModalCloseButton, AssignmentModalFooter } from './AdminDashStyles';
import { userRoleSelectionOptions } from '../../constants/userRoleOptions';
import { userValidator } from '../../utilities/validation/userValidator.js';
import { useToggle } from '../../utilities/hooks/index';

const defaultOrgId = process.env.REACT_APP_DEFAULT_ORG_ID;

const initialValidationState = {
  name: { isInvalid: false, message: '' },
  email: { isInvalid: false, message: '' },
  isValid: false,
};

const ErrorAdornment = () => {
  return (
    <FAIconContainer
      className={'icon'}
      color={'white'}
      backgroundColor={'red'}
      iconName={'exclamation'}
      fontSize={'8px'}
      width={'15px'}
    />
  );
};

const UserModal = ({
  classes,
  user,
  isChangingStatus,
  orgId,
  toggleModalOpen,
  checkUniqueEmail,
  refetch,
  handleCreateOrUpdateUserSuccess,
  handleToastErrorMessage,
}) => {
  const [isAddingNew, setIsAddingNew] = useState(true);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [formUser, setFormUser] = useState({
    name: '',
    email: '',
    roles: [],
    orgId: orgId || defaultOrgId,
  });
  const [validationState, setValidationState] = useState(initialValidationState);
  const [alertIsOpen, toggleAlertIsOpen] = useToggle(false);
  const [isError, setIsError] = useState(false);

  const [createOrUpdateUser, { loading, error }] = useMutation(CreateOrUpdateUser);

  const createRoles = (labels) => {
    return labels?.filter((label) => {
      return !(label === 'Person' || label === 'UserAccount');
    });
  };

  useEffect(() => {
    if (user) {
      const roles = createRoles(user?.labels);
      const formUser = {
        name: user?.name,
        email: user?.email,
        orgId: user?.organization?.id || '',
        roles,
      };
      setFormUser(formUser);
      setIsAddingNew(false);
    } else {
      setIsAddingNew(true);
    }
  }, [user]);
  const handleTextInput = (e) => {
    const { name, value } = e.target;
    setFormUser({ ...formUser, [name]: value });
  };

  const handleRoleChange = (id, value) => {
    setIsError(false);
    setIsFormSubmitted(false);
    setFormUser({ ...formUser, roles: value });
  };

  const validateForm = () => {
    const { name, email } = formUser;
    const validation = userValidator.validate({ name, email });
    setValidationState(validation);
    const entryIsValid = validation.isValid && formUser?.roles?.length >= 1;
    if (isAddingNew) {
      const isUniqueCheck = checkNewUserEmailIsUnique(email);
      return entryIsValid && isUniqueCheck;
    } else if (isChangingStatus) {
      return entryIsValid;
    } else {
      return entryIsValid;
    }
  };

  const checkChangesMade = () => {
    const nameChanged = formUser?.name !== user?.name;
    const userRoles = createRoles(user?.labels) || [];
    const formUserRoles = formUser?.roles || [];
    const rolesChanged = !(
      userRoles.length === formUserRoles.length && userRoles.every((i) => formUserRoles.includes(i))
    );
    if (nameChanged || rolesChanged) {
      return true;
    } else if (isChangingStatus) {
      return true;
    } else {
      toggleAlertIsOpen();
      return false;
    }
  };

  const checkNewUserEmailIsUnique = (userEmail) => {
    const isUnique = checkUniqueEmail(userEmail);
    if (!isUnique) {
      setValidationState({
        ...validationState,
        email: { isInvalid: true, message: 'There seems to be a profile with that email address' },
      });
    }
    return isUnique;
  };
  const clearValidationError = (e) => {
    const { name } = e.target;
    setValidationState({ ...validationState, [name]: { isInvalid: false, message: '' } });
    setIsError(false);
    setIsFormSubmitted(false);
  };

  const handleSuccess = (data) => {
    if (data.CreateOrUpdateUser) {
      if (isAddingNew) {
        handleCreateOrUpdateUserSuccess(formUser, 'newUser');
      } else if (isChangingStatus) {
        handleCreateOrUpdateUserSuccess(formUser, 'reactivate');
      } else {
        handleCreateOrUpdateUserSuccess(formUser, 'updateUser');
      }
    } else {
      handleToastErrorMessage(data?.message);
    }
    toggleModalOpen(false);
    refetch();
  };

  const handleReqFailure = (err) => {
    setIsError(true);
    handleToastErrorMessage('An error occurred.');
    toggleModalOpen(false);
  };

  const sendCreateOrUpdateUser = async () => {
    try {
      const response = await createOrUpdateUser({ variables: formUser });
      setIsFormSubmitted(false);
      return response.data;
    } catch (error) {
      setIsFormSubmitted(false);
      setIsError(true);
      return error;
    }
  };

  const onSubmitClick = async () => {
    setIsFormSubmitted(true);
    const isValid = validateForm();
    const isChanged = checkChangesMade();
    if (isValid && isChanged) {
      sendCreateOrUpdateUser()
        .then((data) => handleSuccess(data))
        .catch((err) => handleReqFailure(err));
    } else {
      setIsError(true);
    }
  };

  return (
    <>
      <GlobalStyle padding={'40px'} />
      <Modal isOpen={true} backdropClassName="customDrop" className="add-edit-user-modal">
        <div>
          <div>
            <Typography className={classes.header}>
              {user ? 'Edit Staff' : 'Add Staff'}
              <AssignmentModalCloseButton color="clear" size="sm" onClick={() => toggleModalOpen(false)}>
                X
              </AssignmentModalCloseButton>
            </Typography>
          </div>
          <div style={{ margin: '25px 0', width: '375px' }}>
            <div style={{ marginBottom: '20px' }}>
              <Typography className={classes.label}>Full Name</Typography>
              <TextField
                className={classes.textField}
                name="name"
                id="name"
                onChange={handleTextInput}
                onFocus={clearValidationError}
                helperText={validationState.name.message}
                value={formUser.name}
                error={validationState.name.isInvalid}
                variant="outlined"
                InputProps={
                  validationState.name.isInvalid
                    ? {
                        endAdornment: <ErrorAdornment />,
                      }
                    : null
                }
              />
            </div>
            <div style={{ marginBottom: '20px' }}>
              <Typography className={classes.label}>Email Address</Typography>
              <TextField
                className={classes.textField}
                name="email"
                id="email"
                onChange={handleTextInput}
                onFocus={clearValidationError}
                helperText={validationState.email.message}
                value={formUser.email}
                error={validationState.email.isInvalid}
                disabled={!isAddingNew && !isChangingStatus}
                variant="outlined"
                InputProps={
                  validationState.email.isInvalid
                    ? {
                        endAdornment: <ErrorAdornment />,
                      }
                    : null
                }
              />
            </div>
          </div>
          <div>
            <Typography className={classes.directions}>Select Role</Typography>
            <Typography className={classes.subdirections}>Select all that apply</Typography>
          </div>
          <SelectMultiFieldWithCheckbox
            id="user-roles"
            onChange={handleRoleChange}
            selections={userRoleSelectionOptions}
            value={formUser.roles}
            style={{ display: 'grid' }}
          />
          {loading && !error && <LoadingSpinner isStatic={true} />}
          <AssignmentModalFooter>
            <SubmitBtn
              disabled={isFormSubmitted || loading || isError || !formUser?.roles?.length}
              onClick={onSubmitClick}
            >
              {isChangingStatus ? 'Reactivate' : 'Submit'}
            </SubmitBtn>
          </AssignmentModalFooter>
        </div>
        <MuiAlert isOpen={alertIsOpen} msg="No update has been made" handleCloseAlert={toggleAlertIsOpen} />
      </Modal>
    </>
  );
};

export default withStyles(adminStyles)(UserModal);
