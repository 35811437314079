import styled, { createGlobalStyle } from 'styled-components';
import { borders, typography } from '../../styles/constants';
import { SmallText } from '../../styles/text';
import { ModalFooter, Button } from 'reactstrap';

//User Table
export const UserRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  box-shadow: ${(props) => (props.clear ? 'none' : borders.boxShadow)};
  padding: 5px 15px 5px 20px;
  margin-bottom: 8px;
  border-radius: ${borders.borderRadius};
  max-width: '825px';
  background-color: ${(props) => {
    if (props.$opaque) {
      return '#FAFDFF';
    } else if (props.clear) {
      return 'transparent';
    } else {
      return '#FFFFFF';
    }
  }};
`;

export const HeaderSortDiv = styled.div`
  display: flex;
  align-items: baseline;
`;

export const HeaderSortText = styled(SmallText)`
  margin-right: 10px;
`;

export const UserCell = styled.div`
  padding: 0 10px;
`;

//User Modal
export const GlobalStyle = createGlobalStyle`
    .customDrop {
        background-color: #373a3c;
    }

    .modal-backdrop.show {
        opacity: 0.7;
    }

    .modal-content {
        padding: ${(props) => (props.padding ? props.padding : '10px')};
        width: ${(props) => props.width};
        align-items: ${(props) => (props.width == '665px' ? 'center' : '')};
    }

    .modal-dialog {
        display: ${(props) => (props.width == '665px' ? 'flex' : '')};
        flex-direction: column;
        align-items: center;
        max-width: ${(props) => (props.width == '665px' ? '665px' : '500px')};
    }
`;

export const AssignmentModalFooter = styled(ModalFooter)`
  border: none;
`;

export const AssignmentModalCloseButton = styled(Button)`
  font-family: ${typography.fontFamily.close};
  background: clear;
  border: none;
  outline: none !important;
  color: ${typography.fontColor.link};
  font-size: ${typography.fontSize.lgtext};
  font-weight: bold;
  position: absolute;
  top: 15px;
  right: 15px;
`;
