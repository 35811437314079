export const makePetStr = (pets) => {
  return pets?.reduce((acc, { species, size }, ix) => {
    const animal = size ? `${size} ${species}` : species;
    return (acc += ix === 0 ? animal : `, ${animal}`);
  }, '');
};

export const makeRentRangeStr = (rents) => {
  const min = Math.min(...rents).toLocaleString();
  const max = Math.max(...rents).toLocaleString();
  return min !== max ? `$${min}-${max}/mo` : `$${min}/mo`;
};
