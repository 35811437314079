import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import {
  UnassignParticipant,
  MoveOutParticipant,
  GetParInfo,
  SetMoveinDate,
  UpdateMoveInDate,
} from '../panels/EditParStatusGQL';
import { TextField } from '@material-ui/core';
import AssignParsDetails from '../HousingUnitCreation/components/AssignParsDetails';
import SelectOneField from '../buttons/SelectOneField.jsx';
import { ParEditButton } from './ParReasonsModalStyles.js';
import { LoadingSpinner, ErrorToast } from '../Alerts';
import { Modal } from 'reactstrap';
import { SubmitBtn } from '../buttons/ButtonStyles';
import { GlobalStyle, AssignmentModalCloseButton, AssignmentModalFooter } from './AssignParsModalStyles';
import { unassignSelectionOptions, moveOutSelectionOptions } from '../../constants/reassignOptions';
import { createNeo4jDateTime, convertToNeo4jDateTime } from '../../utilities/datetimeHelpers';

export default function EditParModal({ participant, toggleModalOpen, houseUnitId, refetchHuData }) {
  const today = moment().format('YYYY-MM-DD');
  const [getParInfo, { data, loading: parLoading, error: parError }] = useLazyQuery(GetParInfo);
  const [setParsMoveinDate, { loading: moveInLoading, error: moveInError }] = useMutation(SetMoveinDate);
  const [updateMoveInDate, { loading: upMoveInLoading, error: upMoveInError }] = useMutation(UpdateMoveInDate);
  const [unassignParticipant, { loading: unassignLoading, error: unassignError }] = useMutation(UnassignParticipant);
  const [moveOutParticipant, { loading: moveOutLoading, error: moveOutError }] = useMutation(MoveOutParticipant);
  const [par, setPar] = useState(null);
  const [isMovedIn, setIsMovedIn] = useState(false);
  const [curBody, setCurBody] = useState('menu');
  const [curReason, setCurReason] = useState(null);
  const [moveInDate, setMoveInDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (data?.Participant?.length) {
      const par = data.Participant[0];
      setPar(par);

      const movedIntoThisHU = par?.movedIntoHousingUnits?.[0]?.id === houseUnitId;
      setIsMovedIn(movedIntoThisHU);
    }
  }, [data, setPar, setIsMovedIn]);

  useEffect(() => {
    if (participant) {
      getParInfo({
        variables: { id: participant?.id },
      });
    }
  }, [participant, getParInfo]);

  useEffect(() => {
    if (parError || moveInError || unassignError || moveOutError || upMoveInError) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [parError, moveInError, unassignError, moveOutError, upMoveInError]);

  useEffect(() => {
    if (parLoading || moveInLoading || unassignLoading || moveOutLoading || upMoveInLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [parLoading, moveInLoading, unassignLoading, moveOutLoading, upMoveInLoading]);

  const handleReasonChange = (id, value) => {
    setCurReason(value);
  };

  const handleDateChange = (e) => {
    setMoveInDate(moment(e.target.value));
  };

  //*SELECT PAR ACTIONS
  const handleSelectReason = () => {
    setCurBody('reason');
  };

  const handleSelectMoveInDate = () => {
    setCurBody('moveIn');
  };

  //* EDIT PAR MUTATIONS
  const submitMoveOutReason = async () => {
    if (curReason) {
      const date = createNeo4jDateTime();
      try {
        const { data } = await moveOutParticipant({
          variables: {
            hu: houseUnitId,
            par: participant?.id,
            moveoutReason: curReason,
            date,
          },
        });
        if (data && curReason !== 'NEVER_MOVED_IN') {
          submitUnassignReason(true);
        } else if (data) {
          toggleModalOpen();
          refetchHuData?.();
        } else {
          setIsError(true);
        }
      } catch (error) {
        setIsError(true);
      }
    }
  };

  const submitUnassignReason = async (isMovingOut = false) => {
    if (isMovingOut || curReason) {
      const reason = isMovingOut ? 'MOVED_OUT' : curReason;
      try {
        const { data } = await unassignParticipant({
          variables: {
            hu: houseUnitId,
            par: participant?.id,
            reason,
          },
        });
        if (data) {
          toggleModalOpen();
          refetchHuData?.();
        } else {
          setIsError(true);
        }
      } catch (error) {
        setIsError(true);
      }
    }
  };

  const submitMoveInDate = async () => {
    const { hour, minute, second, timezone } = createNeo4jDateTime();
    try {
      const moveInDateObj = convertToNeo4jDateTime(moveInDate);
      const { data } = await setParsMoveinDate({
        variables: {
          participants: [participant?.id],
          housingUnit: houseUnitId,
          date: moveInDateObj,
        },
      });
      if (data) {
        toggleModalOpen();
        refetchHuData?.();
      } else {
        setIsError(true);
      }
    } catch (error) {
      setIsError(true);
    }
  };

  const submitUpdateMoveInDate = async () => {
    try {
      const moveInDateObj = convertToNeo4jDateTime(moveInDate);
      const { data } = await updateMoveInDate({
        variables: {
          participant: participant?.id,
          housingUnit: houseUnitId,
          data: { date: moveInDateObj },
        },
      });
      if (data) {
        toggleModalOpen();
        refetchHuData?.();
      } else {
        setIsError(true);
      }
    } catch (error) {
      setIsError(true);
    }
  };

  // //* MODAL BODIES: 'menu', 'reason', 'moveIn'
  const selectReasonBody = (
    <>
      <h3 style={{ fontSize: '20px' }}>Reason for Removing</h3>
      <SelectOneField
        id="par-reason"
        onChange={handleReasonChange}
        selections={isMovedIn ? moveOutSelectionOptions : unassignSelectionOptions}
        value={curReason}
        style={{ display: 'grid' }}
        $btnWidth="100%"
        $btnMargin="10px 0"
        $margin="0"
      />
      <AssignmentModalFooter>
        <SubmitBtn
          disabled={isLoading || isError || !curReason}
          onClick={isMovedIn ? submitMoveOutReason : submitUnassignReason}
        >
          {'Submit'}
        </SubmitBtn>
      </AssignmentModalFooter>
    </>
  );

  const selectMoveInBody = (
    <>
      <h3 style={{ fontSize: '20px' }}>{isMovedIn ? 'Update Move-in Date' : 'Move-in Date'}</h3>
      <div style={{ marginBottom: '20px' }}>
        <TextField
          value={moveInDate?.format('yyy-MM-DD')}
          onChange={handleDateChange}
          inputProps={{ max: today }}
          required={true}
          type="date"
        />
      </div>
      <AssignmentModalFooter>
        <SubmitBtn
          disabled={isLoading || isError || !moveInDate}
          onClick={isMovedIn ? submitUpdateMoveInDate : submitMoveInDate}
        >
          {'Submit'}
        </SubmitBtn>
      </AssignmentModalFooter>
    </>
  );

  const statusChangeBody = (
    <>
      <h3 id="statusChangeBody" style={{ fontSize: '20px' }}>
        Status Change
      </h3>
      <div>
        <ParEditButton onClick={handleSelectReason}>
          {isMovedIn ? 'Participant moved out of unit' : 'Unassign from unit'}
        </ParEditButton>
        <ParEditButton onClick={handleSelectMoveInDate}>
          {isMovedIn ? 'Change Move In Date' : 'Participant moved into unit'}
        </ParEditButton>
      </div>
    </>
  );

  return (
    <Modal isOpen={true} backdropClassName="customDrop">
      <GlobalStyle padding={'40px'} />
      <AssignmentModalCloseButton color="clear" size="sm" onClick={toggleModalOpen}>
        X
      </AssignmentModalCloseButton>
      <h4 style={{ textAlign: 'center', marginBottom: '31px' }}>Edit Participant Status</h4>
      <h6 style={{ fontWeight: 'bold' }}>Participant</h6>
      <div>
        {par && <AssignParsDetails par={par} />}
        {isLoading && <LoadingSpinner isStatic={true} />}
        {isError && <ErrorToast width={true} />}
      </div>
      {curBody === 'menu' && statusChangeBody}
      {curBody === 'moveIn' && selectMoveInBody}
      {curBody === 'reason' && selectReasonBody}
    </Modal>
  );
}
