export default {
  border: '1px solid #A5C9FF',
  buttonBorder: '1px solid #5BB8B4',
  panelBorder: '1px solid #D8D8D8',
  boxShadow: '0px 3px 16px #0A4B6D14',
  borderRadius: '7px',
  panelBorderRadius: '7px',
  headerMargin: '0px 0px -16px 0px',
  rowMargin: '0px 0px 16px 0px',
  borderDisabled: '1px dashed #939393',
  borderEmpty: '1px dashed #939393',
  filterToggleUnselectedBorder: '1px solid #E0E0E0',
  filterToggleSelectedBorder: '1px solid #5BB8B4',
  filterMenuBorder: '1px solid #C2C2C280',
  filterMenuBoxShadow: '0px 3px 20px #00000022',
  errorBorder: '1px solid #fa8181',
};
