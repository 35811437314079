import styled from 'styled-components';
import {
  MuiModalTemplate,
  MuiBackdropTemplate,
  MuiModalHeader,
  MuiModalCloseButtonTemplate,
  ModalButtonGroup,
} from './ModalStyles';

export const PreventActionMuiModal = styled(MuiModalTemplate)``;
export const PreventActionMuiBackdrop = styled(MuiBackdropTemplate)``;
export const PreventActionMuiHeader = styled(MuiModalHeader)``;
export const PreventActionMsg = styled.p`
  ${(props) => (props.$bold ? 'font-weight: bold;' : null)}
  ${(props) => (props.$fontSize ? `font-size: ${props.$fontSize};` : null)}
`;
export const ModalBodyContainer = styled.div`
  position: relative;
  width: 100vw;
  max-width: 650px;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-itmes: center;
  text-align: center;
  padding: 30px;
  &:focus-visible {
    outline: 0 !important;
  }
`;

export const PreventActionModalButtonGroup = styled(ModalButtonGroup)``;

export const ModalCloseButton = styled(MuiModalCloseButtonTemplate)`
  top: 0;
  right: 5px;
`;
