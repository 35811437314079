import React from 'react';

import { Row, Col } from 'reactstrap';
import { sumArray } from '../../utilities/arrayOperations';
import { ListGroupRow, PanelContainer, LinkItem, HeaderLeftCol, ValueRightCol, ItemRow } from './HousingStyles';
import { LargeText } from '../../styles/text';
import { physicalAccessibilityOptionsDict } from '../HousingUnitCreation/sections/AccessibilityByDegreesDict';
import PropTypes from 'prop-types';

function HousingMoreDetailsPanel({ housingUnit }) {
  if (!housingUnit) {
    // TODO: Probably need an error here
    return <div>NO UNIT</div>;
  }
  const {
    bedrooms,
    totalBedrooms,
    totalBathrooms,
    utilities,
    accepts,
    allows,
    region,
    housingUnitAmenities,
    accessibilitiesWithDegree,
    allowsIndoorSmoking,
  } = housingUnit;

  const totalSqFeet = sumArray(bedrooms?.map((b) => b?.squareFeet) || []);

  const getUniqueStr = (arr) => {
    const uniqueTypes = new Set(arr.map((u) => u?.type));
    return [...uniqueTypes].join(', ');
  };

  return (
    <>
      <ListGroupRow style={{ background: 'white' }}>
        <Col md="12">
          <PanelContainer>
            <Row>
              <Col md="10">
                <LargeText bold marginBottom={20}>
                  More Details
                </LargeText>
              </Col>
              <Col className="text-right">
                <LinkItem href={`/housing/edit/${housingUnit.id}/location`}>Edit Unit</LinkItem>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <ItemRow>
                  <HeaderLeftCol md="5">Rooms</HeaderLeftCol>
                  <ValueRightCol md="7">
                    {totalBedrooms} bed, {totalBathrooms} bath
                  </ValueRightCol>
                </ItemRow>
                <ItemRow>
                  <HeaderLeftCol md="5">Monthly Rent/Per Room</HeaderLeftCol>
                  <ValueRightCol md="7">{bedrooms?.map((b) => b?.rent)?.join('/')}</ValueRightCol>
                </ItemRow>
                <ItemRow>
                  <HeaderLeftCol md="5">Square Feet</HeaderLeftCol>
                  <ValueRightCol md="7">{totalSqFeet} sq ft</ValueRightCol>
                </ItemRow>
                <ItemRow>
                  <HeaderLeftCol md="5">Region</HeaderLeftCol>
                  <ValueRightCol md="7">{region?.name}</ValueRightCol>
                </ItemRow>
                <ItemRow>
                  <HeaderLeftCol md="5">Utilities Included</HeaderLeftCol>
                  <ValueRightCol md="7">{getUniqueStr(utilities)}</ValueRightCol>
                </ItemRow>
                <ItemRow>
                  <HeaderLeftCol md="5">Amenities</HeaderLeftCol>
                  <ValueRightCol md="7">{getUniqueStr(housingUnitAmenities)}</ValueRightCol>
                </ItemRow>
              </Col>
              <Col>
                <ItemRow>
                  <HeaderLeftCol md="5">ADA Accessible</HeaderLeftCol>
                  <ValueRightCol md="7">
                    {accessibilitiesWithDegree?.length
                      ? physicalAccessibilityOptionsDict[accessibilitiesWithDegree?.[0]?.degree]?.['HousingUnit']
                      : 'More than 1 flight'}
                  </ValueRightCol>
                </ItemRow>
                <ItemRow>
                  <HeaderLeftCol md="5">Accepted Vouchers</HeaderLeftCol>
                  <ValueRightCol md="7">
                    {accepts?.length
                      ? accepts
                          ?.map((a) => {
                            return a?.type;
                          })
                          .join(', ')
                      : 'No Vouchers Accepted'}
                  </ValueRightCol>
                </ItemRow>
                <ItemRow>
                  <HeaderLeftCol md="5">Pets</HeaderLeftCol>
                  <ValueRightCol md="7">
                    {allows?.length
                      ? allows?.map((a) => (a?.size ? `${a?.size} ${a?.species}` : a?.species)).join(',·')
                      : 'No Pets Allowed'}
                  </ValueRightCol>
                </ItemRow>
                <ItemRow>
                  <HeaderLeftCol md="5">Indoor Smoking</HeaderLeftCol>
                  <ValueRightCol md="7">{allowsIndoorSmoking ? 'Allowed' : 'Not Allowed'}</ValueRightCol>
                </ItemRow>
              </Col>
            </Row>
          </PanelContainer>
        </Col>
      </ListGroupRow>
    </>
  );
}

HousingMoreDetailsPanel.propTypes = {
  housingUnit: PropTypes.shape({
    bedrooms: PropTypes.array,
    totalBedrooms: PropTypes.number,
    totalBathrooms: PropTypes.number,
    utilities: PropTypes.array,
    accepts: PropTypes.array,
    allows: PropTypes.array,
    region: PropTypes.object,
    allowsIndoorSmoking: PropTypes.bool,
  }),
};

export default HousingMoreDetailsPanel;
