import React from 'react';
import { typography } from '../../styles/constants';
import { MediumLargeText } from '../../styles/text';

export default function EmailRichParagraph({ attributes, children, element }) {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return (
        <ul style={{ fontSize: typography.fontSize.xsmtitle }} {...attributes}>
          {children}
        </ul>
      );
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;
    case 'list-item':
      return (
        <li style={{ marginBottom: '16px', paddingLeft: '10px' }}>
          <MediumLargeText inline color={typography.fontColor.text} {...attributes}>
            {children}
          </MediumLargeText>
        </li>
      );
    case 'numbered-list':
      return (
        <ol style={{ fontSize: typography.fontSize.xsmtitle }} {...attributes}>
          {children}
        </ol>
      );
    default:
      return (
        <MediumLargeText marginBottom={16} lineHeight={'28px'} color={typography.fontColor.text} {...attributes}>
          {children}
        </MediumLargeText>
      );
  }
}
