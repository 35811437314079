import styled from 'styled-components';
import { typography } from '../../styles/constants';
import { Modal } from 'reactstrap';

export const DescModal = styled(Modal)`
  .modal-title {
    margin: 0 auto;
    font-size: ${typography.fontSize.title};
  }

  .modal-backdrop {
    background-color: #373a3c;
  }

  .modal-backdrop.show {
    opacity: 0.7;
  }

  .modal-content {
    padding: 40px;
    color: #06446a;
    border-radius: 8px;
  }
`;
