import styled, { createGlobalStyle } from 'styled-components';
import { Text } from '../../../styles/text';
import { borders, typography, colors } from '../../../styles/constants';
import FontAwesome from 'react-fontawesome';

export const GlobalStyle = createGlobalStyle`
div.show {
  display: revert;
}

 button.show {
   display: flex;
 }

div.hide, button.hide {
  display: none;
}

#viewing-options-panel > div > span > span > span:nth-child(1) {
  width: 100% !important;
  white-space: unset !important;
}
`;
export const ViewingSectionLabel = styled.h3`
  position: relative;
  font-size: ${typography.fontSize.smtitle};
  color: ${typography.fontColor.text};
  margin-bottom: 40px;
`;
export const HoldDeadlineLabel = styled(ViewingSectionLabel)`
  margin-bottom: 0px;
  &:after {
    display: none;
  }
`;

const PanelTemplate = styled.div`
  background: white;
  border: ${borders.border};
  border-radius: ${borders.borderRadius};
`;

export const ViewingPanel = styled(PanelTemplate)`
  width: 565px;
  min-height: 232px;
  display: grid;
  grid-template-rows: auto auto;
  padding: 20px 42px 10px 15px;
`;

export const PanelButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const FormArea = styled.div``;
export const PanelTextAreaGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  .viewing-editor {
    width: 505px;
    min-height: 105px;
    padding: 5px;
    border: ${borders.border};
    border-radius: ${borders.borderRadius};
    overflow: auto;
  }
`;
export const LabelGroup = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.$flexColumn ? 'column' : 'row')};
  width: 100%;
  padding: 5px;
`;

const Label = styled(Text)`
  font-size: ${(props) => props.fontSize || typography.fontSize.mdtext};
`;

export const InputLabel = styled(Label)`
  color: #404040;
`;

export const HelperText = styled(Label)`
  color: #939393;
`;

export const HeaderLabelGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const HeaderLabelIcon = styled.img`
  width: ${typography.fontSize.smtext};
  height: ${typography.fontSize.smtext};
  margin-right: 5px;
  &.color {
    filter: invert(80%) sepia(7%) saturate(1598%) hue-rotate(128deg) brightness(160%) contrast(91%);
  }
`;
export const HeaderLabel = styled(Label)`
  font-size: ${typography.fontSize.smtext};
`;

export const PanelTextArea = styled.textarea`
  width: 505px;
  height: 105px;
  padding: 5px;
  border: ${borders.border};
  border-radius: ${borders.borderRadius};
`;

export const ViewingNoteCard = styled(PanelTemplate)`
  display: grid;
  grid-template-rows: auto auto;
  padding: 24px;
  margin: 10px auto;
`;

export const ViewingNoteCardHeader = styled.header``;

export const CardDetails = styled.div`
  display: grid;
  align-items: center;
  padding-right: 15px;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.colNum || '1'}, ${(props) => props.colWidth || 'auto'});
  grid-template-rows: repeat(${(props) => props.rowNum || '1'}, ${(props) => props.rowWidth || 'auto'});
  align-content: center;
  margin-top: 15px;
`;

const PanelButton = styled.button`
  background-color: transparent;
  outline: none;
  border: none;
  font-size: ${(props) => (props.type === 'panel' ? '14px' : props.type === 'card' ? '15px' : 'inherit')};
  &.withIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const EditButton = styled(PanelButton)`
  color: #5bb8b4;
`;
export const DeleteButton = styled(PanelButton)`
  color: #939393;
`;

export const AddTourButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  outline: none;
`;

export const FontAwesomePlus = styled(FontAwesome)`
  box-shadow: 0px 3px 6px #31a3a54e;
  height: 27px;
  width: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 100%;
  background-color: #5bb8b4;
  color: white;
  border: none;
  margin-right: 10px;
`;

export const HoldDeadlineContainer = styled.div`
  margin-top: 60px;

  & .hold-deadline input[type='date'] {
    text-transform: uppercase;
    color: ${colors.grayText};
    font-size: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    min-width: 220px;
    min-height: 56px;
    padding: 10px 20px;
    background-color: #fff;
  }

  & .MuiInput-underline:before,
  & .MuiInput-underline:after {
    border-bottom: none;
  }

  & .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none;
  }

  .MuiTextField-root::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
`;
